import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpProgressEvent} from '@angular/common/http';
import {Helper, IResponseListObject, IResponseBodyObject} from '../_helpers/api.helpers';
import {iif, Observable, of as observableOf, throwError} from 'rxjs';
import {delay, map, retryWhen, take, tap, concatMap} from 'rxjs/operators';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../auth/authentication.service';
import {Audio} from './audio';
import {FileElement} from '../file-explorer/model/element';
import {JsonAppConfigService} from '../config/json-app-config.service';
const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})

export class AudioSystemService {
  constructor(
    public api: HttpClient,
    public notifyService: NotifyService,
    public router: Router,
    public route: ActivatedRoute,
    public auth: AuthenticationService,
    private AppConfig: JsonAppConfigService
  ) {
  }


  move(params) {
    const requestBody = Helper.requestFormat('move', 'SystemAudio', params, false);
    return this.api.post<IResponseBodyObject<Audio>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notifyService.checkCode(res);
        }),
        map(data => {
            return <Audio> data.body;
          }
        )
      );
  }

  changeObject() {}

  getDomain() {
    return null;
  }

  list(params: any = {}): Observable<IResponseListObject<Audio>> {
    const requestBody = Helper.requestFormat('list', 'SystemAudio', params);
    return this.api.post<IResponseListObject<Audio>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notifyService.checkCode(res);
        }),
        retryWhen(errors =>
          errors.pipe(
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => {return (i == 2) || e.message == '403'},
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                observableOf(e).pipe(delay(3000))
              ))
          )),
      );
  }

  detailsByIds(list: any[]): Observable<any> {
    const params = {list: list};
    const requestBody = Helper.requestFormat('detail_by_ids', 'SystemAudio', params);
    return this.api.post<any>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notifyService.checkCode(res);
        }),
       map(data=> data.list)
      );
  }

  delete(id: number): Observable<IResponseBodyObject<Audio>>  {
    const obj = {id: id};
    const requestBody = Helper.requestFormat('delete', 'SystemAudio', obj);
    return this.api.post<IResponseBodyObject<Audio>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  rename(params) {
    const requestBody = Helper.requestFormat('rename', 'SystemAudio', params);
    return this.api.post<IResponseBodyObject<Audio>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notifyService.checkCode(res);
        }),
        map(data => {
            return <Audio> data.body;
          }
        )
      );
  }

  upload(form: FormData)  {
    return this.api.post<IResponseBodyObject<Audio>>(
      this.AppConfig.getValue('apiUrl'), form, {reportProgress: true, observe: 'body'}
    ).pipe(
      tap((res) => {
        this.notifyService.checkCode(res);
      })
    );
  }

  explorer(params: any = {}): Observable<FileElement[]> {
    const requestBody = Helper.requestFormat('explorer', 'SystemAudio', params);
    return this.api.post<IResponseListObject<FileElement>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notifyService.checkCode(res);
        }),
        map(data => {
            return <FileElement[]> data.list;
          }
        ),
        // retryWhen(errors => errors.pipe(delay(3000), take(3)))
        retryWhen(errors =>
          errors.pipe(// delay(3000), take(3)
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => {return (i == 2) || e.message == "403"},
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                observableOf(e).pipe(delay(3000))
              ))
          ))
      );
  }

  calcProgressPercent(event: HttpProgressEvent) {
    return Math.round(100 * event.loaded / event.total);
  }

  action(action, params: any = {}): Observable<IResponseBodyObject<Audio>> {
    const requestBody = Helper.requestFormat(action, 'SystemAudio', params);
    return this.api.post<IResponseBodyObject<Audio>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => {
          return data;
        })
      );
  }
}
