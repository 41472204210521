import { Injectable } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../auth/authentication.service';

@Injectable()
export class NotifyService {
  snackBarRef: MatSnackBarRef<any>;
  snackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    duration: 2000,
    panelClass: 'glam-snackbar'
  };

  constructor(private snackBar: MatSnackBar, public translate: TranslateService,
              private router: Router, public auth: AuthenticationService) {
  }

  message(message, arg={}) {
    this.snackBarRef = this.snackBar.open(this.translate.instant(message, arg), '', this.snackBarConfig);
  }

  pure_message(message, config: MatSnackBarConfig = {}, action = ''){
    this.snackBarRef = this.snackBar.open(message, action, Object.assign(this.snackBarConfig, config));
  }

  messageByCode(code) {
    const message = `NOTIFY.${code}`;
    const messageError = `ERROR.${code}`;
    if (this.hasTranslation(message)) {
      this.snackBarRef = this.snackBar.open(this.translate.instant(message), '', this.snackBarConfig);
    } else if (this.hasTranslation(messageError)) {
        this.snackBarRef = this.snackBar.open(this.translate.instant(messageError), '', this.snackBarConfig);
    } else { this.snackBarRef = this.snackBar.open(this.translate.instant('NOTIFY.UNKNOW', code), '', this.snackBarConfig); }
  }

  hasTranslation(key: string): boolean {
    return this.translate.instant(key) !== key;
  }

  refresh(): void {
    window.location.reload();
  }

  showRequestResult(res, need_show_ok: boolean = false, important_msg: string = '', config: MatSnackBarConfig = {}){
    // отображаю все предупреждения, если они есть, и только при удачном завершении запроса (код=200),
    // иначе предупреждения и ошибки отобразятся в блоке обработки ошибок

    let msgs_text = [];

    if (important_msg) msgs_text.push(important_msg); // важное сообщение, которое надо показать в начале уведомления

    if (res && res.constructor === Object && res.code == 200 && 'notifies' in res && res['notifies'].length) {
      for (let err of res.notifies) {
        switch (err['msg_id']) {
          case 100:
            let err_text;
            if (err.text.startsWith('update or delete on table "user" violates foreign key constraint') && err.obj == 'User') {
              err_text = this.translate.instant('ERROR.TO_DELETE_USER');
            } else if (err.text == 'Got ambiguous input for polymorphic field' && res.obj == 'DomainDialPlan') {
              err_text = this.translate.instant('DP.NO_ROUTE_TYPE_DATA_OR_EMPTY_ROUTE');
            } else {
              err_text = err.text && this.hasTranslation((err.text || '').toUpperCase()) ? this.translate.instant((err.text || '').toUpperCase()) : err.text || '';
            }
            msgs_text.push(this.translate.instant((err['obj'] != 'NA' ? err['obj'] : res['obj'] || '').toUpperCase()) + ': ' + err_text);
            break;
          case 10000:
          case 10001:
          case 10002:
            let obj = this.translate.instant((err['obj'] || '').toUpperCase());
            let not_found_text = this.translate.instant('ERROR.10002_1', {arg1: obj});
            if (err['obj'] == 'DomainAudio') {
              not_found_text += ' (' +
                this.translate.instant('DOMAIN_LOWER_CASE') + ' #' + err['filter']['owner_id'].toString() + ', ' +
                this.translate.instant('FILE_LOWER_CASE') + ' #' + err['filter']['file_id'].toString()
                + ')';
            }
            msgs_text.push(not_found_text);
            break;
          case 10011:
            let dis_obj_name = this.translate.instant((err['obj'] || '').toUpperCase());
            msgs_text.push(this.translate.instant('ERROR.10011_1', {arg1: dis_obj_name}));
            break;
          default:
            msgs_text.push(JSON.stringify(err));
        }
      }
    }
    if (need_show_ok) msgs_text.unshift(this.translate.instant('NOTIFY.200')); // отображаю, что запрос выполнен успешно
    if (msgs_text.length > 1 && !('duration' in config)) config.duration = 3000;
    if (msgs_text.length > 0) this.pure_message(msgs_text.join('\n'), config);
  }

  setDPErrors(resp: any, err: any, errors: any, form){
    for (let dp_key in errors) {
      if (dp_key == 'leg' || dp_key == 'default_leg' || dp_key == 'legs' || !isNaN(parseInt(dp_key, 10))) {
        this.setDPErrors(resp, err, errors[dp_key], form.get(dp_key));
      } else {
        let resp_data = {
          action: resp.action,
          action_id: resp.action_id,
          code: resp.code,
          obj: resp.obj,
          notifies: [{
            type: err.type,
            calling_source: err.calling_source,
            obj: err.obj,
            msg_id: err.msg_id,
            error: {}
          }]
        };
        if ('value' in errors) resp_data.notifies[0].error['value'] = errors['value'];
        if ('dpe_params' in errors) Object.assign(resp_data.notifies[0].error, errors['dpe_params']);
        this.setFormErrors(resp_data, form);
      }
    }
  }

  setFormErrors(resp, form?: FormGroup, navigate?:string){
    if (resp.code == 502 || resp == 'Unknown Error' || resp == 'Bad Gateway' || resp.constructor === String) {
      return 502;
    } else {
      let f_control, msg_text = '', msgs = [], duration = 2000, need_navigate = false;

      for (let err of resp.notifies) {
        msg_text = '';
        // if (err.type == 3) {
        switch (err['msg_id']) {
          case 10003:
            let no_uniq_fields = err.fields.split(', ').map(f => f.trim());
            let no_uniq_values = err.values.split(', ');
            let message = err['obj'] == 'DomainConference' ? 'ERROR.CONFERENCE_UNIQUE' : 'ERROR.UNIQUE';
            if (form) {
              for (let no_uniq_ind in no_uniq_fields) {
                let no_uniq_field = no_uniq_fields[no_uniq_ind];
                let no_uniq_value = no_uniq_values[no_uniq_ind];
                if (no_uniq_field != 'domain_id') {
                  let control_names = no_uniq_field.split('.');
                  f_control = form.get(control_names[0]);
                  if (f_control) {
                    f_control.markAsTouched();

                    if (control_names.length == 2) {
                      if (f_control.value.constructor === Array) {
                        for (let f_control_control of f_control['controls']) {
                          if (f_control_control.get(control_names[1]).value == no_uniq_value) {
                            f_control_control.get(control_names[1]).markAsTouched();
                            f_control_control.get(control_names[1]).setErrors({'unique': {message: message}}, {emitEvent: true, onlySelf: false});
                          }
                        }
                      } else {
                        f_control.get(control_names[1]).markAsTouched();
                        f_control.get(control_names[1]).setErrors({'unique': {message: message}}, {emitEvent: true, onlySelf: false});
                      }
                    } else {
                      if (form['model'] && form['model'].className && form['model'].className() == 'DomainGateway' && control_names[0] == 'name' && f_control.value != no_uniq_value) {
                        message = this.translate.instant('ERROR.ROUTE_NAME_ALREADY_EXISTS', {name: no_uniq_value});
                      }
                      f_control.setErrors({'unique': {message: message}}, {emitEvent: true, onlySelf: false});
                    }
                  } else {
                    msgs.push(err.fields+' ('+err.values+') - '+this.translate.instant(message));
                  }
                }
              }
            } else {
              if (!err['obj'] && (resp['obj'] == 'DomainAudio' || resp['obj'] == 'SystemAudio')) {
                msgs.push(this.translate.instant('ERROR.FILE_ALREADY_EXISTS', {file: err.values.split(', ')[1]}));
              } else {
                msgs.push(err.fields+' ('+err.values+') - '+this.translate.instant(message));
              }
            }
            break;
          case 10005:
            if ('text_error' in err && err.text_error && !err.error) {
              msgs.push(err.text_error);
            } else if (resp.obj == 'DomainDialPlan' && 'body' in err.error) {
              this.setDPErrors(resp, err, err.error.body, form.get('body')); // сразу боди, т.к. с него строится маршрут
            } else {
              let dp_form_errors = {};
              for (let field in err.error) {
                if (field != 'domain_id') {
                  if (form) {
                    f_control = form.get(field);
                    if (f_control) {
                      f_control.markAsTouched();
                      for (let err_code of err.error[field]) {
                        if (err_code == 2) f_control.setErrors({'required': {message: 'ERROR.REQUIRED'}}, {
                          emitEvent: true,
                          onlySelf: false
                        });
                        else f_control.setErrors({'unknown': {message: 'ERROR.10005.' + err_code}}, {emitEvent: true, onlySelf: false});
                      }
                    } else {
                      try {
                        for (let err_code of err.error[field]) {
                          msgs.push(field + ' - ' + this.translate.instant('ERROR.10005.' + err_code));
                        }
                        dp_form_errors[field] = {
                          message: (this.hasTranslation(field) ? this.translate.instant(field) : field) +
                            ' - ' +
                            err.error[field].map(e=>this.translate.instant(('ERROR.10005.'+e))).join(', ')
                        };
                      } catch (e) {
                        msgs.push(field + ' - ' + this.translate.instant('ERROR.10005.' + err.error[field]));
                      }
                    }
                  } else {
                    try {
                      for (let err_code of err.error[field]) {
                        msgs.push(field + ' - ' + this.translate.instant('ERROR.10005.' + err_code));
                      }
                    } catch (e) {
                      msgs.push(field + ' - ' + this.translate.instant('ERROR.10005.' + err.error[field]));
                    }
                  }
                }
              }

              if (err.obj == 'DomainDialPlan' && form) {
                form.setErrors(dp_form_errors, {emitEvent: true});
                console.log(dp_form_errors, form);
              }
            }
            break;
          case 10008:
            for (let field in err.error) {
              if (field != 'domain_id') {
                if (form) {
                  f_control = form.get(field);
                  f_control.markAsTouched();
                  for (let err_code of err.error[field]) {
                    f_control.setErrors({'unknown': {message: 'ERROR.10008.' + err_code}}, {emitEvent: true, onlySelf: false});
                  }
                } else {
                  for (let err_code of err.error[field]) {
                    msgs.push(field+' - '+this.translate.instant('ERROR.10008.' + err_code));
                  }
                }
              }
            }
            break;
          case 10006:
            if ('child_obj' in err) {
              if (!msgs.length || !msgs[0].startsWith(this.translate.instant('ERROR.TO_DELETE_' + (err['obj'] || '').toUpperCase()))) {
                msg_text = this.translate.instant('ERROR.TO_DELETE_' + (err['obj'] || '').toUpperCase()) + '\n';
              }
              msg_text = msg_text + ' - ' +
                this.translate.instant((err['child_obj'] || '').toUpperCase()).toLowerCase() + ': ' + err['child_name'] +
                (!this.auth.getDomain() && err['domain_name'] ? ' (' + this.translate.instant('DOMAIN').toLowerCase() + ': ' + err['domain_name'] + ')' : '');

            } else {
              msg_text = this.translate.instant((err['obj'] || '').toUpperCase())
                + ': '
                + (this.hasTranslation((err.text || '').toUpperCase()) ? this.translate.instant((err.text || '').toUpperCase()) : err.text);
            }

            if (!msgs.filter(msg=>msg == msg_text).length) msgs.push(msg_text);
            duration = 10000;
            break;
          case 10007:
            if ('child_table' in err) {
              msg_text = this.translate.instant('ERROR.TO_DELETE_' + (err['obj'] || '').toUpperCase());
            } else {
              msg_text = this.translate.instant((err['obj'] || '').toUpperCase())
              + ': '
              + (this.hasTranslation((err.text || '').toUpperCase()) ? this.translate.instant((err.text || '').toUpperCase()) : err.text);
            }

            if (!msgs.filter(msg=>msg == msg_text).length) msgs.push(msg_text);
            duration = 3000;
            break;
          case 100:
            if (err.text != 'FORBIDDEN' && ['append', 'update', 'delete'].indexOf(resp.action) == -1) {
              let err_text;
              if (err.text.startsWith('update or delete on table "user" violates foreign key constraint') && err.obj == 'User') {
                err_text = this.translate.instant('ERROR.TO_DELETE_USER');
              } else if (err.text == 'Got ambiguous input for polymorphic field' && resp.obj == 'DomainDialPlan') {
                err_text = this.translate.instant('DP.NO_ROUTE_TYPE_DATA_OR_EMPTY_ROUTE');
              } else if (err.text == 'Can not activate test for domain') {
                err_text = this.translate.instant('ERROR.TEST_MODE_ACTIVATING');
              } else {
                err_text = this.hasTranslation((err.text || '').toUpperCase()) ? this.translate.instant((err.text || '').toUpperCase()) : err.text;
              }
              msg_text = this.translate.instant((err['obj'] && err['obj'] != 'NA' ? err['obj'] : resp['obj'] || '').toUpperCase()) + ': ' + err_text;

              if (form) {
                form.setErrors({'unknown': {message: msg_text}}, {emitEvent: true});
              }
              duration = 3000;
              msgs.push(msg_text);
            }

            break;
          case 10004:
            msgs.push(this.translate.instant('ERROR.' + err['msg_id']) + ': ' + err['method']);
            break;
          case 10000:
          case 10001:
          case 10002:
            msgs.push(this.translate.instant('ERROR.'+(err['msg_id'] == 10002 ? err['msg_id']+'_1' : err['msg_id']), {arg1: this.translate.instant((err['obj'] || '').toUpperCase())}));
            need_navigate = true;
            break;
          case 10011:
            let dis_obj_name = this.translate.instant((err['obj'] || '').toUpperCase());
            msgs.push(this.translate.instant('ERROR.10011_1', {arg1: dis_obj_name}));
            break;
          case 10012:
          case 10015:
          case 10016:
            let av_count = err['key'] ? this.translate.instant('AVAILABLE_COUNT.' + err['key'].toUpperCase()) : this.translate.instant('AVAILABLE_COUNT');
            let av_count_text = this.translate.instant('ERROR.' + err['msg_id']) + '. ' + av_count + ': ' + err['max_value'];
            if (err['obj'] == 'DomainUser' && form) {
              form.setErrors({'limit_over': {message: av_count_text}}, {emitEvent: true});
            }
            msgs.push(av_count_text);
            break;
          case 10009:
          case 10010:
          case 10013:
          case 10014:
          case 10017:
          case 10018:
          case 10019:
          case 10020:
          case 10021:
          case 10022:
          case 10023:
          case 10024:
          case 10025:
          case 10026:
          case 10027:
          case 10028:
            msgs.push(this.translate.instant('ERROR.' + err['msg_id']));
            break;
          default:
            if ('obj' in err && err['obj']) err.obj = this.translate.instant(err.obj.toUpperCase());
            msgs.push(JSON.stringify(err));
        }
        // }
      }

      if (msgs.length > 0) this.pure_message(msgs.join('\n'), {duration: duration});
      if (need_navigate && navigate) this.router.navigate([navigate]);
    }

    return resp.code;
  }


  checkCode(res, with_logout = true) {
    if (res['code'] === 200) {
      return true;

    } else if ((res['code'] === 407 || res['code'] === 401) && with_logout) { // токена нет или он устарел
      this.messageByCode(res['code']);
      // remove user from local storage to log user out
      this.auth.logout();
      return false;

    } else if (res['code'] === 403) {
      if (['append', 'update', 'delete'].indexOf(res['action']) == -1) {
        let obj = res['obj'] || res.notifies?.[0]?.obj;
        let obj_name = this.hasTranslation(obj.toUpperCase()) ? this.translate.instant(obj.toUpperCase()) : obj;
        console.warn('%cДоступ запрещен! obj: ' + obj_name + ' (' + obj + '), action: ' + res['action'], 'color: red; font-weight: 700; font-zise: 16px;');
      } else {
        this.messageByCode(res['code']);
      }

      throw res;

    } else if (res['code'] === 502) {
      this.messageByCode(res['code']);
      throw res;

    } else {
      this.messageByCode(res['code']);
     // Sentry.captureMessage(JSON.stringify(res));
      throw res;
    }
  }
}
