import {
  propObject,
  prop,
  alphaNumeric, alpha, compare, contains, creditCard, digit, email, greaterThanEqualTo,
  greaterThan, hexColor, json, lessThanEqualTo, lowerCase, maxLength, maxNumber,
  minNumber, password, pattern,
  lessThan, range, required, time, upperCase, url, propArray, minLength, minDate, maxDate, toBoolean, unique, port, oneOf
} from '@rxweb/reactive-form-validators';

import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;




export class MarkdownNode {

  @prop() id?: number;
  @required() name: string;
  @prop() index?: number;
  @prop() parent_id: number | null = null;
  @prop() state: number = 1; //0 - черновик, 1 -опубликован
  @prop() perms: any[] = [];
  @prop() type: number = 0;
  @prop() has_structure: boolean = false;
  @prop() has_content: boolean = false;
  @prop() children?: number[] = [];
  @prop() child: MarkdownNode[] = [];
  @prop() icon: string = 'school';


}

export class Markdown {
  @prop() id?: number;

  @required() name: string;
  @prop() parent_id: MarkdownNode | null = null;
  @prop() content: string = '';
  //@prop() resource: string = '';
  @prop() icon: string = 'school';
  @prop() state: number = 1; //0 - черновик, 1 -опубликован
  @prop() perms: any[] = [];
  @prop() tags: string[] = [];
}
