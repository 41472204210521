import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { NotifyService } from '../_helpers/notify.service';
import { RxFormHelpers } from '../_helpers/form.helpers';
import { FileElement } from '../file-explorer/model/element';
import { Observable , of as observableOf } from 'rxjs';
import { FileService } from '../file-explorer/file.service';
import { AudioSystemService } from './audio.system.service';

@Component({
  selector: 'app-system-audio-form',
  templateUrl: './audio-form.component.html',
  styles: []
})
export class AudioFormSystemComponent implements OnInit {
  public show: boolean = false;
  public select_type = [];
  public fileElements: Observable<FileElement[]>;

  //параметры для фильтрации таблицы:
  public filteredColumns: string[] = ['name']; // список полей, по которым осуществляется поиск
  public filter: string = '';
  public filter$: Observable<string> = observableOf('');
  public selectorId: string|null = null;
  @ViewChild('filterText') filterText: ElementRef<HTMLInputElement>;
  public showFilter: boolean = false;
  public navigate: string = '';
  public currentElement: FileElement;
  currentPath: string = '/';
  canNavigateUp = false;

  public isLoadingResults: Observable<boolean> = observableOf(true);
  public isServerAvailable: Observable<boolean> = observableOf(true);
  public rxFormHelpers = new RxFormHelpers();
  actions = {
    sync: false
  }

  constructor(public fileService: FileService, public serviceApi: AudioSystemService, public notifyService: NotifyService) {
    this.navigate = 'gscatalogs';
  }

  ngOnInit() {
    this.refresh();
  }

  refresh(){
    this.serviceApi.changeObject();
    this.isLoadingResults = observableOf(true);
    this.updateFileElementQuery();
  }

  getExplorer(folder) {
    this.serviceApi.explorer(
      {
        with_files: true,
        folder: folder
      })
      .subscribe(
        data => {
          this.fileService.clear();
          data.forEach((file) => this.fileService.add(
            {
              label: file.label,
              link: file.link,
              type: file.type,
              duration: file.duration,
              path: file.path,
              value: file.value
            }));
          this.isLoadingResults = observableOf(false);
          this.isServerAvailable = observableOf(true);
          this.fileElements = this.fileService.queryInFolder(folder);
        },
        resp => {
          this.isLoadingResults = observableOf(false);
          this.isServerAvailable = observableOf(this.notifyService.setFormErrors(resp) != 502);
        }
      );
  }

  addFolder(folder: { label: string }) {
    const elem = this.fileService.add(
      { link: '', type: 'folder', label: folder.label, path: this.currentElement ? this.currentElement.path : '/' }
    );
    this.currentElement = elem;
    this.currentPath = this.pushToPath(this.currentPath, elem.label);
    this.canNavigateUp = true;
    this.fileElements = this.fileService.queryInFolder(this.currentPath);
  }

  addFile(file: {label: string}) {
    this.updateFileElementQuery();
  }

  removeElement(element: FileElement) {
    this.serviceApi.delete(parseInt(element.value, 10)).subscribe(
      data => {
        this.notifyService.showRequestResult(data, true);
        this.fileService.delete(element.id);
        this.updateFileElementQuery();
      },
      resp => {
        this.isServerAvailable = observableOf(this.notifyService.setFormErrors(resp) != 502);
    });
  }

  goFolder(element: FileElement) {
    this.currentElement = null;
    this.currentPath = element.value;
    if (this.currentPath  === '/') {
      this.canNavigateUp = false;
    } else this.canNavigateUp = true;
    this.isLoadingResults = observableOf(true);
    this.updateFileElementQuery();
  }

  navigateToFolder(element: FileElement) {
    if (element.go_to_folder) {
      this.filter = '';
      this.filter$ = observableOf('');
      this.filterText.nativeElement.value = '';
      this.showFilter = false;
      let last_folder_slash = element.path.slice(0, -1).lastIndexOf('/');
      this.currentElement = {
        label: element.path.substring(last_folder_slash+1),
        path: this.popFromPath(element.path),
        type: 'folder',
        duration: element.duration,
        value: element.path
      } as FileElement;
      this.currentPath = element.path;
    } else {
      this.currentElement = element;
      this.currentPath = this.pushToPath(this.currentPath, element.label);
    }

    this.canNavigateUp = true;
    this.isLoadingResults = observableOf(true);
    this.updateFileElementQuery();
  }

  navigateUp() {
    this.isLoadingResults = observableOf(true);
    if (this.currentPath != '/') this.currentPath = this.popFromPath(this.currentPath);
    if (this.currentPath  === '/') {
      this.canNavigateUp = false;
    }
    this.updateFileElementQuery();
    this.currentElement = null; // this.fileService.get(this.currentElement.path);
  }

  moveElement(event: { element: FileElement; moveTo: FileElement | string }) {
    let folder = (typeof event.moveTo === 'object') ? event.moveTo.value : event.moveTo;
    if (folder === '..') {
      folder = this.popFromPath(event.element.path);
    }

    this.serviceApi.move({folder: folder, id: parseInt(event.element.value, 10)})
      .subscribe(data => {
        this.fileService.update(event.element.id, { path: folder });
        this.updateFileElementQuery();
      }, resp => {
        this.isServerAvailable = observableOf(this.notifyService.setFormErrors(resp) != 502);
      });
  }

  renameElement(element: FileElement) {
    this.serviceApi.rename({name: element.label, id: parseInt(element.value, 10)})
      .subscribe(data => {
        this.fileService.update(element.id, { label: element.label });
        this.updateFileElementQuery();
      }, resp => {
        this.isServerAvailable = observableOf(this.notifyService.setFormErrors(resp) != 502);
      });
  }

  updateFileElementQuery() {
    this.getExplorer(this.currentPath ? this.currentPath : '/');
  }

  pushToPath(path: string, folderName: string) {
    let p = path ? path : '/';
    p += `${folderName}/`;
    return p;
  }

  popFromPath(path: string) {
    let p = path ? path : '/';
    const split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  openFilter(filterInput) {
    this.showFilter = !this.showFilter;
    window.setTimeout(() => filterInput.focus(), 0);
  }

  applyFilter(filterValue: string) {
    this.filter = filterValue.trim().toLowerCase();
    this.filter$ = observableOf(this.filter);
    this.selectorId = null;
    if (!this.filter) this.refresh();
    else {
      this.isLoadingResults = observableOf(true);
      this.serviceApi.list({...this.getFilter()}).subscribe(
        data => {
          this.fileService.clear();
          data.list.forEach(
            (file) => this.fileService.add({
              label: file.name,
              link: file.link,
              duration: file.duration,
              type: 'file',
              path: file.folder,
              value: file.id.toString()
            } as FileElement)
          );
          this.isLoadingResults = observableOf(false);
          this.isServerAvailable = observableOf(true);
          this.fileElements = this.fileService.queryInFolder(null);
        },
        resp => {
          this.isLoadingResults = observableOf(false);
          this.isServerAvailable = observableOf(this.notifyService.setFormErrors(resp) != 502);
        }
      );
    }
  }

  getFilter(){ // формирую фильтр, если он есть
    if (this.filter && this.filteredColumns.length > 0){
      let filterData = {filter: {field_list: [], type: 1}}; // условие между полями; FILTER_TYPE_AND = 0, FILTER_TYPE_OR = 1
      for (let field of this.filteredColumns) {
        filterData['filter']['field_list'].push({
          field: field,
          condition_type: 3, // CONDITION_TYPE_EQUAL = 0, CONDITION_TYPE_STARTSWITH = 1, CONDITION_TYPE_ENDSWITH = 2, CONDITION_TYPE_LIKE = 3
          value: this.filter
        });
      }
      return filterData
    } else return {};
  }

  doAction(evt, action){
    this.serviceApi.action(action).subscribe(
    data => {
      if (data.code == 200) {
        setTimeout(() => {
          this.actions[action] = false;
          this.notifyService.message('NOTIFY.200');
        }, 1500);
      } else this.actions[action] = false;
    }, resp => {
      this.actions[action] = false;
      this.isServerAvailable = observableOf(this.notifyService.setFormErrors(resp) != 502);
    });
  }
}

