import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Pipe({
  name: 'momentEpoch'
})

export class MomentEpochPipe implements PipeTransform {
  transform(value: number | undefined, lang, tz: string = 'Europe/Moscow'): string {
    // lang - передан для того, чтобы пайп заметил изменение языка и сам перерисовал дату
    if (value) {
      return new Date(value * 1000).toLocaleString('ru-RU', {timeZone: tz });
    }
    return '-';
  }
}
