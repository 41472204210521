import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DialogBoxMessageObject} from '../../../_helpers/api.helpers';
import {getUUID4} from '../../../_helpers/base.function';
import {AuthenticationService} from '../../../auth/authentication.service';
import {ModalBoxService} from '../../../modal-box/modal-box.service';

@Injectable({providedIn: 'root'})
export class NotificationBarService {
  public dialogBoxMsgList: DialogBoxMessageObject[] = [];

  constructor(
    public modalService: ModalBoxService,
    public auth: AuthenticationService,
    public translate: TranslateService
  ) {
  }

  message(msg: DialogBoxMessageObject) {
    if (!msg.id) msg.id = getUUID4(4);
    if (!msg.type) msg.type = 'message';
    if (!msg.date) msg.date = new Date().toLocaleTimeString();
    // timeout скрытие уведомления:
    //  0 - не скрывать вообще;
    //  >0 - скрывать через указанное время (в мс);
    //  null - скрыть через дефолтное время.
    if (msg.timeout == null) msg.timeout = 5000;

    // this.dialogBoxMsgList.splice(0, 0, msg);
    this.dialogBoxMsgList.push(msg);

    setTimeout(() => {
      this.showMessage(msg.id);
      if (msg.timeout) setTimeout(() => this.closeMessage(msg.id), msg.timeout || 5000);
    }, 10);
  }

  showMessage(id: string, width: string = '100%') {
    let openingBox = document.getElementById(id);
    // openingBox.style.position = 'absolute';
    // openingBox.style['padding-top'] = `60px`;
    openingBox.style['padding-left'] = `0px`;
    openingBox.style['width'] = width;
    openingBox.style['display'] = `block`;
    this.modalService.open(id);
  }

  closeMessage(id): void {
    let ind = this.dialogBoxMsgList.findIndex(msg => msg.id == id);
    if (ind != -1) {
      this.dialogBoxMsgList[ind]['hidding'] = true;
      setTimeout(() => this.dialogBoxMsgList = this.dialogBoxMsgList.filter(mesObj => !mesObj['hidding']), 600);
    }
  }

  closeOldMessages(existed_ids, objType: string) {
    this.dialogBoxMsgList.filter(msg =>  msg.objType == objType && !existed_ids.includes(msg.id)).forEach(msg => this.closeMessage(msg.id));
  }
}
