import {digit, required} from '@rxweb/reactive-form-validators';

export class DOCDialog {
  @required() domain_id: number;
  @required() @digit() status: number = 1;

  static className() {
    return 'DOCDialog';
  }
}
