import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModalBoxModule} from '../../modal-box/modal-box.module';
import {MatIconModule} from '@angular/material/icon';
import {KtdGridModule} from '@katoid/angular-grid-layout';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MaterialModule} from '../../material.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {LangChangeEvent, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {AgentListModule} from '../../queues/agent-list.module';
import {DesktopQueueComponent} from './components/desktop-queue/desktop-queue.component';
import {NgArrayPipesModule} from 'ngx-pipes';
import {PipeModule} from '../../_helpers/pipe.module';
import {NgApexchartsModule} from 'ng-apexcharts';
import {NgSelectModule} from '@ng-select/ng-select';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {DesktopAgentComponent} from './components/desktop-agent/desktop-agent.component';
import {TiersTableComponent} from './components/tiers-table/tiers-table.component';
import {DesktopSettingsComponent} from '../kept/desktop/components/desktop-settings/desktop-settings.component';
import {DesktopControlPanelComponent} from './components/desktop-control-panel/desktop-control-panel.component';


@NgModule({
  declarations: [
    DesktopQueueComponent,
    DesktopAgentComponent,
    TiersTableComponent,
    DesktopSettingsComponent,
    DesktopControlPanelComponent,
  ],
  providers: [],
  exports: [
    DesktopControlPanelComponent,
    DesktopQueueComponent,
    DesktopAgentComponent,
    TiersTableComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    KtdGridModule,
    NgxChartsModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MaterialModule,
    FlexModule,
    TranslateModule.forChild(),
    AgentListModule,
    FlexLayoutModule,
    NgArrayPipesModule,
    PipeModule,
    NgApexchartsModule,
    NgSelectModule,
    PerfectScrollbarModule,
    ModalBoxModule,
  ]
})

export class DesktopDeclarationModule {
}

