import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'battery'
})

export class BatteryStatusPipe implements PipeTransform {
  transform(value: number, arg?: number, until?: string|null, billing_options?: any): string {
    const battery = document.createElement('i');
    if (value === 0) {
      battery.innerHTML = 'looks_one';
    } else if (value === 1) {
      battery.innerHTML = 'looks_two';
    } else if (value === 2) {
      battery.innerHTML = 'looks_3';
    }

    battery.className = 'mat-icon material-icons mat-icon-no-color text-center mat-tooltip-trigger';
    if (arg == 0) {
      battery.className += ' text-inverse';
    }
    else if (billing_options && (billing_options.block_inbound || billing_options.block_outbound || billing_options.block_face)) {
      battery.className += ' text-warning';
    }
    else if (until == null) {
      battery.className += ' text-primary';
    }
    else {
      battery.className += ' text-yellow';
    }
    return battery.outerHTML;
  }
}
