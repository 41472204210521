import {prop, propObject, required} from '@rxweb/reactive-form-validators';

export class DomainAddressBookSyncSource {
  @required() model_name: string = 'DomainAddressBookSyncSource';
}
export class DomainAddressBookSyncSourceMegaplan implements DomainAddressBookSyncSource {
  @required() model_name: string = 'DomainAddressBookSyncSourceMegaplan';
}

export class DomainAddressBookSyncSourceDomainUser implements DomainAddressBookSyncSource {
  @required() model_name: string = 'DomainAddressBookSyncSourceDomainUser';
}

export class DomainAddressBookGroup {
  @prop() id?: number;
  @required()
  name = '';
  @prop()
  parent_id: number|null = null;

  @prop()
  domain_id: number|null = null;

  @propObject(DomainAddressBookSyncSource) sync_source: DomainAddressBookSyncSource;

  static className() {
    return 'DomainAddressBookGroup';
  };

}
