import {AfterViewInit, Component, OnInit } from '@angular/core';
import {DesktopDefService} from '../../desktop-def.service';
import {NotifyService} from '../../../../../_helpers/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseTableDirectoryComponent} from '../../../../_common/base-table-directory.component';
import {DesktopService} from '../../../../_common/base-desktop.service';
import {DomainService} from "../../../../../domains/domain.service";
import {CTIService} from "../../../../../cti-panel/cti-panel.service";
import {AuthenticationService} from "../../../../../auth/authentication.service";

@Component({
  selector: 'app-history-calls-def',
  templateUrl: './history-calls-def.component.html',
  styleUrls: ['./history-calls-def.component.scss', '../../../../_common/desktop-table.scss']
})
export class HistoryCallsDefComponent extends BaseTableDirectoryComponent implements OnInit, AfterViewInit {
  public timezone = 'Europe/Moscow';
  public clickableCells = ['src', 'src_name', 'dst', 'dst_name']
  constructor(public api: DesktopService, public translate: TranslateService,
              public defApi: DesktopDefService,
              private domainService: DomainService,
              public notifyService: NotifyService,
              public CTI: CTIService,
              public auth: AuthenticationService,
              ) {
    super('HistoryCallsDefComponent', api, notifyService);
    super.extParams = {
      sort: {dt: '-'}
    };
    domainService.get_tz().subscribe(data => this.timezone = data);
  }

  getApi(params) {
    return this.defApi.getCDRs(params);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  setClickableClass(columnName: string, row: Object): boolean {
    if (this.auth.isRoleProvider() || !this.CTI.ctiSettings?.click_to_call) {return}
    if (row[columnName] === this.CTI.mynumber) {
      return false
    }
    if (['src_name', 'dst_name'].includes(columnName)) {
      if (row[columnName.slice(0,3)] === this.CTI.mynumber) {
        return false
      }
    }
    return this.clickableCells.includes(columnName);
  }

  setTel(columnName: string, row: Object): string {
    return ['src_name', 'dst_name'].includes(columnName) ? row[columnName.slice(0,3)] : row[columnName]
  }

  makeCall(evt: MouseEvent, row: Object): void {
    if (this.auth.isRoleProvider() || !this.CTI.ctiSettings?.click_to_call) {return}
    const { target } = evt;

    if (target instanceof HTMLElement) {
      const classListArr = Array.from(target.classList)
      let intersection = this.clickableCells.filter((item) => classListArr.includes(item))
      if (intersection) {
        if (row[intersection[0]] === this.CTI.mynumber) {
          return
        } else {
          this.CTI.ctiActions$.next({obj: 'call', action: 'makeCall', number: row[intersection[0].slice(0,3)]})
        }
      }
    }
  }
}
