import 'hammerjs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {SkeletonLoaderModule} from '../../../_helpers/skeleton-loader.module';
import {MatSelectUserModule} from '../../../material-component/mat-select-user/mat-select-user.module';
import { MaterialModule } from '../../../material.module';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import {DomainCRMEventModule} from '../events/event.module';
import {DomainCRMEntityFormComponent} from './entity-form.component';
import {DomainCRMEntityImportComponent} from './entity-import.component';
import {DomainCRMEntityListComponent, ExtFilterDialogComponent} from './entity-list.component';
import { TranslateModule } from '@ngx-translate/core';
import {PipeModule} from '../../../_helpers/pipe.module';
import {MatTableResponsiveModule} from '../../../material-component/mat-table-responsive/mat-table-responsive.module';
import {NgxMatDatetimePickerModule} from "@angular-material-components/datetime-picker";
import {DesktopEntityComponent} from "./desktop-entity.component";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";
import {NgxAudioPlayerModule} from "@khajegan/ngx-audio-player";
import {DomainDialogScriptModule} from "../../domain-dialog-scripts/domain-dialog-script.module";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkTableModule,
    PipeModule,
    MatTableResponsiveModule,
    SkeletonLoaderModule,
    MatSelectUserModule,
    NgSelectModule,
    DomainCRMEventModule,
    NgxMatDatetimePickerModule,
    PerfectScrollbarModule,
    RxReactiveFormsModule,
    NgxAudioPlayerModule,
    DomainDialogScriptModule
  ],
    providers: [],
    exports: [
        DomainCRMEntityFormComponent, DesktopEntityComponent
    ],
    declarations: [
      DomainCRMEntityListComponent, DomainCRMEntityFormComponent, DomainCRMEntityImportComponent,
      ExtFilterDialogComponent, DesktopEntityComponent
    ]
})
export class DomainCRMEntityModule {}
