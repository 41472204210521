import {
  propObject,
  prop,
  alphaNumeric, alpha, compare, contains, creditCard, digit, email, greaterThanEqualTo,
  greaterThan, hexColor, json, lessThanEqualTo, lowerCase, maxLength, maxNumber,
  minNumber, password, pattern,
  lessThan, range, required, time, upperCase, url, propArray, minLength, minDate, maxDate, toBoolean, unique, port, oneOf
} from '@rxweb/reactive-form-validators';


export class Group {
  @prop() id?: number;
  @required()
  name = '';
  @prop() @toBoolean()
  status = true;

  @prop()
  locked = false;

  @prop()
  domain_id: number|null = null;

  static className() {
    return 'DomainGroup';
  };

}
