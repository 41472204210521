import {maxLength, prop, propObject, required} from '@rxweb/reactive-form-validators';

export class DomainVoiceKitParams {
  @required() dep_name: string;
  @prop() group_id?: number;
}

export class DomainVoiceKit {
  @prop() id?: number;
  @prop() domain_id: number;
  @required() @maxLength({value: 150}) name: string;

  @required() domain_extension_id: number;

  @prop() mod_local_stream: number = -1;
  @prop() voice: string = 'oksana';
  @prop() lang: string = 'ru-RU';
  @prop() emotion: string = 'good';
  @prop() speed: number = 1;
  @propObject(DomainVoiceKitParams) params: DomainVoiceKitParams = {
    dep_name: 'DomainVoiceKitSourceText'
  };

  static className() {
    return 'DomainVoiceKit';
  };
}
