import {pattern, prop, required, choice, maxLength, digit} from '@rxweb/reactive-form-validators';
import {RxFormHelpers} from '../../_helpers/form.helpers';

export class DomainWhiteLabel {
  @prop() id?: number;
  @required() @maxLength({value: 500}) name = ''; // наименование компании
  @required() @maxLength({value: 500}) site = ''; // сайт компании
  @prop() dock_url: string = ''; // ссылка на сайт документации

  // Техподдержка:
  @required() @maxLength({value: 50}) support_phone: string;
  @required() @pattern({expression:{onlyAlpha: RxFormHelpers.email}, message: 'ERROR.EMAIL'})
  @maxLength({value: 250}) support_email: string;
  @maxLength({value: 500}) ticket: string;

  // Отдел продаж:
  @required() @maxLength({value: 500}) dep_pbx_site: string; // страница сайта с тарифами на ВАТС
  @required() @maxLength({value: 50}) sales_phone: string;
  @pattern({expression:{onlyAlpha: RxFormHelpers.email}, message: 'ERROR.EMAIL'}) @maxLength({value: 250}) sales_email: string;

  // Стили и параметры кабинета:
  @maxLength({value: 100}) title: string = 'VOIP PBX';
  @maxLength({value: 100}) theme: string; // главный класс веб-страницы
  @prop() minisidebar: boolean = false; // узкое меню
  @prop() boxed: boolean = false; //не расширять на весь экран
  @prop() favicon: string;
  @choice({maxLength: 3}) background_login: string[] = []; // картинки окна входа, не более 3 картинок
  @digit() auth_window_position: number = 13;
  @digit() gauss_palette: number = 5;
  @prop() icon_logo: string; // логотип вверху заголовка рядом с кнопкой сворачивания/разворачивания меню
  @prop() icon_logo_mini: string; // мини-логотип вверху заголовка рядом с кнопкой сворачивания/разворачивания меню
  @maxLength({value: 100}) primary_color: string = '#607d8b';
  @maxLength({value: 100}) primary_contrast_color: string = '#ffffff';
  @maxLength({value: 100}) accent_color: string = '#9e9e9e';
  @maxLength({value: 100}) accent_contrast_color: string = '#ffffff';
  @maxLength({value: 100}) warn_color: string = '#f44336';
  @maxLength({value: 100}) warn_contrast_color: string = '#ffffff';
  @maxLength({value: 100}) header_bg: string = '#F5F5F5';
  @maxLength({value: 100}) header_color: string = '#212121';
  @maxLength({value: 100}) sidebar_icon_color: string = '#757575';
  @maxLength({value: 100}) sidebar_text_color: string = '#212121';
  @maxLength({value: 100}) body_color: string = '#eeeeee';
  @maxLength({value: 100}) dp_border_color: string = '#d8dcdf';
  @maxLength({value: 100}) dp_elem_hover: string = '#dee5ea';

  static className() {
    return 'GSWhiteLabel';
  };


}
