import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Helper, IResponseBodyObject, IResponseListObject} from '../../../_helpers/api.helpers';
import {Observable} from 'rxjs';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService, headers} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DOCChannel} from './doc-channel';
import {map, tap} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})


export class DOCChannelService extends BaseService<DOCChannel> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService) {
    super(DOCChannel.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  list(params: any = {}, action: string = 'list'): Observable<IResponseListObject<DOCChannel>> {
    params['sort'] = {name: '+'}
    return super.list(params, action);
  }

  get<P = DOCChannel>(id: number | string | null, params: any = {}, action: string = 'get'): Observable<P | DOCChannel> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue?.domain_id;
    }
    if (typeof id === 'string') id = parseInt(id, 10);
    if (id) {
      params['id'] = id;
    } else if (params.hasOwnProperty('id')) {
      delete params['id'];
    }
    const requestBody = Helper.requestFormat(action, this.objectName, { ...params});
    return this.http.post<IResponseBodyObject<P | DOCChannel>>(this.AppConfig.getValue('apiUrl'),
      requestBody, {headers: headers})
      .pipe(
        tap(res => {
          // @ts-ignore
          if (res.code != 200 && (!res.notifies.length || res.notifies[0].msg_id != 10002)) this.notifyService.checkCode(res);
        }),
        map(data => {
          return data.body as DOCChannel
        })
      );
  }
}
