import 'hammerjs';
import { NgModule } from '@angular/core';
import {MatLocalStoragePageSize} from './mat-local-storage-page-size';
import {ListContactPipe} from './pipe/list.contact.pipe';
import {ListACLNodePipe} from './pipe/list.acl-node.pipe';
import {SecretPipe} from './pipe/secret.pipe';
import {TimeHHMMEpochPipe} from './pipe/timeHHMM.epoch.pipe';
import {EpochDatePipe} from './pipe/date.epoch.pipe';
import {BatteryStatusPipe} from './pipe/battery.status.pipe';
import {brPipe} from './pipe/br.pipe';
import {DateYYYYMMDDEpochPipe} from './pipe/dateYYYYMMDD.epoch.pipe';
import {MomentDateTimePipe} from './pipe/moment.datetime.pipe';
import {FilterPipe} from './pipe/filter.pipe';
import {ToMMSS} from './pipe/toMMSS';
import {ToHHMMSS} from './pipe/toHHMMSS';
import {UsersStatusPipe} from './pipe/users.status.pipe';
import {DateTimeEpochPipe} from './pipe/dateTime.epoch.pipe';
import {HighLightPipe} from './pipe/highlight.pipe';
import {MapPipe} from './pipe/map.pipe';
import {MomentEpochPipe} from './pipe/moment.epoch.pipe';
import {SearchPipe} from './pipe/search.pipe';
import {SearchUserPipe} from './pipe/searchUser.pipe';
import {TimeEpochPipe} from './pipe/time.epoch.pipe';
import {ToDDHHMMSS} from "./pipe/toDDHHMMSS";
import {RangePipe} from "./pipe/range.pipe";
import { HighlightTagPipe } from './pipe/highlight-tag.pipe';


@NgModule({
  exports: [HighLightPipe, MomentEpochPipe, SearchPipe, SearchUserPipe, TimeEpochPipe, ToDDHHMMSS,
    ListContactPipe, EpochDatePipe, BatteryStatusPipe, ListACLNodePipe, brPipe, UsersStatusPipe, MapPipe, SecretPipe,
    TimeHHMMEpochPipe, DateYYYYMMDDEpochPipe, MomentDateTimePipe, FilterPipe, ToMMSS, ToHHMMSS, DateTimeEpochPipe, MatLocalStoragePageSize,
    RangePipe, HighlightTagPipe,
  ],
  providers: [],
  declarations: [HighLightPipe, MomentEpochPipe, SearchPipe, SearchUserPipe, TimeEpochPipe, ToDDHHMMSS,
    ListContactPipe, EpochDatePipe, BatteryStatusPipe, ListACLNodePipe, brPipe, UsersStatusPipe, MapPipe, SecretPipe,
    TimeHHMMEpochPipe, DateYYYYMMDDEpochPipe, MomentDateTimePipe, FilterPipe, ToMMSS, ToHHMMSS, DateTimeEpochPipe, MatLocalStoragePageSize,
    RangePipe, HighlightTagPipe,
  ]
})
export class PipeModule {}
