import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  Input, OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatFabMenuDirection} from '@angular-material-extensions/fab-menu';
import {TranslateService} from '@ngx-translate/core';
import {ModalBoxService} from '../modal-box/modal-box.service';
import {FormControl, FormGroup} from '@angular/forms';
import {VertoService} from './verto.service';
import {MatTabChangeEvent, MatTabGroup} from '@angular/material/tabs';
import {AGENT_STATUS_LIST} from '../_helpers/constant';
import {MatTableDataSource} from '@angular/material/table';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {generateGUID} from '../../vertojs/dist/verto.js';
import {Subject, timer} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';


@Component({
  selector: 'app-verto',
  templateUrl: './verto.component.html',
  styleUrls: ['./verto.component.scss'],
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VertoComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  @ViewChild('ringer') ringer: ElementRef;
  @ViewChild('play') play: ElementRef;
  @Input()
  position: string = 'fab-right-bottom';
  @Input()
  direction: MatFabMenuDirection = 'left';
  private readonly destroy$ = new Subject();
  public status: any = 'phone_disabled';
  public agent_status_list = [];
  public agent_status: number | null = null;
  public address_limit: number = 20;
  public address_count: number = 0;
  public cdr_count: number = 0;
  public cdr_limit: number = 20;
  public inbound_calls = [];
  public mynumber = '';
  public lines_calls = [];
  public tz = 'Europe/Moscow';
  public form: FormGroup;
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public dataCDRSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: false, suppressScrollX: true, suppressScrollY: false
  };

  //public  audioDtmf = new Dtmf({duration: 100, pause: 40})

  public transportConfig: any = {
    //userVariables: {toll_allow: '456'},
    //loginParams: {login_test: '456'},
    socketUrl: '',
    login: '',
    passwd: '',
    funcBtn: '#1',
    sessid: generateGUID()
  }

  public ice_server_list = [];
  public number = '';
  public name = '';

  public eavesdrop1 = '';
  public eavesdrop2 = '';

  public isPhone = false;
  public timers = {};

  formData: any = {};
  public transfer: string | null = null;
  volume: number = 0.7;
  alarm = '/assets/sounds/bell_ring2.mp3';

  public alerts = [
    {label: 'GUDOK1', value: '/assets/sounds/1.mp3'},
    {label: 'GUDOK2', value: '/assets/sounds/2.mp3'},
    {label: 'GUDOK3', value: '/assets/sounds/3.mp3'},
    {label: 'DEFAULT_VALUE', value: '/assets/sounds/bell_ring2.mp3'},
    {label: 'MUTE', value: null},
  ]
  public testAudio: Boolean = false;
  public autocall: Boolean = false;
  public autowait: number = 5;
  public deny_inbound_call: boolean = false; // одноканальность

  public curLine: number = 0; // текущая линия

  constructor(public translate: TranslateService, public modalService: ModalBoxService,
              public verto: VertoService, private changeDetector: ChangeDetectorRef) {
    this.form = new FormGroup({
      username: new FormControl(''),
      password: new FormControl('')
    }, {updateOn: 'change'});

  }

  idCall(index, item) {
    return item.call?.id;
  }

  inboundCall(index, item) {
    return item?.id;
  }

  ngAfterViewInit() {
    setTimeout(()=>{
      if (this.tabGroup && this.verto?.phone?.logged_in) {
        this.tabGroup.selectedIndex = 0;
      }
      else this.tabGroup.selectedIndex = 3;
    }, 1000);
    this.changeDetector.detectChanges()
  }

  ngOnInit(): void {

    timer(1000,1000).pipe(takeUntil(this.destroy$))
      .subscribe(t => {

        const callID = this.activeCallID();
        if (callID && this.timers[callID]) {
          this.name = new Date(new Date().getTime() - this.timers[callID].call_time_start.getTime())
            .toTimeString().replace(/.*\d{2}:(\d{2}:\d{2}).*/, '$1');
        }
        this.changeDetector.detectChanges()
      })


    if (localStorage && localStorage.getItem('verto_session')) {
      this.transportConfig.sessid = localStorage.getItem('verto_session');
    } else if (localStorage) {
      localStorage.setItem('verto_session', this.transportConfig.sessid);
    }

    if (localStorage && localStorage.getItem('verto_settings')) {
      let verto_settings = JSON.parse(localStorage.getItem('verto_settings'));
      if (verto_settings && verto_settings.volume) this.volume = verto_settings.volume;
      if (verto_settings && verto_settings.alarm) this.alarm = verto_settings.alarm;
      if (verto_settings && verto_settings.autocall) this.autocall = verto_settings.autocall;
      if (verto_settings && verto_settings.autowait) this.autowait = verto_settings.autowait;
    }

    this.verto.get_verto().pipe(take(1)).toPromise().then(item=>{
      this.deny_inbound_call = item.deny_inbound_call;
      if (item?.device_id !== 'DEVICE') this.isPhone = false;
      else if (item?.login && item?.wss && item?.password) {
        this.isPhone = true;
        this.transportConfig.socketUrl = item.wss;
        this.transportConfig.login = item.login;
        this.transportConfig.passwd = item.password;
        if (item.ice_server_list) {
          item.ice_server_list?.forEach(ice=> {
            this.ice_server_list.push({urls: ice})
          })
        }

      if(item?.auto_login) this.onLogin();
      } else {
        this.isPhone = false;
      }


    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  open(id: string) {
    this.modalService.open(id);
  }

  close(id: string) {
    this.modalService.close(id);
  }

  changeAudioSettings() {
    this.testAudio = true;
    setTimeout(() => this.testAudio=false, 1500);
    if (localStorage) {
      localStorage.setItem('verto_settings', JSON.stringify({
        alarm: this.alarm,
        volume: this.volume,
        autocall: this.autocall,
        autowait: this.autowait
      }));
    }
  }

  isEavesdrop() {
    const active = this.verto?.phone?.getActiveCall();
    return  active &&
      (active?.getOptions()?.caller_id_name=='Eavesdrop' ||
       active?.getOptions()?.callee_id_name=='Eavesdrop')


  }


  onEversdrop(s) {
    const active = this.verto.phone.getActiveCall();
    active?.dtmf(s);
  }

  onDigit(s: string) {
    const active = this.verto.phone.getActiveCall();
    active?.dtmf(s);
    this.number += s;
    //this.audioDtmf.play(s);


  }

  setDisplayNumber(s: string) {
    this.number = s;
  }

  setDisplayName(s: string) {
    this.name = s;
  }

  async answer(uid: string) {
    this.stop();
    let local_stream = await navigator.mediaDevices.getUserMedia({audio:true})
    let calls = this.inbound_calls?.filter(item=> item.id === uid);
    if (calls?.length>0) {
      const call = calls[0];
      call.answer(local_stream.getTracks())
      call.subscribeEvent('track', (track) => {
        if(track.kind!='audio') return;
        let stream = new MediaStream();
        stream.addTrack(track);
        this.lines_calls.push({call: call, track: track, stream: stream});
        this.curLine = this.lines_calls.length-1;
        this.start_timer(call.id);
        this.inbound_calls = this.inbound_calls.filter(item => item.id!==uid);
        this.changeDetector.detectChanges();
      })
    }
  }

  getAgentStatus() {
    let statuses = this.agent_status_list.concat(AGENT_STATUS_LIST.filter(item => item.type=='user'));
    return statuses;
  }

  async subscriber() {
    this.verto.phone.subscribeEvent('invite', call => {
      if (this.deny_inbound_call && this.lines_calls.length == 1) {
        // завершаю вызов таким образом, не вызывая onHangup, иначе в телефоне при вызове из очереди, начинается мигание линий
        if (this.verto.phone?.calls[call.id]) this.verto.phone.hangup(call.id, {cause: 'DENY_INBOUND_CALL', causeCode: 486});
      } else if (this.inbound_calls.filter(item=>item.call?.id == call.id)?.length === 0) {
        this.stop();
        this.tabGroup.selectedIndex = 0;
        this.inbound_calls.push(call);
        if (this.autocall && this.lines_calls?.length == 0) {
          setTimeout(_=>this.answer(call.id), (this.autowait || 5)*1000 );
        }
      }
      this.changeDetector.detectChanges();
    });

    this.verto.phone.subscribeEvent('answer', params => {
      this.stop();
      this.start_timer(params.callID);
      this.changeDetector.detectChanges();
    });

    this.verto.phone.subscribeEvent('attach', call => {
      navigator.mediaDevices.getUserMedia({audio:true}).then((local_stream)=>{
        call.answer(local_stream.getTracks())
        call.subscribeEvent('track', (track) => {
          if(track.kind!='audio') return;
          let stream = new MediaStream();
          stream.addTrack(track);
          this.lines_calls.push({call: call, track: track, stream: stream});
          this.curLine = this.lines_calls.length-1;
          this.start_timer(call.id);
          this.changeDetector.detectChanges();
        })
      }, (error) => console.log(error));

      if (call?.options?.caller_id_name=='Eavesdrop' || call?.options?.callee_id_name=='Eavesdrop') {
        const regexp = /Eavesdrop_(\w+)_(\w+)/gm;

        let str = (call?.options?.caller_id_name=='Eavesdrop') ? (call?.options?.caller_id_number) : (call?.options?.callee_id_number);
        let matchAll: any = Array.from(str.matchAll(regexp));

        if (matchAll.length>0) {
          const match = Array.from(matchAll[0]);
          if (match.length===3) {
            this.eavesdrop1 = match[1] as string;
            this.eavesdrop2 = match[2] as string;
            this.changeDetector.detectChanges();

          }
        }
      }




    });

    this.verto.phone.subscribeEvent('clientReady', result => {
      if (this.verto.isLogged()) {
        this.verto.get_status_list_verto().pipe(take(1)).toPromise().then(
          data=>{
            if (data) this.agent_status_list = data;
          }
        )

        this.verto.get_agent_status_verto().pipe(take(1)).toPromise().then(
          data=>{
            if (data) this.agent_status = data;
          }
        )

        navigator.mediaDevices.getUserMedia({audio:true}).then(
          local_stream => {
            console.log(local_stream);
          }, error => {
         console.log(error);
        });

        this.dataSource = new MatTableDataSource([])
        this.getAddressData(0)
        this.transfer = null;
      }
      this.changeDetector.detectChanges();
    })

    this.verto.phone.subscribeEvent('display', display => {
      this.setDisplayName(display?.display_name);
      this.setDisplayNumber(display?.display_number);

      if (display?.caller_id_name=='Eavesdrop' || display?.callee_id_name=='Eavesdrop') {
        const regexp = /Eavesdrop_(\w+)_(\w+)/gm;

        let str = (display?.caller_id_name=='Eavesdrop') ? (display?.caller_id_number) : (display?.callee_id_number);
        let matchAll: any = Array.from(str.matchAll(regexp));

        if (matchAll.length>0) {
          const match = Array.from(matchAll[0]);
          if (match.length===3) {
            this.eavesdrop1 = match[1] as string;
            this.eavesdrop2 = match[2] as string;

          }
        }
      }
      this.changeDetector.detectChanges();
    })

    this.verto.phone.subscribeEvent('bye', bye => {
      if (this.inbound_calls.find(item => item.id === bye) || this.lines_calls.find(item => item.call?.id === bye)) {
        this.setDisplayName('');
        this.setDisplayNumber('');
      }
      this.clearCall(bye);
      this.changeDetector.detectChanges()
    })

  }

  updateCDR() {
    if (this.verto.isLogged()) {

      this.verto.get_tz().pipe(take(1)).toPromise().then(data => this.tz = data);

      this.verto.cdr_verto({}).pipe(take(1)).toPromise().then(
        data => {
          this.dataCDRSource = new MatTableDataSource(data?.list);
        }
      )
    }
  }


  scrollEnd() {
    this.getAddressData(this.dataSource.data?.length + this.address_limit)
  }

  getAddressData(offset= 0) {
    if (this.verto.isLogged()) {
      this.verto.address_book_verto({offset: offset, limit: this.address_limit, sort: {name: '+'}})
        .pipe(take(1)).toPromise().then(data => {
        this.address_count = data.total_count;
        if (offset === 0) this.dataSource = new MatTableDataSource(data?.list);
        else if (this.address_count >= this.dataSource.data.length) {
          this.dataSource = new MatTableDataSource([...this.dataSource.data, ...data?.list]);
        }
      });
    }
  }

  onLogin() {
    this.lines_calls = [];
    this.inbound_calls = [];
    this.curLine = 0;
    if (this.verto?.phone?.logged_in) {
      this.verto.logout();
      this.mynumber = '';
    } else {
      let VertoOptions = {
        rtcConfig: {},
        transportConfig: this.transportConfig,
        caller_id_name: this.transportConfig.login.split('@')[0],
        caller_id_number: this.transportConfig.login.split('@')[0],
        debug: true,
        ice_timeout: 5000,
        deny_inbound_call: this.deny_inbound_call
      };

      if (this.ice_server_list.length>0) {
        VertoOptions.rtcConfig['iceServers'] = this.ice_server_list;
      }

      console.log(this.ice_server_list)

      this.mynumber = this.transportConfig.login.split('@')[0];
      this.verto.init(VertoOptions);
      this.subscriber();
      this.verto.login();
      this.tabGroup.selectedIndex = 0;
    }
  }

  onBackspace() {
    this.number = this.number.slice(0, -1);
  }

  onEnter(number) {
    this.stop();
    if (this.getStatus() === 'call') {
      // удаляю вызов на активной линии, а она = индексу вызова в массиве вызовов
      this.onHangup(this.lines_calls[this.curLine]?.call?.id);
    } else if (number?.length>0 && this.getStatus() === 'online'){ // && this.curLine == this.lines_calls.length) { // если набран номер на последней лини
      this.onCall(number);
    }
  }

  getStatus() {
    if (this.verto.isLogged() && this.activeCallID()) {
      return 'call';
    } else if (this.verto.isLogged() && !this.activeCallID()) {
      return 'online';
    } else if (!this.verto.isLogged()) {
      return 'logout';
    }
  }

  async onCall(number: any, name: any = null) {
    if (this.verto?.phone?.logged_in) {
        navigator.mediaDevices.getUserMedia({audio:true}).then((local_stream)=>{
          let call = this.verto.phone.call(local_stream.getTracks(), number);
          this.tabGroup.selectedIndex = 0;
          this.setDisplayNumber(number);
          if (name) this.setDisplayName(name);
          call.subscribeEvent('track', (track: any) => {
            if (track.kind != 'audio') return;
            let stream = new MediaStream();
            stream.addTrack(track);
            this.lines_calls.push({call: call, track: track, stream: stream});

            if (this.transfer) {
              const aCall = this.verto.phone?.calls[this.transfer];
              if (aCall){
                setTimeout(()=>call?.xref(this.transfer), 3000);
              }
            }
          })

          call.subscribeEvent('bye', (bye: any) => {
            this.setDisplayName('');
            this.setDisplayNumber('');
            this.clearCall(bye);
          })

        }, (error) => console.log(error))


    }
  }

  onLine(callID=null) {
    this.lines_calls.filter(x=>x.call.id!==callID).forEach(item=>item.call.hold());
    let callInd = this.lines_calls.findIndex(x=>x.call.id==callID);
    let call;
    if (callID && callInd != -1) {
      call = this.lines_calls[callInd]?.call;
      this.curLine = callInd;
    } else this.curLine = this.lines_calls.length;
    this.transfer = null;

    if (call) {
      call.unhold();
      this.setDisplayName((call.direction === 0) ? call?.options?.caller_id_name : call?.options?.callee_id_name);
      this.setDisplayNumber((call.direction === 0) ? call?.options?.caller_id_number : call?.options?.callee_id_number);
    } else {
      this.setDisplayName('');
      this.setDisplayNumber('');
    }
  }

  clearCall(id) {
    if (this.verto.phone?.calls[id]) this.verto.phone.hangup(id);
    this.stop_timer(id);
    this.transfer = null;

    if (this.inbound_calls.find(item => item.id === id) || this.lines_calls.find(item => item.call?.id === id)) {
      if (this.inbound_calls.find(item => item.id === id)) this.inbound_calls = this.inbound_calls.filter(item => item.id !== id);
      else {
        this.lines_calls = this.lines_calls.filter(item => item.call?.id !== id);
        this.curLine = 0;
        if (this.lines_calls.length > 0) this.onLine(this.lines_calls[0]?.call?.id);
      }
    }
  }

  onHangup(id, params: any = {}) {
    this.transfer = null;
    if (this.verto?.phone?.logged_in) {
      if (id) {
        this.verto.phone.hangup(id, params);
        this.clearCall(id);
      } else if (this.verto.phone?.calls) {
        Object.keys(this.verto.phone.calls).forEach(
          key => {
            this.verto.phone.hangup(key, params);
          }
        )
        this.lines_calls = [];
        this.inbound_calls = [];
      }
    }
  }

  activeCallID(){
    return this.verto?.phone?.getActiveCallId();
  }

  onTransfer() {
    if (this.transfer) {
      this.onLine(this.transfer)
    }

    const active = this.verto.phone.getActiveCall()
    if (active) {
      active.hold()
      this.setDisplayNumber('');
      this.transfer = active.id;
    }
  }

  onHold() {
    const active = this.verto.phone.getActiveCall()
    active?.hold();
    this.transfer = null;
  }

  onChangeStatus(value: any) {
    this.verto.agent_change_status_verto({status_id: value})
      .pipe(takeUntil(this.destroy$)).subscribe(ok => this.agent_status = value, error => this.verto.message(error))
  }

  getStatusIcon(agent_status: number) {
    return this.getAgentStatus().find(item=>item.id===agent_status)?.icon
  }


  download(link: any, uid: string) {
    const player = this.play.nativeElement as HTMLMediaElement;
    if (player) {
      this.play.nativeElement.src = link;
      this.play.nativeElement.setAttribute('playId', uid );
      player.play().then();
    }
  }

  stop() {
    const player = this.play.nativeElement as HTMLMediaElement;
    if (player) {
      player.pause();
      player.srcObject = null;
      player.removeAttribute('playId');
    }

  }

  getPlayed() {
    return this.play?.nativeElement?.getAttribute('playId');
  }

  start_timer(callID: string) {
    const call = this.lines_calls.find(x=>x.call.id==callID)?.call;
    //debugger
    if (call) {
      let dt_start = new Date(), time_delta = 0, timer_data = {
        call_time_start: new Date(), timer: null
      };
      this.timers[callID] = timer_data;

      if (localStorage) {
        let calls_dt_start = 'calls_dt_start' in localStorage ? JSON.parse(localStorage.getItem('calls_dt_start')) : {};
        if (callID in calls_dt_start) {
          dt_start = new Date(calls_dt_start[callID]);
        } else {
          dt_start = new Date();
          calls_dt_start[callID] = dt_start;
          localStorage.setItem('calls_dt_start', JSON.stringify(calls_dt_start));
        }
      }

      this.timers[callID].call_time_start = dt_start;
    }
  };

  stop_timer(callID) {
    if (this.timers[callID]) {
      delete this.timers[callID];
      if (localStorage) {
        let calls_dt_start = 'calls_dt_start' in localStorage ? JSON.parse(localStorage.getItem('calls_dt_start')) : {};
        if (callID in calls_dt_start) {
          delete calls_dt_start[callID];
          localStorage.setItem('calls_dt_start', JSON.stringify(calls_dt_start));
        }
      }
    }
  }

  mergeCall() {
    const calls = Object.keys(this.verto.phone?.calls);
    if (calls?.length==2) {
      const aCall = this.verto.phone?.calls[calls[0]];
      const bCall = this.verto.phone?.calls[calls[1]];
      if (aCall && bCall){
        aCall?.xref(bCall.id);
      }
    }
  }

  tabChange(tabChangeEvent: MatTabChangeEvent) {
    if(tabChangeEvent.index === 2) { this.updateCDR(); }
  }
}
