import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AppService} from '../../../../app.service';
import {NotifyService} from '../../../../_helpers/notify.service';
import {BehaviorSubject, forkJoin, merge, of, of as observableOf, Subscription} from 'rxjs';
import {BaseTableDirectoryComponent} from '../../base-table-directory.component';
import {catchError, map, mergeMap, startWith, switchMap, take, tap} from 'rxjs/operators';
import {UserService} from '../../../../users/user.service';
import {DomainUserService} from '../../../../users/domain-user.service';
import {Tier} from '../../../../queues/tier';
import {DomainAgentStatusService} from '../../../../domains/domain-agent-status/domain-agent-status.service';
import {AGENT_STATUS_LIST} from '../../../../_helpers/constant';
import {DesktopService} from '../../base-desktop.service';
import {MatSort, Sort} from "@angular/material/sort";


@Component({
  selector: 'app-tiers-table',
  templateUrl: './tiers-table.component.html',
  styleUrls: ['./tiers-table.component.css', '../../desktop-table.scss']
})
export class TiersTableComponent  extends BaseTableDirectoryComponent implements AfterViewInit, OnDestroy {
  public status_system = AGENT_STATUS_LIST;
  customAgentStatus = [];
  subscription: Subscription = new Subscription();
  constructor(public api: DesktopService,
              public appService: AppService,
              public userService: UserService,
              public userDomainService: DomainUserService,
              public notifyService: NotifyService,
              public agentStatus: DomainAgentStatusService) {
    super('TiersTableComponent', api, notifyService);
    super.methods = this.methods;
    super.extParams =  {filter: {field_list: [
          {field: 'queue_id', condition_type: 0, value: this.api.currentQueue?.value}
        ], type: 0}};


    let subCurrent$ = this.api.currentQueue.subscribe(item=>{
      // console.log('TiersTableComponent::Current::'+item);
      this.extParams =  {filter: {field_list: [
            {field: 'queue_id', condition_type: 0, value: item}
          ], type: 0}};
      this.refresh();
      //this.table?.renderRows();
    });
    let subAgent$ = agentStatus.toSelect( {domain_id: userService.getDomain()}).subscribe(msg=> {
      this.customAgentStatus = msg;
    });
    let subData$ = this.api.agentData$.subscribe(agents=>{
      agents.forEach(
        agent=>{
          this.dataSource.data?.filter(item=>item?.agent_id == agent.agent_id)
            ?.forEach(item=>{
              item.status_time = agent.state_time;
              item.status_id = agent.state;
            });
        }

      )
      this.table.renderRows();
      setTimeout(_=>this.sortData(this.sort), 800);
    });

    this.subscription.add(subCurrent$);
    this.subscription.add(subAgent$);
    this.subscription.add(subData$);

    // updateSourceSkypeState(message) {
    //   let user = message.user;
    //   if (user) {
    //     this.dataSource.data.filter(item=>item?.s4b_status?.user == user).forEach(item=>item.s4b_status = message);
    //     this.dataSource.data.filter(item=>item?.pa_s4b_status?.user == user).forEach(item=>item.pa_s4b_status = message);
    //     this.dataSource.data.filter(item=>item?.da_s4b_status?.user == user).forEach(item=>item.da_s4b_status = message);
    //     this.table.renderRows();
    //   }
    // }
  }

  getApi(params) {
    return this.api.getTiers(params);
  }

  getStatus(id) {
    const stateArray = this.status_system.concat(this.customAgentStatus).filter(elem => elem.id == id);
    return (stateArray.length > 0) ? stateArray[0].name : '-';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  sortData(sort: Sort) {
    this.dataSource.sort = new MatSort();
    this.dataSource.sort.sort({id: null, start: 'asc', disableClear: true});

    if (sort.active && sort.direction !== '') {
      this.dataSource.sort.sort({id: sort.active, start: sort.direction, disableClear: true});
    }
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe((val) => {
      this.sortData(val);
    });

    let subInit$ = this.paginator.page
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.getApi({
            limit: this.paginator.pageSize,
            offset: this.paginator.pageIndex * this.paginator.pageSize,
            ...this.extParams,
            ...this.getFilter()
          }).pipe(
            tap(res => {
              this.notifyService.checkCode(res, false);
            }),
            catchError(resp => {
              let code = this.notifyService.setFormErrors(resp);
              return observableOf({list: [], total_count: 0, code: code});
            })
          );
        }),
        map((data: any) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.dataSource.data = [];
          this.isRateLimitReached = data.code == 502;
          this.resultsLength = data.total_count;
          return data.list;
        })
      )
      .pipe(mergeMap(data=>forkJoin(data.map(list =>
        this.userDomainService.get(list.agent_id, {__ignore__: {10002: true}})
          .pipe(
            map(user=> {
              let tier = list;
              tier.user_uid = user.uid;
              tier.user_id = user.user_id;
              tier.status_id = user.agent.status_id;
              return tier;
            }),
            catchError(error => of({} as Tier))
          )
      ))),
        mergeMap((data: any)=>
          forkJoin(
            data.map((list: any) =>
              this.userService.get(list.user_id, {__ignore__: {10002: true}})
                .pipe(
                  map(user=> {
                    let tier = list;
                    tier.user_name = (user?.surname?.length>0) ? `${user.surname} ${user.name}` : user.name;
                    //tier.user_name = user.name;
                    return tier;
                  }),
                  catchError(error => of({} as Tier))
                )
            )
          )
        )
      ).subscribe((data: any) => {
        this.dataSource.data = data;
        setTimeout(_=>this.sortData(this.sort), 800);
        //this.table.renderRows();
      }, error=>{
        this.dataSource.data = [];
        //this.table.renderRows();
      }
    );
    this.subscription.add(subInit$);
  }
}
