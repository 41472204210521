import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from '../material.module';
import { NewFolderDialogComponent } from './modals/newFolderDialog/newFolderDialog.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { RenameDialogComponent } from './modals/renameDialog/renameDialog.component';
import { LoadDialogComponent } from './modals/loadDialog/loadDialog.component';
import { AudioPlayerDialogComponent } from './modals/audioDialog/audio-player-dialog.component';
import { FileExplorerComponent } from './file-explorer.component';
import {TranslateModule} from '@ngx-translate/core';
import {NgxAudioPlayerModule} from '@khajegan/ngx-audio-player';
import {FileDialogComponent} from '../dialogs/modals/fileDialog/fileDialog.component';
import {PlayListDialogComponent} from './modals/playListDialog/play-list-dialog.component';
import {FileDialogSystemComponent} from '../dialogs/modals/fileDialog/fileDialog.system.component';
import {SpeechDialogComponent} from '../dialogs/modals/speechDialog/speechDialog.component';
import {DirectoryDialogComponent} from '../dialogs/modals/directoryDialog/directoryDialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatCardModule,
        FlexLayoutModule,
        MatIconModule,
        MatGridListModule,
        MatMenuModule,
        MatDialogModule,
        MatInputModule,
        FormsModule,
        MatButtonModule,
        TranslateModule,
        NgxAudioPlayerModule,
        MaterialModule
    ],
    declarations: [FileExplorerComponent, FileDialogComponent, SpeechDialogComponent, DirectoryDialogComponent, FileDialogSystemComponent, NewFolderDialogComponent,
        RenameDialogComponent, AudioPlayerDialogComponent, LoadDialogComponent, PlayListDialogComponent],
    exports: [FileExplorerComponent, FileDialogComponent, FileDialogSystemComponent]
})
export class FileExplorerModule {}
