import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Pipe({
  name: 'toMMSS'
})

export class ToMMSS implements PipeTransform {
  transform(s: number): string {
    return (s-(s%=60))/60+(9<s?':':':0')+s;
  }
}
