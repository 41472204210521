import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Pipe({
  name: 'momentDateTime'
})

export class MomentDateTimePipe implements PipeTransform {
  transform(value: string, lang, withSeconds: boolean = false, empty: boolean = false ): any {
    // lang - передан для того, чтобы пайп заметил изменение языка и сам перерисовал дату
    if (value) {
      return moment(value, 'YYYY-MM-DD HH:mm:ss').format(withSeconds ? 'L LTS' : 'L LT');
    }
    if (empty) {
      return '-';
    } else return moment().format('L');
  }
}
