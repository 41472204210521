import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DomainCRMRefEvent} from './event';


@Injectable({
  providedIn: 'root'
})


export class DomainCRMRefEventService extends BaseService<DomainCRMRefEvent> {
  private api: HttpClient;
  private notify: NotifyService;
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, AppConfig: JsonAppConfigService) {
    super(DomainCRMRefEvent.className(), http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.notify = notifyService;
  }
}
