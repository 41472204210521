import {AfterViewInit, Component, Directive, EventEmitter, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {merge, Observable, of as observableOf, Subscriber, Subscription, SubscriptionLike} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {NotifyService} from '../../_helpers/notify.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {DesktopColumns} from './desktop-columns';
import {DesktopService} from './base-desktop.service';


@Directive()
export class BaseTableDirectoryComponent implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns: string[] = [];
  displayedColFilter: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  extParams = {};
  empFilters = [];
  public config: PerfectScrollbarConfigInterface = {
    minScrollbarLength: 25,
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
    wheelSpeed: 2,
    wheelPropagation: true
  };
  methods = {get: 'get'};
  subscription: Subscription = new Subscription();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;
  @Input() columns: DesktopColumns[];
  @Input() change = new EventEmitter();
  constructor(public component: string, public api: DesktopService,
              public notifyService: NotifyService) {
  }


  ngOnInit(): void {
    this.displayedColumns = this.columns.filter(item=>item.checked).map(item=>item.id);
    this.displayedColFilter = this.columns.filter(item=>item.checked)
      .map(item=>`${item.id}_${item.filterType || 'empty'}`);
    this.api.changeMenu.subscribe(menu => {
      if (menu.name == this.component) {
        this.displayedColumns = menu.columns;
        this.displayedColFilter = this.columns.filter(item=>menu.columns.includes(item.id))
          .map(item=>`${item.id}_${item.filterType || 'empty'}`);
      }
    });
  }

  getApi(params) {
    return observableOf({list: [], total_count: 0, code: 200});
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    let subSort$ = this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    let subPage$ = merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        debounceTime(1200),
        distinctUntilChanged(),
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          return this.getApi({
            limit: this.paginator.pageSize,
            offset: this.paginator.pageIndex * this.paginator.pageSize,
            ...this.extParams,
            ...this.getFilter()
          }).pipe(
            tap(res => {
              this.notifyService.checkCode(res, false);
            }),
            catchError(resp => {
              let code = this.notifyService.setFormErrors(resp);
              return observableOf({list: [], total_count: 0, code: code});
            })
          );
        }),
        map((data: any) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data.code == 502;
          this.resultsLength = data.total_count;
          return data.list;
        })
      ).subscribe(data => {
        this.setDataSource(data);
      }
    );

    this.subscription.add(subSort$);
    this.subscription.add(subPage$);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setDataSource(data) {
    this.dataSource.data = data;
  }

  changeFilter(id: string, value: string | number) {
    if (typeof value == 'number' || value.length>=1) {
      let exists = this.empFilters?.find(item => item.name == id);
      if (exists) {
        exists.value = value;
      } else this.empFilters.push({name: id, value: value, type: 3});
      if (this.paginator && this.paginator.pageIndex != 0) this.paginator.pageIndex = 0;
      this.refresh();

    } else if (value.length==0) {
      let index = this.empFilters?.findIndex(item => item.name == id);
      this.empFilters.splice(index, 1);
      if (this.paginator && this.paginator.pageIndex != 0) this.paginator.pageIndex = 0;
      this.refresh();
    }

  }
  refresh() {

    if (this.paginator) this.paginator._changePageSize(this.paginator.pageSize);
  }

  getFilter() {
    if (this.empFilters.length>0) {
      let filterData = {filter: {field_list: [], type: 0}};
      for (let filter of this.empFilters) {
        filterData['filter']['field_list'].push({
          field: filter.name,
          condition_type: filter.type, // CONDITION_TYPE_EQUAL = 0, CONDITION_TYPE_STARTSWITH = 1,
                                       // CONDITION_TYPE_ENDSWITH = 2, CONDITION_TYPE_LIKE = 3
          value: filter.value
        });
      }
      return filterData;
    } else {
      return {};
    }
  }
}
