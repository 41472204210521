import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'br'
})

export class brPipe implements PipeTransform {
  transform(arr: any[] = []): string {
    let spans = [];
    if (arr.length > 1) {
      arr.forEach(item => {
        const span = document.createElement('span');
        span.innerHTML = item;
        spans.push(span.outerHTML);
        spans.push(document.createElement('br').outerHTML);
      });
      return spans.join('');

    } else if (arr.length == 1) return arr[0];
    else return '';
  }
}
