import {AfterViewInit, Component, OnInit } from '@angular/core';
import {AppService} from '../../../../../app.service';
import {DesktopKeptService} from '../../desktop-kept.service';
import {NotifyService} from '../../../../../_helpers/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseTableDirectoryComponent} from '../../../../_common/base-table-directory.component';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap, take, tap} from 'rxjs/operators';
import {DesktopService} from '../../../../_common/base-desktop.service';
import {CTIService} from "../../../../../cti-panel/cti-panel.service";
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {getOffset} from '../../../../../_helpers/base.function';
import {DomainService} from "../../../../../domains/domain.service";
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-history-transfer',
  templateUrl: './history-transfer.component.html',
  styleUrls: ['./history-transfer.component.css', '../../../../_common/desktop-table.scss']
})
export class HistoryTransferComponent extends BaseTableDirectoryComponent implements OnInit, AfterViewInit {

  filter = {};
  timezone = 'Europe/Moscow';
  constructor(public keptApi: DesktopKeptService, public api: DesktopService, public appService: AppService,
              public domainService: DomainService,
              public translate: TranslateService, public CTI: CTIService, public notifyService: NotifyService) {
    super('HistoryTransferComponent', api, notifyService);
    super.extParams = {
      sort: {dt: '-'},
      transferred_number: true
    };
    this.isLoadingResults = false;
    domainService.get_tz().subscribe(data => this.timezone = data);
  }

  getApi(params) {
    return this.keptApi.getCDRs(params);
  }

refresh(limit= 1000) {
    this.isLoadingResults = true;
    this.getApi({
      limit: limit,
      offset: 0,
      ...this.extParams,
      ...this.filter
    }).pipe(
      tap(res => {
        this.notifyService.checkCode(res, false);
      }),
      catchError(resp => {
        this.dataSource.data = [];
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = 0;

        let code = this.notifyService.setFormErrors(resp);
        return observableOf({list: [], total_count: 0, code: code});
      })
    ).subscribe(item=>{
      this.dataSource.data = [];
      this.dataSource.data = item?.list;
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.resultsLength = item?.list?.length || 0;
    });


}

ngAfterViewInit() {
  this.CTI.ctiCalls$.subscribe(calls=>{
    if (calls.length>0) {
      this.filter = {filter: {field_list: [], type: 0}};

        let abonent = (calls[0].direction == 1) ? calls[0].caller_id_number : calls[0].callee_id_number;


        this.filter['filter']['field_list'].push(
        {
          field: 'src',
          condition_type: 0,
          value: abonent
        },
          {field: 'dt', condition_type: 5,
            value: moment.utc().add(-1, 'year').unix()}
          );

      if (calls?.[0]?.status == 'ringing' || calls?.[0]?.status == 'attached') {
        this.refresh(5);
      }  else if (calls?.[0]?.status == 'ended') {
        this.dataSource.data = [];
      }

    }
    else {
      this.dataSource.data = [];
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.resultsLength = 0;
    }
  });
}

  ngOnInit(): void {
    super.ngOnInit();

  }
}
