import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../../../app.service';
import {DesktopColumns} from '../../desktop-columns';
import {AGENT_STATUS_LIST} from '../../../../_helpers/constant';
import {AuthenticationService} from '../../../../auth/authentication.service';
import {DomainAgentStatusService} from '../../../../domains/domain-agent-status/domain-agent-status.service';
import {Subscription} from 'rxjs';
import {DesktopService} from '../../base-desktop.service';
import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {CTIService} from "../../../../cti-panel/cti-panel.service";


@Component({
  selector: 'app-desktop-agent',
  templateUrl: './desktop-agent.component.html',
  styleUrls: ['./desktop-agent.component.scss',
    '../../desktop-list.component.scss'] //,
  //providers: [Monitor2Service]
})
export class DesktopAgentComponent implements OnDestroy, OnInit {

  @Input() columns: DesktopColumns[];
  public status_system = AGENT_STATUS_LIST;
  public showColumns: DesktopColumns[] = [];
  private subscription: Subscription = new Subscription();
  customAgentStatus = [];
  public config: PerfectScrollbarConfigInterface = {
    minScrollbarLength: 5,
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
    wheelSpeed: 2,
    wheelPropagation: true
  };
  constructor(public authenticationService: AuthenticationService,
              public appService: AppService,
              public CTI: CTIService,
              public desktopService: DesktopService,
              public agentStatus: DomainAgentStatusService) {
    let subAgent$ = agentStatus.toSelect( {domain_id: authenticationService.getDomain()}).subscribe(msg=> {
      this.customAgentStatus = msg;


      //let item of ((desktopService.agentData$ | async) | filterBy : ['user_uid']: (CTI.ctiUser$ | async))


    });

    this.subscription.add(subAgent$);


    desktopService.agentData$.subscribe(_=> {

      const data = _.find(item=>item.user_uid == CTI.ctiUser$.value)
      if (data) {
        if (data?.tiers?.length === 1 && !this.desktopService.currentQueue.value) this.desktopService.currentQueue.next(data?.tiers[0]);
      }
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.showColumns = this.columns.filter(item=>item.checked);
    let subMenu$ = this.desktopService.changeMenu.subscribe(menu => {
      if (menu.name == 'DesktopAgentComponent') {
        this.showColumns = this.columns.filter(item=>menu.columns.includes(item.id))
      }
    });

    this.subscription.add(subMenu$);
  }

  getStatus(id) {
    const stateArray = this.status_system.concat(this.customAgentStatus).filter(elem => elem.id == id);
    return (stateArray.length > 0) ? stateArray[0].name : '-';
  }

}
