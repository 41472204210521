import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Helper, IResponseBodyObject, IResponseListObject, IResponseObject} from '../_helpers/api.helpers';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {DomainChat} from './chat-messenger/chat';

const headers = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(public http: HttpClient, public notifyService: NotifyService,
              public router: Router, public route: ActivatedRoute, public authenticationService: AuthenticationService,
              public translate: TranslateService, public AppConfig: JsonAppConfigService) {
  }

  get_hot_keys(params: any = {}, action: string = 'get_call_hot_keys'): Observable<any> {
    const requestBody = Helper.requestFormat(action, 'DomainCTI', params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers}).pipe(
      tap(res => {
        this.notifyService.checkCode(res);
      })
    );
  }

  get_cti_settings(params: any = {}, action: string = 'get'): Observable<any> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, 'DomainCTI', params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers}).pipe(
      tap(res => {
        this.notifyService.checkCode(res);
      })
    );
  }


  file_upload(formData): Observable<any> {
    return this.http.post(this.AppConfig.getValue('apiUrl'), formData, {
      headers: {
        'Authorization': `Bearer ${this.authenticationService.currentUserValue?.token}`}
    })
      .pipe(
        map(data => {
          return data as IResponseObject
        }),
        // catchError(() => observableOf({} as T))
      )
  }

  set(obj_: DomainChat): Observable<IResponseBodyObject<DomainChat>>  {
    const id = obj_['id'];
    const domain_id = obj_['domain_id'];
    delete obj_['id'];
    delete obj_['domain_id'];

    let obj = {
      id: id,
      domain_id: domain_id,
      params: obj_
    }


    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }


    let requestBody = Helper.requestFormat('append', DomainChat.className(), obj);
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('id') && obj['id']) {
      requestBody = Helper.requestFormat('update', DomainChat.className(), obj);
    }

    return this.http.post<IResponseBodyObject<DomainChat>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }));
  }

  get_queue_member(id) {
    const requestBody = Helper.requestFormat('get_by_queue_attempt', 'DOCCustomer', {
      domain_id: this.authenticationService.currentUserValue.domain_id,
      app_queue_attempt_id: id
    });
    return this.http.post<IResponseBodyObject<DomainChat>>
    (this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        catchError(resp => {
          return of(null);
        }),
        map(data => {
          if (data?.body) return data.body;
          else return data
        }),
             );
  }
  get_user_member(id) {
    const requestBody = Helper.requestFormat('get_by_dialog_id', 'DOCCustomer', {
      domain_id: this.authenticationService.currentUserValue.domain_id,
      dialog_id: id
    });
    return this.http.post<IResponseBodyObject<DomainChat>>
    (this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        catchError(resp => {
          return of(null);
        }),
        map(data => {
          if (data?.body) return data.body;
          else return data
        }),
      );
  }
  get(): Observable<DomainChat> {

    const requestBody = Helper.requestFormat('get', DomainChat.className(), {
      domain_id: this.authenticationService.currentUserValue.domain_id,

    });


    return this.http.post<IResponseBodyObject<DomainChat>>
      (this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        catchError(resp => {
          return of(null);
        }),
        // tap(res => this.notifyService.checkCode(res) ),
        map((data: any) => {
          if (data?.body?.params) return {
            ...data.body?.params,
            id: data.body?.id,
            domain_id: data.body?.domain_id,
          } as DomainChat;

          else if (data?.body) return data.body;
          else return of(new DomainChat())
        }),

      );
  }


}
