import {Component, OnInit} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {AuthenticationService} from '../auth/authentication.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
@Component({
  selector: 'app-docs',
  template: `<iframe style="position: absolute; height: 100%" width="100%"
                     height="100%" frameBorder="0" [src]="urlSafe" >
              </iframe>`,
  styles: [ ]
})

export class DocsComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: false,
    suppressScrollX: true,
    suppressScrollY: false,
    wheelSpeed: 2,
    wheelPropagation: true
  };

  constructor(public sanitizer: DomSanitizer,
              public authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.authenticationService.getDockURL());
  }
}
