import {
  propObject,
  prop,
  alphaNumeric,
  alpha,
  compare,
  contains,
  creditCard,
  digit,
  email,
  greaterThanEqualTo,
  greaterThan,
  hexColor,
  json,
  lessThanEqualTo,
  lowerCase,
  maxLength,
  maxNumber,
  minNumber,
  password,
  pattern,
  lessThan,
  range,
  required,
  time,
  upperCase,
  url,
  propArray,
  minLength,
  minDate,
  maxDate,
  toBoolean,
  unique,
  port,
  oneOf,
  numeric,
  NumericValueType, minTime, choice
} from '@rxweb/reactive-form-validators';
import {ipValidator} from '@rxweb/reactive-form-validators/reactive-form-validators';
import {LSAudioList} from '../local-stream/local-stream';
import {DPCQueueEvaluation} from "../dialplans/components/queue/dialplan.queue";
import {DOCBaseRouteMessage} from "../domains/domain-omni-channel/doc-chat-plan/doc-chat-plan";
import {DirectionCall} from "../dashboard/dashboard";
import {DPCVoiceMail} from "../dialplans/components/voice-mail/dialplan.voice-mail";

export class DomainQueueCallbackScheduleParams {
  @required()
  @time({allowSeconds: true})
  f: string = '09:00:00';

  @required()
  @minTime({ fieldName: 'f', allowSeconds: true })
  t: string = '17:59:59';
}


export class DomainQueueCallbackScheduleLimitParams {
  @required()
  @time({allowSeconds: true})
  f: string = '09:00:00';

  @required()
  @minTime({ fieldName: 'f', allowSeconds: true })
  t: string = '17:59:59';

  @required()
  limit: number = 10;
}

export class DomainQueueCallbackLimitParams {

  [key: number]: DomainQueueCallbackScheduleLimitParams[];

}


export class DomainQueueDPTransferParams {
  @prop()
  order_hk  : string = '';

  @propObject(LSAudioList)
  order_voice: LSAudioList;


  @required() @minLength({value: 2})
  value : string;

  @required() @numeric({acceptValue: NumericValueType.Both}) @minNumber({value: -1}) @maxNumber({value: 5})
  annonce_period : number = 1;

}

export class DomainQueueRouteParams {
  @required() @digit()
  dp_number?: number;

  @required()
  call_evaluation: boolean = false;

  @required() @propObject(LSAudioList)
  audio_after: LSAudioList;

  @propArray(DPCQueueEvaluation)
  questions: Array<DPCQueueEvaluation> = [];
}

export class DomainQueueAnnouncement {
  @required() @digit()
  directory: number = 0;

  @required()
  directory_lang: string = 'ru';

  @required() @numeric({acceptValue: NumericValueType.Both}) @minNumber({value: -1}) @maxNumber({value: 5})
  period : number = 1;

  @required() @choice({minLength: 1, message: 'ERROR.REQUIRED'})
  say_list: number[] = [];
  // ANNOUNCEMENT_SAY_SAY = 0
  // ANNOUNCEMENT_SAY_POSITION = 1
  // ANNOUNCEMENT_SAY_WAITTIME = 2
  // ANNOUNCEMENT_SAY_CALLBACK = 3
  // ANNOUNCEMENT_SAY_DP = 4

  @propObject(LSAudioList)
  say: LSAudioList;
  @propObject(LSAudioList)
  on_join: LSAudioList;
  @propObject(LSAudioList)
  say_wait_time : LSAudioList;
  @propObject(LSAudioList)
  say_position : LSAudioList;

}

export class DomainQueueCallbackParams {
  @required()
  order_hk  : string;

  @required() @propObject(LSAudioList)
  order_voice: LSAudioList;

  @required() @propObject(LSAudioList)
  after_voice: LSAudioList;

  @required()
  order_on_noanswer: boolean = false;

  @required() @digit()
  expire: number = 10;

  @required() @digit()
  attempts_count : number = 3;

  @required() @digit()
  delay_next_call : number = 60;

  @required() @digit()
  group_id : number;

  @required() @numeric({acceptValue: NumericValueType.Both}) @minNumber({value: -1}) @maxNumber({value: 5})
  annonce_period : number = 1;

  @propObject(DomainQueueCallbackScheduleParams)
  schedule: DomainQueueCallbackScheduleParams;

  @propObject(DomainQueueCallbackLimitParams)
  limits: DomainQueueCallbackLimitParams;

  @propObject(DPCVoiceMail)
  vm: DPCVoiceMail;

  @required()
  external_callback: boolean = false;
  @prop()
  preview_hk: string;
  @prop() @propObject(LSAudioList)
  preview_great: LSAudioList;
  @required() @digit() @maxNumber({value: 600})
  preview_timeout: number = 60;  // Максимальное время ожидания предпросмотра в секундах
}

export class Queue {
  @prop() id?: number;
  @required()
  name = '';
  @prop()
  status = 1;
  @required() @digit()
  strategy = 1;
  @prop()
  mod_local_stream = 2147483647;

  @propObject(LSAudioList)
  moh_file_list: LSAudioList;

  // @propObject(DomainQueueCallbackParams)
  // callback: DomainQueueCallbackParams;

  @prop() @toBoolean()
  tier_rules_apply = false;
  @required() @digit()
  tier_rule_wait_second = 5;
  @prop() @toBoolean()
  tier_rule_wait_multiply_level = false;
  @prop() @toBoolean()
  tier_rule_no_agent_no_wait = false;
  @required() @digit()
  discard_abandoned_after = 60;
  @prop() @toBoolean()
  abandoned_resume_allowed = false;
  @required() @digit()
  max_wait_time = 0;
  @required() @digit()
  max_wait_time_with_no_agent = 0;
  @required() @digit()
  max_wait_time_with_no_agent_time_reached = 5;
  @required() @digit()
  ring_progressively_delay = 10;

  // @required() @digit()
  // annonce = 0;

  static className() {
    return 'DomainQueue';
  };

}


export class DomainQueueExtraOption {

  @prop()
  domain_id: number;

  @prop()
  queue_id: number;

  @propObject(DomainQueueCallbackParams)
  callback: DomainQueueCallbackParams;

  @propObject(DomainQueueDPTransferParams)
  dp: DomainQueueDPTransferParams;

  @propObject(DomainQueueAnnouncement)
  announcement: DomainQueueAnnouncement;

  @propObject(DomainQueueRouteParams)
  route: DomainQueueRouteParams;

  static className() {
    return 'DomainQueueExtraOption';
  };
}


