import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {NotifyService} from '../../../_helpers/notify.service';
import {FormControl, FormGroup} from '@angular/forms';
import {RxFormHelpers} from '../../../_helpers/form.helpers';


@Component({
  selector: 'app-phone-dialog',
  templateUrl: './phoneDialog.component.html',
  styleUrls: ['./phoneDialog.component.css']
})

export class PhoneDialogComponent implements OnInit {
  public form: FormGroup = new FormGroup({
    phone: new FormControl('', [
      RxwebValidators.required({message:'ERROR.REQUIRED'}),
      RxwebValidators.minLength({value: 10})
    ])
  });
  public rxFormHelpers = new RxFormHelpers();


  constructor(public dialogRef: MatDialogRef<PhoneDialogComponent>, public notifyService: NotifyService,
              @Inject(MAT_DIALOG_DATA) public data
              ) {

  }

  ngOnInit(): void {
  }

  save(form: FormGroup) {
    form.updateValueAndValidity();
    form.markAllAsTouched();
    if (form.valid) {
      this.dialogRef.close(form.value.phone);
    }

  }

  close() {
    this.dialogRef.close();
  }
}
