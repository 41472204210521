import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {iif, Observable, of as observableOf, throwError} from 'rxjs';
import {catchError, delay, retryWhen, tap, concatMap} from 'rxjs/operators';
import {Helper, IResponseBodyObject} from '../../_helpers/api.helpers';
import {BaseService} from '../../_helpers/base.service';
import {NotifyService} from '../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../auth/authentication.service';
import {JsonAppConfigService} from '../../config/json-app-config.service';
import {DomainExtension} from './marketplace';
const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})
export class MarketplaceService extends BaseService<DomainExtension> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService) {
    super(DomainExtension.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  tariff_option_list(params: any = {}, action: string = 'tariff_option_list'): Observable<IResponseBodyObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notifyService.checkCode(res);
        }),
        retryWhen(errors =>
          errors.pipe(// delay(3000), take(3)
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => {return (i == 2) || e.message == '403'},
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                observableOf(e).pipe(delay(3000))
              ))
          )),
        catchError((error) => observableOf({} as IResponseBodyObject<any>))
      );
  }

  test(params: any = {}, action: string = 'test_stg'): Observable<IResponseBodyObject<DomainExtension>>  {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, DomainExtension.className(), params);
    return this.http.post<IResponseBodyObject<DomainExtension>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }
}




