import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contactList'
})

export class ListContactPipe implements PipeTransform {
  wrap(contact: any): string {
    return `${contact.name}: ${contact.phone} ${contact.email}`;
  }

  transform(contacts: any): string {
    const contactsList: any[] = [];
    for (const i in contacts) {
      if (contacts[i].name) {
        contactsList.push(this.wrap(contacts[i]));
      }
    }
    return contactsList.join('\n');
  }
}
