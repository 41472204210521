import {Component, Input, Output, EventEmitter, AfterContentInit, ViewChild, OnChanges, SimpleChange} from '@angular/core';
import {RxFormHelpers} from '../_helpers/form.helpers';
import {NotifyService} from '../_helpers/notify.service';
import {ConfirmComponent} from '../dialogs/modals/confirm/confirm.component';
import { FileElement } from './model/element';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { NewFolderDialogComponent } from './modals/newFolderDialog/newFolderDialog.component';
import { RenameDialogComponent } from './modals/renameDialog/renameDialog.component';
import {MatGridList} from '@angular/material/grid-list';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import {LoadDialogComponent} from './modals/loadDialog/loadDialog.component';
import {TranslateService} from '@ngx-translate/core';
import {AudioService} from '../audio/audio.service';
import {HttpClient} from '@angular/common/http';
import {AudioPlayerDialogComponent} from './modals/audioDialog/audio-player-dialog.component';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {getUUID4} from '../_helpers/base.function';
import {MatTableDataSource} from '@angular/material/table';


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.scss']
})

export class FileExplorerComponent implements AfterContentInit, OnChanges {
  @ViewChild('grid') grid: MatGridList;
  @ViewChild('languageMenuTrigger') rootMenuTrigger: MatMenuTrigger;
  public selectedFiles: FileList
  public cols = 4;
  gridByBreakpoint = { xl: 12, lg: 10, md: 6, sm: 4, xs: 2 };
  @Input() fileElements: FileElement[];
  @Input() audioModel = null;
  @Input() canNavigateUp: boolean;
  @Input() path: string;
  @Input() read: boolean = false;
  @Input() tableView: boolean = true;
  @Input() isLoadingResults: boolean = true;
  @Input() isServerAvailable: boolean = true;
  @Input() filter: string = '';
  @Input() selectorId: string|null = null;

  @Output() folderAdded = new EventEmitter<{ label: string }>();
  @Output() fileAdded = new EventEmitter<{ label: string }>();
  @Output() elementRemoved = new EventEmitter<any[]>();
  @Output() elementRenamed = new EventEmitter<FileElement>();
  @Output() elementMoved = new EventEmitter<{ element: FileElement; moveTo: FileElement | string }>();
  @Output() navigatedDown = new EventEmitter<FileElement>();
  @Output() navigatedUp = new EventEmitter();
  @Output() navigatedFile = new EventEmitter<FileElement>();
  @Output() navigatedFolder = new EventEmitter<FileElement>();
  @Output() selectorIdChange = new EventEmitter<string|null>();
  public selectedRowIndex = null;
  public rxFormHelpers = new RxFormHelpers();
  public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['label', 'duration', 'id', 'actions'];

  constructor(public dialog: MatDialog, private mediaObserver: MediaObserver,
    public translate: TranslateService, public  http: HttpClient, public notify: NotifyService,
    public serviceApi: AudioService, private AppConfig: JsonAppConfigService) {
  }

  ngAfterContentInit() {
    this.mediaObserver.asObservable().subscribe((change: MediaChange[]) => {
      change.forEach((item) => {
        this.cols = this.gridByBreakpoint[item.mqAlias];
      });

    });


  }

  ngOnChanges(changes: {[propertyName: string]: SimpleChange}) {
    if (changes.fileElements)
    {
      this.dataSource.data = this.fileElements;
      if (this.audioModel) this.serviceApi.changeObject(this.audioModel);
    }
  }

  deleteElement(element: FileElement, tile: any) {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        tile._element.nativeElement.className += ' blinker';
        this.elementRemoved.emit([element, tile]);
      }
    });
  }

  deleteRow(row) {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        const index = this.dataSource.data.findIndex(el => el.key==row.key && el.value==row.value);
        this.dataSource.data.splice(index, 1);
        this.dataSource._updateChangeSubscription(); // Refresh the datasource

        this.elementRemoved.emit([row, null]);
      }
    });
  }

  navigateGo(element: FileElement) {
    if (element.type === 'folder' ) {
      this.navigatedFolder.emit(element);
      this.selectorIdChange.emit(element.value);
    }
  }

  navigate(element: FileElement, event, menuTrigger) {
    this.selectedRowIndex=element.value;
    if (element.type === 'folder' || element.go_to_folder) {
      // переход в папку нажатием на нее или по кнопке перехода через найденный файл
      this.navigatedDown.emit(element);
      this.selectorIdChange.emit(element.go_to_folder ? element.value : null);
      // при переходе в папку из найденного файла, подсвечиваю этот файл
    } else {
      this.navigatedFile.emit(element);
      this.selectorIdChange.emit(element.value);
      if (menuTrigger) this.openMenu(event, menuTrigger);
    }
  }

  navigateUp() {
    this.navigatedUp.emit();
  }

  moveElement(element: FileElement, moveTo: FileElement | string) {
    this.elementMoved.emit({ element: element, moveTo: moveTo });
  }

  openNewFolderDialog() {
    const dialogRef = this.dialog.open(NewFolderDialogComponent);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.folderAdded.emit({ label: res });
      }
    });
  }

  uploadFile(event) {
    this.selectedFiles = event.target.files as FileList;
    this.loadDialog();

  }

  parsePath(){
   let spl = this.path.split('/').filter(e=>e);
   let elem: FileElement[] = [];

    elem.push(
      {
        label: '',
        path: '/',
        value: '/',
        type: 'folder',
        link: ''
      }
    )


   spl.forEach((el, index,array) => {
     elem.push(
       {
         label: el,
         path: elem[index].value,
         value: elem[index].value+el+'/',
         type: 'folder',
         link: ''
       }
     )
   });
   return elem;


   // return this.path.match(/[^ ]+/g)
  }

  loadDialog() {
    const dialogRef = this.dialog.open(LoadDialogComponent, {data: {
      files: this.selectedFiles, path: this.path
    }});
    dialogRef.afterClosed().subscribe(result => {
      if (!result) result = dialogRef.componentInstance.message || [];
      for (let res of result) {
        this.fileAdded.emit({label: res});
      }
    });
  }

  openRenameDialog(element: FileElement) {
    const dialogRef = this.dialog.open(RenameDialogComponent);
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        element.label = res;
        this.elementRenamed.emit(element);
      }
    });
  }

  downloadFile(data: any) {
    let blob = new Blob([data], { type: data.type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
    }
  }

  downloadAudio(id, name) {
    this.serviceApi.get(id)
      .subscribe( data => {
        const link = `${this.AppConfig.getValue('sourceUrl')}${data.link}`;
        this.http.get(link,  {responseType: 'blob'})
          .subscribe(response => {
            const type = 'application/octet-stream';
            const blob = new Blob([response], { type: type});

            var downloadLink = document.createElement('a');
            downloadLink.setAttribute('download', `${name}.wav`)
            downloadLink.href = window.URL.createObjectURL(blob);
            document.body.appendChild(downloadLink);
            downloadLink.click();
          }, resp => {
            this.isServerAvailable = this.notify.setFormErrors(resp) != 502;
          });


      }, resp => {
        this.isServerAvailable = this.notify.setFormErrors(resp) != 502;
      });
  }

  openAudioPlayerDialog(id) {
    console.log(id);
    this.serviceApi.get(id)
      .subscribe( data => {
        const link = `${this.AppConfig.getValue('sourceUrl')}${data.link}`;
        const dialogRef = this.dialog.open(AudioPlayerDialogComponent, {data: {link: link}});
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
      }, resp => {
        this.isServerAvailable = this.notify.setFormErrors(resp) != 502;
      });
  }

  openMenu(event: MouseEvent, viewChild: MatMenuTrigger) {
    if (this.read) return;
    event.preventDefault();
    viewChild.openMenu();
  }

  menuRow(row) {

  }
}
