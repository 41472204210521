import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {Helper, IResponseBodyObject, IResponseListObject} from '../../_helpers/api.helpers';
import {Observable} from 'rxjs';
import {NotifyService} from '../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../_helpers/base.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../config/json-app-config.service';
import {SystemTextTemplate} from './system-text-template';

const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})


export class SystemTextTemplateService extends BaseService<SystemTextTemplate> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService) {
    super(SystemTextTemplate.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  list(params: any = {}, action: string = 'list'): Observable<IResponseListObject<SystemTextTemplate>> {
    params['sort'] = {name: '+'}
    return super.list(params, action);
  }

  get(id: number, params: any = {}): Observable<SystemTextTemplate> {
    let ignore = {};
    if (params.ignore) {
      ignore = {...params.ignore};
      delete params['ignore'];
    }
    const requestBody = Helper.requestFormat('get', this.objectName, { id: id, ...params});
    return this.http.post<IResponseBodyObject<SystemTextTemplate>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          if (res.code != 200 && (!res.notifies.length || !(ignore[res.notifies[0].msg_id]))) this.notifyService.checkCode(res);
        }),
        map(data => {
          this.changeObjectName(data.body);
          return data.body as SystemTextTemplate;
        }),
        // catchError(() => observableOf({} as SystemTextTemplate))
      );
  }

  delete(id: number|string, action: string = 'delete'): Observable<IResponseBodyObject<SystemTextTemplate>>  {
    const obj = {id: id};
    const requestBody = Helper.requestFormat(action, this.objectName, obj);
    return this.http.post<IResponseBodyObject<SystemTextTemplate>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  details(id, state?: any) {
    this.router.navigate([this.router.url.split('?')[0], 'edit', id], {state: state});
  }
}
