import {required, compare, alpha, minLength, prop} from '@rxweb/reactive-form-validators';

export class DomainBillingOptions {
  @prop()
  balance: number = 0;
  @prop()
  block_inbound: boolean = false;
  @prop()
  block_outbound: boolean = false;
  @prop()
  block_face: boolean = false;
}

export class Domain {
  billing_id: string | null;
  contact_list: ContactList[];
  contract_date_end: number | null;
  contract_date_start: number | null;
  contract_name: string;
  customer_name: string;
  fmc_uuid: string | null;
  id?: number;
  mode: number;
  name: string;
  notify_email_list: string[] = [];
  pbx_host_id: number;
  secret_token: string;
  status: number;
  tariff_ext: number[] = [];
  tariff_id: number;
  test_until: string|null = null;
  users = {};
  billing_options: DomainBillingOptions | null;

  static className() {
    return 'Domain';
  };
}


export class ContactList {
  name = '';
  phone = '';
  email = '';
}


export class LetterConfig {
  @required() subject_id: number;
  @required() html_body_id: number;
  @required() txt_body_id: number;
  @prop() text: string = '';
}
