import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {IResponseListObject} from '../../../_helpers/api.helpers';
import {Observable} from 'rxjs';
import {generateOrderedGUID} from '../../../_helpers/base.function';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DPCScheduleLeg, ToolDates, ToolTimes, ToolWeeks} from '../../../dialplans/components/schedule/dialplan.schedule';
import {
  DOCBaseMessageParams,
  DOCBaseRouteMessage,
  DOCChatPlan,
  DOCRMConditionRouter,
  DOCRMConditionRouterCondition,
  DOCRMConditionRouterParams,
  DOCRMConditionRouterRow,
  DOCRMConditionRouterRowCallCount,
  DOCRMConditionRouterRowQueueAgentAvailable,
  DOCRMConditionRouterRowUserAvailable,
  DOCRMConditionRouterRowQueueLen,
  DOCRMConditionRouterRowQueueSL,
  DOCRMConditionRouterRowAgentAnswer,
  DOCRMConditionRouterRowValueCompare,
  DOCRMDefaultLeg,
  DOCRMExternalControl,
  DOCRMExternalControlCondition,
  DOCRMExternalControlParams,
  DOCRMIGEntitySearch,
  DOCRMIGEntitySearchParams,
  DOCRMLegMenuConditions,
  DOCRMMenu,
  DOCRMMenuParams,
  DOCRMNotify,
  DOCRMNotifyParams,
  DOCRMQueue,
  DOCRMQueueParams,
  DOCRMSchedule,
  DOCRMScheduleParams,
  DOCRMSingleQuestion,
  DOCRMSingleQuestionAnswer,
  DOCRMSingleQuestionAnswerParams,
  DOCRMSingleQuestionParams,
  DOCRMTransfer,
  DOCRMTransferParams,
  DOCRMUser,
  DOCRMUserParams,
  DPEScheduleConditions,
  MultiLegs,
  DOCRMDetailForm,
  DOCRMDetailFormParams,
  DOCRMDuplicateByEmail, DOCRMDuplicateByEmailParams
} from './doc-chat-plan';


@Injectable({
  providedIn: 'root'
})


export class DOCChatPlanService extends BaseService<DOCChatPlan> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService, protected fb: RxFormBuilder) {
    super(DOCChatPlan.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  list(params: any = {}, action = 'list'): Observable<IResponseListObject<DOCChatPlan>> {
    params['sort'] = {name: '+'}
    return super.list(params, action);
  }

  getLabel(controlValue) {
    switch (controlValue.model_name) {
      case 'DOCRMNotify': return controlValue.params?.text;
      case 'DOCRMIGEntitySearch': return controlValue.params?.pm_name ? this.translate.instant('DEP'+ controlValue.params?.pm_name.toUpperCase()) : controlValue.params?.pm_name;
      // case 'DOCRMExternalControl': return controlValue.params?.control_name;
      case 'DOCRMSingleQuestion': return controlValue.params?.question;
      case 'DOCRMSingleQuestionAnswer': return controlValue.params?.value;
      default: return controlValue.params?.name;
    }
  }

  getNextUID(uids) {
    if (uids.length > 0) {
      let lastUid = uids.sort()[uids.length -1].substring(0, 8);
      let lastUidInt: number;
      try {
        lastUidInt = parseInt(lastUid, 10);
        if (parseInt(lastUid.substring(0, 8 - lastUidInt.toString().length), 10) == 0) return generateOrderedGUID(lastUidInt + 1);
      } catch (e) {}
    }
    return generateOrderedGUID(uids.length + 1);
  }

  createScheduleCondition(data: any = {}) {
    let scCondition = new DPCScheduleLeg();
    if (data.times?.length > 0) scCondition.times = Array.from<ToolTimes>(data.times);
    if (data.date_range?.length > 0) scCondition.date_range = Array.from<ToolDates>(data.date_range);
    if (data.week_days && Object.keys(data.week_days).length > 0) scCondition.week_days = new ToolWeeks();

    let scConditionRx = this.fb.formGroup(scCondition);
    if (scConditionRx.get('month_days') && data.month_days == null){
      scConditionRx.removeControl('month_days');
    }

    return scConditionRx;
  }

  getConditionModel(model_name) {
    let rxModel;
    switch (model_name) {
      case 'DOCRMConditionRouterRowCallCount': rxModel = this.fb.formGroup(new DOCRMConditionRouterRowCallCount()); break;
      case 'DOCRMConditionRouterRowQueueLen': rxModel = this.fb.formGroup(new DOCRMConditionRouterRowQueueLen()); break;
      case 'DOCRMConditionRouterRowQueueAgentAvailable': rxModel = this.fb.formGroup(new DOCRMConditionRouterRowQueueAgentAvailable()); break;
      case 'DOCRMConditionRouterRowUserAvailable': rxModel = this.fb.formGroup(new DOCRMConditionRouterRowUserAvailable()); break;
      case 'DOCRMConditionRouterRowValueCompare': rxModel = this.fb.formGroup(new DOCRMConditionRouterRowValueCompare()); break;
      case 'DOCRMConditionRouterRowQueueSL': rxModel = this.fb.formGroup(new DOCRMConditionRouterRowQueueSL()); break;
      case 'DOCRMConditionRouterRowAgentAnswer': rxModel = this.fb.formGroup(new DOCRMConditionRouterRowAgentAnswer()); break;
      default: rxModel = this.fb.formGroup(new DOCRMConditionRouterRow());
    }
    return rxModel;
  }

  legModel(model_name) {
    let model;
    switch(model_name) {
      /* Одноножки */
      case 'DOCRMNotify':
        model = new DOCRMNotify();
        model.params = new DOCRMNotifyParams();
        break;
      case 'DOCRMUser':
        model = new DOCRMUser();
        model.params = new DOCRMUserParams();
        break;
      case 'DOCRMQueue':
        model = new DOCRMQueue();
        model.params = new DOCRMQueueParams();
        break;
      case 'DOCRMIGEntitySearch':
        model = new DOCRMIGEntitySearch();
        model.params = new DOCRMIGEntitySearchParams();
        break;
      case 'DOCRMDetailForm':
        model = new DOCRMDetailForm();
        model.params = new DOCRMDetailFormParams();
        break;
      case 'DOCRMSingleQuestion':
        model = new DOCRMSingleQuestion();
        model.params = new DOCRMSingleQuestionParams();
        break;
      case 'DOCRMTransfer':
        model = new DOCRMTransfer();
        model.params = new DOCRMTransferParams();
        break;
      case 'DOCRMDuplicateByEmail':
        model = new DOCRMDuplicateByEmail();
        model.params = new DOCRMDuplicateByEmailParams();
        break;
      case 'DOCRMSingleQuestionAnswer':
        model = new DOCRMSingleQuestionAnswer();
        model.params = new DOCRMSingleQuestionAnswerParams();
        break;

      /* Многоножки */
      case 'DOCRMMenu':
        model = new DOCRMMenu();
        model.params = new DOCRMMenuParams();
        model.params.conditions = new DOCRMLegMenuConditions();
        model.default_leg = new DOCRMDefaultLeg();
        model.legs = new MultiLegs();
        break;
      case 'DOCRMSchedule':
        model = new DOCRMSchedule();
        model.params = new DOCRMScheduleParams();
        model.params.conditions = new DPEScheduleConditions();
        model.default_leg = new DOCRMDefaultLeg();
        model.legs = new MultiLegs();
        break;
      case 'DOCRMExternalControl':
        model = new DOCRMExternalControl();
        model.params = new DOCRMExternalControlParams();
        model.params.conditions = new DOCRMExternalControlCondition();
        model.default_leg = new DOCRMDefaultLeg();
        model.legs = new MultiLegs();
        break;
      case 'DOCRMConditionRouter':
        model = new DOCRMConditionRouter();
        model.params = new DOCRMConditionRouterParams();
        model.params.conditions = new DOCRMConditionRouterCondition();
        model.default_leg = new DOCRMDefaultLeg();
        model.legs = new MultiLegs();
        break;
      default:
        model = new DOCBaseRouteMessage();
        model.params = new DOCBaseMessageParams();
    }
    let modelRx;
    if (model) {
      modelRx = this.fb.formGroup(model);
      modelRx.setControl('params', this.fb.formGroup(model.params));
      if (
        (
          model_name == 'DOCRMMenu' ||
          model_name == 'DOCRMExternalControl' ||
          model_name == 'DOCRMConditionRouter' ||
          model_name == 'DOCRMSchedule'
        ) &&
        modelRx.get(['params', 'conditions', 'deletingKey'])
      ) {
        modelRx.get(['params', 'conditions']).removeControl('deletingKey');
      }
      if (model.default_leg) modelRx.setControl('default_leg', this.fb.formGroup(model.default_leg));
      if (model.legs) {
        modelRx.setControl('legs', this.fb.formGroup(model.legs));
        if (modelRx.get('legs')?.get('deletingKey')) modelRx.get('legs').removeControl('deletingKey');
      }
    }
    return modelRx;
  }

  paramsModel(model_name) {
    return this.legModel(model_name).get('params');
  }
}
