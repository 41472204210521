import {
  propObject,
  prop,
  alphaNumeric,
  alpha,
  compare,
  contains,
  creditCard,
  digit,
  email,
  greaterThanEqualTo,
  greaterThan,
  hexColor,
  json,
  lessThanEqualTo,
  lowerCase,
  maxLength,
  maxNumber,
  minNumber,
  password,
  pattern,
  lessThan,
  range,
  required,
  time,
  upperCase,
  url,
  propArray,
  minLength,
  minDate,
  maxDate,
  toBoolean,
  unique,
  port,
  oneOf,
  numeric,
  NumericValueType
} from '@rxweb/reactive-form-validators';


export class Tier {
  @prop() id?: number;
  @prop() domain_id: number;
  @required() agent_id: number;
  @required() user_id?: number;
  @required() queue_id: number;
  @required() level: number = 1;
  @required() position: number = 1;

  @prop() status_id?: number;
  @prop() name?: string = '';
  @prop() extension?: string = '';


  static className() {
    return 'DomainTier';
  };

}
