import {Component, ElementRef, QueryList, ViewChildren} from '@angular/core';
import {AppService} from '../../../../../app.service';
import {DesktopKeptService} from '../../desktop-kept.service';
import {NotifyService} from '../../../../../_helpers/notify.service';
import {BaseTableDirectoryComponent} from '../../../../_common/base-table-directory.component';
import {ConfirmComponent} from '../../../../../dialogs/modals/confirm/confirm.component';
import {MatDialog} from '@angular/material/dialog';
import {ExternalClientsDialogComponent} from './external-clients-dialog.component';
import {DesktopService} from '../../../../_common/base-desktop.service';
import {MatSelect} from '@angular/material/select';
import {CTIService} from "../../../../../cti-panel/cti-panel.service";

@Component({
  selector: 'app-external-clients',
  templateUrl: './external-clients.component.html',
  styleUrls: ['./external-clients.component.css', '../../../../_common/desktop-table.scss']
})
export class ExternalClientsComponent extends BaseTableDirectoryComponent {
  @ViewChildren('filter') filtersInput: QueryList<ElementRef>;
  @ViewChildren('filterSelect') filtersSelect: QueryList<MatSelect>;
  constructor(public api: DesktopService, public CTI: CTIService, public keptApi: DesktopKeptService, public appService: AppService, public dialog: MatDialog,
              public notifyService: NotifyService) {
    super('ExternalClientsComponent', api, notifyService);
    super.extParams = {
      sort: {name: '+'}
    };
    super.methods = this.methods;
    this.api.searchOffEvent.subscribe(evt=> {
      if (evt == 'ExternalClientsComponent') this.searchOff();
    });
  }

  getApi(params) {
    return this.keptApi.getClients(params);
  }
  getFilter() {
    if (this.empFilters.length>0) {
      let filterData = {filter: {field_list: [], type: 0}};
      for (let filter of this.empFilters) {

        let query = {
          field: filter.name,
          condition_type: filter.type // CONDITION_TYPE_EQUAL = 0, CONDITION_TYPE_STARTSWITH = 1,
                                       // CONDITION_TYPE_ENDSWITH = 2, CONDITION_TYPE_LIKE = 3
        };
        if (filter.name == 'name' || filter.name == 'comment') {
          query['value_condition_type'] = 1;
          query['value_list'] = [filter.value, this.api.getTranslateText(filter.value)];

        } else {
          query['value'] = filter.value
        }
        filterData['filter']['field_list'].push(query);
      }
      return filterData;
    } else {
      return {};
    }
  }
  onDelete(row) {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        row.blinker = true;
        this.keptApi.deleteClient(row.id).subscribe(
          data => {
            this.notifyService.showRequestResult(data, true);
            this.refresh();
          },
          resp => {
            row.blinker = false;
            this.refresh();
            this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
          });
      }
    });
  }
  onEdit(row: any = {}) {
    if (!row?.id) {
      if (this.CTI.ctiCalls$.value?.length>0) {
        row['phone'] = this.CTI.ctiCalls$.value[0].caller_id_number;
      }
    }
    const confirmDialog = this.dialog.open(ExternalClientsDialogComponent, {
      data: {
        title: (row?.id) ? 'DIALOG.EDIT_CONTACT_TITLE' : 'DIALOG.СREATE_CONTACT_TITLE',
        form: row
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        row.blinker = true;
        this.keptApi.editClient(result).subscribe(
          data => {
            this.notifyService.showRequestResult(data, true);
            this.refresh();
          },
          resp => {
            row.blinker = false;
            this.refresh();
            this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
          });
      }
    });
  }
  searchOff() {
    this.filtersInput?.forEach(item=>{
      item.nativeElement.value = '';
    })
    this.filtersSelect?.forEach(item=>{
      item.value = '';
    })
    this.empFilters = [];
    if (this.paginator && this.paginator.pageIndex != 0) this.paginator.pageIndex = 0;
    this.refresh();
  }
}
