import {NgModule} from "@angular/core";
import {ChatComponent} from "./chat.component";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialModule} from "../material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {CdkTableModule} from "@angular/cdk/table";
import {PipeModule} from "../_helpers/pipe.module";
import {MatTableResponsiveModule} from "../material-component/mat-table-responsive/mat-table-responsive.module";
import {SkeletonLoaderModule} from "../_helpers/skeleton-loader.module";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {AvatarModule} from "ngx-avatar-2";
import {KnowledgebaseModule} from "../knowledgebase/viewer/knowledgebase.module";
import {NgArrayPipesModule, NgPipesModule} from "ngx-pipes";
import {NgSelectModule} from "@ng-select/ng-select";
import {FieldModule} from "../_helpers/dynamic/field.module";
import {GravatarModule} from "ngx-gravatar";
import {ModalBoxModule} from "../modal-box/modal-box.module";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {FileExplorerModule} from "../file-explorer/file-explorer.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {NgxAudioPlayerModule} from "@khajegan/ngx-audio-player";
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";
import {DomainExtraDetailFormModule} from "../domains/domain-detail-form/domain-extra-detail-form.module";


@NgModule({
  declarations: [
    ChatComponent
  ],
  exports: [
    ChatComponent
  ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatDatepickerModule, MatMomentDateModule,
        NgxAudioPlayerModule,
        RxReactiveFormsModule,
        NgSelectModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CdkTableModule,
        PipeModule,
        MatTableResponsiveModule,
        SkeletonLoaderModule,
        PerfectScrollbarModule,
        AvatarModule,
        KnowledgebaseModule,
        NgPipesModule,
        NgSelectModule,
        FieldModule,
        GravatarModule, AvatarModule,
        NgArrayPipesModule,
        ModalBoxModule,
        AngularEditorModule,
        FileExplorerModule, DomainExtraDetailFormModule
    ]
})
export class MainChatModule {

}
