import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {NotifyService} from '../../../_helpers/notify.service';
import {FormControl, FormGroup} from '@angular/forms';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {PERIOD_LOCKED_LIST} from '../../../_helpers/constant'


@Component({
  selector: 'app-date-dialog',
  templateUrl: './dateDialog.component.html',
  styleUrls: ['./dateDialog.component.css']
})

export class DateDialogComponent implements OnInit {
  public form: FormGroup = new FormGroup({
    period: new FormControl(-1, [
      RxwebValidators.required({message:'ERROR.REQUIRED'})
    ])
  });
  public rxFormHelpers = new RxFormHelpers();
  period_locked_list = PERIOD_LOCKED_LIST;


  constructor(public dialogRef: MatDialogRef<DateDialogComponent>, public notifyService: NotifyService,
              @Inject(MAT_DIALOG_DATA) public data
              ) {

  }

  ngOnInit(): void {
  }

  save(form: FormGroup) {
    form.updateValueAndValidity();
    form.markAllAsTouched();
    if (form.valid) {
      this.dialogRef.close(form.value.period);
    }

  }

  close() {
    this.dialogRef.close();
  }
}
