import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService, headers} from '../_helpers/base.service';
import {AuthenticationService} from '../auth/authentication.service';
import {VoiceDirectory} from './voice-directory';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {Observable} from 'rxjs';
import {Helper, IResponseBodyObject} from '../_helpers/api.helpers';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class VoiceDirectoryService extends BaseService<VoiceDirectory> {
  constructor(http: HttpClient,
              notifyService: NotifyService,
              router: Router,
              route: ActivatedRoute,
              authenticationService: AuthenticationService,
              AppConfig: JsonAppConfigService) {
    super(VoiceDirectory.classNameDomain(), http, notifyService, router, route, authenticationService, AppConfig);
    this.changeObject();
  }
  changeObject(object = null) {
    if (object) {
      super.objectName = object;
    }
    else if (this.isDomainView()) {
      super.objectName = VoiceDirectory.classNameDomain();
    } else if (this.isSystemView()) {
      super.objectName = VoiceDirectory.classNameSystem();
    } else {

    }
  }
  get(id: number, params: any = {}, action: string = 'get'): Observable<VoiceDirectory> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue?.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, { ...params});
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'),
      requestBody, {headers: headers})
      .pipe(
        tap(res => {
          // @ts-ignore
          if (res.code != 200 && (!res.notifies.length || res.notifies[0].msg_id != 10002)) this.notifyService.checkCode(res);
        }),
        map(data => {
          // this.changeObjectName(data.body);
          if (this.authenticationService.currentUserValue?.domain_id)
          {
            return {
              ...data.body?.params,
              id: data.body?.id,
              domain_id: data.body?.domain_id,
            } as VoiceDirectory
          }
          else {
            return {
              ...data.body
            } as VoiceDirectory
          }
        }),
        // catchError(() => observableOf({} as T))
      );
  }

  save(obj_: any, action_append: string = 'append', action_update: string = 'update'): Observable<IResponseBodyObject<VoiceDirectory>>  {
    const id = obj_['id'];
    const domain_id = obj_['domain_id'];
    delete obj_['id'];
    delete obj_['domain_id'];

    const sys_domain = this.authenticationService.currentUserValue?.domain_id;
    let obj = {};
    if (sys_domain) {
      obj = {
        id: id,
        domain_id: sys_domain,
        params: obj_
      }
    } else {
      action_append = 'update';
      obj = obj_;
    }


    let requestBody = Helper.requestFormat(action_append, this.objectName, obj);
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('id') && obj['id']) {
      requestBody = Helper.requestFormat(action_update, this.objectName, obj);
    }
    return this.http.post<IResponseBodyObject<VoiceDirectory>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }
}
