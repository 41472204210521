import {prop, digit, maxLength, required, minLength, propArray} from '@rxweb/reactive-form-validators';

export class DomainCRMRefEvent{
  @prop() id?: number;
  @required() @minLength({value: 3}) name: string;
  @prop() desc: string = '';
  @required() etype: number = 0; // CRM_EVENT_TYPE_CALL = 0 | CRM_EVENT_TYPE_MSG = 1 | CRM_EVENT_TYPE_NOTE = 2

  static className() {
    return 'DomainCRMRefEvent';
  }
}

export class DomainCRMEventDField {
  @prop() id?: number;
  @digit() event_id: number;
  @required() @digit() field_id: number;
  @maxLength({value: 250}) str_val: string;
  @prop() int_val: number;
  @prop() bool_val: boolean;
  @prop() dt_val: number;
  @prop() list_val: any[] = [];
  @prop() txt_val: string;

  static className(){
    return 'DomainCRMEventDField';
  }
}

export class DomainCRMEvent{
  @prop() id?: number;
  @required() @digit() entity_id: number;
  @required() @digit() ref_event_id: number;
  @prop() name: string = '';
  @prop() desc: string = ''; // комментарий
  @required() create_dt: number = Math.floor(Date.now()/1000);
  @required() start_dt: number = Math.floor(Date.now()/1000);
  @prop() end_dt: number;
  @required() @digit() responsible_user_id: number;
  @required() @digit() author_user_id: number;
  @propArray(DomainCRMEventDField) dfields: DomainCRMEventDField[];

  // @prop() with_dfields: boolean = true;

  static className() {
    return 'DomainCRMEvent';
  };
}
