import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secret'
})

export class SecretPipe implements PipeTransform {
  transform(value: string, replaceChar?: string): any {
    if (!value || !(value.startsWith('__SECRET__') || value.startsWith('secret'))) return value;

    if (!replaceChar) replaceChar = '*';

    return value.replace(/__SECRET__/g, replaceChar.repeat(10));
  }
}
