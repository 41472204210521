import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {BigScreenService} from '../../../../_helpers/bigscreen.service';
import {DesktopControlPanelService} from './desktop-control-panel.service';
import {FormControl} from '@angular/forms';
import {DomainConference} from '../../../../domains/domain-conference/domain-conference';
import {NotifyService} from '../../../../_helpers/notify.service';

@Component({
  selector: 'app-desktop-control-panel',
  templateUrl: './desktop-control-panel.component.html',
  styleUrls: ['./desktop-control-panel.component.css']
})
export class DesktopControlPanelComponent implements OnInit, OnDestroy {

  isCardExpanded: boolean = false;
  @Input() activeTabIndex: number = 0;
  @Output() tabChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(public bigScreenService: BigScreenService,
              public api: DesktopControlPanelService,
              public notifyService:  NotifyService,
              public elRef: ElementRef) {
  }

  ngOnInit(): void { }

  ngOnDestroy() { }

  fullscreen() {
    let doc = this.elRef.nativeElement.parentNode.parentNode;

    if (this.bigScreenService.isFullscreen()) {
      this.bigScreenService.exit();
      this.isCardExpanded = false;
    }
    else {
      this.bigScreenService.request(doc);
      this.isCardExpanded = true;
    }

  }

  openTab() {
    if (this.activeTabIndex == 0) {
      this.activeTabIndex = 1;
    } else if (this.activeTabIndex == 1) {
      this.activeTabIndex = 0;
    }
    this.tabChange.emit(this.activeTabIndex);
  }
}
