import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Helper, IResponseListObject, IResponseBodyObject, ISelect} from '../_helpers/api.helpers';
import {forkJoin, from, Observable, of as observableOf, throwError, timer} from 'rxjs';
import {catchError, concatAll, delay, delayWhen, filter, map, retry, retryWhen, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../_helpers/base.service';
import {AuthenticationService} from '../auth/authentication.service';
import {Queue} from './queue';
import {Tier} from './tier';
import {User} from '../users/user';
import {DomainUserService} from '../users/domain-user.service';
import {JsonAppConfigService} from '../config/json-app-config.service';
const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})


export class AgentService extends BaseService<Tier> {
  private api: HttpClient;
  private notify: NotifyService;
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, AppConfig: JsonAppConfigService) {
    super(Tier.className(), http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.notify = notifyService;
  }

  agentNew(agent_id, queue_id) {
    return this.save({
      agent_id: agent_id,
      level: 1,
      position: 1,
      domain_id: this.getDomain(),
      queue_id: parseInt(queue_id, 10)
    })
  }

}
