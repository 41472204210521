import {Injectable, OnDestroy} from '@angular/core';
import {headers} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DomainService} from '../../../domains/domain.service';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, iif, Observable, of as observableOf, Subject, Subscription, throwError} from 'rxjs';
import {Helper, IResponseBodyObject, IResponseListObject} from '../../../_helpers/api.helpers';
import {catchError, delay, map, mergeMap, retryWhen, tap, concatMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../../_helpers/notify.service';
import {UserService} from '../../../users/user.service';
import {DomainUserService} from '../../../users/domain-user.service';
import {AgentService} from '../../../queues/agent.service';
import {QueueService} from '../../../queues/queue.service';
import {AGENT_STATUS_LIST} from '../../../_helpers/constant';
import {DomainAgentStatusService} from '../../../domains/domain-agent-status/domain-agent-status.service';
import {DesktopAgentComponent} from '../../_common/components/desktop-agent/desktop-agent.component';
import {StorageMap} from '@ngx-pwa/local-storage';
import {MatButton} from '@angular/material/button';


@Injectable({
  providedIn: 'root'
})
export class DesktopKeptService implements OnDestroy {
  subscription: Subscription = new Subscription();
  constructor(public authenticationService: AuthenticationService,
              public AppConfig: JsonAppConfigService,
              public http: HttpClient,
              public notifyService: NotifyService,
              public domainService: DomainService,
              public queueService: QueueService,
              public tierService: AgentService,
              public storage: StorageMap,
              public agentStatus: DomainAgentStatusService,
              public translate: TranslateService) {

  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  eapiList(params: any = {}, action: string = 'list'): Observable<any[]> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, 'KeptClient', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('sourceUrl')+'eapi/', requestBody, {headers: headers})
      .pipe(
        tap(res => {
          //this.notifyService.checkCode(res);
        }),
        map(data => data.list as any[]
        ),
        retryWhen(errors => errors.pipe(
          // delay(3000),
          // take(3)
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => {return (i == 2) || e.message == '403'},
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              observableOf(e).pipe(delay(3000))
            ))
        )),
        catchError((error) => {
          console.log(error);
          return observableOf([] as any[]);
        })
      );
  }
  eRoleList(params: any = {}, action: string = 'permission_list'): Observable<any[]> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, 'ERole', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('sourceUrl')+'eapi/', requestBody, {headers: headers})
      .pipe(
        tap(res => {
          //this.notifyService.checkCode(res);
        }),
        map(data => data.list as any[]
        ),
        retryWhen(errors => errors.pipe(
          // delay(3000),
          // take(3)
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => {return (i == 2) || e.message == '403'},
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              observableOf(e).pipe(delay(3000))
            ))
        )),
        catchError((error) => {
          console.log(error);
          return observableOf([] as any[]);
        })
      );
  }
  sendMail(params: any = {}): Observable<IResponseBodyObject<any>>  {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    let requestBody = Helper.requestFormat('send_missing_call_email', 'KeptStaffDirectory', params);
    // eslint-disable-next-line no-prototype-builtins

    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('sourceUrl')+'eapi/', requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res, false);
        })
      );
  }
  deleteClient(id: number, action: string = 'delete'): Observable<IResponseBodyObject<any>>  {
    if (typeof id === 'string') id = parseInt(id, 10);
    const obj = {id: id};
    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, 'KeptClient', obj);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('sourceUrl')+'eapi/', requestBody, {headers: headers})
      .pipe(tap(res => {  this.notifyService.checkCode(res, false);      }));
  }
  editClient(obj: any, action_append: string = 'append', action_update: string = 'update'): Observable<IResponseBodyObject<any>>  {
    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    let requestBody = Helper.requestFormat(action_append, 'KeptClient', obj);
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('id') && obj['id']) {
      requestBody = Helper.requestFormat(action_update, 'KeptClient', obj);
    }
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('sourceUrl')+'eapi/', requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res, false);
        })
      );
  }
  getDA(params: any = {}, action: string = 'get_da_list'): Observable<IResponseBodyObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, 'KeptStaffDirectory', params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('sourceUrl')+'eapi/', requestBody, {headers: headers})
      .pipe(
        tap(res => {
          //this.notifyService.checkCode(res);
        }),
        retryWhen(errors => errors.pipe(
          // delay(3000),
          // take(3)
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => {return (i == 2) || e.message == '403'},
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              observableOf(e).pipe(delay(3000))
            ))
        ))
      );
  }

  staffDirectory(params: any = {}, action: string = 'list_with_detail'): Observable<IResponseListObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, 'KeptStaffDirectory', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('sourceUrl')+'eapi/', requestBody, {headers: headers})
      .pipe(
        tap(res => {
          //this.notifyService.checkCode(res);
        }),
        retryWhen(errors => errors.pipe(
          // delay(3000),
          // take(3)
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => {return (i == 2) || e.message == '403'},
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              observableOf(e).pipe(delay(3000))
            ))
        ))
      );
  }
  getClients(params: any = {}, action: string = 'list'): Observable<IResponseListObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, 'KeptClient', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('sourceUrl')+'eapi/', requestBody, {headers: headers})
      .pipe(
        tap(res => {
          //this.notifyService.checkCode(res);
        }),
        retryWhen(errors => errors.pipe(
          // delay(3000),
          // take(3)
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => {return (i == 2) || e.message == '403'},
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              observableOf(e).pipe(delay(3000))
            ))
        ))
      );
  }
  getCDRs(params: any = {}, action: string = 'list'): Observable<IResponseListObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, 'KeptCDR', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('sourceUrl')+'eapi/', requestBody, {headers: headers})
      .pipe(
        tap(res => {
          //this.notifyService.checkCode(res);
        }),
        retryWhen(errors => errors.pipe(
          // delay(3000),
          // take(3)
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => {return (i == 2) || e.message == '403'},
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              observableOf(e).pipe(delay(3000))
            ))
        ))
      );
  }
}
