export const last_chat = [
  {
    from: 'Сидоров Иван',
    photo: 'assets/images/users/r4.jpg',
    icon: 'message',
    comment: 'Перезвон в 13:10'
  },
  {
    from: 'Корельская Елена',
    photo: 'assets/images/users/r1.jpg',
    icon: 'phone',
    comment: 'Ждет КП'
  }
];

export const messages = [{
    from: 'Стародубцев Сергей',
    photo: 'assets/images/users/r4.jpg',
    email: 's@runtel.ru',
    subject: 'Спасибо!',
    phone: ['1008', '89081435926'],
    no_read: 2,
    status_chat: 'В сети',
    status_phone: 'Перерыв',

    chat: [
        {
            type: 'odd',
            msg: 'Лида, привет',
            date: new Date('2022-08-31')
        },
        {
            type: 'odd',
            msg: 'Залей правки в гит',
            date: new Date('2022-08-31')
        },
        {
            type: 'even',
            msg: 'Готово',
            date: new Date('2022-08-31')
        },
        {
            type: 'odd',
            msg: 'Какой билд?',
            date: new Date('2022-08-31')
        },
        {
            type: 'even',
            msg: '2.17.5',
            date: new Date('2022-08-31')
        },
        {
            type: 'odd',
            msg: 'Спасибо!',
            date: new Date('2022-08-31')
        }
    ]
}, {
    from: 'Нестеров Николай',
    photo: 'assets/images/users/r3.jpg',
    email: 'nesterov@runtel.ru',
    subject: 'Привет',
    phone: ['1030', '1031', '89304077677'],
    no_read: 1,
    status_chat: 'Не в сети',
    status_phone: 'Выключен',
    chat: [
        {
            type: 'odd',
            msg: 'Привет',
            date: new Date('2022-08-31')
        },
        {
            type: 'even',
            msg: 'Приевт',
            date: new Date('2022-08-31')
        }
    ]
}, {
    from: 'Паневин Алексей',
    photo: 'assets/images/users/r2.jpg',
    email: 'a@runtel.ru',
    subject: 'уже',
    phone: ['1022'],
    no_read: 0,
    status_chat: 'Занят',
    status_phone: 'В очереди',
    chat: [
        {
            type: 'odd',
            msg: 'Подключайся к конференции',
            date: new Date('2022-08-31  15:10:04')
        },
        {
            type: 'even',
            msg: 'уже',
            date: new Date('2022-08-31 15:12:00')
        }
    ]
}, {
    from: 'Игорь Петров',
    photo: 'assets/images/users/r1.jpg',
    email: 'i@runtel.ru',
    subject: 'Уже связался',
    phone: ['1025'],
    no_read: 0,
    status_chat: 'Не в сети',
    status_phone: 'Разговаривает',
    chat: [
        {
            type: 'odd',
            msg: 'Клиент звонил, спрашивал тебя',
            date: new Date('2022-08-31')
        },
        {
            type: 'even',
            msg: 'Уже связался',
            date: new Date('2022-08-31')
        }
    ]
}];
