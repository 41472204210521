import {
  propObject,
  prop,
  alphaNumeric,
  alpha,
  compare,
  contains,
  creditCard,
  digit,
  email,
  greaterThanEqualTo,
  greaterThan,
  hexColor,
  json,
  lessThanEqualTo,
  lowerCase,
  maxLength,
  maxNumber,
  minNumber,
  password,
  pattern,
  lessThan,
  range,
  required,
  time,
  upperCase,
  url,
  propArray,
  minLength,
  minDate,
  maxDate,
  toBoolean,
  unique,
  port,
  oneOf,
  date,
  minTime,
  maxTime, minTimeAsync, maxTimeAsync, choice
} from '@rxweb/reactive-form-validators';


export class ToolTimes {
  @required()
  @time({allowSeconds: true})
  //@maxTime({ fieldName: 't', allowSeconds: true })
  f: string = '09:00:00';

  @required()
  //@time({allowSeconds: true})
  @minTime({ fieldName: 'f', allowSeconds: true })
  t: string = '17:59:59';
}

export class ToolDates {
  @required()
  //@date()
  f: string;

  @required()
  //@date()
  t: string;
}

export class ToolWeeks {

  @prop()
  monday: boolean = false;

  @prop()
  tuesday: boolean = false;

  @prop()
  wednesday: boolean = false;

  @prop()
  thursday: boolean = false;

  @prop()
  friday: boolean = false;

  @prop()
  saturday: boolean = false;

  @prop()
  sunday: boolean = false;
}



export class DPCSchedule {
  @prop() name: string = '';
  @required() tz: string = 'Europe/Moscow';
}

export class DPCScheduleLeg {
  @propArray(ToolTimes) times?: ToolTimes[];
  @propArray(ToolDates) date_range?: ToolDates[];
  @prop() month_days?: Array<number>;
  @propObject(ToolWeeks) week_days?: ToolWeeks;

  @prop({defaultValue: false})
  production_weekend_days: boolean = false;

  @prop({defaultValue: false})
  production_working_days: boolean = false;
}
