import {Injectable, OnDestroy} from '@angular/core';
import {headers} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DomainService} from '../../../domains/domain.service';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, iif, Observable, of as observableOf, Subject, Subscription, throwError} from 'rxjs';
import {Helper, IResponseBodyObject, IResponseListObject} from '../../../_helpers/api.helpers';
import {catchError, delay, map, mergeMap, retryWhen, tap, concatMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../../_helpers/notify.service';
import { DesktopColumns } from '../../_common/desktop-columns';
import {AgentService} from '../../../queues/agent.service';
import {QueueService} from '../../../queues/queue.service';
import {AGENT_STATUS_LIST} from '../../../_helpers/constant';
import {DomainAgentStatusService} from '../../../domains/domain-agent-status/domain-agent-status.service';
import {DesktopAgentComponent} from '../../_common/components/desktop-agent/desktop-agent.component';
import {StorageMap} from '@ngx-pwa/local-storage';
import {MatButton} from '@angular/material/button';
import {DesktopService, MenuEvent} from '../../_common/base-desktop.service';



@Injectable({
  providedIn: 'root'
})
export class DesktopDefService implements OnDestroy {
  subscription: Subscription = new Subscription();
  constructor(public authenticationService: AuthenticationService,
              public AppConfig: JsonAppConfigService,
              public http: HttpClient,
              public notifyService: NotifyService,
              public domainService: DomainService,
              public queueService: QueueService,
              public tierService: AgentService,
              public storage: StorageMap,
              public agentStatus: DomainAgentStatusService,
              public translate: TranslateService) {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  getCDRs(params: any = {}, action: string = 'list'): Observable<IResponseListObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, 'DomainCDR', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('sourceUrl')+'api/', requestBody, {headers: headers})
      .pipe(
        tap(res => {
          //this.notifyService.checkCode(res);
        }),
        retryWhen(errors => errors.pipe(
          // delay(3000),
          // take(3)
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => {return (i == 2) || e.message == '403'},
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              observableOf(e).pipe(delay(3000))
            ))
        ))
      );
  }
}
