import { Component, OnInit, Input } from '@angular/core';
import { FieldModel } from './field.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.css']
})
export class FieldComponent implements OnInit {
  @Input() field: FieldModel;
  @Input() formFields: FormGroup = new FormGroup({});
  get isValid() {
    return !this.formFields.controls[this.field.id].touched || this.formFields.controls[this.field.id].valid;
  }
  constructor() {
    this.formFields.valueChanges.subscribe(value => console.log(value))
  }

  ngOnInit() {
  }

}
