import {Component} from '@angular/core';

@Component({
  selector: 'app-skeleton-loader-leg',
  template: `
    <form>

    <mat-card-content  fxLayout="row wrap" class="m-b-0 m-t-0 p-l-10 p-r-10">
      <div class="p-l-0 p-r-0" fxFlex.gt-sm="7" fxFlex.gt-xs="10" fxFlex="100">
            <ngx-skeleton-loader animation="pulse"
                                 appearance="circle"
                                 count="2"
                                 [theme]="{ width: '45px', height: '45px' }">
            </ngx-skeleton-loader>
      </div>

      <div class="p-l-0 p-r-10" fxFlex.gt-sm="15" fxFlex.gt-xs="30" fxFlex="100">
        <mat-form-field floatLabel="always" appearance="none">
          <div>
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-0 p-r-10" fxFlex.gt-sm="15" fxFlex.gt-xs="30" fxFlex="100">
        <mat-form-field floatLabel="always" appearance="none">
          <div>
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-0 p-r-10" fxFlex.gt-sm="13" fxFlex.gt-xs="30" fxFlex="100">
        <mat-form-field floatLabel="always" appearance="none">
          <div>
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-0 p-r-10" fxFlex.gt-sm="15" fxFlex.gt-xs="30" fxFlex="100">
        <mat-form-field floatLabel="always" appearance="none">
          <div>
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-0 p-r-10" fxFlex.gt-sm="15" fxFlex.gt-xs="30" fxFlex="100">
        <mat-form-field floatLabel="always" appearance="none">
          <div>
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-0 p-r-10" fxFlex.gt-sm="15" fxFlex.gt-xs="30" fxFlex="100">
        <mat-form-field floatLabel="always" appearance="none">
          <div>
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>

      <div class="p-l-0 p-r-0" fxFlex.gt-sm="5" fxFlex.gt-xs="10" fxFlex="100">
        <ngx-skeleton-loader animation="pulse"
                             appearance="circle"
                             count="2"
                             [theme]="{ width: '38px', height: '38px' }">
        </ngx-skeleton-loader>
      </div>


    </mat-card-content>

  </form>
  `,
  styleUrls: []
})

export class SkeletonLoaderLegComponent {}
