import {Directive, OnInit} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';


export const PAGE_SIZE_KEY = 'paginatorPageSize';

@Directive({
  selector: '[matLocalStoragePageSize]'
})
export class MatLocalStoragePageSize implements OnInit {
  private element: MatPaginator;

  get pageSize() {
    return parseInt(localStorage.getItem(PAGE_SIZE_KEY), 10);
  }

  set pageSize(size: number){
    localStorage.setItem(PAGE_SIZE_KEY, size.toString());
  }

  constructor(private el: MatPaginator) {
    this.element = el;
  }

  ngOnInit() {
    this.element.showFirstLastButtons = true;
    this.element.pageSize = this.pageSize;

    this.element.page.subscribe(
      (page: PageEvent) => {
        this.pageSize = page.pageSize;
      }
    )
  }
}
