import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Helper, IResponseBodyObject, IResponseListObject} from '../_helpers/api.helpers';
import {Observable, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService, headers} from '../_helpers/base.service';
import {AuthenticationService} from '../auth/authentication.service';
import {User} from './user';
import {JsonAppConfigService} from '../config/json-app-config.service';


@Injectable({
  providedIn: 'root'
})


export class UserService extends BaseService<User> {
  private api: HttpClient;
  private notify: NotifyService;

  private usersSource = new Subject<IResponseListObject<User>>();
  users$ = this.usersSource.asObservable();

  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, AppConfig: JsonAppConfigService) {
    super('User', http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.notify = notifyService;
  }

  list(params: any = {}, action: string = 'list'): Observable<IResponseListObject<User>> {
    let is_preloading = params.is_preloading;
    delete params.is_preloading;
    let copy_params = Object.assign({}, params);

    // if (this.isRoleOperator()) {
    //   return this.get(this.getUser().user_id).pipe(
    //     map(data=>{
    //       return <IResponseListObject<User>> {
    //         list: [data],
    //         total_count: 1
    //       }
    //     })
    //   ).pipe(
    //     map(data => {
    //       this.usersSource.next(Object.assign(data, {is_preloading: is_preloading, params: copy_params}));
    //       return data;
    //     })
    //   );
    // } else
      return super.list(params, action).pipe(
      map(data => {
        this.usersSource.next(Object.assign(data, {is_preloading: is_preloading, params: copy_params}));
        return data;
      })
    );
  }

  clear_list(){
    this.usersSource.next(Object.assign({list: [], total_count: 0} as IResponseListObject<User>, {clear: true}));
  }

 getBotData(botName: string, action: string, params: any = {}): Observable<IResponseBodyObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    let obj: string;
    if (botName == 'tg') obj = 'TelegramSystemBotMember';

    const requestBody = Helper.requestFormat(action, obj, params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue(this.requestPathKey), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          // @ts-ignore
          if (res.code != 200 && (!res.notifies.length || res.notifies[0].msg_id != 10002)) this.notifyService.checkCode(res, false);
        })
      );
  }

  connectToBot(botName: string, action: string, params: any = {}): Observable<IResponseBodyObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    let obj: string;
    if (botName == 'tg') obj = 'TelegramSystemBotMember';

    const requestBody = Helper.requestFormat(action, obj, params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue(this.requestPathKey), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }
}
