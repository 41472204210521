import {Injectable} from '@angular/core';
import {QueueService} from "./queue.service";


@Injectable({
  providedIn: 'root'
})


export class EapiQueueService extends QueueService {
  protected requestPathKey: string = 'sourceUrl';
  protected extraPath: string = 'eapi/';
}
