import {
  Component,
  ViewChild,
  ElementRef,
  OnDestroy,
  ViewChildren,
  AfterViewInit,
  OnInit, Input
} from '@angular/core';
import {AppService} from '../app.service';
import {last_chat, messages} from './chat-data';
import {QueueService} from '../queues/queue.service';
import {DomainUserService} from '../users/domain-user.service';
import {MatTableDataSource} from '@angular/material/table';
import {DomainCRMEntityService} from '../domains/domain-crm/entities/entity.service';
import {AuthenticationService} from '../auth/authentication.service';
import {
  forkJoin,
  Observable,
  Subject,
  SubscriptionLike,
  of,
  BehaviorSubject,
  merge,
  of as observableOf
} from 'rxjs';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {ChatService} from './chat.service';
import {NotifyService} from '../_helpers/notify.service';
import {UserService} from '../users/user.service';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {User} from '../users/user';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {extFromBase64, getDayOfWeekOrToday, getUUID4} from '../_helpers/base.function';
import {DOCChatPlanService} from "../domains/domain-omni-channel/doc-chat-plan/doc-chat-plan.service";
const moment = _rollupMoment || _moment;
import {formatBytes} from "../_helpers/base.function";
import {FormControl} from "@angular/forms";
import {DOCAgentService} from "../domains/domain-omni-channel/doc-agent/doc-agent.service";
import {DOCQueueService} from "../domains/domain-omni-channel/doc-queue/doc-queue.service";
import {AGENT_STATUS_LIST, PERM_SELECT_LIST} from "../_helpers/constant";
import {DomainAgentStatusService} from "../domains/domain-agent-status/domain-agent-status.service";
import {DOCChatPlan} from "../domains/domain-omni-channel/doc-chat-plan/doc-chat-plan";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {MatDialog} from "@angular/material/dialog";
import {ChatGroupDialogComponent} from "./dialogs/chat-group-dialog.component";
import {DOCAgent} from "../domains/domain-omni-channel/doc-agent/doc-agent";
import {CTIService} from "../cti-panel/cti-panel.service";
import {DOCChannelService} from "../domains/domain-omni-channel/doc-channel/doc-channel.service";
import {DomainService} from "../domains/domain.service";
declare var require: any;
const jsonpath = require('jsonpath-plus');

enum DOCRM_TRANSFER {
  DST_CHATPLAN = 0,
  DST_AGENT = 1,
  DST_QUEUE = 2
}

interface DetailFormViewData {detail_form: any, long_detail_form: any, omni_customer: any, omni_dialog: any, call_session: any}

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnDestroy, OnInit, AfterViewInit {

    private messages$; // сообщения сокета
    private messagesSub: SubscriptionLike;
    public toggleKnowBaseEvent = new Subject<any>();
    private filterSub: SubscriptionLike;
    public showFilter: boolean = false;
    public detailFormViewData: DetailFormViewData;
    public link = '';
    public agent_status_list = [];
    public config: PerfectScrollbarConfigInterface = {
      useBothWheelAxes: false, suppressScrollX: true, suppressScrollY: false
    };

    PERM_SELECT_LIST = PERM_SELECT_LIST;
    them;
    user;
    sidePanelOpened = true;
    basePanelOpened = false;
    detailPanelOpened = false;
    msg: string;
    send='';
    @ViewChild('chatBox') chatBox;
    @ViewChild('menuBox') menuBox;
    @ViewChild('transferNumber') transferNumber;
    @ViewChildren('msg') chatMessages;
    // MESSAGE
    selectedMessage: any;
    selectedChat: any;
    typing: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    typingTimer;
    messages: Object[] = messages;
    chats: Object[] = last_chat;
    chatPlans = [];
    public users$: Observable<User[]>
    public chatQueueList$ = new BehaviorSubject([]);
    public chatPersonalList$ = new BehaviorSubject([]);
    public chatGroupList$ = new BehaviorSubject([]);
    public chatUserList$ = new BehaviorSubject([]);
    public requestQueue$ = new BehaviorSubject([]);
    public requestUser$ = new BehaviorSubject([]);
    public headers = {};
    public channels = {};
    public filter$ = new Subject();
    public chat_messages$ = new BehaviorSubject([]);
    public dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    public selectedChatId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public chatHotKeys: any[] = [];
    public defaultTransferFilterEntity: FormControl = new FormControl(DOCRM_TRANSFER.DST_AGENT);
    DOCRM_TRANSFER = DOCRM_TRANSFER;
    private doc_agent_list: any[];
    private doc_queue_list: any[];
    private doc_dp_list: any[];

    @Input('chats') chatTypes = 'group,personal,omni';
    @Input('know') know = false;


    private doc_agent_count = 0;
    private doc_queue_count = 0;
    private doc_dp_count = 0;
    public JSONPath = jsonpath.JSONPath;

    public answerOmniON = false;

    domain_queues: any;
    public search$: Subject<any> = new Subject<any>();
    public searchSub;
    loadingDomainUsers: any;
    lines_calls: any;
    messageAgentSelect = [];
    showToolbarEditor = false;
    configEditor: AngularEditorConfig = {
      editable: true,
      showToolbar: this.showToolbarEditor,
      placeholder: this.notifyService.translate.instant('EMAIL_DIALOG.ENTER_EXTRA_TEXT'),
      height: 'auto',
      minHeight: '10px',
      maxHeight: 'auto',
      width: '100%',
      minWidth: '100%',
      translate: 'yes',
      sanitize: false,
      toolbarPosition: 'bottom',
      toolbarHiddenButtons: [
        [],
        ['toggleEditorMode',
          'customClasses',
          'link',
          'textColor', 'backgroundColor','insertHorizontalRule',
          'unlink',
          'insertVideo']
      ]
    };
    private doc_channel_list = [];


    constructor(public queueService: QueueService,
                public domainUserService: DomainUserService,
                public userService: UserService,
                public crmService: DomainCRMEntityService,
                public auth: AuthenticationService,
                public notifyService: NotifyService,
                public translate: TranslateService,
                public appService: AppService,
                public CTI: CTIService,
                public agentStatusService: DomainAgentStatusService,
                public api: ChatService,
                private el: ElementRef,
                public chatApi: DOCChatPlanService,
                public docAgentService: DOCAgentService,
                private dialog: MatDialog,
                public domainService: DomainService,
                public docQueueService: DOCQueueService,
                public docChannelService: DOCChannelService
                ) {
      this.answerOmniON = (localStorage?.getItem('chatCustomer::autoAnswer') == 'true');
      if (localStorage?.getItem('defaultTransferFilterEntity')) this.defaultTransferFilterEntity.setValue(+localStorage?.getItem('defaultTransferFilterEntity'));
      this.messages$ = this.appService.on<any>();
      this.messagesSub = this.messages$.subscribe(
        msg => this.onMessage(msg),
        err => console.log(err),
        () => console.log('complete')
      );

      if (auth.hasPerm(DOCAgent.className(), PERM_SELECT_LIST, true)) {
        this.docAgentService.toSelect({}, 'select')
          .subscribe(data=>this.messageAgentSelect=data);
      }


      this.docChannelService.toSelect({}, 'select').subscribe(data => this.doc_channel_list = data);

      this.agentStatusService.toSelect().subscribe(data => this.agent_status_list = data);


      if (auth.hasPerm(DOCChatPlan.className(), PERM_SELECT_LIST, true)) {
        this.chatApi.toSelect({}, 'select').subscribe(
          chat => this.chatPlans = chat
        );
      }

      merge(this.requestQueue$, this.requestUser$)
        .subscribe(()=>{
          const data = [...this.requestQueue$.value, ...this.requestUser$.value];
          this.CTI.chatEvent$.next(data?.length);
          if (this.answerOmniON) {
            setTimeout(_=>{
              if (data?.length>0) {
                console.log(data[0]);
                this.allowChat(data[0], data[0]?.message?.model_name=="DOCRMQueueAttemptRequest", this.send == '')
              }
            }, 3000)
          }
        })

      merge(this.chatQueueList$, this.chatUserList$)
        .subscribe(()=>{
        const data = [...this.chatQueueList$.value, ...this.chatUserList$.value];
        this.CTI.chatEventDialogs$.next(data?.length);
        console.log(data);
        if (data.length == 0) {
          this.selectedChat = null;
          this.chat_messages$.next([]);
        } else if (this.selectedChatId.value) {
          this.onOmniChatSelect(
            data.find(item=>item.dialog_id == this.selectedChatId.value))
        } else if (data.find(item=>item.dialog_id == this.selectedChat?.dialog_id)) {
          this.onOmniChatSelect(data.find(item=>item.dialog_id == this.selectedChat?.dialog_id))
        }  else {
        }

      })

      this.chat_messages$.subscribe((data: any)=>{
        if (this.selectedChat) this.selectedChat.noread = data.filter(item=>item.status < 2 && item.sender === -1)?.length
        const noread = data.find(item=>item.status < 2  && item.sender === -1)?.id

        if (noread) {
          this.chatBox.nativeElement.scrollTop = 0;
          setTimeout(()=> {
            const elem = document.getElementById(`message_${noread}`)
            if (elem) {
              this.chatBox.nativeElement.scrollTo({
                top: Math.max(elem.offsetTop-5-this.chatBox.nativeElement.clientHeight, 0)
              })
            }
          }, 500)

        }
        else { this.scrollToBottom() }

        setTimeout(item=>{
          this.el.nativeElement.querySelectorAll('a[href]').forEach((ind: any) => ind.target = "_blank" );
        }, 1000)

      })
      this.users$ = this.userService.toSelect({}, 'select');
    }
    setAnswerOmni(state: boolean) {
      this.answerOmniON = state;
      if (localStorage) {
          localStorage.setItem('chatCustomer::autoAnswer', String(state))
      }
    }
    ngOnInit() {
      console.log('init chat');
      console.log(this.chatTypes);
      if (this.appService.authenticationService.isLic('Built-inChat')
          && (this.isGroup() || this.isPersonal())) {
        console.log('Built-inChat', this.appService.readyState);
        this.appService.subscribe(
          [{name: 'chat|internal', params: {params: this.appService.authenticationService.getUserId()}}]
        );
      }

      if (this.appService.authenticationService.isLic('Built-inCustomerChat')
          && this.isOmni()) {
        console.log('Built-inCustomerChat', this.appService.readyState);
        this.appService.subscribe([{name: 'omni|agent|dialog'}]);
      }
    }

    ngAfterViewInit() {
      this.searchSub = this.search$.pipe(
        debounceTime(800), // в течении этого времени не учитываются изменения в поле поиска
        distinctUntilChanged(),
        switchMap((filterValue: any) => { // отменяю предыдущий запрос и начинаю новый
          this.loadingDomainUsers = true;
          this.doc_agent_list = [];
          this.doc_queue_list = [];
          this.doc_dp_list = [];
          this.doc_agent_count = 0;
          this.doc_queue_count = 0;
          this.doc_dp_count = 0;

          if (!filterValue?.trim().toLowerCase()) {
            this.loadingDomainUsers = false;
            return observableOf([]);
          }

          this.loadingDomainUsers = true;
          return this.getEntityFilter(filterValue?.trim().toLowerCase());
        })
      ).subscribe(
        data => {this.loadingDomainUsers = false;},
        resp => {this.loadingDomainUsers = false;}
      );
    }

    ngOnDestroy() {
      this.appService.unsubscribe(['omni|agent|dialog', 'chat|internal']);
      this.messagesSub.unsubscribe();
      if (this.searchSub) this.searchSub.unsubscribe();
    }

    isLeftOver(): boolean {
        return window.matchMedia('(max-width: 599px)').matches;
    }

    isRightOver(): boolean {
      return window.matchMedia('(max-width: 960px)').matches;
    }

    getChatHotKeys() {
      this.chatHotKeys = [];
      forkJoin([
        this.api.get_hot_keys({}, 'get_chat_hot_keys'),
        this.api.get_cti_settings()
      ]).pipe(
        map(([hot_keys, settings])=>{
          if (hot_keys?.body?.groups && settings?.body?.params?.chat_hk) {

            for (const [group, item] of Object.entries(hot_keys.body?.groups)) {
              Object.keys(item).forEach(key=>{
                const ob_set = settings.body.params.chat_hk[group].find(_=>_.id==key)
                if (ob_set) {
                  this.chatHotKeys.push({
                    key: key,
                    plan: item[key][0],
                    indicator: item[key][1],
                    settings: ob_set,
                    icon: ob_set.icon,
                    dst_type: ob_set.action,
                    dst_id: item[key][0] || ob_set.value,
                    name: ob_set.name

                  });
                }
              })
            }
          }

        })
      ).subscribe(()=>console.log(this.chatHotKeys));

    }

    OnAddMsg(value) {

        if (value != '') {
          value = value.replaceAll('<div><br></div>', '').replaceAll('<br><br><br>', '').replaceAll('<br>', '\n').replaceAll('   ', ' ');

          const placeholder = document.createElement("div");
          placeholder.innerHTML = value;

          //let html_value = new DOMParser().parseFromString(value, "text/html");




          let imgs = placeholder.querySelectorAll('img[src]');

          if (imgs.length>0 && this.selectedChat.type == 'omni') {


            imgs.forEach((img: HTMLElement) => {
              console.log(placeholder.innerText)

              this.sendPicture(this.selectedChat.dialog_id, img.getAttribute('src'), placeholder.innerText);
            })

          } else {
            this.sendMessage(this.selectedChat.dialog_id, this.selectedChat.type, value)
          }




        }
    }

    toBytes(bytes) {
      return formatBytes(bytes);
    }
    openFilter(filterInput) {
      this.showFilter = !this.showFilter;
      setTimeout(() => filterInput.focus(), 0);
    }
    applyFilter(filterValue: string) {
      // this.filter = filterValue.trim().toLowerCase(); // Remove whitespace; MatTableDataSource defaults to lowercase matches
      //if (this.paginator && this.paginator.pageIndex != 0) {
      //  this.paginator.pageIndex = 0;
      //}
      // при поиске возвращаю пользователя на первую страницу
      //this.refresh();
    }

    getChannel(id) {
      return this.doc_channel_list.find(item=>item.id == id);
    }

    public trackItem (index: number, item: any) {
      return item.id;
    }

    public onMessage(msg: any = {}) {
      //console.log('form. received message:', msg);

      if (typeof msg === 'string') msg = JSON.parse(msg);
      let member_index, member;

      if (msg.code === 407 || msg.code === 401) { // токена нет или он устарел
        this.notifyService.messageByCode(msg.code);
        // remove user from local storage to log user out
        this.notifyService.auth.logout();
        return false;
      }

      switch (`${msg.action}::${msg.obj}`) {
        case 'auth::WebSocketMember':  // событие установки соединения с сокетом, после которого нужно подписаться на нужные события
          if (this.appService.authenticationService.isLic('Built-inChat') && (this.isGroup() || this.isPersonal())) {
            this.appService.subscribe(
              [{name: 'chat|internal', params: {params: this.appService.authenticationService.getUserId()}}]
            );
          }
          if (this.appService.authenticationService.isLic('Built-inCustomerChat') && this.isOmni()) {
            this.appService.subscribe([{name: 'omni|agent|dialog'}]);
          }
        break;
        case 'subscribe::WebSocketMember':
          if (msg.code == 200) {
            if (msg?.body?.name == 'chat|internal') {
              if (this.isPersonal()) this.getPersonalChats();
              if (this.isGroup()) this.getGroupChats();
            } else if (msg?.body?.name == 'omni|agent|dialog') {
              if (this.isOmni()){
                this.headers = {};
                this.channels = {};
                this.updateChatQueueList();
                this.updateChatUserList();
              }

            }
          }
          break;

        case 'append::DICGroupMessage':
          if (msg.code == 200) {
            if (msg?.body?.message?.model_name === 'DICHMTypes') {
              if (msg?.body?.sender !== this.auth.getUserId()) {
                this.typing.next({
                  sender: msg?.body?.sender,
                  dialog_id: msg?.body?.dialog_id || msg?.body?.chat_id,
                  value: msg?.body?.message?.params?.value
                })
                clearTimeout(this.typingTimer)
                this.typingTimer = setTimeout(_ => this.typing.next(null), 1000)
              }
            } else {
              if (this.selectedChat) this.onGroupChatSelect(this.selectedChat)
              this.getGroupDialogs([msg.body.chat_id]);
              if (msg.body.sender === this.auth.getUserId()) {
                setTimeout(() => this.scrollToBottom(), 300);
              }
              // this.updateChatQueueList()
            }
          }
          break;
        case 'append::DICPersonalMessage':
          if (msg.code == 200) {
            if (msg?.body?.message?.model_name === 'DICHMTypes') {
              if (msg?.body?.sender !== this.auth.getUserId()) {
                this.typing.next({
                  sender: msg?.body?.sender,
                  dialog_id: msg?.body?.dialog_id || msg?.body?.chat_id,
                  value: msg?.body?.message?.params?.value
                })
                clearTimeout(this.typingTimer)
                this.typingTimer = setTimeout(_ => this.typing.next(null), 1000)
              }
            } else {
              if (this.selectedChat) this.onPersonalChatSelect(this.selectedChat)
              this.getPersonalDialogs([msg.body.chat_id]);
              if (msg.body.sender === this.auth.getUserId()) {
                setTimeout(() => this.scrollToBottom(), 300);
              }
              // this.updateChatQueueList()
            }
          }
          break;


        case 'append::DOCDialogMessage':
          if (msg.code == 200) {
            if (msg?.body?.message?.model_name === 'DOCHMTypes' ||
                msg?.body?.message?.model_name === 'DOCHMSimpleTextPreview') {
              if (msg?.body?.sender !== this.auth.getUserId()) {
                this.typing.next({
                  sender: msg?.body?.sender,
                  dialog_id: msg?.body?.dialog_id || msg?.body?.chat_id,
                  value: msg?.body?.message?.params?.value
                })
                clearTimeout(this.typingTimer)
                this.typingTimer = setTimeout(_ => this.typing.next(null), 1000)
              }
            } else if (msg?.body?.message?.model_name === 'DOCRMQueueAttemptAccept' ||
              msg?.body?.message?.model_name === 'DOCRMQueueAttemptCancel') {
              setTimeout(()=>this.updateChatQueueList(), 300)
            } else if (msg?.body?.message?.model_name === 'DOCRMUserAttemptAccept' ||
              msg?.body?.message?.model_name === 'DOCRMUserAttemptCancel') {
              setTimeout(()=>this.updateChatUserList(), 300)
            } else if (msg?.body?.message?.model_name === 'DOCRMQueueAttemptRequest') {
              this.getRequestAttempt(msg.body).subscribe(
                data => {
                  this.requestQueue$.next(data)
                }
              );
            } else if (msg?.body?.message?.model_name === 'DOCRMUserAttemptRequest') {
              console.log('DOCRMUserAttemptRequest');
              console.log(this.auth.getUserId());
              console.log(msg?.body?.recipient);
              if (msg?.body?.recipient == this.auth.getUserId()) {
                this.getRequestAttempt(msg.body).subscribe(
                  data => {
                    this.requestUser$.next(data)
                  }
                );
              }

            } else if (["DOCRMClose", "DOCRMQueueExit", "DOCRMUserExit"].includes(msg?.body?.message?.model_name)) {
              this.selectedChat = null;
              this.chat_messages$.next([]);
              setTimeout(()=>this.updateChatQueueList(), 300);
              setTimeout(()=>this.updateChatUserList(), 300);
            }

            else {
              if (this.selectedChat) this.onOmniChatSelect(this.selectedChat)
              this.getDialogs([msg.body.dialog_id]);
              if (msg.body.sender === this.auth.getUserId()) {
                setTimeout(() => this.scrollToBottom(), 300);
              }
              // this.updateChatQueueList()
            }
          }
          break;

        case 'list::DOCDialogMessage':
          if (msg.code == 200) {
            if (msg?.list?.length>0) {
              this.chat_messages$.next(msg.list.filter(
                item =>
                  item.message.model_name=='DOCRMNotify' ||
                  item.message.model_name=='DOCHMFile' ||
                  item.message.model_name=='DOCRMSingleQuestion' ||
                  item.message.model_name=='DOCRMClose' ||
                  item.message.model_name=='DOCRMIdleNotify' ||
                  item.message.model_name=='DOCHMEmailMessage' ||
                  item.message.model_name=='DOCRMQueueExit' ||
                  item.message.model_name=='DOCRMUserExit' ||
                  item.message.model_name=='DOCRMSingleQuestionAnswer' ||
                  item.message.model_name=='DOCHMHoldOff' ||
                  item.message.model_name=='DOCHMHoldOn' ||
                  item.message.model_name=='DOCHMImageBase64' ||
                  item.message.model_name=='DOCHMSimpleText'));
            } else this.chat_messages$.next([]);

          }
          // setTimeout(()=> this.scrollToBottom());
          // setTimeout(()=> this.scrollToNoRead());

          break;

        case 'list::DICPersonalMessage':
          if (msg.code == 200) {
            if (msg?.list?.length>0) {
              this.chat_messages$.next(msg.list);
            } else this.chat_messages$.next([]);
          }
          // setTimeout(()=> this.scrollToBottom());
          // setTimeout(()=> this.scrollToNoRead());

          break;
        case 'list::DICGroupMessage':
          if (msg.code == 200) {
            if (msg?.list?.length>0) {

              Array.from(msg.list).forEach(item => {
                const my_status = item?.['status']?.[this.auth.getUserId()]?.s || 0;
                item['status'] = my_status;
                console.log(item)
              });




              this.chat_messages$.next(msg?.list);
            } else this.chat_messages$.next([]);
          }
          break;

        case 'leave::DICGroup':
        case 'delete::DICGroup':
        case 'update_members_and_admins::DICGroup':
        case 'detail_update::DICGroup':
        case 'append::DICGroup':
          if (msg.code == 200) {
            this.getGroupChats();
          }
          break;
        case 'append::DICPersonal':
          if (msg.code == 200) {
            this.getPersonalChats();
          }
          break;

        case 'list_with_detail::DICGroup':
          // console.log(msg);
          if (msg.code == 200) {
            //if (!msg?.list?.length) this.chatGroupList$.next([]);
            this.clearDialogDetails();

            const joinArrays = (lists, users) =>
              lists
                .map(list => Object.assign(
                  {},
                  list,
                  {
                    members_data: list.members.map(item=>users.find(user=>user.id == item)),
                    admins_data: list.admins.map(item=>users.find(user=>user.id == item)),
                    name: list?.detail?.name,
                    member_name: list?.detail?.name,
                    icon: list?.details?.icon
                  }
                ));

            forkJoin([of(msg.list), this.users$]).pipe(
              map(([lists, users]) => joinArrays(lists, users)),
              tap(rslt => {
                // console.log(rslt);
              })

            ).subscribe(data=>{
              // console.log(data);
              this.behaviorListUpdater(this.chatGroupList$, data, msg?.filter)
            });
          }
          break;

        case 'list_with_detail::DICPersonal':
          // console.log(msg);
          if (msg.code == 200) {
           // if (!msg?.list?.length) this.chatPersonalList$.next([]);
            this.clearDialogDetails();
            const joinArrays = (lists, users) =>
            lists
              .map(list => Object.assign(
                {},
                list,
                { name: users.find(item=>item.id == ((this.auth.getUserId()==list.members[0])?list.members[1]:list.members[0]))?.name,
                          member_name: users.find(item=>item.id == ((this.auth.getUserId()==list.members[0])?list.members[1]:list.members[0]))?.name
                }
              ));
            forkJoin([of(msg.list), this.users$]).pipe(
              map(([lists, users]) => joinArrays(lists, users)),
              tap(rslt => {
                // console.log(rslt);
              })
            ).subscribe(data=>{
              // console.log(data);
              this.behaviorListUpdater(this.chatPersonalList$, data, msg?.filter)

            });
          }
          break;

        case 'list_with_detail::DOCAppQueueAttempt':
          if (msg.code == 200) {
            if (!msg?.list?.length) this.chatQueueList$.next([]);
            this.clearDialogDetails();
            this.CTI.chatEventMessages$.next(0)
            this.getListChat(msg.list).subscribe(
              data => {
                if (data.length !== 0) {
                  this.getDialogs(data.map(item=>item.dialog_id));
                }
                this.chatQueueList$.next(data)
              }
            );
          }

          break;

        case 'list_with_detail::DOCAppUserAttempt':
          if (msg.code == 200) {
            const listUser = (msg?.list || []).filter(item=>item.status!==4);
            if (listUser.length == 0) this.chatUserList$.next([]);
            this.clearDialogDetails();
            this.CTI.chatEventMessages$.next(0)
            this.getListChat(listUser).subscribe(
              data => {
                if (data.length !== 0) {
                  this.getDialogs(data.map(item=>item.dialog_id));
                }
                this.chatUserList$.next(data)
              }
            );
          }

          break;

        case 'status_update::DICPersonalMessage':
          if (msg.code == 200) {
            if (this.selectedChat.dialog_id == msg.body.chat_id) {
              if (this.selectedChat.type == 'personal') {
                this.getPersonalDialogs([msg.body.chat_id])
              }
            }

          }
          break

        case 'status_update::DICGroupMessage':
          if (msg.code == 200) {
            if (this.selectedChat.dialog_id == msg.body.chat_id) {
              if (this.selectedChat.type == 'group') {
                this.getGroupDialogs([msg.body.chat_id])
              }
            }

          }
          break

        case 'update::DOCDialogMessage':
          if (msg.code == 200) {
            if (this.selectedChat.dialog_id == msg.body.dialog_id) {
              if (this.selectedChat.type == 'omni') {
                this.getDialogs([msg.body.dialog_id])
                this.updateDOCMessage(msg.body)
              }
            }

          }
          break

        case 'status_update::DOCDialogMessage':
          if (msg.code == 200) {
            if (this.selectedChat?.dialog_id == msg.body?.dialog_id) {
              if (this.selectedChat.type == 'omni') {
                this.getDialogs([msg.body.dialog_id])
              }
            }

          }
          break

        case 'hold_dialog::DOCDialog':
          if (msg.code == 200) {
            if (this.selectedChat && this.headers[`${this.selectedChat.dialog_id}`])
              this.headers[`${this.selectedChat.dialog_id}`].status = 3;
          }

          break;

        case 'unhold_dialog::DOCDialog':
          if (msg.code == 200) {
            if (this.selectedChat && this.headers[`${this.selectedChat.dialog_id}`])
              this.headers[`${this.selectedChat.dialog_id}`].status = 0;
          }

          break;

        case 'list_with_detail::DOCDialog':
          this.headers = {};
          if (msg.code == 200) {
            let count = 0;
            msg.list.forEach(item => {
              this.headers[`${item.id}`] = item;
              if (item.channel_id) this.channels[`${item.id}`] = item.channel_id;
            });

            Object.values(this.headers).forEach(
              (item: any) => count += item.has_not_seen_cnt
            )
            this.CTI.chatEventMessages$.next(count)
          }

          break;


        default:
        // console.log(msg);
      }


    }

    getRequestAttempt(attempt): Observable<any> {
      return of([attempt]).pipe(
        mergeMap(data=>forkJoin(data.map(list =>{
            let request = (list.message?.params?.attempt_id) ?
              this.api.get_queue_member(list.message.params.attempt_id) :
              this.api.get_user_member(list.dialog_id);
            return request
              .pipe(
                map((member: any)=> {
                  let tier = list;
                  tier.member_name = member.name;
                  tier.email = member.data?.email || '';
                  return tier;
                }),
                catchError(error => of(list))
              )

          }

        ))))
    }

    getListChat(chats): Observable<any> {
      const joinArrays = (lists, users) =>
        lists
          .map(list => Object.assign(
            {},
            list,
            { agent_name: users.find(item=>item.id == list?.agent_id)?.name }
          ));

      return forkJoin([of(chats),
        this.userService.toSelect({filter: {field_list: [
              {field: 'id', condition_type: 9, value: [...chats.map(val=>val.agent_id)]}

            ], type: 0}}, 'select')
      ]).pipe(
        map(([lists, users]) => joinArrays(lists, users)),
        tap(rslt => {
          //console.log(rslt)
        }),

        mergeMap(data=>forkJoin(data.map(list => {
            let request = (list.queue_id) ?
              this.api.get_queue_member(list.id) :
              this.api.get_user_member(list.dialog_id);
            return request
            .pipe(
              map((member: any)=> {
                let tier = list;
                tier.member_name = member.name;
                return tier;
              }),
              catchError(error => of({}))
            )
        }

        ))));
    }

    action(number: number) {
      const domain_id = this.auth.getDomain();
      if (number == 0) {
        this.queueService.toSelect({domain_id: domain_id}, 'select').subscribe(data=>
          this.dataSource.data = data
        );
      } else if (number == 1) {
        this.domainUserService.toSelect({domain_id: domain_id}, 'select').subscribe(data=>
          this.dataSource.data = data.map(it=>{ return {name: it.uid}})
        );
      }
      else if (number == 2) {
        this.crmService.toSelect({domain_id: domain_id,
          filter: {field_list: [{field: 'entity_type', value: 0, condition_type: 0}], type: 0}}, 'select').subscribe(data=>
          this.dataSource.data = data.map(it=>{ return {name: it.name}})
        );
      }
      else if (number == 3) {
        this.crmService.toSelect({domain_id: domain_id,
          filter: {field_list: [{field: 'entity_type', value: 1, condition_type: 0}], type: 0}}, 'select').subscribe(data=>
          this.dataSource.data = data.map(it=>{ return {name: it.name}})
        );
      }
    }

    getDialogs(dialogs: any[]) {
      this.appService.sendMessage({
        action: 'list_with_detail',
        obj: 'DOCDialog',
        filter: {
          field_list: [
            {
              field: 'id',
              condition_type: 9, // CONDITION_TYPE_EQUAL = 0,CONDITION_TYPE_STARTSWITH = 1,
                                 // CONDITION_TYPE_ENDSWITH = 2,CONDITION_TYPE_LIKE = 3
              value: dialogs
            }
          ],
          type: 0
        }

      });
    }


    isOmni() {
      return this.chatTypes.includes('omni')
    }

    isGroup() {
      return this.chatTypes.includes('group')
    }

    isPersonal() {
      return this.chatTypes.includes('personal')
    }

    updateDOCMessage(data) {
      let cm = 0;
      this.chat_messages$.value.forEach(item => {
        if (item.id == data.id) {
          item.message = data.message;
          item.update_dt = data.update_dt;
          cm +=1;
        }
      })

      if (cm>0) {
        this.chat_messages$.next(this.chat_messages$.value);
      }
    }

  getGroupDialogs(dialogs: any[]) {
      this.appService.sendMessage({
        action: 'list_with_detail',
        obj: 'DICGroup',
        filter: {
          field_list: [
            {
              field: 'id',
              condition_type: 9, // CONDITION_TYPE_EQUAL = 0,CONDITION_TYPE_STARTSWITH = 1,
                                 // CONDITION_TYPE_ENDSWITH = 2,CONDITION_TYPE_LIKE = 3
              value: dialogs
            }
          ],
          type: 0
        }

      });
    }



    getPersonalDialogs(dialogs: any[]) {
      this.appService.sendMessage({
        action: 'list_with_detail',
        obj: 'DICPersonal',
        filter: {
          field_list: [
            {
              field: 'id',
              condition_type: 9, // CONDITION_TYPE_EQUAL = 0,CONDITION_TYPE_STARTSWITH = 1,
                                 // CONDITION_TYPE_ENDSWITH = 2,CONDITION_TYPE_LIKE = 3
              value: dialogs
            }
          ],
          type: 0
        }

      });
    }
    onOmniChatSelect(chat: any) {
      this.chat_messages$.next([]);
      if (chat?.answer_epoch) {
        this.appService.sendMessage({
          action: 'list',
          obj: 'DOCDialogMessage',
          sort: {create_dt: '+'},
          limit: 1000,
          filter: {
            field_list: [
              {
                field: 'dialog_id',
                condition_type: 0, // CONDITION_TYPE_EQUAL = 0,CONDITION_TYPE_STARTSWITH = 1,
                                   // CONDITION_TYPE_ENDSWITH = 2,CONDITION_TYPE_LIKE = 3
                value: chat.dialog_id
              }],
            type: 0
          }
        });

        // console.log(chat);
        this.selectedChat = chat;
        this.selectedChat.channel_id = chat.channel_id;
        this.selectedChat.type = 'omni';
        this.getDialogDetails(chat);
      }
    }

    changeFilter(value: string) {
      this.filter$.next(value);
    }

    sendMessage(chat_id, type, text) {
      if (this.selectedMessage) {
        // this.editMessage(chat_id,  this.selectedMessage.id, text);
      } else {

        let request: any = {
          action: 'append',
        }

        if (type == 'omni') {
          request.obj = 'DOCDialogMessage'
          request.params = {
            sender: this.auth.getUserId(),
            recipient: -1,
            dialog_id: chat_id,
            message: {
              model_name: 'DOCHMSimpleText',
              params: {value: text}
            }
          }
        }
        else  if (type == 'personal') {
          request.obj = 'DICPersonalMessage'
          request.params = {
            chat_id: chat_id,
            message: {
              model_name: "DICHMSimpleText",
              params: {value: text}
            }
          }
        } else if (type == 'group') {
          request.obj = 'DICGroupMessage'
          request.params = {
            chat_id: chat_id,
            message: {
              model_name: "DICHMSimpleText",
              params: {value: text}
            }
          }
        } else return false

        this.appService.sendMessage(request);
      }
    }

    sendPicture(chat_id, base64, caption) {
      if (this.selectedMessage) {
        // this.editMessage(chat_id,  this.selectedMessage.id, text);
      } else {

        let request: any = {
          action: 'append',
        }


        request.obj = 'DOCDialogMessage'
        request.params = {
          sender: this.auth.getUserId(),
          recipient: -1,
          dialog_id: chat_id,
          message: {
            model_name: 'DOCHMImageBase64',
            params: {image: base64, caption: caption}
          }
        }


        this.appService.sendMessage(request);
      }
    }
    editMessage(chat_id,  message_id, text) {
        this.appService.sendMessage({
          action: 'edit',
          obj: 'DomainPersonalChatMessage',
          params: {
            domain_id: this.auth.getDomain(),
            chat_id: chat_id,
            id: message_id,
            msg: text
          }
        });
    }

    beginEditMessage(c){
      this.selectedMessage = c;
      this.send = c.msg;
    }

    deleteMessage(chat_id,  message_id) {
      this.appService.sendMessage({
        action: 'delete',
        obj: 'DomainPersonalChatMessage',
        params: {
          domain_id: this.auth.getDomain(),
          chat_id: chat_id,
          id: message_id
        }
      });
    }
    typeMessage(chat) {
      let params: any = {};
      if (chat.type == 'omni') {
        params = {
          "recipient": -1,
          "dialog_id": chat.dialog_id,
          "sender": this.auth.getUserId(),
          "message": {
            "model_name": "DOCHMTypes"
          }
        }
      } else {
        params = {
          "chat_id": chat.dialog_id,
          "sender": this.auth.getUserId(),
          "message": {
            "model_name": "DICHMTypes",
          }
        }
      }

      this.appService.sendMessage({
        action: 'append',
        obj: (chat.type == 'omni')?'DOCDialogMessage':((chat.type == 'personal')?'DICPersonalMessage':'DICGroupMessage'),
        params: params
      });
    }

  scrollToBottom(): void {
    try {
      this.chatBox.nativeElement.scrollTop = 0;
      setTimeout(()=>
        this.chatBox.nativeElement.scrollTop = this.chatBox.nativeElement.scrollHeight, 500
      )

    } catch(err) {
      console.log(err)
    }
  }



  getChatHeader(chat_id) {
    return (this.headers[chat_id]?.last_dialog_message?.message?.params?.text ||
      this.headers[chat_id]?.last_dialog_message?.message?.params?.value ||
      this.headers[chat_id]?.last_dialog_message?.message?.params?.name ||'').trim()?.replace(/<[^>]*>/g, '');
  }

  getStatusHeader(chat_id) {
      return this.headers[chat_id]?.status;
  }

  getChatDate(chat_id) {
    return getDayOfWeekOrToday(this.headers[chat_id]?.update_dt);
  }

  getMessageDate(date) {
    return getDayOfWeekOrToday(date);
  }

  getChatNoReadCount(chat_id) {
    return this.headers[chat_id]?.has_not_seen_cnt || 0;
  }

  updateChatQueueList() {
    this.requestQueue$.next([]);
    this.appService.sendMessage({
      action: 'list_with_detail',
      obj: 'DOCAppQueueAttempt',
      params: {}
    })

  }
  updateChatUserList() {
    this.requestUser$.next([]);
    this.appService.sendMessage({
      action: 'list_with_detail',
      obj: 'DOCAppUserAttempt',
      params: {}
    })
  }

  allowChat(chat: any, isQueue=true, selected = true) {
    if (isQueue) this.requestQueue$.next([]); else this.requestUser$.next([]);
    this.appService.sendMessage({
      action: 'append',
      obj: 'DOCDialogMessage',
      params: {
        create_dt: new Date().getTime()/1000,

        dialog_id: chat.dialog_id,
        message: {model_name: (isQueue)?'DOCRMQueueAttemptAccept':'DOCRMUserAttemptAccept',
          params: {
            // reason: reason, // 0 - по действию, 1 - по таймауту
            attempt_id: chat?.message?.params?.attempt_id
          }
        },
        recipient: -1,
        sender: this.auth.getUserId(),
        status: 0,
      }
    });
    if (selected) {
      this.selectedChatId.next(chat.dialog_id);
      setTimeout(_ => this.selectedChatId.next(null), 5000)
    }
  }

  denyChat(chat: any, reason = 0, isQueue = true) {
    if (isQueue) this.requestQueue$.next([]); else this.requestUser$.next([]);
    this.appService.sendMessage({
      action: 'append',
      obj: 'DOCDialogMessage',
      params: {
        create_dt: new Date().getTime()/1000,

        dialog_id: chat.dialog_id,
        message: {model_name: (isQueue)?'DOCRMQueueAttemptCancel':'DOCRMUserAttemptCancel',
          params: {
            reason: reason, // 0 - по действию, 1 - по таймауту
            attempt_id: chat?.message?.params?.attempt_id
          }
        },
        recipient: -1,
        sender: chat.agent_id,
        status: 0,
      }
    });
  }

  queueExit(dialog_id, cause) {
    this.appService.sendMessage({
      action: 'append',
      obj: 'DOCDialogMessage',
      params: {
        create_dt: new Date().getTime()/1000,

        dialog_id: dialog_id,
        message: {model_name: 'DOCRMQueueExit',
          params: {cause: cause}
        },
        recipient: -2,
        sender: -1,
        status: 0,
      }
    });
  }

  userExit(dialog_id, cause) {
    this.appService.sendMessage({
      action: 'append',
      obj: 'DOCDialogMessage',
      params: {
        create_dt: new Date().getTime()/1000,

        dialog_id: dialog_id,
        message: {model_name: 'DOCRMUserExit',
          params: {reason: cause}
        },
        recipient: -2,
        sender: -1,
        status: 0,
      }
    });
  }
  closeDialog(dialog_id: number, cause: number) {
    const isQueue = this.isQueue(dialog_id);
    if (isQueue == true) {
      this.queueExit(dialog_id, cause);
    } else if (isQueue == false) {
      this.userExit(dialog_id, cause);
    } else return;
  }

  updateDialogs() {
    this.updateChatQueueList()
    this.updateChatUserList()
  }

  opt(ev) {
    console.log(ev);
  }
  transferDialog(dialog_id: number, dst_id: number, dst_type=0) {
    if (Object.values(DOCRM_TRANSFER).includes(dst_type))
    this.appService.sendMessage({
      action: 'append',
      obj: 'DOCDialogMessage',
      params: {
        create_dt: new Date().getTime()/1000,

        dialog_id: dialog_id,
        message: {model_name: 'DOCRMTransfer',
          params: {
            dst_type: dst_type, // 0 - по маршруту
            dst_id: dst_id
          }
        },
        recipient: -2,
        sender: this.selectedChat.agent_id,
        status: 0,
      }
    });
    this.search$.next(null);
  }

  transferUserDialog(dialog_id: number, agent_id: number) {

    this.appService.sendMessage({
      action: 'append',
      obj: 'DOCDialogMessage',
      params: {
        create_dt: new Date().getTime()/1000,

        dialog_id: dialog_id,
        message: {
          model_name: 'DOCRMUser',
          id: '00000000-0000-0000-0000-000000000000',
          params: {
            agent_id: agent_id
          }
        },
        recipient: -2,
        sender: this.selectedChat.agent_id,
        status: 0,
      }
    });
    this.search$.next(null);
  }

  onFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target)
    if (target.files.length !== 1) { throw new Error('Cannot use multiple files') }
    if (target.files[0].size / (1024 * 1024) > 15) {
      this.notifyService.messageByCode(10026);
    } else {
      const formData: FormData = new FormData()
      formData.append('file_data', target.files[0])
      if (this.selectedChat.type == 'omni') {
        formData.append('dialog_id', this.selectedChat?.dialog_id)
      } else {
        formData.append('chat_id', this.selectedChat?.dialog_id)
      }

      formData.append('action', 'file_upload')
      formData.append('obj',
        (this.selectedChat.type == 'group')?'DICGroupMessage': (
          (this.selectedChat.type == 'personal')?'DICPersonalMessage':'DOCDialogMessage'
        )
      )
      formData.append('domain_id', this.auth.getDomain().toString())
      formData.append('action_id', getUUID4(4))
      this.api.file_upload(formData)
        .subscribe(
          data => {
            if (this.selectedChat) {
              this.onOmniChatSelect(this.selectedChat);
              setTimeout(() => this.scrollToBottom(), 300);
            }
          },
          error => console.log(error)
        )
    }
  }

  changePosition(evt: Event) {
    if (!this.selectedChat?.dialog_id) return

    this.chatMessages.filter(_=>_.nativeElement.dataset.status < 2 && _.nativeElement.dataset.id &&
      (_.nativeElement.offsetTop)<=(this.chatBox.nativeElement.scrollTop+this.chatBox.nativeElement.clientHeight))
      .forEach(data=> {
        data.nativeElement.dataset.status = 2;
        if (parseInt(data.nativeElement.dataset.sender) !== this.auth.getUserId())
          this.updateMessageStatus(parseInt(data.nativeElement.dataset.id), this.selectedChat, 2)
      })

    this.selectedChat.noread = this.chatMessages.filter(_=>_.nativeElement.dataset.status < 2 && _.nativeElement.dataset.id)?.length

  }

  public updateMessageStatus(id, chat, status = 0) {

    let params: any = {};
    if (chat.type == 'omni') {
      params = {
        dialog_id: chat.dialog_id,
        id: id,
        status: status
      }
    } else {
      params = {
        chat_id: chat.dialog_id,
        id: id,
        status: status
      }
    }

    this.appService.sendMessage({
      action: 'status_update',
      obj: (chat.type == 'omni')?'DOCDialogMessage':((chat.type == 'personal')?'DICPersonalMessage':'DICGroupMessage'),
      params: params,
    })

  }

  openKnowledgeBase() {

  }

  focusChatBox(b: boolean) {
    if (b && this.selectedChat?.dialog_id) {

      this.chatMessages.filter(_=>_.nativeElement.dataset.status < 2 && _.nativeElement.dataset.id)
        .forEach(data=> {
          data.nativeElement.dataset.status = 2;
          this.updateMessageStatus(parseInt(data.nativeElement.dataset.id), this.selectedChat, 2)
        })

      this.selectedChat.noread = this.chatMessages.filter(_=>_.nativeElement.dataset.status < 2 && _.nativeElement.dataset.id)?.length

    }
  }

  copyAll(data) {
      if (this.detailFormViewData) {
        Array.from(data.matchAll(/{{(\S+)}}/gm)||[]).forEach((a)=>{
          const finder = this.JSONPath(a[1], this.detailFormViewData?.omni_dialog).concat(this.JSONPath(a[1], this.detailFormViewData?.omni_customer));
          if (finder?.length>0) {
            data = data.replace(a[0], finder[0])
          }
        })

      }
    this.send = data?.trim(); //?.replace(/<[^>]*>/g, '');
  }

  copySelection() {
    this.send =  window.getSelection()?.toString()?.trim(); //?.replace(/<[^>]*>/g, '') || '';
  }

  isSelected() {
    return window.getSelection()?.toString()?.trim()?.length > 0
  }

  getEntities(filterValue) {
    this.loadingDomainUsers = true;
    this.doc_agent_list = [];
    this.doc_queue_list = [];
    this.doc_dp_list = [];
    // console.log(filterValue);
    if (!filterValue?.trim().toLowerCase()) {
      this.loadingDomainUsers = false;
      return observableOf([]);
    }

    this.loadingDomainUsers = true;
    this.getEntityFilter(filterValue?.trim().toLowerCase()).subscribe(
      data => {this.loadingDomainUsers = false; },
      resp => {this.loadingDomainUsers = false;}
    );
  }

  updateFilterEntity(key, value) {
    if (localStorage) localStorage.setItem(key, value);
  }

  findEntity(type, number) {
    if (type != -1) this.getEntities(number.trim().toLowerCase());
  }

  scrollAgentEnd() {

  }

  scrollEndAgent(filterValue, offset = 0, limit = 10) {
    return (this.docAgentService.list({
      // sort: {user_name: '+', user_surname: '+'},
      limit: limit, offset: offset,
      filter: {
        field_list: [
          {field: 'user.id.name', condition_type: 3, value: filterValue},
          {field: 'user.id.surname', condition_type: 3, value: filterValue}
        ],
        type: 1
      }
    }, 'select_with_detail')).pipe(
      map(data => {
        this.doc_agent_count = data.total_count;
        for (let user of (data.list as any[])) {
          let username = user.user_name + (user.user_surname ? ' ' + user.user_surname : '');
          let tooltip = '', color = '', active = 0, icon = '', status_color = '';
          if (user.agent_available === true) {
            color = 'text-green';
            active = 1;
          } else {
            color = 'text-muted';
            tooltip = 'CTI.USER_OFFLINE';
            active = 0;
          }

          tooltip = this.getAgentStatusById(user.status_id)?.name;
          icon = this.getAgentStatusById(user.status_id)?.icon;
          status_color = this.getAgentStatusById(user.status_id)?.base_status_id == -1 ? 'text-success' : (this.getAgentStatusById(user.status_id)?.base_status_id == -3 ? 'text-muted' : 'text-danger');

          if (!this.doc_agent_list.find(i=>i.id == user.id))
            this.doc_agent_list.push({id: user.id, name: username, class: color, tooltip: tooltip, active: active, icon: icon, status_color: status_color});
        }
        this.doc_agent_list = [...this.doc_agent_list];

        return data;
      })
    )
  }

  scrollEndQueue(filterValue, offset = 0, limit = 10) {
    return (this.docQueueService.list({
      sort: {name: '+'},
      limit: limit, offset: offset,
      filter: {
        field_list: [
          {field: 'name', condition_type: 3, value: filterValue}
        ],
        type: 1
      }
    }, 'select_with_detail')).pipe(
      map(data => {
        this.doc_queue_count = data.total_count;
        for (let queue of (data.list as any[])) {
          let username = queue.name, color='', active=0;

          if (queue.free_agents > 0) {
            color = 'text-green';
            active = 1;
          } else if (queue.agent_count>0) {
            color = 'text-yellow';
            active = 0;
          }
          else {
            color = 'text-muted';
            active = 0;
          }
          if (!this.doc_queue_list.find(i=>i.id == queue.id))
            this.doc_queue_list.push({id: queue.id, name: username, class: color, agent_count: queue.agent_count, free_agents: queue.free_agents, active: active});
        }
        this.doc_queue_list = [...this.doc_queue_list];

        return data;
      })
    )
  }

  scrollEndChatPlan(filterValue, offset = 0, limit = 10) {
    return (this.chatApi.list({
      sort: {name: '+'},
      limit: limit, offset: offset,
      filter: {
        field_list: [
          {field: 'name', condition_type: 3, value: filterValue}
        ],
        type: 1
      }
    }, 'select')).pipe(
      map((data)  => {
        this.doc_dp_count = data.total_count;
        for (let plan of data.list) {
          if (!this.doc_dp_list.find(i=>i.id == plan.id))
            this.doc_dp_list.push({name: plan.name, id: plan.id, active: plan.status, class: (plan.status) ? 'text-green' : 'text-muted'});
        }
        this.doc_dp_list = [...this.doc_dp_list];

        return data;
      })
    )
  }



  getEntityFilter(filterValue){
    let filter_reqs = [];
    if (filterValue) {
      switch (this.defaultTransferFilterEntity.value) {
        case 100:
        case DOCRM_TRANSFER.DST_AGENT:
          filter_reqs.push(this.scrollEndAgent(filterValue));
          if (this.defaultTransferFilterEntity.value != 100) break;
        case DOCRM_TRANSFER.DST_QUEUE:
          filter_reqs.push(this.scrollEndQueue(filterValue));
          if (this.defaultTransferFilterEntity.value != 100) break;
        case DOCRM_TRANSFER.DST_CHATPLAN:
          filter_reqs.push(this.scrollEndChatPlan(filterValue));
          if (this.defaultTransferFilterEntity.value != 100) break;
      }
    }

    return forkJoin(filter_reqs);
  }

  onTransfer() {
    return null;
  }

  getAgentStatusById(status: number) {
    let st = this.getAgentStatuses().find(item=>item.id === status);
    if (st && !st.base_status_id) st.base_status_id = st.id;
    return st || {};
  }

  getAgentStatuses() {
    return this.agent_status_list.concat(AGENT_STATUS_LIST.filter(s => s.type === 'user'));
  }

  scrollMenu(evt) {
    const limit  = 10;
    if (this.menuBox.scrollHeight === this.menuBox.clientHeight) {
      if (this.defaultTransferFilterEntity.value === DOCRM_TRANSFER.DST_AGENT) {
        if (this.doc_agent_list.length<this.doc_agent_count) this.scrollEndAgent(this.transferNumber.nativeElement.value, this.doc_agent_list.length, limit).subscribe()
      } else if (this.defaultTransferFilterEntity.value === DOCRM_TRANSFER.DST_QUEUE) {
        if (this.doc_queue_list.length<this.doc_queue_count) this.scrollEndQueue(this.transferNumber.nativeElement.value, this.doc_queue_list.length, limit).subscribe()
      } else if (this.defaultTransferFilterEntity.value === DOCRM_TRANSFER.DST_CHATPLAN) {
        if (this.doc_dp_list.length<this.doc_dp_count) this.scrollEndQueue(this.transferNumber.nativeElement.value, this.doc_dp_list.length, limit).subscribe()
      } else if (this.defaultTransferFilterEntity.value === 100) {

        const l_doc_agent = Math.min(limit, this.doc_agent_count - this.doc_agent_list.length);
        const l_doc_queue = Math.min(limit-l_doc_agent, this.doc_queue_count - this.doc_queue_list.length);
        const l_doc_dp = Math.min(limit-l_doc_agent-l_doc_queue, this.doc_dp_count - this.doc_dp_list.length);

        if (l_doc_agent>0) this.scrollEndAgent(this.transferNumber.nativeElement.value, this.doc_agent_list.length, l_doc_agent).subscribe()

        if (l_doc_queue>0) this.scrollEndQueue(this.transferNumber.nativeElement.value, this.doc_queue_list.length, l_doc_queue).subscribe()

        if (l_doc_dp>0) this.scrollEndQueue(this.transferNumber.nativeElement.value, this.doc_dp_list.length, l_doc_dp).subscribe()

      }
    }
  }

  getAgentName(id: any) {
    if (id) {
      const agent = this.messageAgentSelect.find(_=>_.id==id)
      if (!agent) return null;
      return (agent?.user_surname?.length>0)?`${agent?.user_name} ${agent?.user_surname}`:`${agent?.user_name}`;
    }
  }

  togglePin(dialog_id: any, onHold = true) {
    this.appService.sendMessage({
      action: (onHold)?'hold_dialog':'unhold_dialog',
      obj: 'DOCDialog',
      params: {
        id: dialog_id
      }
    })
  }

  onPersonalChatSelect(chat: any) {
    this.chat_messages$.next([]);
    this.clearDialogDetails();
    this.appService.sendMessage({
      action: 'list',
      obj: 'DICPersonalMessage',
      sort: {create_dt: '+'},
      limit: 1000,
      params: {
        chat_id: chat.id,
      }
    });

    // console.log(chat);
    this.selectedChat = chat;
    this.selectedChat.dialog_id = chat.id;
    this.selectedChat.type = 'personal';
  }

  onGroupChatSelect(chat: any) {
    this.chat_messages$.next([]);
    this.clearDialogDetails();
    this.appService.sendMessage({
      action: 'list',
      obj: 'DICGroupMessage',
      sort: {create_dt: '+'},
      limit: 1000,
      params: {
        chat_id: chat.id,
      },

    });

    // console.log(chat);
    this.selectedChat = chat;
    this.selectedChat.dialog_id = chat.id;
    this.selectedChat.type = 'group';
  }

  createPersonalChat(user: any) {
    this.appService.sendMessage({
      action: 'append',
      obj: 'DICPersonal',
      params: {
        members: [this.auth.getUserId(), user.id],
        params: {}
        // domain_id: 3148,
      }
    });
  }

  private getGroupChats() {
    this.appService.sendMessage({
      action: 'list_with_detail',
      obj: 'DICGroup',
      params: {
        // domain_id: this.auth.getDomain(),
      }
    });
  }

  private getPersonalChats() {
    this.appService.sendMessage({
      action: 'list_with_detail',
      obj: 'DICPersonal',
      params: {
        // domain_id: this.auth.getDomain(),
      }
    });
  }

  // Для персональных и групповых чатов

  getPersonalChatHeader(chat: any) {
    if (chat?.last_message?.message) {
      return (chat?.last_message?.message?.params?.text ||
        chat?.last_message?.message?.params?.value ||
        chat?.last_message?.message?.params?.name ||'');
    }
    return '';
  }

  getPersonalMessageDate(chat: any) {
      if (chat?.last_message?.create_dt) {
        return getDayOfWeekOrToday(chat?.last_message?.create_dt);
      }
  }

  getPersonalNoReadCount(chat) {
    return chat?.has_not_seen_cnt || 0;
  }

  leaveGroup(chat) {
    this.appService.sendMessage({
      action: 'leave',
      obj: 'DICGroup',
      params: {
        id: chat.dialog_id
      }
    });
  }

  deleteGroup(chat) {
    this.appService.sendMessage({
      action: 'delete',
      obj: 'DICGroup',
      params: {
        id: chat.dialog_id
      }
    });
  }
  addGroup(chat = null) {
    const dialogRef = this.dialog.open(ChatGroupDialogComponent,
      {data: {chat: chat, owner: this.auth.getUserId()}, panelClass: 'cti-panel'});
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result.id != null) {
        this.appService.sendMessage({
          action: 'update_members_and_admins',
          obj: 'DICGroup',
          params: {
            members: [...new Set([...result.members, ...result.admins])],
            admins: result.admins,
            id: result.id
          }
        });
        if (result?.detail?.name!==chat?.detail?.name) this.appService.sendMessage({
          action: 'detail_update',
          obj: 'DICGroup',
          params: {
            icon: '',
            name: result.detail.name,
            id: result.id
          }
        });
      } else {
        delete result['id']
        this.appService.sendMessage({
          action: 'append',
          obj: 'DICGroup',
          params: result
        })
      }
      // this.appService.sendMessage({
      //   action: 'append',
      //   obj: 'DICGroup',
      //   params: {
      //     members: [3148, 49, 48],
      //     admins: [3148],
      //     detail: {
      //       icon: '',
      //       name: 'Первая группа',
      //     }
      //   }
      // })
      //

    });



  }

  cutName(member_name: string = '', words = 3) {
    return member_name?.split(' ')?.slice(0, words)?.join(' ');
  }

  clearDialogDetails() {
    if (this.detailFormViewData) this.detailFormViewData['detail_form'] = null;

  }

  private getDialogDetails(chat) {
    this.domainService.get_data({obj_ident: chat.dialog_id, source_type: 'omni', place: 0}).pipe(catchError(err => observableOf({body: {error: true}})))
    .subscribe(
      results => {

        if (results?.body) {
          this.detailFormViewData = results.body;
          // this.detailPanelOpened = true; this.basePanelOpened = false;
        }
      },
      resp => {
      //   this.isLoadingResults = false;
      //   this.isServerAvailable = resp.code != 502;
      }
    );
  }

  behaviorListUpdater(next$, data, filter) {
    let old_data = next$.value
    if (!filter) {
      //next$.next([]);
      next$.next(data);

    } else {
      const fieldId = filter?.field_list?.find(item => item.field=='id')
      if (fieldId) {
        if(fieldId.condition_type == 9) {
          Array.from(fieldId.value || []).forEach(id=>{
            const ndata = data.find(item => item.id == id)
            const itemIndex = old_data.findIndex(item=>item.id == id)
            if (ndata && itemIndex>-1) {
              old_data[itemIndex] = ndata
            } else if (ndata) {
              old_data.push(ndata)
            }
          })
          next$.next(old_data);
        }
      } else {
        next$.next(data);
      }

    }


  }

  getFileNameFromBase64(base64str) {
    let ext = extFromBase64(base64str);
    return 'Image_'+(new Date()).toISOString().split('T')[0]+'.'+ (ext || 'png');
  }

  channelFromDialog(dialog_id: any) {
      if (dialog_id) return this.channels[`${dialog_id}`];
      return null;
  }

  isQueue(dialog_id: any) {
      const queue = this.chatQueueList$.value?.find(item=>item.dialog_id == dialog_id);
      const user = this.chatUserList$.value?.find(item=>item.dialog_id == dialog_id);
      return (queue)? true: ((user) ? false: null);
  }
}
