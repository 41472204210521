export const DDSEModel = [
  /* Одноножки */
  {
    name: 'DDSEForm',
    img: 'dynamic_form', // картинка, отображаемая в узле
    title: 'DOMAINDIALOGSCRIPTFORM', // Название узла
    child: 'onechildren', // безногий/одноногий/двуногий/многоногий элемент
    editable: true, // редактируемый элемент или нет
    group: 'distrib',
    goto: '/domain-catalogs/dialog-script/form/edit/'
  },
  {
    name: 'DDSESetVar',
    img: 'textsms',
    title: 'SENDING_VALUE',
    child: 'onechildren',
    editable: true,
    group: 'action'
  },

  /* Многоножки */
  {
    name: 'DDSEMenu',
    img: 'dialpad',
    title: 'MENU',
    child: 'manychildrens',
    editable: true,
    group: 'distrib'
  }
];

export const DDSERouterGroup = [
  {key: 'action', label: 'DP_GROUP.ACTION', icon: 'done', direction: [0,1,2]},
  {key: 'distrib', label: 'DP_GROUP.DISTRIB', icon: 'mediation', direction: [0,1,2]},
  {key: 'advanced', label: 'DP_GROUP.ADVANCED', icon: 'more_vert', direction: [0,1,2], license: 'AdvancedDP'}
]

export const DDSEModelExtra = [
];
