import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DomainDialogScriptResult} from './domain-dialog-script';


@Injectable({
  providedIn: 'root'
})


export class DomainDialogScriptResultService extends BaseService<DomainDialogScriptResult> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService, protected fb: RxFormBuilder) {
    super(DomainDialogScriptResult.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }
}
