import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-skeleton-loader-card',
  template: `
    <div fxLayout="row wrap">
      <div *ngFor="let i of numbers"  fxFlex.xs="100" fxFlex.lt-lg="50" fxFlex="25">
        <mat-card class="stretch">
          <a>
            <mat-card-content class="mat-card-content text-center">
              <ngx-skeleton-loader appearance="circle" [theme]="{width: '56px', height: '56px', 'background-color': 'var(--theme-primary-500)', color: 'white'}"></ngx-skeleton-loader>

              <h4 class="card-title m-t-15 m-b-0 m-l-15 m-r-15 text-primary">
                <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
              </h4>
              <small class="text-muted">
                <ngx-skeleton-loader [count]="bodyRowCount" animation="pulse" [theme]="{height: '10px', 'margin-bottom': '5px'}"></ngx-skeleton-loader>
              </small>
            </mat-card-content>
          </a>
        </mat-card>
      </div>
    </div>
  `,
  styleUrls: []
})

export class SkeletonLoaderCardComponent implements OnInit{
  @Input() count: number;
  @Input() bodyRowCount: number = 3;
  public numbers: number[] = [];

  constructor() {}

  ngOnInit() {
    this.numbers = Array(this.count).fill(this.count);
  }
}
