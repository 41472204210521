import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'search'
})
@Injectable()
export class SearchPipe implements PipeTransform {
  transform(items: any[], value : string, key: string = 'name', key2: string): string {
    if (!items) return '';
    const search =  items.find(it => it['id'] == value);
    if (search && !key2) return search[key];
    else if (search && key2) return `${search[key]} - ${search[key2]}`;
    return value;
  }
}
