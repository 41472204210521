import {LOCALE_ID, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalBoxComponent } from './modal-box.component';

@NgModule({
    imports: [CommonModule],
    declarations: [ModalBoxComponent],
    exports: [ModalBoxComponent]
})
export class ModalBoxModule { }
