import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ModalBoxService {
    private modals: any[] = [];
    constructor() {
    }

    add(modal: any) {
        // add modal-box to array of active modals
        this.modals.push(modal);
    }

    remove(id: string) {
        // remove modal-box from array of active modals
        this.modals = this.modals.filter(x => x.id !== id);
    }

    open(id: string) {
        // open modal-box specified by id
        const modal = this.modals.find(x => x.id === id);
        modal.open();
    }

    close(id: string) {
        // close modal-box specified by id
        const modal = this.modals.find(x => x.id === id);
        modal.close();
    }
}
