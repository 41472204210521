import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Verto, VertoOptions, VertoConf } from '../../vertojs';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from '@angular/material/snack-bar';
import {BehaviorSubject, from, fromEvent, Observable, of as observableOf} from 'rxjs';
import {Helper, IResponseBodyObject, IResponseListObject, IResponseObject} from '../_helpers/api.helpers';
import {catchError, map, startWith, switchMap, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NotifyService} from '../_helpers/notify.service';
import {JsonAppConfigService} from '../config/json-app-config.service';
const headers = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({ providedIn: 'root' })
export class VertoService {
  snackBarRef: MatSnackBarRef<any>;
  snackBarConfig: MatSnackBarConfig = {
    horizontalPosition: 'center',
    verticalPosition: 'bottom',
    duration: 2000,
    panelClass: 'glam-snackbar'
  };
  public phone: any = null;
  public option: VertoOptions = {};
  public chattingWith;
  public confRole = 'moderator';
  public conferenceMemberID = null;
  public conf = null;
  public instance = null;
  public canvases = null;
  public confLayouts = [];
  public liveArray = null;

  public videoQuality = [];
  public videoDevices = [];
  public audioDevices = [];
  public shareDevices = [];
  public videoQualitySource = [
    {
    id: 'qqvga',
    label: 'QQVGA 160x120',
    width: 160,
    height: 120
  }, {
    id: 'qvga',
    label: 'QVGA 320x240',
    width: 320,
    height: 240
  }, {
    id: 'vga',
    label: 'VGA 640x480',
    width: 640,
    height: 480
  }, {
    id: 'qvga_wide',
    label: 'QVGA WIDE 320x180',
    width: 320,
    height: 180
  }, {
    id: 'vga_wide',
    label: 'VGA WIDE 640x360',
    width: 640,
    height: 360
  }, {
    id: 'hd',
    label: 'HD 1280x720',
    width: 1280,
    height: 720
  }, {
    id: 'hhd',
    label: 'HHD 1920x1080',
    width: 1920,
    height: 1080
  }, ];
  public videoResolution = {
    qqvga: {
      width: 160,
      height: 120
    },
    qvga: {
      width: 320,
      height: 240
    },
    vga: {
      width: 640,
      height: 480
    },
    qvga_wide: {
      width: 320,
      height: 180
    },
    vga_wide: {
      width: 640,
      height: 360
    },
    hd: {
      width: 1280,
      height: 720
    },
    hhd: {
      width: 1920,
      height: 1080
    },
  };
  public bandwidth = [
    {
    id: '250',
    label: '250kb'
  }, {
    id: '500',
    label: '500kb'
  }, {
    id: '1024',
    label: '1mb'
  }, {
    id: '1536',
    label: '1.5mb'
  }, {
    id: '2048',
    label: '2mb'
  }, {
    id: '3196',
    label: '3mb'
  }, {
    id: '4192',
    label: '4mb'
  }, {
    id: '5120',
    label: '5mb'
  }, {
    id: '0',
    label: 'No Limit'
  }, {
    id: 'default',
    label: 'Server Default'
  }, ];
  public framerate = [
    {
    id: '15',
    label: '15 FPS'
  }, {
    id: '20',
    label: '20 FPS'
  }, {
    id: '30',
    label: '30 FPS'
  }, ];
  public updateReq = null;

  constructor(private snackBar: MatSnackBar, private http: HttpClient,
              private AppConfig: JsonAppConfigService,
              private notifyService: NotifyService,
              private translate: TranslateService) {


  }

  message(message, arg={}) {
    this.snackBarRef = this.snackBar.open(this.translate.instant(message, arg), '', this.snackBarConfig);
  }

  async logout(){
    if (this.phone) {
      return await this.phone.logout();
    }
  }

  cleanConf() {
    this.conf = null;
    this.confLayouts = [];
    this.confRole = null;
    this.chattingWith = null;
  }

  setLocalParam(param) {

  }

  getLocalParam() {

  }

  isLogged() {
    if (this.phone) return this.phone.isLogged();
    else return false;

  }

  async login(){

    if (!this.phone) {
      this.message('ERROR_INIT_PHONE');
    }

    try {
      return await this.phone.login();
    } catch(error) {
      this.message('AUTH_ERROR');
      return
    }

  }

  get_verto(): Observable<any> {
    const requestBody = Helper.requestFormat('get_profile_verto', 'User', {});
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        map(data => {
          return data.body;
        })
      );
  }

  get_verto_mode(params: any = {}): Observable<any> {
    const headers_verto = new HttpHeaders({'Content-Type': 'application/json', 'Verto-Session-Id': params?.sessid});
    const requestBody = Helper.requestFormat('get_verto_mode', 'Verto', {
      login: params.login, channels_for_check: params.channels_for_check
    });
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl')+'verto/', requestBody, {headers: headers_verto});
  }

  get_state(name: PermissionName): Observable<PermissionState> {
    return from(navigator.permissions.query({name})).pipe(
      switchMap(
        (permissionStatus: PermissionStatus) => {
          return fromEvent(permissionStatus, 'change').pipe(
            map(() => permissionStatus.state),
            startWith(permissionStatus.state)
          )
        }
      )
    );
  }

  get_tz(params= {}): Observable<any> {

    return this.verto_http_post<IResponseBodyObject<any>>('get_tz', params)
      .pipe(
        map(data => {
          return data.body;
        })
      );
  }

  get_status_list_verto(params= {}): Observable<any> {
    return this.verto_http_post<IResponseListObject<any>>('agent_status_list', params)
      .pipe(
        map(data => {
          return data.list;
        })
      );
  }

  get_call_list_verto(params= {}): Observable<any> {
    return this.verto_http_post<IResponseListObject<any>>('call_list', params)
      .pipe(
        map(data => {
          return data.list;
        })
      );
  }

  get_agent_status_verto(params = {}): Observable<any> {
    return this.verto_http_post<IResponseBodyObject<any>>('agent_status', params)
      .pipe(
        map(data => {
          return data.body;
        })
      );
  }

  agent_change_status_verto(params= {}): Observable<any> {
    return this.verto_http_post<IResponseObject>('agent_change_status', params);
  }

  address_book_verto(params={}): Observable<any> {
    return this.verto_http_post<IResponseListObject<any>>('address_book', params);
  }

  cdr_verto(params={}): Observable<any> {
    return this.verto_http_post<IResponseListObject<any>>('cdr', params);
  }

  get_hot_keys(params: any = {}, action: string = 'get_call_hot_keys'): Observable<any> {
    const requestBody = Helper.requestFormat(action, 'DomainCTI', params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers}).pipe(
      tap(res => {
        this.notifyService.checkCode(res);
      })
    );
  }

  verto_http_post<T>(action, params) {
    console.log('%csessid', 'background: yellow;', this.phone?.sessid, action, params);
    const headers_verto = new HttpHeaders({
      'Content-Type': 'application/json',
      'Verto-Session-Id': this.phone?.sessid });

    const requestBody = Helper.requestFormat(action, 'Verto',
      {login: this.option?.transportConfig?.login, ...params});
    return this.http.post<T>(this.AppConfig.getValue('apiUrl')+'verto/', requestBody, {headers: headers_verto});

  }

  init(option = {}) {
    this.option = option;

    return this.phone = new Verto(option);
  }

  createConf(call, params) {
    return new VertoConf(this.phone, call, params)
  }
}
