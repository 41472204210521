import {HttpResponse} from '@angular/common/http';
import {MarkedOptions, MarkedRenderer} from "ngx-markdown";
import {UserService} from "../users/user.service";


export function markedOptionsFactory(user: UserService): MarkedOptions {
  const renderer: any = new MarkedRenderer();
  const linkRenderer = renderer.link;
  const imgRenderer = renderer.image;

  function setAttribute(html: string, attr: string, val: string): string {
    const template = document.createElement("template");
    template.innerHTML = html;

    if (attr) {
      template.content.firstElementChild.setAttribute(attr, val)
    }
    return template.innerHTML
  }

  renderer.link = (href, title, text) => {
    const url = new URL(href);
    const target = url.searchParams.get('target'); //_blank
    const token = url.searchParams.get('token');

    let token_href = url.toString();
    if (token) {
      url.searchParams.set('token', user.getUser()?.token);
      token_href = url.toString();
    }


    let html = linkRenderer.call(renderer, token_href, title, text);

    if (target) {
      html = html.replace(/^<a /, `<a role="link" tabindex="0" target="${target}" rel="nofollow noopener noreferrer" `)
    }

    return html;
  };

  renderer.image = (href, title, text) => {
    const url = new URL(href);
    url.searchParams.set('token', user.getUser()?.token);
    const token_href = url.toString();

    let html = imgRenderer.call(renderer, token_href, title, text);

    const width = url.searchParams.get('width');
    const height = url.searchParams.get('height');
    const align = url.searchParams.get('align');
    const vspace = url.searchParams.get('vspace');
    const hspace = url.searchParams.get('hspace');

    if (width) {
      html = setAttribute(html, 'width', width)
    }
    if (height) {
      html = setAttribute(html, 'height', height)
    }
    if (align) {
      html = setAttribute(html, 'align', align)
    }
    if (vspace) {
      html = setAttribute(html, 'vspace', vspace)
    }
    if (hspace) {
      html = setAttribute(html, 'hspace', hspace)
    }

    return html
  }

  return {
    renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}
export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) { return '0 Байт' }
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Байт', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function getDayOfWeek(date) {
  const dayOfWeek = new Date(date).getDay();
  return isNaN(dayOfWeek) ? null :
    ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'][dayOfWeek];
}

/* @return A timezone offset in seconds */
export function getOffset(timeZone: string = 'UTC', date: Date = new Date()) {
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  return (tzDate.getTime() - utcDate.getTime()) / 1000;
}

export function getDayOfWeekOrToday(date) {
  let current = new Date();
  if (new Date(date).toDateString() == current.toDateString()) return `${new Date(date).getHours()}:
  ${('0'+new Date(date).getMinutes()).slice(-2)}`;
  else if (new Date(date).getTime() < new Date(current.setDate(current.getDate() - 7 ) ).getTime()) {
    return new Date(date).toLocaleDateString();
  } else return getDayOfWeek(date)
}


export function getUUID4(parts: number): string {
  const stringArr = [];
  for(let i = 0; i< parts; i++){
    // tslint:disable-next-line:no-bitwise
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('-');
}

export function generateGUID(): string {
  return 'xxxxxxxx-xxxx-yxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export function generateOrderedGUID(n: number): string {
  // например: 00000001-ac4a-... 00000002-45df-... - для сортировки по первым 8 цифрам
  let nStr = n.toString();
  return new Array(8 - nStr.length).fill(0).join('') + nStr +
    '-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    });
}

export function getHEX(parts: number): string {
  let result = '';
  for(let i = 0; i< parts; i++){
    // tslint:disable-next-line:no-bitwise
    result += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return result;
}

export function downloadBlobObject(response: HttpResponse<Blob>, filename) {
  let binaryData = [];
  binaryData.push(response.body);
  let downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
  downloadLink.setAttribute('download', filename);
  document.body.appendChild(downloadLink);
  downloadLink.target = '_blank';
  downloadLink.click();
  document.body.removeChild(downloadLink);
}


export function replaceKey(obj, from, to) {
  Object.entries(obj).forEach(([key, value]) => (
    key == from && (obj[to] = obj[from] ) && (delete obj[from])
      , typeof value === 'object' && replaceKey(value, from, to)
  ))
}

export function getFileName(response: HttpResponse<Blob>) {

  try {
    const contentDisposition: string = response.headers.get('Content-Disposition');
    const r = /(?:filename=")(.+)(?:")/
    return r.exec(contentDisposition)[1];
  }
  catch (e) {
    return null;
  }
}

export function  escapeFileName(name) {
  if (name)
  {
    name = name.replace(/[&\/\\#,+()$~%.'":*?<>{}\s]/g,'_');
  }
  return name;
}

// Функции для работы с таблицами
export function tableParse(data) {


}

export function extFromBase64(base64String) {
  // Remove the metadata prefix if it exists
  if (!base64String) return null;
  const base64WithoutPrefix = base64String.replace(/^data:image\/[a-z]+;base64,/, '');


  // Determine the file extension based on the magic numbers
  let extension = null;
  if (base64WithoutPrefix.charAt(0) === 'i') {
    extension = 'png';
  } else if (base64WithoutPrefix.charAt(0) === '/') {
    extension = 'jpg';
  } else if (base64WithoutPrefix.charAt(0) === 'R') {
    extension = 'gif';
  } else if (base64WithoutPrefix.charAt(0) === 'Q') {
    extension = 'bmp';
  } else if (base64WithoutPrefix.charAt(0) === 'S') {
    extension = 'tiff';
  } else if (base64WithoutPrefix.charAt(0) === 'J') {
    extension = 'pdf';
  }

  return extension;
}


