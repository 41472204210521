import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, delay, delayWhen, filter, map, retry, retryWhen, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {NotifyService} from '../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../_helpers/base.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {JsonAppConfigService} from '../../config/json-app-config.service';
import {Markdown, MarkdownNode} from '../editor/markdown';
import {Observable} from 'rxjs';
const headers = new HttpHeaders().set('Content-Type', 'application/json');



@Injectable({
  providedIn: 'root'
})

export class TreeService extends BaseService<MarkdownNode> {
  private api: HttpClient;
  private notify: NotifyService;
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, AppConfig: JsonAppConfigService) {
    super('DomainDocumentStructure', http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.notify = notifyService;
  }

  GetNodes(parentId: number | null = null, type = 0) {
    let params = {};


    params['filter'] = {field_list: [], type: 0};
    params['filter']['field_list'].push({field: 'parent_id', condition_type: 0, value: parentId});
    if (type>-1) {
      params['filter']['field_list'].push({field: 'type', condition_type: 0, value: type});
    }


    return super.list(params, 'list_with_detail')
      .pipe(map(item => item.list.map((elem,_index) => {elem.index = _index; return elem; })))
  }

}

@Injectable({
  providedIn: 'root'
})

export class KnowledgebaseService extends BaseService<Markdown> {
  private api: HttpClient;
  private notify: NotifyService;
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, AppConfig: JsonAppConfigService) {
    super('DomainDocumentContent', http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.notify = notifyService;
  }
  GetContent(parentId: number | null = null): Observable<Markdown[]> {
    let params = {};
    params['filter'] = {field_list: [
        {field: 'parent_id', condition_type: 0, value: parentId}
      ], type: 0};
    return super.list<Markdown>(params, 'short_list').pipe(map(item => item.list as Markdown[]))
  }

}
