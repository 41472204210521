import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IResponseListObject} from '../../../_helpers/api.helpers';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DomainCRMDFieldConf} from './dfield';


@Injectable({
  providedIn: 'root'
})


export class DomainCRMDFieldConfService extends BaseService<DomainCRMDFieldConf> {
  private api: HttpClient;
  private notify: NotifyService;
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, AppConfig: JsonAppConfigService) {
    super(DomainCRMDFieldConf.className(), http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.notify = notifyService;
  }

  list<P = DomainCRMDFieldConf>(params: any = {}, action: string = 'list'): Observable<IResponseListObject<P | DomainCRMDFieldConf>> {
    params['sort'] = {id: '+'};
    return super.list(params, action);
  }
}
