import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {FileElement} from '../../../file-explorer/model/element';
import {AudioFormSystemComponent} from '../../../audio/audio-system-form.component';
import {FileService} from '../../../file-explorer/file.service';
import {NotifyService} from '../../../_helpers/notify.service';
import {AudioSystemService} from '../../../audio/audio.system.service';

@Component({
  selector: 'app-file-dialog',
  templateUrl: './fileDialog.component.html',
  styleUrls: ['./fileDialog.component.css']
})

export class FileDialogSystemComponent extends AudioFormSystemComponent implements OnInit {
  public returnFile;
  public objectName = 'SystemAudio';
  constructor(
    public dialogRef: MatDialogRef<FileDialogSystemComponent>,
    public translate: TranslateService,
    public fileService: FileService,
    public serviceApi: AudioSystemService,
    public notifyService: NotifyService
  ) {
    super(fileService, serviceApi, notifyService);
  }

  selectFile(event) {
    if (typeof event.value == 'string') event.value = parseInt(event.value, 10) || event.value;
    this.returnFile = event;
  }

  navigateToFolder(element: FileElement) {
    super.navigateToFolder(element);
    this.returnFile = null;
  }

  navigateUp() {
    super.navigateUp();
    this.returnFile = null;
  }

  goFolder(element: FileElement) {
    super.goFolder(element);
    this.returnFile = null;
  }

}
