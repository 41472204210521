import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import {DesktopKeptService} from '../../desktop-kept.service';
import {NotifyService} from '../../../../../_helpers/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseTableDirectoryComponent} from '../../../../_common/base-table-directory.component';
import {DesktopService} from '../../../../_common/base-desktop.service';
import {MatSelect} from '@angular/material/select';
import {AppService} from '../../../../../app.service';
import {CTIService} from "../../../../../cti-panel/cti-panel.service";

@Component({
  selector: 'app-history-calls-kept',
  templateUrl: './history-calls-kept.component.html',
  styleUrls: ['./history-calls-kept.component.css', '../../../../_common/desktop-table.scss']
})
export class HistoryCallsKeptComponent extends BaseTableDirectoryComponent implements OnInit, AfterViewInit {
  @ViewChildren('filter') filtersInput: QueryList<ElementRef>;
  @ViewChildren('filterSelect') filtersSelect: QueryList<MatSelect>;
  constructor(public api: DesktopService, public keptApi: DesktopKeptService, public CTI: CTIService,
              public appService: AppService, public translate: TranslateService,
              public notifyService: NotifyService) {
    super('HistoryCallsKeptComponent', api, notifyService);
    super.extParams = {
      sort: {dt: '-'}
    };
    this.api.searchOffEvent.subscribe(evt=> {
      if (evt == 'HistoryCallsKeptComponent') this.searchOff();
    });
    this.CTI.ctiCalls$.subscribe((calls: any) => {
        if (calls?.[0]?.status == 'ended') {
          setTimeout(()=>this.refresh(), 1000);
        }
    });



  }

  getApi(params) {
    return this.keptApi.getCDRs(params);
  }
  ngOnInit(): void {
    super.ngOnInit();
  }
  searchOff() {
    this.filtersInput?.forEach(item=>{
      item.nativeElement.value = '';
    })
    this.filtersSelect?.forEach(item=>{
      item.value = '';
    })
    this.empFilters = [];
    if (this.paginator && this.paginator.pageIndex != 0) this.paginator.pageIndex = 0;
    this.refresh();
  }
}
