import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Pipe({
  name: 'timeHHMM'
})

export class TimeHHMMEpochPipe implements PipeTransform {
  transform(value: string): string {
    if (value && value.split(':').length == 3) {
      const array = value.split(':');
      return `${array[0]}:${array[1]}`;
    }
    return value;
  }
}
