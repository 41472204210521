import {prop, required, pattern, digit} from '@rxweb/reactive-form-validators';
import {RxFormHelpers} from '../../_helpers/form.helpers'

export class DomainFileStorage {
  @required() name: string;
  @prop() id?: number;
  @prop() link?: string;
  @digit() size?: number;
  @digit() domain_id?: number;
  @prop() folder?: string;

  static className() {
    return 'DomainFileStorage';
  };
}
