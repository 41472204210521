import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpProgressEvent} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {Helper, IResponseBodyObject, IResponseListObject} from '../../_helpers/api.helpers';
import {Observable, of as observableOf, BehaviorSubject} from 'rxjs';
import {NotifyService} from '../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../_helpers/base.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {DomainFileStorage} from './domain-file-storage';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../config/json-app-config.service';

const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})


export class DomainFileStorageService extends BaseService<DomainFileStorage> {


  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService) {
    super(DomainFileStorage.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }


  list(params: any = {}): Observable<IResponseListObject<DomainFileStorage>> {
    params['sort'] = {name: '+'}
    return super.list(params);
  }

  get(id: number, params: any = {}): Observable<DomainFileStorage> {
    return super.get(id, params);
  }

  delete(id: number, action: string = 'delete'): Observable<IResponseBodyObject<DomainFileStorage>>  {
    return super.delete(id, action);
  }

  save(obj: DomainFileStorage, action_append: string = 'append', action_update: string = 'update') {
    let requestBody = Helper.requestFormat(action_append, this.objectName, obj);
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('id')) {
      requestBody = Helper.requestFormat(action_update, this.objectName, obj);
    }
    return this.http.post<IResponseBodyObject<DomainFileStorage>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  upload(form: FormData)  {
    return this.http.post(this.AppConfig.getValue('apiUrl'), form, {reportProgress: true, observe: 'events'});
  }

  calcProgressPercent(event: HttpProgressEvent) {
    return Math.round(100 * event.loaded / event.total);
  }
}
