import {Directive, ElementRef, OnInit, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Directive({
  selector: '[appResizable]' // Attribute selector
})

export class ResizableDirective implements OnInit, AfterViewInit {


  @Input() resizableGrabWidth = 8;
  //@Input() startWidth: Observable<number> = ;
  @Input() resizableMinWidth = 10;
  @Output() widthChange: EventEmitter<any> = new EventEmitter<any>();

  dragging = false;

  constructor(private el: ElementRef) {

    const self = this;

    const EventListenerMode = { capture: true };

    function preventGlobalMouseEvents() {
      document.body.style['pointer-events'] = 'none';
    }

    function restoreGlobalMouseEvents() {
      document.body.style['pointer-events'] = 'auto';
    }


    const newWidth = (wid) => {
      const newWidth = Math.max(this.resizableMinWidth, wid);
      el.nativeElement.style.width = (newWidth) + 'px';
      this.widthChange.emit(newWidth);
    }


    const mouseMoveG = (evt) => {
      if (!this.dragging) {
        return;
      }

      const width = evt.layerX - el.nativeElement.getBoundingClientRect().x;
      newWidth(width);
      evt.stopPropagation();
      this.widthChange.emit(width);
    };

    const dragMoveG = (evt) => {
      if (!this.dragging) {
        return;
      }
      const newWidth = Math.max(this.resizableMinWidth, (evt.layerX - el.nativeElement.getBoundingClientRect().x)) + 'px';
      el.nativeElement.style.width = newWidth;
      this.widthChange.emit(newWidth);
      evt.stopPropagation();
    };

    const mouseUpG = (evt) => {
      if (!this.dragging) {
        return;
      }
      restoreGlobalMouseEvents();
      this.dragging = false;
      evt.stopPropagation();
    };

    const mouseDown = (evt) => {
      if (this.inDragRegion(evt)) {
        this.dragging = true;
        preventGlobalMouseEvents();
        evt.stopPropagation();
      }
    };


    const mouseMove = (evt) => {
      if (this.inDragRegion(evt) || this.dragging) {
        el.nativeElement.style.cursor = 'col-resize';
      } else {
        el.nativeElement.style.cursor = 'default';
      }

    }


    document.addEventListener('mousemove', mouseMoveG, true);
    document.addEventListener('mouseup', mouseUpG, true);
    el.nativeElement.addEventListener('mousedown', mouseDown, true);
    el.nativeElement.addEventListener('mousemove', mouseMove, true);

    // this.startWidth.subscribe(
    //   width => {
    //     if (width >= this.resizableMinWidth) {
    //         this.el.nativeElement.style.width = (width) + 'px';
    //         console.log(width);
    //     }
    //   }
    // );

  }

  ngOnInit(): void {
    this.el.nativeElement.style['border-right'] = this.resizableGrabWidth + 'px solid darkgrey';

  }

  ngAfterViewInit(): void {

  }

  inDragRegion(evt) {
    return this.el.nativeElement.clientWidth - (evt.layerX - this.el.nativeElement.offsetLeft) < this.resizableGrabWidth;
  }

}
