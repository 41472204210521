import {digit, minNumber, prop, required} from '@rxweb/reactive-form-validators';

export class DOCAgent {
  @required({conditionalExpression: x => x.status != 0}) domain_id: number;
  @prop() id: number;

  @required() @digit() @minNumber({value: 1}) max_no_answer: number = 50;
  @required() @digit() @minNumber({value: 0}) wrap_up_time: number = 1; // задержка, если закончил диалог (постобработка)
  @required() @digit() @minNumber({value: 0}) reject_delay_time: number = 10; // задержка, если отклонил
  @required() @digit() @minNumber({value: 0}) busy_delay_time: number = 3; // прочие задержки
  @required() @digit() @minNumber({value: 1}) accept_timeout: number = 30; // Сколько ждем ответа от оператора о принятии сообщения
  @prop() greeting_message: string = '';

  @required({conditionalExpression: x => x.status != 0}) status_id: number; // call center status
  @required() @digit() status: number = 0;

  static className() {
    return 'DOCAgent';
  }
}
