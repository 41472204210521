import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseListComponent} from '../../../_helpers/base-list.component';
import {NotifyService} from '../../../_helpers/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {DomainDialogScript} from './domain-dialog-script';
import {DomainDialogScriptService} from './domain-dialog-script.service';
import {ConfirmComponent} from "../../../dialogs/modals/confirm/confirm.component";

@Component({
  selector: 'app-domain-dialog-script-list',
  templateUrl: './domain-dialog-script-list.component.html',
  styleUrls: ['../../../material-component/mat-table-responsive/mat-table-responsive.directive.scss'],
  styles: [
    '.mat-column-icon, .mat-column-hk { text-align: center; }'
  ]
})

export class DomainDialogScriptListComponent extends BaseListComponent<DomainDialogScript>{
  displayedColumns: string[] = ['name', 'actions'];
  filteredColumns: string[] = ['name'];
  public navigate: string = '';

  constructor(
    api: DomainDialogScriptService,
    translate: TranslateService,
    notifyService: NotifyService,
    public route: ActivatedRoute,
    dialog: MatDialog,
    router: Router
  ) {
    super(api, translate, notifyService, dialog, router);
    this.navigate = 'domain-catalogs';
  }

  onRowClicked(row) {
    this.api.details(row, {filter: this.filterCtrl.value});
  }

  onDelete(row) {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        row.blinker = true;
        this.api.delete(row).subscribe(
          data => {
            this.notifyService.showRequestResult(data, true);
            this.cbDeleteSuccess();
          },
          resp => {
            row.blinker = false;
            this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
            this.cbDeleteError();
          });
      }
    });
  }
}
