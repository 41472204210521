import 'hammerjs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {SkeletonLoaderModule} from '../_helpers/skeleton-loader.module';
import { MaterialModule } from '../material.module';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import {PipeModule} from '../_helpers/pipe.module';
import {MatTableResponsiveModule} from '../material-component/mat-table-responsive/mat-table-responsive.module';
import {EditInputComponent} from '../material-component/edit-input/edit-input.component';
import {AutofocusDirective} from '../material-component/edit-input/autofocus.directive';
import {AudioInputComponent} from '../material-component/audio-input/audio-input.component';
import {AudioInputModule} from '../material-component/audio-input/audio-input.module';
import {VertoComponent} from '../verto/verto.component';
import {ModalBoxModule} from '../modal-box/modal-box.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CdkTableModule,
        PipeModule,
        MatTableResponsiveModule,
        AudioInputModule,
        SkeletonLoaderModule,
        ModalBoxModule,
        PerfectScrollbarModule
    ],
    providers: [],
    exports: [VertoComponent],
    declarations: [VertoComponent]
})
export class VertoModule {}
