import {Routes} from '@angular/router';
import {FullComponent} from './layouts/full/full.component';
import {TechWorkComponent} from './layouts/tech-work/tech-work.component';
import {TechWorkGuard} from './layouts/tech-work/tech-work.guard';
import {LoginComponent} from './login/login.component';
import {AuthModule} from './auth/auth.guard';
import {NoPageFoundComponent} from './layouts/no-page-found/no-page-found.component';
import {DocsComponent} from './docs/docs.component';
import {WebAppComponent} from "./webapp/webapp.component";



export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthModule],
    children: [
      // {
            /* ЗАККОМЕНТИРОВАНО ПО ПРИЧИНЕ:
               при открытии кабинета без пути или только со слэшем в адресе, например:
                 https://pbx.runtel.org или https://pbx.runtel.org/
               происходит перенаправление:
                 1) на логин из-за этого {path: '', redirectTo: '/login'...}
                 2) затем защитник перекидывает в доступное меню и, в меню компонент проверяет жива ли сессия,
                 3) если не жива, то тогда переадресует снова на логин.
            */
      //   path: '',
      //   redirectTo: '/login',
      //   pathMatch: 'full'
      // },
      {
        path: 'profile',
        loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'material',
        loadChildren: () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
      },
      {
        path: 'pbx-settings',
        loadChildren: () => import('./pbx-settings/pbx-settings.module').then(m => m.PbxSettingsModule)
      },
      {
        path: 'doc',
        component: DocsComponent
      },
      {
        path: 'chat',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: 'pbx-hosts',
        loadChildren: () => import('./pbx-hosts/pbx-host.module').then(m => m.PbxHostModule)
      },
      {
        path: 'dialer',
        loadChildren: () => import('./dialer/dialer.module').then(m => m.DialerModule)
      },

      {
        path: 'domains',
        loadChildren: () => import('./domains/domain.module').then(m => m.DomainModule)
      },
      {
        path: 'roles',
        loadChildren: () => import('./roles/role.module').then(m => m.RoleModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./users/user.module').then(m => m.UserModule)
      },

      // {
      //   path: 'domain-users',
      //   children: [
      //     {
      //       path: 'edit/:id',
      //       canActivate: [
      //         userGuard
      //       ],
      //       children: []
      //     }
      //   ]
      // },
      {
        path: 'audio',
        loadChildren: () => import('./audio/audio.module').then(m => m.AudioModule)
      },
      {
        path: 'local-stream',
        loadChildren: () => import('./local-stream/local-stream.module').then(m => m.LocalStreamModule)
      },
      {
        path: 'domain-settings',
        canActivate: [AuthModule],
        loadChildren: () => import('./domains/domain-settings/domainSettings.module').then(m => m.DomainSettingsModule)
      },
      {
        path: 'qm',
        loadChildren: () => import('./domains/qm/qm.module').then(m => m.DomainQMModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'billing-reports',
        loadChildren: () => import('./billing-reports/billing-reports.module').then(m => m.BillingReportsModule)
      },
      {
        path: 'crm',
        canActivate: [AuthModule],
        loadChildren: () => import('./domains/domain-crm/crm.module').then(m => m.CRMModule)
      },
      {
        path: 'omni',
        canActivate: [AuthModule],
        loadChildren: () => import('./domains/domain-omni-channel/domain-omni-channel.module').then(m => m.DomainOmniChannelModule)
      },
      {
        path: 'domain-catalogs',
        loadChildren: () => import('./domains/domain-catalogs/domain-catalog.module').then(m => m.DomainCatalogModule)
      },
      {
        path: 'domain-journals',
        loadChildren: () => import('./domains/domain-journals/domain-journals.module').then(m => m.DomainJournalsModule)
      },
      {
        path: 'aons',
        canActivate: [AuthModule],
        loadChildren: () => import('./aons/aon.module').then(m => m.AonModule)
      },
      {
        path: 'queues',
        canActivate: [AuthModule],
        loadChildren: () => import('./queues/queue.module').then(m => m.QueueModule)
      },
      {
        path: 'conferences',
        canActivate: [AuthModule],
        loadChildren: () => import('./domains/domain-conference/domain-conference.module').then(m => m.DomainConferenceModule)
      },
      {
        path: 'domain-teams',
        canActivate: [AuthModule],
        loadChildren: () => import('./domains/domain-teams/domain-team.module').then(m => m.DomainTeamModule)
      },
      {
        path: 'dialplans',
        canActivate: [AuthModule],
        loadChildren: () => import('./dialplans/dialplan.module').then(m => m.DialplanModule)
      },
      {
        path: 'gscatalogs',
        loadChildren: () => import('./gscatalogs/gscatalog.module').then(m=>m.GSCatalogModule)
      },

      {
        path: 'marketplace',
        loadChildren: () => import('./domains/marketplace/marketplace.module').then(m => m.MarketplaceModule)
      }
    ]
  },
  {
    path: 'meeting',
    loadChildren: () => import('./meeting/meeting.module').then(m => m.MeetingModule)
  },
  {
    path: 'sso/login',
    component: LoginComponent,
    data: {sso: true}
  },
  {
    path: 'sso', // callback для SSO-авторизации
    component: LoginComponent,
    data: {sso: true}
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'webapp/:token',
    component: WebAppComponent,
  },
  {
    path: 'tech_work',
    component: TechWorkComponent,
    canActivate: [TechWorkGuard] // не пускаю на страницу тех работ, если они не ведутся (при успешном получении DomainWhiteLabel-настроек)
  },
  {
    path: '**',
    component: NoPageFoundComponent
  }
];

