import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Component, ContentChild, Inject, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {NotifyService} from '../../../_helpers/notify.service';
import {TranslateService} from "@ngx-translate/core";
import {DomainFileStorageListComponent} from "../domain-file-storage-list.component";
import {DomainFileStorageService} from "../domain-file-storage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatStepper} from "@angular/material/stepper";

@Component({
  selector: 'app-domain-file-dialog',
  templateUrl: 'domain-file-dialog.component.html',
  styleUrls: ['domain-file-dialog.component.scss']
})



export class DomainFileDialogComponent extends DomainFileStorageListComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  public rxFormHelpers;
  selectedRowIndex:any;
  stepperLoaded = false;
  public settings = {};
  dialogForm = this._formBuilder.group({
    file: ['', Validators.required],
    width: ['150px', Validators.required],
    height: ['100px', Validators.required],
    align: ['left', Validators.required],
    vspace: ['10', Validators.required],
    hspace: ['10', Validators.required]
  });
  constructor(
    api: DomainFileStorageService,
    private _formBuilder: FormBuilder,
    public route: ActivatedRoute,
    dialog: MatDialog,
    router: Router,

    public notifyService: NotifyService,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<DomainFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public fb: RxFormBuilder
  ) {
    super(api, translate, notifyService, route, dialog, router);
    dialogRef.disableClose = true;
    super.displayedColumns = ['name', 'size', 'actions'];
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.stepperLoaded = true;
  }

  ngOnInit() {
    this.rxFormHelpers = new RxFormHelpers();
    this.settings = this.data.settings;
  }

  highlight(row){
    return row;
  }

  save() {
    this.dialogForm.updateValueAndValidity();
    this.dialogForm.markAllAsTouched();
    if (this.dialogForm.valid) {
      this.dialogRef.close(this.dialogForm.value);
    }
    else {
      this.notifyService.message('NOTIFY.REQUIRED');
    }
  }
  close() {
    this.dialogRef.close();
  }

}
