import {MediaMatcher} from '@angular/cdk/layout';
import {Router} from '@angular/router';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../../auth/authentication.service';
import {JsonAppConfigService} from '../../config/json-app-config.service';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnInit, OnDestroy {
  mobileQuery: MediaQueryList;
  showHide: boolean;
  url: string;
  sidebarOpened;
  version = '';
  public showSearch = false;
  public company_site: string;
  public minisidebar: boolean;
  public boxed: boolean;
  public style: string;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public translate: TranslateService,
    public authenticationService: AuthenticationService,
    private AppConfig: JsonAppConfigService
  ) {
    this.minisidebar = AppConfig.getValue('minisidebar');
    this.boxed = AppConfig.getValue('boxed');
    this.style = AppConfig.getValue('theme');
    this.company_site = AppConfig.getValue('site');
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.authenticationService.version().subscribe(ver => {
        this.version = ver;
      }
    );
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  onToggle() {
    setTimeout(()=>window.dispatchEvent(new Event('resize')), 1000);
  }
  // Mini sidebar
}
