import {Component} from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  template: `
    <form>
    <div fxLayout="row wrap" class="p-l-20 m-t-10">
      <div fxFlex.lt-sm="25" fxFlex.lt-md="20" fxFlex.lt-lg="10" fxFlex="10" class="m-0">
        <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
      </div>
    </div>
    <mat-card-content  fxLayout="row wrap" class="m-b-0 m-t-0">
      <div class="p-l-10 p-r-10" fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="100">
        <mat-form-field>
          <div fxFlex.lt-sm="25" fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="100">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-10 p-r-10" fxFlex.gt-sm="45" fxFlex.gt-xs="40" fxFlex="85">
        <mat-form-field>
          <div fxFlex.gt-sm="45" fxFlex.gt-xs="40" fxFlex="85">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-10 p-r-10" fxFlex.gt-sm="10" fxFlex.gt-xs="15" fxFlex="15">
        <mat-form-field floatLabel="always" appearance="none">
          <div fxFlex.gt-sm="10" fxFlex.gt-xs="15" fxFlex="15">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-10 p-r-10" fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="100">
        <mat-form-field>
          <div fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="100">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-10 p-r-10" fxFlex.gt-sm="45" fxFlex.gt-xs="40" fxFlex="100">
        <mat-form-field>
          <div fxFlex.gt-sm="45" fxFlex.gt-xs="40" fxFlex="100">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-10 p-r-10" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" >
        <mat-form-field>
          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
    </mat-card-content>

    <div fxLayout="row wrap" class="p-l-20">
      <div fxFlex.lt-sm="25" fxFlex.lt-md="20" fxFlex.lt-lg="10" fxFlex="10" class="m-0">
        <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
      </div>
    </div>
    <mat-card-content fxLayout="row wrap" class="m-b-0 m-t-0">
      <div class="p-l-10 p-r-10" fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
        <mat-form-field>
          <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-10 p-r-10" fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
        <mat-form-field>
          <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div class="p-l-10 p-r-10" fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
        <mat-form-field>
          <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
    </mat-card-content>

    <div fxLayout="row wrap" class="p-l-20">
      <div fxFlex.lt-md="20" fxFlex.lt-lg="10" fxFlex="10" class="m-0">
        <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
      </div>
    </div>
    <mat-card-content fxLayout="row wrap" class="m-b-0 m-t-0">
      <div class="p-l-10 p-r-10" fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
        <mat-form-field>
          <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
      <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
        <mat-form-field>
          <div fxFlex.gt-sm="33" fxFlex.gt-xs="33" fxFlex="100">
            <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
          </div>
          <input matInput>
          <ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader>
        </mat-form-field>
      </div>
    </mat-card-content>
  </form>
  `,
  styleUrls: []
})

export class SkeletonLoaderComponent {}
