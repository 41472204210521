import {prop, required, choice, minLength} from '@rxweb/reactive-form-validators';

export class DomainCRMDFieldConf {
  @prop() id?: number;
  @required() name: string;
  @required() @choice({minLength: 1}) obj: number[] = []; // OBJ_TYPE_COMPANY = 0 | OBJ_TYPE_CONTACT = 1 | OBJ_TYPE_EVENT = 2;
  @prop() required: boolean = false;
  @prop() visible: boolean = true;
  @required() ftype: number = 0; // FIELD_STR = 0 | FIELD_INT = 1 | FIELD_BOOL = 2 | FIELD_DATE = 3 | FIELD_LIST = 4 | FIELD_TEXT = 5
  @prop() event_id_list: number[] = []; // событие для, к которому относится это поле; если нет выбранных событий, то применимо ко всем
  @required() @choice({conditionalExpression: x => x.choices_only && (x.ftype == 0 || x.ftype == 4), minLength: 1, message: 'ERROR.REQUIRED'}) choices: string[] = [];
  @required() choices_only: boolean = false;

  static className() {
    return 'DomainCRMDFieldConf';
  };
}

export const DOMAINCRMFTYPE = {
  0: 'str_val',
  1: 'int_val',
  2: 'bool_val',
  3: 'dt_val',
  4: 'list_val',
  5: 'txt_val'
}
