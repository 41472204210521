import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NotifyService} from '../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../config/json-app-config.service';
import {DomainCCMonitor2} from '../../monitor2/monitor2';
import {Observable} from 'rxjs';
import {Helper, IResponseBodyObject} from '../../_helpers/api.helpers';
import {catchError, map, tap} from 'rxjs/operators';
const headers = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable()
export class MonitorWSService {
  constructor(public http: HttpClient, public notifyService: NotifyService,
              public router: Router, public route: ActivatedRoute, public authenticationService: AuthenticationService,
              public translate: TranslateService, public AppConfig: JsonAppConfigService) {

  }

  set(obj_: DomainCCMonitor2): Observable<IResponseBodyObject<DomainCCMonitor2>>  {
    const id = obj_['id'];
    const domain_id = obj_['domain_id'];
    delete obj_['id'];
    delete obj_['domain_id'];

    let obj = {
      id: id,
      domain_id: domain_id,
      params: obj_
    }


    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }


    let requestBody = Helper.requestFormat('append', DomainCCMonitor2.className(), obj);
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('id') && obj['id']) {
      requestBody = Helper.requestFormat('update', DomainCCMonitor2.className(), obj);
    }

    return this.http.post<IResponseBodyObject<DomainCCMonitor2>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res, false);
        }));
  }

  get(): Observable<DomainCCMonitor2> {

    const requestBody = Helper.requestFormat('get', DomainCCMonitor2.className(), {
      domain_id: this.authenticationService.currentUserValue.domain_id,
    });


    return this.http.post<IResponseBodyObject<DomainCCMonitor2>>
    (this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        catchError(resp => {
          return null;
        }),
        // tap(res => this.notifyService.checkCode(res) ),
        map((data:any) => {
          if (data?.body?.params) return {
            ...data.body?.params,
            id: data.body?.id,
            domain_id: data.body?.domain_id,
          } as DomainCCMonitor2;

          else if (data?.body) return data.body;
          else return new DomainCCMonitor2()
        }),

      );
  }
}
