import {digit, maxLength, numeric, NumericValueType, prop, propArray, propObject, required} from '@rxweb/reactive-form-validators';
import {LSAudioList} from '../local-stream/local-stream';
import {DEPAmoCRMNumber, DEPAmoCRMProcessing} from '../domains/marketplace/marketplace';


export class VoiceTag {
  @prop() id: string;
  @prop() desc: string;
}

export class Directory {
  [key: string]: number;
}

export class VoiceDirectory {
  @prop() id?: number;
  @prop() domain_id?: number;
  @propObject(Directory) directory;


  static classNameDomain() {
    return 'DomainVoiceDirectory';
  }

  static classNameSystem() {
    return 'GSVoiceDirectory';
  }
}

