import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { KtdGridComponent, ktdTrackById } from '@katoid/angular-grid-layout';
import { DOCUMENT } from '@angular/common';
import {FormControl, FormGroup} from '@angular/forms';
import {StorageMap} from '@ngx-pwa/local-storage';
import {DomainService} from '../../../domains/domain.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from '../../../_helpers/notify.service';
import {MonitorWSService} from '../../_common/monitor-ws.service';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
// @ts-ignore
import GRID_DATA from './def-grid.json';
import {BaseDesktopComponent} from '../../_common/base-desktop.component';
import {AppService} from '../../../app.service';
import {DesktopService} from '../../_common/base-desktop.service';
import {MatTab, MatTabChangeEvent, MatTabGroup} from "@angular/material/tabs";
import {ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, NavigationStart, Router} from "@angular/router";
import {merge} from "rxjs";
import {filter} from "rxjs/operators";
import {DesktopEntityComponent} from "../../../domains/domain-crm/entities/desktop-entity.component";

@Component({
  selector: 'app-desktop-def',
  templateUrl: './desktop-def.component.html',
  styleUrls: ['../../_common/desktop.component.scss'],
  providers: [MonitorWSService]
})
export class DesktopDefComponent extends BaseDesktopComponent implements OnInit, AfterViewInit {
  @ViewChild(KtdGridComponent, {static: true}) grid: KtdGridComponent;
  @ViewChild('generalTab') generalTab : MatTabGroup;
  @ViewChild('desktopCRMEntity') desktopCRMEntity: DesktopEntityComponent;

  public data: any = GRID_DATA?.data;
  public selectedTabCtrl = new FormControl(0);
  public layout: any = GRID_DATA?.layout;
  public storageName = 'DEFAULT';

  trackById = ktdTrackById;
  settings: FormGroup = new FormGroup({
    DesktopQueueComponent: new FormControl(true),
    InboundScriptComponent: new FormControl(true),
    DesktopAgentComponent: new FormControl(true),
    KnowledgebaseListComponent: new FormControl(false),
    DesktopEntityComponent: new FormControl(false),
    HistoryCallsDefComponent: new FormControl(true),
    TiersTableComponent: new FormControl(true),
    DragAndResize: new FormControl(false),
    // IFRAME: new FormControl(true),
  });
  cols = 50;
  rowHeight = 10;
  tabIndex = 0;
  compactType = 'vertical';
  private fragment: string;

  constructor(@Inject(DOCUMENT) public document: Document,
              public appService: AppService,
              public desktopService: DesktopService,
              public fb: RxFormBuilder,
              public translate: TranslateService,
              public route: ActivatedRoute,
              public router: Router,
              public domainService: DomainService,
              public monitorWSService: MonitorWSService,
              public authenticationService: AuthenticationService,
              public notifyService: NotifyService,
              public storage: StorageMap) {
    super(document, appService, desktopService, fb, translate, domainService, monitorWSService,
      authenticationService, notifyService, storage)

      route.queryParams.pipe(filter(params => params.tab)).subscribe((val: any)=>{
        if (val.tab) {
          const position = this.getTabIndexByName(val.tab)
          this.selectedTabCtrl.setValue(position);
        }
      })
  }

  getTabIndexByName(name) {
    let index = -1;
    if (this.generalTab) {

      this.generalTab._tabs.forEach((item, i)=>{
        if (item.textLabel == name ) {
          index = i
        }
      });
    }
    return index;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(()=>{
       this.selectedTabCtrl.setValue(this.getTabIndexByName(this.route.snapshot.queryParamMap.get('tab')));
    }, 3000)
  }

  changeSelectedTab(event: MatTabChangeEvent) {
    this.selectedTabCtrl.setValue(event.index);
    this.generalTab._tabs.forEach((item)=>{
      if (item.isActive) {
        this.router.navigate([], {
          queryParams: {tab: item.textLabel}
        });

        if (item.textLabel == 'desktop') {
          this.redraw(300);
        } else if (item.textLabel == 'crm') {
          this.desktopCRMEntity.desktopEntityTabsGroup?.realignInkBar();
          this.desktopCRMEntity.appEntityChildren.forEach(aec => aec.entityTabsGroup?.realignInkBar());
        }
      }
    });

  }
  ngOnInit() {
    super.ngOnInit();
    this.storage.get(`${this.storageName}::DESKTOP::COLUMNS`).subscribe(
      (store: any) => {
        Object.keys(store || {}).forEach(key=> {
          this.desktopService.findColumns(this.data, key)?.forEach(column=>{
            if (store?.[key]?.includes(column.id)) column.checked = true;
            else column.checked = false;
          });
          this.desktopService.setMenu({name: key, columns: store[key] || []});
        })
      });

  }
}
