import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ConfirmComponent} from '../../../../../dialogs/modals/confirm/confirm.component';
import {NotifyService} from '../../../../../_helpers/notify.service';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {AuthenticationService} from '../../../../../auth/authentication.service';
import {BaseFormComponent} from '../../../../../_helpers/base-form.component';
import {KeptSettingsDesktop, SMTPSettings} from './smtp-setting';
import {SMTPSettingsService} from './smtp-setting.service';
import {TranslateService} from '@ngx-translate/core';
import {SystemTextTemplateService} from '../../../../../gscatalogs/system-text-templates/system-text-template.service';
import {forkJoin, Subscription} from 'rxjs';
import {SystemTextTemplate} from '../../../../../gscatalogs/system-text-templates/system-text-template';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {RxFormHelpers} from '../../../../../_helpers/form.helpers';
import {ROLE_TYPE_DOMAIN_LIST, ROLE_TYPE_PROVIDER_LIST} from '../../../../../_helpers/constant';
import {DomainQueueExtraOption} from '../../../../../queues/queue';
import {KeptSettingsService} from './kept-setting.service';
import {DomainProject} from '../../../../../domains/domain-projects/domain-project';
import {DomainProjectService} from '../../../../../domains/domain-projects/domain-project.service';
import {StorageMap} from '@ngx-pwa/local-storage';


@Component({
  selector: 'app-kept-settings',
  templateUrl: './smtp-settings.component.html',
  styles: []
})

export class SMTPSettingsComponent extends BaseFormComponent<SMTPSettings> implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription();
  select_context: SystemTextTemplate[] = [];
  select_project: DomainProject[] = [];
  public desktop_form: FormGroup;
  public storageName = 'KEPT';
  constructor(
    api: SMTPSettingsService,
    public settingsService: KeptSettingsService,
    templateService: SystemTextTemplateService,
    projectService: DomainProjectService,
    fb: RxFormBuilder,
    route: ActivatedRoute,
    router: Router,
    http: HttpClient,
    notifyService: NotifyService,
    public translate: TranslateService,
    public storage: StorageMap,
    authenticationService: AuthenticationService,
  ) {
    super(SMTPSettings, api, fb, route, router, http, notifyService, authenticationService);
    super.navigate = 'domain-settings';
    let subTemplate$ = templateService.toSelect({},'select').subscribe(data => this.select_context = data);
    this.subscription.add(subTemplate$);
    let subProject$ = projectService.toSelect({},'select').subscribe(data => this.select_project = data);
    this.subscription.add(subProject$);
    this.desktop_form = this.fb.formGroup(new KeptSettingsDesktop());

    this.translate.use(this.translate.currentLang || 'ru');
  }

  ngOnInit() {
    this.rxFormHelpers = new RxFormHelpers();
    this.form = this.fb.formGroup(this.formT);

    let subGet$ = this.api.get(null).subscribe(
      data => {
        if (!this.form.get('id')) { this.form.addControl('id', new FormControl(this.id)); }
        this.form.patchValue(<SMTPSettings>data);
        this.callbackFormPatch(<SMTPSettings>data);
        this.isLoadingResults = false;
      },
      resp => {
        this.isLoadingResults = false;
        this.callbackGetError();
        this.isServerAvailable = this.notifyService.setFormErrors(resp, this.form, this.navigate) != 502;
      }
    );
    this.subscription.add(subGet$);

    this.subscription.add(this.settingsService.get(null).subscribe(
      (data: KeptSettingsDesktop) => {
        //if (!this.extra_form.get('id')) { this.extra_form.addControl('id', new FormControl(this.id)); }
        this.desktop_form.patchValue(<KeptSettingsDesktop>data);
        this.isLoadingResults = false;
      },
      resp => {
        this.isLoadingResults = false;
        this.callbackGetError();
        this.isServerAvailable = this.notifyService.setFormErrors(resp, this.desktop_form, this.navigate) != 502;
      }
    ));


  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  callbackFormPatch(data) {
    this.form.patchValue(<SMTPSettings>data);

    super.callbackFormPatch(data);
  }

  onSubmit(submit_btn?: MatButton) {
    this.desktop_form.updateValueAndValidity({emitEvent: true, onlySelf: false});
    this.desktop_form.markAllAsTouched();
    this.form.updateValueAndValidity({emitEvent: true, onlySelf: false});
    this.form.markAllAsTouched();

    if (this.desktop_form.valid && this.form.valid) {

      forkJoin([
        this.api.save(this.form.value),
        this.settingsService.save(this.desktop_form.value)])
      .subscribe(result=> {
            this.router.navigate([this.navigate]);
          }, resp => {
        this.isServerAvailable = this.notifyService.setFormErrors(resp[0], this.form) != 502
        && this.notifyService.setFormErrors(resp[1], this.desktop_form) != 502;

      });
    }
  }

  onTemplate(tmpBtn?: MatButton) {
    if (tmpBtn) tmpBtn.disabled = true;
    forkJoin([
      this.storage.get(`${this.storageName}::DESKTOP::COLUMNS`),
      this.storage.get(`${this.storageName}::DESKTOP::SETTINGS`)
    ])
    .subscribe(
      (result: any) => {
        this.desktop_form.patchValue({interface: {
          columns: result[0], settings: result[1]
          }});
        this.notifyService.message('NOTIFY.200');
        if (tmpBtn) tmpBtn.disabled = false;

      }, err=> {
        if (tmpBtn) tmpBtn.disabled = false;
      });





  }
}
