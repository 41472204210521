import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {BigScreenService} from '../../../../../_helpers/bigscreen.service';
import {AppService} from '../../../../../app.service';
import {DesktopDashboardService} from './desktop-dashboard.service';
import {FormControl} from '@angular/forms';
import {DomainConference} from '../../../../../domains/domain-conference/domain-conference';
import {NotifyService} from '../../../../../_helpers/notify.service';
import { ChartComponent } from 'ng-apexcharts';

import {
  ApexNonAxisChartSeries,
  ApexResponsive, ApexPlotOptions,
  ApexChart, ApexFill, ApexStroke
} from 'ng-apexcharts';
import {QueueService} from '../../../../../queues/queue.service';
import {SubscriptionLike, timer} from 'rxjs';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive?: ApexResponsive[];
  labels: string[];
  fill?: ApexFill;
  stroke?: ApexStroke;
  plotOptions?: ApexPlotOptions;
};

@Component({
  selector: 'app-desktop-dashboard',
  templateUrl: './desktop-dashboard.component.html',
  styleUrls: ['./desktop-dashboard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DesktopDashboardComponent implements OnInit, OnDestroy {
  private messages$; // сообщения сокета
  private messagesSub: SubscriptionLike;
  public chartOptions: Partial<ChartOptions>;
  isCardExpanded: boolean = false;
  activeTabIndex: number = 0;
  @Output() tabChange: EventEmitter<any> = new EventEmitter<any>();

  public queueAgentSource = {};
  public queueMemberSource = {};
  public queues = [];

  constructor(public bigScreenService: BigScreenService,
              public api: DesktopDashboardService,
              public appService: AppService,
              public queueService: QueueService,
              public notifyService:  NotifyService,
              public elRef: ElementRef,
              private changeDetector: ChangeDetectorRef) {

    this.messages$ = this.appService.on<any>();
    this.messagesSub = this.messages$.subscribe(
      msg => this.onMessage(msg),
      err => console.log(err),
      () => console.log('complete')
    );

    this.appService.subscribe([{name: 'mss4b|domain_online_dashboard', params: {queue_agent_state: [], queue_member_state: []}}]);

    // timer(2000,5000)
    //   .subscribe(t => {
    //     // this.isLoadingResults = false;
    //     changeDetector.detectChanges()
    //   })

    queueService.toSelect({}, 'select').subscribe(data=>{
      this.queues = data;
    });
  }

  ngOnInit(): void { }

  public onMessage(msg: any = {}) {
    // console.log('form. received message:', msg);

    if (typeof msg==='string') msg = JSON.parse(msg);
    let member_index, member;

    if (msg.code === 407 || msg.code === 401) { // токена нет или он устарел
      this.notifyService.messageByCode(msg.code);
      // remove user from local storage to log user out
      this.notifyService.auth.logout();
      return false;
    }

    switch (msg.action) {
      case 'auth':  // событие установки соединения с сокетом, после которого нужно подписаться на нужные события
        this.appService.subscribe([{name: 'mss4b|domain_online_dashboard', params: {queue_agent_state: [], queue_member_state: []}}]);
        break;
      case 'subscribe': // запрашиваю данные конференций (после подписки на конференции)
        if (msg.code == 200) {}
        // console.log(msg);
        break;

      case 'queue_member_state':
        this.queueMemberSource = msg.body;
        this.changeDetector.detectChanges();
        break;
      case 'queue_agent_state':
        this.queueAgentSource = msg.body;
        this.changeDetector.detectChanges();
        break;
      default:
        // if (msg.action!=='ping') console.log(msg);
    }

  }

  ngOnDestroy() {
    this.appService.unsubscribe(['mss4b|domain_online_dashboard']);
    if (this.messagesSub) this.messagesSub.unsubscribe();
  }

  fullscreen() {
    let doc = this.elRef.nativeElement.parentNode.parentNode;

    if (this.bigScreenService.isFullscreen()) {
      this.bigScreenService.exit();
      this.isCardExpanded = false;
    }
    else {
      this.bigScreenService.request(doc);
      this.isCardExpanded = true;
    }

  }

  openTab() {
    if (this.activeTabIndex == 0) {
      this.activeTabIndex = 1;
    } else if (this.activeTabIndex == 1) {
      this.activeTabIndex = 0;
    }
    this.tabChange.emit(this.activeTabIndex);
  }

  calcWaiting(queue_id) {
    const vals = this.queueMemberSource[queue_id];
    const total = vals.waiting+vals.answered;
    return total > 0 ? Math.round(vals.waiting/total*100) : 0;
  }

  calcAgentState(queue_id) {
    const vals = this.queueAgentSource[queue_id];

    return [vals.free || 0, vals.busy || 0];
  }
  getCharMemberOptions(queue_id: number, section='series'): any {
    if (section == 'labels') {
      const val = this.queues.find(data=>data.id == queue_id)?.name || '';
      return [val]
    } else if (section == 'series') {
      return [this.calcWaiting(queue_id)];
    } else if (section == 'chart') {
      return {
        height: 150,
        type: 'radialBar',
      }
    } else if (section == 'plotOptions') {
      return {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: '14px',
              color: undefined,
              offsetY: 65
            },
            value: {
              offsetY: 30,
              fontSize: '14px',
              color: undefined,
              formatter: function(val) {
                return val + '%';
              }
            }
          }
        }
      }
    } else if (section == 'fill') {
      return {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91]
        }
      }
    } else if (section == 'stroke') {
      return {
        dashArray: 4
      }
    }
  }

  getCharAgentOptions(queue_id: number, section='series'): any {
    if (section == 'labels') {
      return ['free', 'busy']
    } else if (section == 'title') {
      const val = this.queues.find(data=>data.id == queue_id)?.name || '';
      return {
        text: val,
        align: 'center',
        style: {
          fontSize: '14px',
          fontWeight: 400
        }
      }
    } else if (section == 'series') {
      return this.calcAgentState(queue_id);
    } else if (section == 'chart') {
      return {
        type: 'donut',
        height: 150
      }
    } else if (section == 'plotOptions') {
      return {
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '12px',
                formatter: function (val) {
                  return val
                }
              }
            }
          }
        }
      }
    }
  }
}
