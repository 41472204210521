import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {JsonAppConfigService} from '../../config/json-app-config.service';

@Injectable({providedIn: 'root'})
export class TechWorkGuard implements CanActivate {
  constructor(private ConfigService: JsonAppConfigService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let loadingError = this.ConfigService.getValue('loadingError');
    if (loadingError) return true;
    else this.router.navigate(['']);
    return false;
  }
}
