import {AfterViewInit, Renderer2, Component, ElementRef, Inject, OnInit, ViewChild, inject} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {WebAppService} from "./webappservice";
import {ActivatedRoute} from "@angular/router";
import { NgForm, Validators } from '@angular/forms'

declare var WebApp: any;


@Component({
  selector: 'app-webapp',
  templateUrl: './webapp.component.html',
  styleUrls: ['./webapp.component.scss']
})
export class WebAppComponent implements OnInit, AfterViewInit {
  @ViewChild('script', {static: true}) script: ElementRef;
  @ViewChild('contactForm') contactForm: NgForm;
  private window;
  public forms = [];
  public contact: any = {};

  tg;
  constructor(@Inject(DOCUMENT) private _document,
              public api: WebAppService,
              public route: ActivatedRoute,
              private renderer2: Renderer2) {
    this.window = this._document.defaultView;
    this.tg = this.window.Telegram.WebApp;

  }

  fieldValid() {
    let flag = true
    this.forms.filter(item => item.model_name === 'DOCChannelFormOptionRadioButton' && item.required?.length > 0)
      ?.forEach(_ => {
        if (this.contactForm?.value?.[_.session_var]) {
          if (!_.required.includes(this.contactForm.value?.[_.session_var])) {
            this.contactForm.form.controls[_.session_var].setValidators(Validators.required)
            this.contactForm.form.controls[_.session_var].setErrors({incorrect: true})
            this.contactForm.form.controls[_.session_var].markAsDirty()
            flag = false
          } else {
            this.contactForm.form.controls[_.session_var].setErrors(null)
            this.contactForm.form.controls[_.session_var].clearValidators()
          }
        }
      })
    return flag
  }
  ngAfterViewInit() {
    let token = this.route.snapshot.paramMap.get('token');
    console.log(token);
    this.api.basicAuth({token: token}).subscribe((data: any)=> {
      console.log(data?.form_options)
      this.forms = data?.form_options || [];
    })
  }

  ngOnInit() {
    this.tg = this.window.Telegram.WebApp;
  }

  testBasicAuth() {
    this.contactForm.form.markAllAsTouched()
    if (!this.fieldValid()) { return }
    if (this.contactForm.valid) {

      let token = this.route.snapshot.paramMap.get('token');
      this.api.postTgData(token, this.contactForm.value).subscribe((data: any)=> {
        if (data?.success == true) this.tg.close();
        else this.tg.showAlert('Что-то пошло не так :( ');
      })
    }
  }
}
