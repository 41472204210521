import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IResponseListObject} from '../../_helpers/api.helpers';
import {Observable} from 'rxjs';
import {NotifyService} from '../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../_helpers/base.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {DomainProject} from './domain-project';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../config/json-app-config.service';

const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})


export class DomainProjectService extends BaseService<DomainProject> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService) {
    super(DomainProject.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  list(params: any = {}): Observable<IResponseListObject<DomainProject>> {
    params['sort'] = {name: '+'}
    return super.list(params);
  }
}
