import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from '../../../_helpers/notify.service';
import {FormControl, FormGroup} from '@angular/forms';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {DomainVoiceKitService} from '../../../domains/domain-voice-kit/domain-voice-kit.service';

@Component({
  selector: 'app-speech-dialog',
  templateUrl: './speechDialog.component.html',
  styleUrls: ['./speechDialog.component.css']
})

export class SpeechDialogComponent implements OnInit {
  public returnFile;
  public rxFormHelpers = new RxFormHelpers();
  public voice_select = []
  public form: FormGroup = new FormGroup({
    id: new FormControl(''),
    dpe_name: new FormControl('DPEDomainVoiceKit'),
    template: new FormControl('')
  });

  constructor(
    public dialogRef: MatDialogRef<SpeechDialogComponent>,
    public translate: TranslateService,
    protected domainVoiceKitService: DomainVoiceKitService,
    public notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    domainVoiceKitService.toSelect({}, 'select').subscribe(kits => this.voice_select = kits);
  }

  ngOnInit(): void {
  }
}
