export class AppConfiguration {
  apiUrl: string;
  fileUrl: string;
  wsUrl: string;
  sourceUrl: string;
  dockURL: string = 'https://doc-cp.runtel.ru/';
  ctiUrl?: string;
  locales: Array<string> = ['en', 'ru'];
  defaultLocale: string = 'ru';
  title: string = 'VoIP PBX';
  site: string = '';
  minisidebar: boolean = false;
  boxed: boolean = false;
  theme: string = 'white';
  backgroundLogin?: string;
  authWindowPosition?: number = 13;
  gaussPalette?: number = 5;
  iconLogo?: string;
  iconLogoMini?: string;
  favicon?: string;
  primaryColor?: string;
  primaryContrastColor?: string;
  accentColor?: string;
  accentContrastColor?: string;
  warnColor?: string;
  warnContrastColor?: string;
  bodyColor?: string;
  dpBorderColor?: string;
  dpElemHover?: string;
  sidebarIconColor?: string;
  sidebarTextColor?: string;
  headerBg?: string;
  headerColor?: string;
  sentryDSN?: string;
  sentry?: boolean;
  defaultGeoCountry: string = '78cef85a-64b5-4ad6-a29e-d365e3ecee43';
  loadingError: boolean = false; // ошибка получения DomainWhiteLabel от АПИ при инициализации объекта
}
