import { NgControl } from '@angular/forms';
import {Directive, Input} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[disableControl]'
})
export class DisableControlDirective {

  @Input() set disableControl( condition : boolean ) {
    const action = condition ? 'disable' : 'enable';
    setTimeout(()=>this.ngControl.control[action]())

  }

  constructor( private ngControl : NgControl ) {
  }

}
