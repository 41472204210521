import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../../_helpers/notify.service';
import {FormControl, FormGroup} from '@angular/forms';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {NOTIFY_TAGS} from '../../../_helpers/constant';
import {FieldModel} from '../../../_helpers/dynamic/field.model';
import {FormControlService} from '../../../_helpers/dynamic/form.control.service';

@Component({
  selector: 'app-tag-notify-dialog',
  templateUrl: './tagNotifyDialog.component.html',
  styleUrls: ['./tagNotifyDialog.component.css']
})

export class TagNotifyDialogComponent implements OnInit {
  public returnFile;
  public rxFormHelpers = new RxFormHelpers();
  public tag_select = NOTIFY_TAGS;
  public form: FormGroup = new FormGroup({
    dpe_name: new FormControl(''),
    field: new FormControl(''),
  });
  public tag_options = []
  fields: FieldModel[];
  personalInfoForm: FormGroup = new FormGroup({});


  constructor(public dialogRef: MatDialogRef<TagNotifyDialogComponent>, public translate: TranslateService,
              fb: RxFormBuilder, route: ActivatedRoute, formControlService: FormControlService,
              public notifyService: NotifyService,
              @Inject(MAT_DIALOG_DATA) public data
              ) {
    this.form.get('dpe_name').valueChanges.subscribe(value => {
      this.fields = (NOTIFY_TAGS.find(id=>id.id === value)?.dynamic || []) as FieldModel[];
      this.personalInfoForm = formControlService.getFormGroupObject(this.fields);
    });
    this.tag_options = data?.notify_option || []
  }

  ngOnInit(): void {
  }

  getFormData() {
    return {
      ...this.form.value, ...this.personalInfoForm.value
    }
  }
}
