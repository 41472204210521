import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {FileElement} from '../../../file-explorer/model/element';
import {AudioFormComponent} from '../../../audio/audio-form.component';
import {FileService} from '../../../file-explorer/file.service';
import {AudioService} from '../../../audio/audio.service';
import {NotifyService} from '../../../_helpers/notify.service';
import {AuthenticationService} from '../../../auth/authentication.service';

@Component({
  selector: 'app-file-dialog',
  templateUrl: './fileDialog.component.html',
  styleUrls: ['./fileDialog.component.css']
})

export class FileDialogComponent extends AudioFormComponent implements OnInit {
  public returnFile;
  public objectName = 'DomainAudio';
  constructor(
    public dialogRef: MatDialogRef<FileDialogComponent>,
    public translate: TranslateService,
    public fileService: FileService,
    public serviceApi: AudioService,
    public notifyService: NotifyService,
    authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public domain
  ) {
    super(fileService, serviceApi, notifyService, authenticationService);
  }

  selectFile(event) {
    if (typeof event.value == 'string') event.value = parseInt(event.value, 10) || event.value;
    this.returnFile = event;
  }

  goFolder(element: FileElement) {
    super.goFolder(element);
    this.returnFile = null;
  }

  navigateToFolder(element: FileElement) {
    super.navigateToFolder(element);
    this.returnFile = null;
  }

  navigateUp() {
    super.navigateUp();
    this.returnFile = null;
  }

}
