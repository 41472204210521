import { NgModule } from '@angular/core';
import {AgentListComponent} from './agent-list.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ReportsRoutes} from '../reports/reports.routing';
import {TranslateModule} from '@ngx-translate/core';
import {MaterialModule} from '../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CdkTableModule} from '@angular/cdk/table';
import {PipeModule} from '../_helpers/pipe.module';
import {MatTableResponsiveModule} from '../material-component/mat-table-responsive/mat-table-responsive.module';
import {EditInputModule} from '../material-component/edit-input/edit-input.module';
import {SkeletonLoaderModule} from '../_helpers/skeleton-loader.module';
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgApexchartsModule} from 'ng-apexcharts';
import {MatSelectUserModule} from '../material-component/mat-select-user/mat-select-user.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CdkTableModule,
        PipeModule,
        MatTableResponsiveModule,
        EditInputModule,
        SkeletonLoaderModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        NgxMatMomentModule,
        MatDatepickerModule,
        PerfectScrollbarModule,
        NgSelectModule,
        NgApexchartsModule,
        MatSelectUserModule,
    ],
  exports: [AgentListComponent],
  providers: [],
  declarations: [AgentListComponent]
})
export class AgentListModule {}
