import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ENTER} from "@angular/cdk/keycodes";
import {Domain, LetterConfig} from "../../domains/domain";
import {MatChipInputEvent, MatChipList} from "@angular/material/chips";
import {RxFormBuilder} from "@rxweb/reactive-form-validators";
import {NotifyService} from "../../_helpers/notify.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RxFormHelpers} from "../../_helpers/form.helpers";
import {tap} from "rxjs/operators";
import {UserService} from "../../users/user.service";
import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {User} from "../../users/user";
import {ChatGroup, ChatGroupDetail} from "./chat-group";

@Component({
  selector: 'app-chat-group-dialog',
  templateUrl: 'chat-group-dialog.component.html',
  styleUrls: ['chat-group-dialog.component.scss'],
  styles: [`
      ::ng-deep .ng-dropdown-panel.above {
          z-index: 9999999;
      }
  `]
})
export class ChatGroupDialogComponent implements OnInit, AfterViewInit {
  public isServerAvailable: boolean = true;
  public form: FormGroup;
  public rxFormHelpers;
  public separatorKeysCodes = [ENTER];
  public extraEmails: string[] = [];
  public userControl = new FormControl([], Validators.required);
  public user_list: Domain[] = [];
  private total_count: number = null;
  public selected_member_list: User[] = [];
  public selected_admin_list: User[] = [];
  public config: PerfectScrollbarConfigInterface = {
    useBothWheelAxes: false, suppressScrollX: true, suppressScrollY: false
  };

  @ViewChild('chipList') chipList: MatChipList;
  @ViewChild('inputFilter') inputFilter;
  public filter = '';
  public user_select = [];

  constructor(
    private fb: RxFormBuilder,
    private notifyService: NotifyService,
    private userService: UserService,
    public dialogGroup: MatDialogRef<ChatGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.rxFormHelpers = new RxFormHelpers();
    let group = new ChatGroup();
    group.detail = new ChatGroupDetail();
    this.form = this.fb.formGroup(group);
    if (!this.data?.chat) {
      this.data.chat = {
        admins: [this.data.owner],
        members: [this.data.owner],
      }
    }

    this.userScrollEnd();
    const userIds = (this.data?.chat?.admins || []).concat(this.data?.chat?.members || []);
    if (userIds.length>0) {
      this.userService.list({
        limit: 1000000,
        offset: 0,
        filter: {field_list: [{field: 'id', condition_type: 9, value: userIds}], type: 0 },
        sort: {name: '+', surname: '+'},
      }, 'select').pipe(tap(res => this.notifyService.checkCode(res))).subscribe(
        (data: any) => this.user_select = data.list
      )
    }
  }

  addUser(user_id) {
    if (!this.user_select.find(_ => _.id == user_id)) {
      this.userService.list({
        filter: {field_list: [{field: 'id', condition_type: 0, value: user_id}], type: 0 },
      }, 'select').pipe(tap(res => this.notifyService.checkCode(res))).subscribe(
        (data: any) => {
          this.user_select = [...this.user_select, ...data.list];
          // this.form.updateValueAndValidity({emitEvent: true});
          console.log(this.user_select)
        }
      )
    }
  }

  getUserList() {
    return this.user_list.filter(user => this.form.get('admins').value.indexOf(user.id) == -1);
  }

  ngOnInit() {

    console.log(this.data?.chat);
    if (this.data?.owner) {
      this.form.patchValue({owner: this.data?.owner})
    }
    if (this.data?.chat) {
      this.form.patchValue(this.data?.chat);
      console.log(this.form.value);
    }
  }

  ngAfterViewInit() {

  }

 userScrollEnd(change = false) {
    console.log(this.filter);
    let filter_list = {}
    if (this.filter?.length>0) {
      filter_list = {filter: {field_list: [
        {field: 'name', condition_type: 3, value: this.filter},
        {field: 'surname', condition_type: 3, value: this.filter}
      ], type: 1 }}
    }
    if (this.total_count == null || this.user_list.length < this.total_count || change) {
      this.userService.list({
        limit: 10,
        offset: (change) ? 0 : this.user_list.length,
        sort: {name: '+', surname: '+'},
        ...filter_list,

      }, 'select').pipe(tap(res => this.notifyService.checkCode(res))).subscribe(
        (data: any) => {
          this.total_count = data.total_count;
          if (change) {
            this.user_list = data.list;
          } else {
            this.user_list = [...this.user_list.concat(data.list)];
          }
          console.log(this.user_list)

        },
        resp => this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502
      );
    }
  }

  submit() {
    this.form.markAllAsTouched();
    this.form.updateValueAndValidity();
   if (this.form.valid) {
     this.dialogGroup.close(this.form.value);
    } else {
      console.log(this.form.errors);
      this.notifyService.message('NOTIFY.REQUIRED');
    }
  }

  removeMember(user_id: any) {
      if (this.form.get('members').value.indexOf(user_id) > -1) {
        this.form.get('members').value.splice(this.form.get('members').value.indexOf(user_id), 1)
        this.form.get('members').updateValueAndValidity({onlySelf: false, emitEvent: true});
      }
      this.removeAdmin(user_id);
    this.inputFilter.nativeElement.value = '';
  }

  removeAdmin(user_id: any) {
    if (this.form.get('admins').value.indexOf(user_id) > -1) {
      this.form.get('admins').value.splice(this.form.get('admins').value.indexOf(user_id), 1)
      this.form.get('admins').updateValueAndValidity({onlySelf: false, emitEvent: true});
    }
    this.inputFilter.nativeElement.value = '';
  }

  addAdmin(user) {
    if (user) {
      this.addUser(user.id);
      if (this.form.get('admins').value.indexOf(user.id) == -1) {
        this.form.get('admins').setValue([...this.form.get('admins').value, user.id])
      }
      if (this.form.get('members').value.indexOf(user.id) == -1) {
        this.form.get('members').setValue([...this.form.get('members').value, user.id])
      }
      this.inputFilter.nativeElement.value = '';
    }
  }

  addMember(user) {
    if (user) {
      this.addUser(user.id);
      if (this.form.get('members').value.indexOf(user.id) == -1) {
        this.form.get('members').setValue([...this.form.get('members').value, user.id])
      }
      this.inputFilter.nativeElement.value = '';
    }
  }

  findUser(user_id) {
    const user = this.user_select.find(i=>i.id == user_id);
    if (user) {
      return (user.surname) ? `${user.name} ${user.surname}` : user.name;
    }
    return ''
  }
}
