import {
  prop,
  digit,
  maxLength,
  required,
  propArray,
  minLength,
  pattern,
  unique,
  choice
} from '@rxweb/reactive-form-validators';
import {RxFormHelpers} from '../../../_helpers/form.helpers';

export class CRMFieldPhone {
  @required() @minLength({value: 3}) value: string;
  @required() desc: string = '';
}

export class CRMFieldEmail {
  @required() @minLength({value: 3}) @pattern({expression:{onlyAlpha: RxFormHelpers.email}, message: 'ERROR.EMAIL'}) value: string;
  @required() desc: string = '';
}

export class CRMFieldAddress {
  @required() @minLength({value: 3}) value: string;
  @required() desc: string = '';
}

export class DomainCRMEntityDField {
  @prop() id?: number;
  @prop() entity_id: number;
  @required() @digit() field_id: number;
  @maxLength({value: 250}) str_val: string;
  @prop() int_val: number;
  @prop() bool_val: boolean;
  @prop() dt_val: number;
  @prop() list_val: any[] = [];
  @prop() txt_val: string;
}

export class DomainCRMEntity {
  @prop() id?: number;
  @required() @minLength({value: 3}) name: string;
  @required() entity_type: number = 1; // CRM_ENTITY_COMPANY = 0 | CRM_ENTITY_CONTACT = 1
  @prop() parent_id_list: number[] = []; // список компаний, которым принадлежит контакт, если тип сущности - контакт
  @maxLength({value: 250}) desc: string = '';
  @required() @digit() responsible_user_id: number;

  @required() @propArray(CRMFieldPhone) phone_list: CRMFieldPhone[];
  @required() @propArray(CRMFieldEmail) email_list: CRMFieldEmail[];
  @required() @propArray(CRMFieldAddress) address_list: CRMFieldAddress[];
  @propArray(DomainCRMEntityDField) dfields: DomainCRMEntityDField[];

  static className() {
    return 'DomainCRMEntity';
  };
}

export class Desc {
  @required() desc: string;
  @prop() @unique({message: 'ERROR.UNIQUE'}) column: string;
}

export class DFieldColumn {
  @required() field_id: number;
  @required() desc: number;
  @prop() column: string;

}

export class EntityImportingColumns {
  @required() @choice({minLength: 1, message: 'ERROR.REQUIRED'}) name: number[] = [];
  @prop() desc: number;
  @propArray(Desc) phone_list: Desc[] = [];
  @propArray(Desc) email_list: Desc[] = [];
  @propArray(Desc) address_list: Desc[] = [];
  @propArray(DFieldColumn) dfields: DFieldColumn[] = [];
}
