import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {CRM_FIELDTYPES} from '../../../_helpers/constant';
import {ExcelService} from '../../../_helpers/excel.service';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {NotifyService} from '../../../_helpers/notify.service';
import {DomainUserService} from '../../../users/domain-user.service';
import {MatTable} from '@angular/material/table';
import {DomainCRMDFieldConfService} from '../dfields/dfield.service';
import {
  EntityImportingColumns,
  DomainCRMEntity,
  Desc,
  DFieldColumn,
  CRMFieldAddress,
  CRMFieldEmail,
  CRMFieldPhone,
  DomainCRMEntityDField
} from './entity';
import {DomainCRMEntityService} from './entity.service';
import {TranslateService} from "@ngx-translate/core";
import {DOMAINCRMFTYPE} from "../dfields/dfield";
// @ts-ignore
import * as _moment from 'moment';
import {default as _rollupMoment} from "moment/moment";
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-crm-entity-import',
  templateUrl: 'entity-import.component.html',
  styleUrls: ['./entity-import.component.scss']
})

export class DomainCRMEntityImportComponent implements OnInit, AfterViewInit {
  public columnForm: FormGroup;
  public rxFormHelpers: RxFormHelpers = new RxFormHelpers();
  public isLoadingResults: boolean = false;
  public isRateLimitReached: boolean = false;
  public pbxUsers: any[] = [];
  public modelsMeta = {};
  public importingList: FormArray = new FormArray([]);
  public renderedList: FormArray = new FormArray([]);
  public fileHeaderRow = [];
  public fileRows = [];
  public displayedColumns: string[] = [
    'name', 'desc', 'responsible_user_id', 'parent_id_list', 'phone_list', 'email_list', 'address_list', 'dfields', 'actions'
  ];
  public companies = [];
  public dfields = [];
  public dfieldsObj = {};
  public isColumnMatched: boolean = false;
  public defRespUserId: FormControl = new FormControl(null);
  public defCompany: FormControl = new FormControl(null);
  @ViewChild(MatTable) table;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public allIsImported = false;
  public importing = false;
  public fileParsing = false;
  public fileOpening = false;
  public innerHeight: number = window.innerHeight;

  public domainUsers = [];
  public totalDomainUsers: number = null;
  public loadedDomainUsers: number = 0; // не учитываю загруженных пользователей для отображения выбранных ID при открытии формы
  public domainUserLoading: boolean = false;
  public domainUserLimit: number = 200;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    private fb: RxFormBuilder,
    private notifyService: NotifyService,
    private dialogRef: MatDialogRef<DomainCRMEntityImportComponent>,
    private domainUserService: DomainUserService,
    private domainCRMDFieldConfService: DomainCRMDFieldConfService,
    private domainCRMEntityService: DomainCRMEntityService,
    private excelService: ExcelService,
    public translate: TranslateService
  ) {
    if (this.data['entity_type'] !== 1) this.displayedColumns.splice(3, 1);
    let columnModel = new EntityImportingColumns();
    columnModel.phone_list = new Array<Desc>();
    columnModel.email_list = new Array<Desc>();
    columnModel.address_list = new Array<Desc>();
    this.columnForm = this.fb.formGroup(columnModel);
    for (let t of CRM_FIELDTYPES.phone) {
      let descRx = this.fb.formGroup(new Desc());
      descRx.patchValue({desc: this.notifyService.translate.instant(t)});
      (this.columnForm.get('phone_list') as FormArray).push(descRx);
    }
    for (let t of CRM_FIELDTYPES.email) {
      let descRx = this.fb.formGroup(new Desc());
      descRx.patchValue({desc: this.notifyService.translate.instant(t)});
      (this.columnForm.get('email_list') as FormArray).push(descRx);
    }
    for (let t of CRM_FIELDTYPES.address) {
      let descRx = this.fb.formGroup(new Desc());
      descRx.patchValue({desc: this.notifyService.translate.instant(t)});
      (this.columnForm.get('address_list') as FormArray).push(descRx);
    }
  }

  ngOnInit() {
    this.domainUsersScrollEnd();

    this.isLoadingResults = true;
    this.domainCRMDFieldConfService.list({
      filter: {type: 0, field_list: [{field: 'obj', value: [this.data['entity_type']], condition_type: 9}]},
      sort: {name: '+'}
    }).subscribe(
      data => {
        this.dfields = data.list;
        data.list.forEach(f => this.dfieldsObj[f.id] = f);
        if (this.dfields.length < data.total_count) {
          this.domainCRMDFieldConfService.list({
            limit: data.total_count - this.dfields.length,
            offset: this.dfields.length,
            filter: {type: 0, field_list: [{field: 'obj', value: [this.data['entity_type']], condition_type: 9}]},
            sort: {name: '+'}
          }).subscribe(
            data => {
              this.dfields = [...this.dfields, ...data.list];
              data.list.forEach(f => this.dfieldsObj[f.id] = f);
              this.updateDFields();

              this.isLoadingResults = false;
            },
            resp => {
              this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
              this.isLoadingResults = false;
            }
          );
        } else {
          this.updateDFields();
          this.isLoadingResults = false;
        }
      },
      resp => {
        this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
        this.isLoadingResults = false;
      }
    );

    if (this.data['entity_type'] == 1) {
      this.domainCRMEntityService.toSelect({
        filter: {type: 0, field_list: [{field: 'entity_type', value: 0, condition_type: 0}]}, sort: {name: '+'}, limit: 500
      }, 'select').subscribe(
        companies => this.companies = companies,
        resp => this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502
      );
    }
  }

  ngAfterViewInit() {
    this.paginator.page.pipe().subscribe(v => {
      this.renderedList.clear();
      let offset = this.paginator.pageIndex * this.paginator.pageSize;
      for (let rowCtrl of this.importingList.controls.slice(offset, offset + this.paginator.pageSize)){
        this.renderedList.push(rowCtrl);
      }
      this.table.renderRows();
    });
  }

  isSticky(id: string) {
    return (['name', 'actions']).indexOf(id) !== -1;
  }

  domainUsersScrollEnd() {
    if (this.totalDomainUsers === null || (this.loadedDomainUsers < this.totalDomainUsers)) {
      this.domainUserLoading = true;
      this.domainUserService.list({
        sort: {user_name: '+', user_surname: '+'},
        limit: this.domainUserLimit,
        offset: this.loadedDomainUsers
      }, 'select').subscribe(
        (data) => {
          if (this.totalDomainUsers === null) this.totalDomainUsers = data.total_count;
          this.loadedDomainUsers += data.list.length;

          for (let user of data.list){
            // кладу в опции пользователей, которых нет еще в списке
            if (!this.domainUsers.find(u => u.id === user.id)) this.domainUsers.push({
              id: user.id,
              name: user['user_name'] + (user['user_surname'] ? ' ' + user['user_surname'] : ''),
              proto: user.proto,
              uid: user.uid
            });
          }
          this.domainUsers.sort((a,b) => (a.uid < b.uid) ? -1 : (a.uid > b.uid ? 1 : (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)));
          this.domainUserLoading = false;
        },
        resp => {
          this.domainUserLoading = false;
          this.notifyService.setFormErrors(resp);
        }
      );
    }
  }

  onBlur(event: any) {
    if (event.relatedTarget && event.relatedTarget.tagName === 'MAT-OPTION') {
      // the input was blurred, but the user is still interacting with the component, they've simply
      // selected a mat-option
      event.preventDefault();
      event.stopPropagation();
      return;
    }
  }

  updateDFields() {
    for (let dfield of this.dfields){
      if (dfield.visible) {
        let rxDfield = this.fb.formGroup(new DFieldColumn());
        rxDfield.patchValue({field_id: dfield.id, desc: dfield.name});
        if (dfield.required) rxDfield.get('column').setValidators([RxwebValidators.required({message:'ERROR.REQUIRED'})]);
        (this.columnForm.get('dfields') as FormArray).push(rxDfield);
      }
    }
  }

  changeChips(ctrlName, i, evt, val?: number) {
    if (val !== undefined) {
      evt.preventDefault();
      evt.stopPropagation();
      if (this.columnForm.get(ctrlName).value.indexOf(val) === -1) {
        this.columnForm.get(ctrlName).setValue([...this.columnForm.value[ctrlName], +val]);
      }
    } else {
      this.columnForm.value[ctrlName].splice(i, 1);
      this.columnForm.get(ctrlName).setValue([...this.columnForm.value[ctrlName]]);
    }
  }

  getDField(id) {
    return this.dfields.find(df => df.id === id) || {};
  }

  addField(ctrlName) {
    (this.columnForm.get(ctrlName) as FormArray).push(this.fb.formGroup(new Desc()));
  }

  deleteRow(index: number) {
    this.renderedList.removeAt(index);
    this.renderedList.markAsDirty();
    this.importingList.removeAt((this.paginator.pageIndex*this.paginator.pageSize)+index);
    this.importingList.markAsDirty();
    if (this.renderedList.length === 0 && this.paginator) this.paginator._changePageSize(this.paginator.pageSize);

    this.table.renderRows();
  }

  onFileChange(evt) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length > 0) {
      this.importingList.clear();
      this.columnForm.get('name').setValue([]);
      this.columnForm.get('desc').setValue([]);
      this.isColumnMatched = false;
      this.allIsImported = false;
      this.fileParsing = false;
      this.fileOpening = true;

      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const fileData = <any[]> this.excelService.importFromFile(bstr);
        this.fileHeaderRow = fileData.shift();
        this.fileRows = fileData;

        // поиск и автоматическое сопоставление полей файла с полями в таблице, чтобы не сопоставлять вручную

        let surnameInd = this.fileHeaderRow.findIndex(f => ['фамилия', 'surname'].indexOf(f.trim().toLowerCase()) >= 0);
        let nameInd = this.fileHeaderRow.findIndex(f => ['name', 'имя', 'название'].indexOf(f.trim().toLowerCase()) >= 0);
        let secondNameInd = this.fileHeaderRow.findIndex(f => ['отчество', 'secondname', 'second_name'].indexOf(f.trim().toLowerCase()) >= 0);
        let fullNameInd = this.fileHeaderRow.findIndex(f => ['fio', 'fullname', 'фио', 'полное имя'].indexOf(f.trim().toLowerCase()) >= 0);
        if (fullNameInd !== -1)  this.columnForm.patchValue({name: [fullNameInd]});
        else {
          if (surnameInd !== -1)  this.columnForm.patchValue({name: [...this.columnForm.value.name, surnameInd]});
          if (nameInd !== -1)  this.columnForm.patchValue({name: [...this.columnForm.value.name, nameInd]});
          if (secondNameInd !== -1)  this.columnForm.patchValue({name: [...this.columnForm.value.name, secondNameInd]});
        }

        let commentIndex = this.fileHeaderRow.findIndex(f => ['comment', 'desc', 'коммент', 'комментарий'].indexOf(f.trim().toLowerCase()) >= 0);
        if (commentIndex != -1) this.columnForm.patchValue({desc: [commentIndex]});

        let workTelInd = this.fileHeaderRow.findIndex(f => [
          'телефон (рабочий)', 'phone (work)', 'телефон', 'рабочий телефон', 'рабочий', 'телефон', 'раб. телефон', 'телефон раб.', 'телефон рабочий',
          'work phone', 'phone work', 'workphone', 'phonework', 'phone_work', 'work_phone', 'phone'
        ].indexOf(f.trim().toLowerCase()) >= 0);
        if (workTelInd != -1) {
          (this.columnForm.get('phone_list') as FormArray)
            .controls.find(c => c.value.desc === 'Телефон (рабочий)' || c.value.desc === 'Phone (work)')
            .patchValue({column: workTelInd});
        }

        let mobTelInd = this.fileHeaderRow.findIndex(f => [
          'телефон (мобильный)', 'phone (mobile)', 'мобильный телефон', 'мобильный', 'моб. телефон', 'телефон моб.', 'mobile phone',
          'phone mobile', 'mobilephone', 'phonemobile', 'phone_mobile', 'mobile_phone'
        ].indexOf(f.trim().toLowerCase()) >= 0);
        if (mobTelInd != -1) {
          (this.columnForm.get('phone_list') as FormArray)
            .controls.find(c => c.value.desc === 'Телефон (мобильный)' || c.value.desc === 'Phone (mobile)')
            .patchValue({column: mobTelInd});
        }

        let homeTelInd = this.fileHeaderRow.findIndex(f => [
          'телефон (домашний)', 'phone (home)', 'домашний телефон', 'домашний', 'дом. телефон', 'телефон дом.', 'home phone', 'phone home',
          'homephone', 'phonehome', 'phone_home', 'home_phone'
        ].indexOf(f.trim().toLowerCase()) >= 0);
        if (homeTelInd != -1) {
          (this.columnForm.get('phone_list') as FormArray)
            .controls.find(c => c.value.desc === 'Телефон (домашний)' || c.value.desc === 'Phone (home)')
            .patchValue({column: homeTelInd});
        }

        let workEmailInd = this.fileHeaderRow.findIndex(f => [
          'эл. почта (рабочая)', 'рабочая почта', 'рабочий e-mail', 'рабочая электронная почта', 'Email (work)', 'электронная почта',
          'эл. почта', 'эл. адрес', 'электронный адрес', 'email', 'e-mail', 'mail', 'work email', 'email work', 'email (work)', 'workemail',
          'work_email', 'email_work', 'почтовый адрес', 'ящик', 'мыло'
        ].indexOf(f.trim().toLowerCase()) >= 0);
        if (workEmailInd != -1) {
          (this.columnForm.get('email_list') as FormArray)
            .controls.find(c => c.value.desc === 'Эл. почта (рабочая)' || c.value.desc === 'Email (work)')
            .patchValue({column: workEmailInd});
        }

        let persEmailInd = this.fileHeaderRow.findIndex(f => [
          'эл. почта (персональная)', 'личная почта', 'личный e-mail', 'личная электронная почта', 'Email (personal)',
          'электронная почта личная', 'эл. почта личная', 'личный эл. адрес', 'личный электронный адрес', 'email personal',
          'e-mail personal', 'presonal email', 'email (personal)', 'personalemail', 'personal_email', 'email_personal',
          'персональный почтовый адрес', 'персональный ящик', 'персональное мыло'
        ].indexOf(f.trim().toLowerCase()) >= 0);
        if (persEmailInd != -1) {
          (this.columnForm.get('email_list') as FormArray)
            .controls.find(c => c.value.desc === 'Эл. почта (личная)' || c.value.desc === 'Phone (personal)')
            .patchValue({column: homeTelInd});
        }

        let actAddrInd = this.fileHeaderRow.findIndex(f => [
          'факт. адрес', 'адрес факт.', 'фактический адрес', 'адрес фактический', 'адрес', 'место жительства',
          'прописка', 'пмж', 'actual address', 'address', 'address actual', 'adres', 'address (actual)', 'address (act)', 'address (act.)'
        ].indexOf(f.trim().toLowerCase()) >= 0);
        if (actAddrInd != -1) {
          (this.columnForm.get('address_list') as FormArray)
            .controls.find(c => c.value.desc === 'Факт. адрес' || c.value.desc === 'Address actual')
            .patchValue({column: actAddrInd});
        }

        let legAddrInd = this.fileHeaderRow.findIndex(f => [
          'юр. адрес', 'адрес юр.', 'юридический адрес', 'адрес юридический', 'legal address', 'address legal', 'address (legal)',
          'address (leg)', 'address (leg.)'
        ].indexOf(f.trim().toLowerCase()) >= 0);
        if (legAddrInd != -1) {
          (this.columnForm.get('address_list') as FormArray)
            .controls.find(c => c.value.desc === 'Юр. адрес' || c.value.desc === 'Address legal')
            .patchValue({column: legAddrInd});
        }

        for (let dfCtrl of (this.columnForm.get('dfields') as FormArray).controls) {
          let dfInd = this.fileHeaderRow.indexOf(dfCtrl.value.desc) || this.fileHeaderRow.indexOf(dfCtrl.value.desc.toLowerCase());
          if (dfInd !== -1) dfCtrl.patchValue({column: dfInd});
        }

        this.fileOpening = false;
      };
      reader.onerror = (e: any) => {
        this.fileOpening = false;
        this.notifyService.message(e);
      }
      reader.readAsBinaryString(target.files[0]);
    }
  }

  updateResponsibleUserId() {
    if (this.isColumnMatched && !this.allIsImported && !this.importing && this.defRespUserId.value) {
      this.importingList.controls.forEach(ctrl => {
        /*if (ctrl.value.responsible_user_id === null) */
        ctrl.patchValue({responsible_user_id: this.defRespUserId.value});
      });
    }
  }

  updateCompany() {
    if (this.isColumnMatched && !this.allIsImported && !this.importing && this.defCompany.value.length > 0) {
      this.importingList.controls.forEach(ctrl => {
        /*if (ctrl.value.parent_id_list.length === 0) */
        ctrl.patchValue({parent_id_list: this.defCompany.value});
      });
    }
  }

  parseFileData(){
    this.columnForm.updateValueAndValidity();
    this.columnForm.markAllAsTouched();
    this.isColumnMatched = this.columnForm.valid;

    if (this.columnForm.valid) {
      this.fileParsing = true;
      this.importingList.clear();

      for (let fileRow of this.fileRows) {
        if (fileRow.length) {
          let objM = new DomainCRMEntity();
          objM.phone_list = new Array<CRMFieldPhone>();
          objM.email_list = new Array<CRMFieldEmail>();
          objM.address_list = new Array<CRMFieldAddress>();
          objM.dfields = new Array<DomainCRMEntityDField>();
          let objRx = this.fb.formGroup(objM);
          objRx.removeControl('id');
          //собираю данные импортируемой сущности
          let objData = {entity_type: this.data['entity_type'], name: '', desc: ''};
          if (this.defRespUserId.value) objData['responsible_user_id'] = this.defRespUserId.value;
          if (this.defCompany.value) objData['parent_id_list'] = this.defCompany.value;

          for (let fieldArr of ['name', 'desc']){
            if (this.columnForm.value[fieldArr].length > 0) {
              for (let ind of this.columnForm.value[fieldArr]) {
                if (fileRow[ind]) {
                  let f = fileRow[ind];
                  if (typeof f === 'string') f = f.trim();
                  objData[fieldArr] += ' ' + f;
                }
              }
              objData[fieldArr] = objData[fieldArr].trim();
            }
          }

          for (let colGroup of ['phone_list', 'email_list', 'address_list', 'dfields']) {
            for (let column of this.columnForm.value[colGroup]) {
              if (column.column != null && fileRow[column.column]) {
                let values = [];
                if (colGroup === 'phone_list' || colGroup == 'email_list') {
                  if (typeof fileRow[column.column] === 'string') values = fileRow[column.column].toLowerCase().split(/(?:,| |, |; |;)+/);
                  else values = fileRow[column.column].toString().toLowerCase().split(/(?:,| |, |;)+/);
                } else {
                  values = [fileRow[column.column]];
                }

                for (let val of values) {
                  if (val) {
                    if (typeof val === 'string') val = val.trim();
                    let colGroupM;
                    switch (colGroup) {
                      case 'phone_list':
                        colGroupM = new CRMFieldPhone();
                        break;
                      case 'email_list':
                        colGroupM = new CRMFieldEmail();
                        break;
                      case 'address_list':
                        colGroupM = new CRMFieldAddress();
                        break;
                      case 'dfields':
                        colGroupM = new DomainCRMEntityDField();
                        switch (this.dfieldsObj[column.field_id].ftype) {
                          case 0: // строка
                            if (typeof val == 'number') val = val.toString();
                            break;
                          case 1: // число
                            break;
                          case 2: // логическое
                            val = ['true', 'yes', 'y', '+', '1', 1, 'да', 'ага'].indexOf(typeof val == 'string' ? val.toLowerCase() : val) != -1;
                            break;
                          case 3: // дата
                            if (val && typeof val == 'number') {
                              // console.log(val, new Date((val - 25569)*86400000).toLocaleDateString(this.translate.currentLang));
                              val = new Date((val - 25569)*86400000)//.toLocaleDateString(this.translate.currentLang);
                              // val = moment(val);
                            }
                            break;
                          case 4: // список
                            val = val.split(/(?:,|, |; |;)+/);
                            break;
                          case 5: // текст
                            break;
                        }

                        // if (typeof val == 'number') val = val.toString();
                        // console.log(this.dfieldsObj[column.field_id], val);
                        break;
                    }
                    let colGroupRx = this.fb.formGroup(colGroupM);
                    colGroupRx.patchValue(
                      colGroup !== 'dfields' ?
                        {value: val, desc: column.desc} :
                        {id: null, entity_id: null, field_id: column.field_id, [DOMAINCRMFTYPE[this.dfieldsObj[column.field_id].ftype]]: val}
                    );
                    (objRx.get(colGroup) as FormArray).push(colGroupRx);
                  }
                }
              }
            }
          }
          objRx.patchValue(objData, {emitEvent: false});
          this.importingList.push(objRx);
        }
      }

      if (this.importingList.length) {
        this.paginator._changePageSize(this.paginator.pageSize);
      }
      this.fileParsing = false;
    } else {
      this.fileParsing = false;
    }
  }

  hasSourceField(i: number) {
    return this.columnForm.value.name.indexOf(i) !== -1 ||
      this.columnForm.value.desc.indexOf(i) !== -1 ||
      this.columnForm.value.phone_list.filter(p => p.column == i).length > 0 ||
      this.columnForm.value.email_list.filter(p => p.column == i).length > 0 ||
      this.columnForm.value.address_list.filter(p => p.column == i).length > 0 ||
      this.columnForm.value.dfields.filter(p => p.column == i).length > 0;
  }

  submit() {
    if (!this.allIsImported && !this.importing) {
      this.importingList.updateValueAndValidity();
      this.importingList.markAllAsTouched();
      if (this.importingList.valid) {
        this.importing = true;
        let savedCount = 0;
        //for (let ent of this.importingList.controls) {
          console.log(this.importingList.controls.length);
          this.domainCRMEntityService.import_entity({entities: this.importingList.value}).subscribe(
            data => {
              console.log(data);

              if (data.code == 200) {
                savedCount = Object.values(data?.body?.imported || {})?.length || 0;

                this.importing = false;

                let countEnt = 0;
                for (let ent of this.importingList.controls) {
                  const key = `${countEnt}`;
                  if (key in data?.body?.imported) {
                    (ent as FormGroup)
                      .addControl('id', new FormControl(data?.body?.imported[key]));
                    ent.patchValue({id: data?.body?.imported[key]})
                  } else this.notifyService.setFormErrors(data, ent as FormGroup)

                  countEnt += 1;
                }

              }

              if (savedCount === this.importingList.controls.length) {
               this.allIsImported = true;
               this.importing = false;

              }
              if (this.allIsImported) this.notifyService.message('IMPORTING.FINISHED');
            },
            resp => {
              this.notifyService.setFormErrors(resp);
              this.table.renderRows();

              //savedCount += 1;
              //if (savedCount === this.importingList.controls.length) {
              //  this.allIsImported = true;
              //  this.importing = false;
              //  if (this.allIsImported) this.notifyService.message('IMPORTING.FINISHED');
              //}
            }
          );
        //}
      } else {
        this.notifyService.message('NOTIFY.REQUIRED');
        let errorCount = 0;
        this.importingList.controls.forEach((ent: FormGroup)=>{
          if (errorCount > 5)  return;
          Object.entries(ent.controls).filter(filter => filter[1]['status']==='INVALID').forEach(item => {
            if (errorCount > 5)  return;
            ent.controls[`${item[0]}`].updateValueAndValidity();
            ent.controls[`${item[0]}`].markAllAsTouched();
            console.log('invalid field: ', item);
            errorCount += 1;
          });
        })


      }
    } else if (this.allIsImported) {
      this.dialogRef.close({
        confirm: true,
        idList: this.importingList.controls.filter(v => v.value.id !== null).map(v => v.value.id)
      });
    }
  }
}
