import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from '../../../_helpers/notify.service';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {AuthenticationService} from '../../../auth/authentication.service';
import {BaseFormComponent} from '../../../_helpers/base-form.component';
import {ConfirmComponent} from '../../../dialogs/modals/confirm/confirm.component';
import {DomainService} from '../../domain.service';
import {MarketplaceService} from '../../marketplace/marketplace.service';
import {DDSEDefaultLeg, DDSEOneLeg, DDSEStart, DomainDialogScript, LegMenu} from './domain-dialog-script';
import {DomainDialogScriptService} from './domain-dialog-script.service';


@Component({
  selector: 'app-domain-dialog-script-form',
  templateUrl: './domain-dialog-script-form.component.html',
  styles: [
    `.mat-card .mat-divider-horizontal {
      position: initial;
      left: 0;
      width: 100%;
    }`,
    `.mat-list-version {
      border: 1px solid rgba(0, 0, 0, 0.12);
      padding: 3px;
      width: 210px;
      height: 100%;
    }`,
    `.mat-list-version .mat-list-after-icon {

    }`
  ]
})

export class DomainDialogScriptFormComponent extends BaseFormComponent<DomainDialogScript> implements OnInit, OnDestroy {
  public nodeData: any;
  public marketplace = [];
  public licenses = [];
  public select_integration = [];
  public versions = []
  public current_dp = null;
  public hasChanges = false;
  public timezone = 'Europe/Moscow';

  constructor(
    api: DomainDialogScriptService,
    fb: RxFormBuilder,
    route: ActivatedRoute,
    router: Router,
    http: HttpClient,
    notifyService: NotifyService,
    authenticationService: AuthenticationService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public domainExtension: MarketplaceService,
    private domainService: DomainService
  ) {
    super(DomainDialogScript, api, fb, route, router, http, notifyService, authenticationService);
    super.navigate = 'domain-catalogs/dialog-script/script';
    this.formT.body = new DDSEStart();
    this.extParams = {id: this.route.snapshot.paramMap.get('id'), ...(this.route.snapshot.paramMap.get('ver') ? {ver: +this.route.snapshot.paramMap.get('ver')} : {})};

    this.domainExtension.tariff_option_list().subscribe(data => {
      this.licenses = (data?.body?.tariff_option_list || []).filter(item=>item.available === true).map(item=>item.name);
      this.marketplace = data?.body?.exists || [];
      this.select_integration = (data?.body?.tariff_option_list || [])
        .filter(item => (data?.body?.exists || []).includes(item.name) && item.type.includes(1));
    });

    domainService.get_tz().subscribe(data => this.timezone = data);
  }

  ngOnInit() {
    super.ngOnInit();
    this.form.patchValue({domain_id: this.authenticationService.currentUserValue.domain_id});
    if (this.id == null) {
      this.form.get('body').valueChanges.subscribe(body => this.hasChanges = !!body.leg);
    } else {
      this.api.list({sort: {ver: '-'}, limit: 5, filter: {field_list: [{field: 'id', value: this.id, condition_type: 0}], type: 0}}, 'full_list').subscribe(data => {
        this.versions = data.list;
      });
    }
  }

  callbackFormPatch(data: DomainDialogScript) {
    super.callbackFormPatch(data);
    this.nodeData = data.body;
    this.recursiveNode(this.form.get('body'), data.body);

    this.current_dp = data;
    this.form.get('body').valueChanges.subscribe(body => this.hasChanges = !!body.leg);
  }

  recursiveNode(control, data) {
    if (data?.leg?.id != null) {
      // @ts-ignore
      (<FormGroup>control).setControl('leg', this.api.legModel(data.leg.model_name));
      control.get('leg').patchValue(data.leg);

      if (data.leg.model_name == 'DDSEMenu' && (data.leg.params.conditions)) {
        for (let uid of Object.keys(data.leg.params.conditions).sort()) {
          control.get(['leg', 'params', 'conditions']).setControl(uid, this.fb.formGroup(new LegMenu()));
          control.get(['leg', 'params', 'conditions', uid]).patchValue(data.leg.params.conditions[uid]);
        }
      }

      if (data.leg.leg?.id != null || data.leg.default_leg || (data.leg.legs && Object.keys(data.leg.legs).length > 0)) {
        this.recursiveNode(control.get('leg'), data.leg);
      }
    }

    if (data.default_leg || data.model_name == 'DDSEMenu') {
      (<FormGroup>control).setControl('default_leg', this.fb.formGroup(new DDSEDefaultLeg()));
      control.get('default_leg').patchValue(data?.default_leg);
      if (!data.default_leg) {
        let m = new DDSEOneLeg();
        // m.params = new DOCBaseMessageParams();
        let addingControl = this.fb.formGroup(m);
        control.get('default_leg').setControl('leg', addingControl);
      }
      if (data.default_leg?.leg?.id != null) this.recursiveNode(control.get('default_leg'), data.default_leg);
    }

    if (data.legs && Object.keys(data.legs).length > 0) {
      for (let k of Object.keys(data.legs).sort()) {
        if (data.legs[k]?.leg != null) {
          // @ts-ignore
          control.get('legs').setControl(k, this.fb.formGroup(new DDSEDefaultLeg()));
          control.get('legs').get(k).patchValue(data.legs[k]);
          if (data.legs[k].leg?.id != null) this.recursiveNode(control.get('legs').get(k), data.legs[k]);
        }
      }
    }
  }

  addNewNode() {
    let m = new DDSEOneLeg();
    // m.params = new DDSEBaseParams();
    let addingControl = this.fb.formGroup(m);
    if (this.form.value.body.leg != null) addingControl.setControl('leg', this.form.get('body').get('leg'));
    (this.form.get('body') as FormGroup).setControl('leg', addingControl);
  }

  changeVersion(selected) {
    if (selected.length > 0 && selected[0].value) {
      this.form.patchValue({name: selected[0].value.name});
      if (selected[0].value.body) {
        if (selected[0].value.body?.leg === null) (this.form.get('body') as FormGroup).removeControl('leg');
        this.form.get('body').patchValue(selected[0].value.body, {onlySelf: true});
        this.recursiveNode(this.form.get('body'), selected[0].value.body);
      }
      this.hasChanges = true;
    } else {
      if (this.current_dp) {
        this.form.patchValue({name: this.current_dp.name});
        this.form.get('body').patchValue(this.current_dp.body, {onlySelf: true});
        this.recursiveNode(this.form.get('body'), this.current_dp.body);
        this.hasChanges = false;
      } else if (!this.id) {
        (this.form.get('body') as FormGroup).removeControl('leg');
        this.hasChanges = false;
      }
    }
  }

  removeVersion(evt, id) {
    evt.stopPropagation();
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.api.delete(id, 'version_delete').subscribe(
          data => {
            this.notifyService.showRequestResult(data, true);
            this.versions = this.versions.filter(item => item.id !== id);
          },
          resp => {
            this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502;
          }
        );
      }
    });
  }
}
