import 'hammerjs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import {NgxMatDatetimePickerModule} from '@angular-material-components/datetime-picker';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {NgxAudioPlayerModule} from '@khajegan/ngx-audio-player';
import {DomainCRMEventModule} from "../../domains/domain-crm/events/event.module";
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";
import {MaterialModule} from "../../material.module";
import {PipeModule} from "../../_helpers/pipe.module";
import {MatTableResponsiveModule} from "../../material-component/mat-table-responsive/mat-table-responsive.module";
import {SkeletonLoaderModule} from "../../_helpers/skeleton-loader.module";
import {DomainHideContactCallComponent} from "./domain-hide-contact-call.component";


@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      TranslateModule.forChild(),
      MaterialModule,
      FormsModule,
      ReactiveFormsModule,
      FlexLayoutModule,
      CdkTableModule,
      PipeModule,
      MatTableResponsiveModule,
      SkeletonLoaderModule,
      DomainCRMEventModule,
      NgxMatDatetimePickerModule,
      PerfectScrollbarModule,
      RxReactiveFormsModule,
      NgxAudioPlayerModule
    ],
  exports: [
    DomainHideContactCallComponent
  ],
  providers: [],
  declarations: [DomainHideContactCallComponent]
})
export class DomainHideContactCallModule {}
