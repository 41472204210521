import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
  transform(items: any[], field : string, value : string|boolean|number, negative = false): any[] {
    if (!items) return [];

    if (negative) return items.filter(it => it[field] !== value);
    else return items.filter(it => it[field] == value)
  }
}
