import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {catchError, map, tap} from 'rxjs/operators';
import {Helper, IResponseBodyObject, IResponseListObject} from '../../../_helpers/api.helpers';
import {Observable, of as observableOf} from 'rxjs';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DOCAgent} from './doc-agent';


const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})

export class DOCAgentService extends BaseService<DOCAgent> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService, protected fb: RxFormBuilder) {
    super(DOCAgent.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  list(params: any = {}, action = 'list'): Observable<IResponseListObject<DOCAgent>> {
    params['sort'] = {name: '+'}
    return super.list(params, action);
  }

  agentStatusSwitch(id: number, status_id: number, params: any ={}): Observable<IResponseBodyObject<any>>  {
    let ignore = {};
    if (params.ignore) {
      ignore = {...params.ignore};
      delete params['ignore'];
    }
    let obj = {id: id, status_id: status_id};
    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    let requestBody = Helper.requestFormat('agent_status_switch', this.objectName, obj);

    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          if (res.code != 200 && (!res.notifies.length || !(ignore[res.notifies[0].msg_id]))) this.notifyService.checkCode(res);
        })
      );
  }
}
