import {HttpClient} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {BaseWsService} from './_helpers/base-ws.service';
import {NotifyService} from './_helpers/notify.service';
import {AuthenticationService} from './auth/authentication.service';
import {JsonAppConfigService} from './config/json-app-config.service';


@Injectable({
  providedIn: 'root'
})
export class AppService extends BaseWsService implements OnDestroy {



  constructor(
    public http: HttpClient,
    public notifyService: NotifyService,
    public router: Router,
    public route: ActivatedRoute,
    public authenticationService: AuthenticationService,
    public translate: TranslateService,
    public AppConfig: JsonAppConfigService
  ) {
    super([], http, notifyService, router, route, authenticationService, translate, AppConfig);
  }

  ngOnDestroy() {}
}
