import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, Inject,
  OnDestroy, OnInit
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import {from, Observable, of as observableOf, Subject} from 'rxjs';
import {ROLE_TYPE_PROVIDER} from '../../../_helpers/constant';
import {AuthenticationService} from '../../../auth/authentication.service';
import {MenuService} from '../../../roles/menu.service';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {APP_MENU_DOMAIN_SETTINGS, APP_MENU_MODULES} from '../../../app.injection';
import {TranslateService} from '@ngx-translate/core';
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: []
})
export class AppSidebarComponent implements OnDestroy, AfterViewInit, OnInit {
    public config: PerfectScrollbarConfigInterface = {};
    mobileQuery: MediaQueryList;
    public domain: Observable<any> = observableOf(null);
    public menu$ = new Subject();

    private _mobileQueryListener: () => void;
    status = true;

    itemSelect: number[] = [];
    parentIndex: Number;
    childIndex: Number;

    setClickedRow(i, j) {
        this.parentIndex = i;
        this.childIndex = j;
    }
    subclickEvent() {
        this.status = true;
    }
    scrollToTop() {
        document.querySelector('.page-wrapper').scroll({
            top: 0,
            left: 0
        });
    }

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public translate: TranslateService,
        public authenticationService: AuthenticationService,
        public menuService: MenuService,
        public router: Router,
        @Inject(APP_MENU_MODULES) public menuModules
    ) {
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        if (this.isProvider() && this.isDomainView()) {
          this.refreshDomainData();
        }

      // подписываюсь на получение актуальных данных авторизованного в кабинете пользователя
      this.authenticationService.currentUser.subscribe(user => {
        // обновляю данные домена вверху левого меню кабинета, если это провайдер и он вошел в клиентский домена
        if (this.isProvider()) {
          this.domain.subscribe(domain => {
            // очищаю данные домена, если они !были! и провайдер вернулся из кабинета домена
            if (domain && (!user || !user.domain_id)) {
              this.domain = observableOf(null);
            }
            // запрашиваю новые данные домена, если провайдер вошел в кабинет домена
            if (user && user.domain_id) {
              this.refreshDomainData();
            }
          });
        }
      });
    }

    ngOnInit() {
      this.menu$.next(this.menuService.getMenuItem())

    }

    ngAfterViewInit() {
      setTimeout(_=>this.menu$.next(this.menuService.getMenuItem()));
    }

    getUserEmail() {
      return this.authenticationService.getUserEmail();
    }

    getUserId() {
      return this.authenticationService.getUserId();
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    isProvider() {
      const role = this.authenticationService.currentUserValue?.role_type;
      return Object.values(ROLE_TYPE_PROVIDER).includes(role);
    }

    isDomainView() {
      return !!this.authenticationService.currentUserValue?.domain_id;
    }

    refreshDomainData(){
      this.authenticationService.getDomainData(this.authenticationService.currentUserValue?.domain_id).subscribe(data => {
        this.domain = observableOf(data);
      });
    }

  routerExist(state) {
    return !!this.router.config.find(i=>i.path=='')?.children
      ?.find(i=>i.path == state);

  }
}
