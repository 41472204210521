import {prop, propObject, required} from "@rxweb/reactive-form-validators";

export class ChatGroupDetail {
  @required() name: string = '';
  @prop() icon: string = '';

}

export class ChatGroup {
  @prop() id: number;
  @required() admins: number[] = [];
  @required() members: number[] = [];
  @required() owner: number;
  @propObject() detail: ChatGroupDetail;
}
