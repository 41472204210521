import {required, prop, maxLength, minNumber, maxNumber, digit, propObject, unique} from '@rxweb/reactive-form-validators';
import {DEPRoiStatNumber} from '../domains/marketplace/marketplace';

export class MonitorColorFields {
  @required() c1: number = 10;
  @required() c2: number = 20;
  @required() c3: number = 30;
}

export class KeyValue {
  [key: string]: number;
}

export class CCKeyValue {
  [key: string]: MonitorColorFields;
}

export class DomainCCMonitor2 {
  @prop()
  id?: number;
  @required() model_name: string = 'DomainCCMonitor2';
  @required() sl_time?: CCKeyValue;
  @required() agent_status_food?: number;
  @required() agent_status_postprocess?: number;
  @required() agent_status_break?: number;
  @required() agent_status_colors?: CCKeyValue;
  @required() client_count?: MonitorColorFields;
  @required() client_avg_answer_time?: MonitorColorFields;
  @required() asr_perc?: MonitorColorFields;
  @required() sla_perc?: MonitorColorFields;

  static className() {
    return 'DomainCCMonitor2';
  };
}

let jes = {
  id: 16,
  domain_id: 286,
  params: {
    model_name: 'DomainCCMonitor2',
    sl_time: {
      155: 10
    },
    agent_status_food: null,
    agent_status_postprocess: null,
    agent_status_break: null,
    agent_status_colors: {
      '-21': {
        c1: 10,
        c2: 20,
        c3: 30
      }
    },
    client_count: {
      c1: 10,
      c2: 20,
      c3: 30
    },
    client_avg_answer_time: {
      c1: 10,
      c2: 20,
      c3: 30
    },
    asr_perc: {
      c1: 10,
      c2: 20,
      c3: 30
    },
    sla_perc: {
      c1: 10,
      c2: 20,
      c3: 30
    }
  }
}
