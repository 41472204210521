import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Helper, IResponseListObject, IResponseBodyObject, ISelect} from '../_helpers/api.helpers';
import {from, Observable, of as observableOf, throwError, timer} from 'rxjs';
import {catchError, delay, delayWhen, filter, map, retry, retryWhen, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../_helpers/base.service';
import {AuthenticationService} from '../auth/authentication.service';
import {DialPlan} from './dialplan';
import {JsonAppConfigService} from '../config/json-app-config.service';
const headers = new HttpHeaders().set('Content-Type', 'application/json');



@Injectable({
  providedIn: 'root'
})


export class DialplanService extends BaseService<DialPlan> {
  private api: HttpClient;
  private notify: NotifyService;
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, private ApiConfig: JsonAppConfigService) {
    super(DialPlan.className(), http, notifyService, router, route, authenticationService, ApiConfig);
    this.api = http;
    this.notify = notifyService;
  }


  getConditions(params: any = {}): Observable<any[]> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('get', 'GSConditions', params);
    return this.http.post<IResponseBodyObject<any>>(this.ApiConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => data.body),
        map(data => data.list),
        retryWhen(errors => errors.pipe(delay(3000), take(3))),
        catchError((error) => observableOf([]))
      );
  }

  list(params: any = {}, action: string = 'list'): Observable<IResponseListObject<DialPlan>> {
    params['sort'] = {'name': '+'};
    return super.list(params, action);
  }

}
