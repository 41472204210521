import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Component, Inject, OnInit} from '@angular/core';
import { Track } from '@khajegan/ngx-audio-player';

@Component({
  selector: 'play-list-dialog',
  templateUrl: 'play-list-dialog.component.html',
  styleUrls: ['play-list-dialog.component.scss']
})

export class PlayListDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public playlist: Track[] = []) {}
  onEnded(event){
  }

}
