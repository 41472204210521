import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '../../../../../app.service';
import {DesktopDefService} from '../../desktop-def.service';
import {DesktopColumns} from '../../../../_common/desktop-columns';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {DesktopService} from '../../../../_common/base-desktop.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {CTIService} from "../../../../../cti-panel/cti-panel.service";

@Component({
  selector: 'app-inbound-script',
  templateUrl: './inbound-script.component.html',
  styleUrls: ['./inbound-script.component.css',
    '../../../../_common/desktop-list.component.scss']
})
export class InboundScriptComponent implements OnInit, OnDestroy {
  @Input() columns: DesktopColumns[]
  public showColumns: DesktopColumns[] = [];
  public abonentStaff = new BehaviorSubject<any>(null);
  public abonentContact = new BehaviorSubject<any>(null);
  private subscription: Subscription = new Subscription();

  public config: PerfectScrollbarConfigInterface = {
    minScrollbarLength: 5,
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
    wheelSpeed: 2,
    wheelPropagation: true
  };
  constructor(public desktopService: DesktopService, public appService: AppService, public CTI: CTIService,
              public defService: DesktopDefService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.showColumns = this.columns.filter(item=>item.checked);
    let subMenu$ = this.desktopService.changeMenu.subscribe(menu => {
      if (menu.name == 'InboundCallsComponent') {
        this.showColumns = this.columns.filter(item=>menu.columns.includes(item.id))
      }
    });

    let subCti$ = this.CTI.ctiCalls$.subscribe((calls: any) => {
      // Перемещено в саму CTI-панель
      // calls.forEach(call => {
      //   if (call.status == 'ringing' && call.direction == 1) {
      //     let permission = Notification.permission;
      //     if(permission === 'granted') {
      //       this.showNotification(call);
      //     } else if(permission === 'default'){
      //       this.requestAndShowPermission(call);
      //     }
      //   }
      // });

      if (calls?.[0]?.status == 'ringing' || calls?.[0]?.status == 'attached') {
        let abonent = (calls[0].direction == 1) ? calls[0].caller_id_number : calls[0].callee_id_number;
        if (abonent?.length < 7) {
          // Поиск по внутреннему номеру в Staff по точному соответствию
          // this.subscription.add(
          //   this.defService.staffDirectory({
          //     filter:{
          //       field_list:[
          //         {field: 'ext', condition_type: 0, value: abonent}
          //       ],
          //       type: 0
          //     }, sort: {status: '+'}
          //   }).pipe(map(data => data.list)).subscribe((data: any) => {
          //     if (data?.length>0) {
          //       this.abonentStaff.next(data[0]);
          //     }
          //   })
          // );
        } else {
          // Поиск по мобильному номеру в Staff по like 10 последних цифр
          // this.subscription.add(
          //   this.defService.staffDirectory({
          //     filter:{
          //       field_list:[
          //         {field: 'mobile', condition_type: 3, value: abonent.slice(-10)}
          //       ],
          //       type: 0
          //     }, sort: {status: '+'}
          //   }).pipe(map(data => data.list)).subscribe((data: any) => {
          //     if (data?.length>0) {
          //       this.abonentStaff.next(data[0]);
          //     } else this.findContactPhone(abonent);
          //   }, ()=> {
          //     this.findContactPhone(abonent);
          //   })
          // );
        }
      }
      if (calls?.[0]?.status == 'ended') {
        this.abonentStaff.next(null);
        this.abonentContact.next(null);
      }
    });

    this.subscription.add(subMenu$);
    this.subscription.add(subCti$);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

 showNotification(call) {
  if(document.visibilityState === 'visible') {
    return;
  }
  let title = this.translate.instant('NEW_INBOUND_CALL');

  let body = this.translate.instant('NEW_INBOUND_CALL_BODY', {
    ABONENT: ((call.direction == 1) ? call.caller_id_number : call.callee_id_number)
  } );
  let notification = new Notification(title, { body });
  notification.onclick = () => {
    notification.close();
    window.parent.focus();
  }
}

requestAndShowPermission(call) {
  Notification.requestPermission((permission) => {
    if (permission === 'granted') {
      this.showNotification(call);
    }
  });
}

onlyNumber(str = '') {
  return str?.replace(/\D/g, '');
}

getCallState(state) {
    return state;
  }

  getCallDirection(direction) {
    return (direction == 1 ? 'INBOUND_CALL' : 'OUTBOUND_CALL');
  }

  timeDiff(date) {
    if (date) return new Date(new Date().getTime() - new Date(date).getTime())
      .toTimeString().replace(/.*\d{2}:(\d{2}:\d{2}).*/, '$1');
    else return '-';
  }

  getAbonent(item: any) {
    let abonent = (item.direction == 1) ? item.caller_id_number : item.callee_id_number;
    let name = (item.direction == 1) ? item.caller_id_name : item.callee_id_name;
    return (name?.length>0 && name != abonent) ? `${name} - ${abonent}` : `${abonent}`;
  }


  getStaffStatus(status: string) {
    if (status == 'Current') return 'WORKING';
    else if (status == 'Leaver') return 'LEAVER';
    else return '-';
  }

  private findContactPhone(abonent: string) {
    // this.subscription.add(this.keptService.getClients({
    //   filter:{
    //     field_list:[
    //       {field: 'phone', condition_type: 3, value: abonent.slice(-10)}
    //     ],
    //     type: 0
    //   }
    //   }).pipe(map(data => data.list)).subscribe((data: any) => {
    //     if (data?.length>0) {
    //       this.abonentContact.next(data[0]);
    //     }
    //   })
    // );
  }
}
