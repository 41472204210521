import { NgModule } from '@angular/core';
import {AudioInputComponent} from './audio-input.component';
import {MaterialModule} from '../../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {CdkTableModule} from '@angular/cdk/table';
import {PipeModule} from '../../_helpers/pipe.module';
import {TagDialogComponent} from '../../dialogs/modals/tagDialog/tagDialog.component';
import {TagNotifyDialogComponent} from '../../dialogs/modals/tagNotifyDialog/tagNotifyDialog.component';
import {FieldModule} from '../../_helpers/dynamic/field.module';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CdkTableModule,
        PipeModule,
        FieldModule
    ],
    declarations: [AudioInputComponent, TagDialogComponent, TagNotifyDialogComponent],
    exports: [AudioInputComponent]
})
export class AudioInputModule {}
