import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aclNodeList'
})

export class ListACLNodePipe implements PipeTransform {
  wrap(node: any): string {
    return `${node.value} (${node.type})`;
  }

  transform(nodes: any): string {
    const aclNodeList: any[] = [];
    for (const i in nodes) {
      aclNodeList.push(this.wrap(nodes[i]));
    }
    return aclNodeList.join(', ');

  }
}
