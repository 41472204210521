import {digit, pattern, prop, propArray, propObject, required} from '@rxweb/reactive-form-validators';
import {generateGUID} from '../../../../../../vertojs';
import {RxFormHelpers} from '../../../../../_helpers/form.helpers';

export class SMTPSettings {
  @required() @digit() missing_call_html_id: number;
  @required() @digit() missing_call_subject_id: number;
  @required() @digit() missing_call_txt_id: number;

  static className() {
    return 'KeptSettingsMsgTemplate';
  }
}

export class KeptSettingsDesktop {
  @prop() show_staff_directory: number[] = [];
  @prop() show_clients: number[] = [];
  @prop() show_transfers: number[] = [];
  @prop() interface: any = null;

  static className() {
    return 'KeptSettingsDesktop';
  }
}

