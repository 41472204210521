import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../_helpers/base.service';
import {AuthenticationService} from '../../auth/authentication.service';
import { DomainAddressBookGroup } from './domain-address-book-group';
import {JsonAppConfigService} from '../../config/json-app-config.service';


@Injectable({
  providedIn: 'root'
})


export class DomainAddressBookGroupService extends BaseService<DomainAddressBookGroup> {
  private api: HttpClient;
  private notify: NotifyService;
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, AppConfig: JsonAppConfigService) {
    super(DomainAddressBookGroup.className(), http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.notify = notifyService;
  }
}
