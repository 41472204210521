import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService, headers} from '../../../../../_helpers/base.service';
import {AuthenticationService} from '../../../../../auth/authentication.service';
import {JsonAppConfigService} from '../../../../../config/json-app-config.service';
import {Observable} from 'rxjs';
import {Helper, IResponseBodyObject} from '../../../../../_helpers/api.helpers';
import {map, tap} from 'rxjs/operators';
import {SMTPSettings} from './smtp-setting';

@Injectable({
  providedIn: 'root'
})

export class SMTPSettingsService extends BaseService<SMTPSettings> {
  constructor(
    http: HttpClient,
    notifyService: NotifyService,
    router: Router,
    route: ActivatedRoute,
    authenticationService: AuthenticationService,
    AppConfig: JsonAppConfigService
  ) {
    super(SMTPSettings.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  get(id: number, params: any = {}, action: string = 'get'): Observable<SMTPSettings> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue?.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, { ...params});
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('sourceUrl')+'eapi/',
      requestBody, {headers: headers})
      .pipe(
        tap(res => {
          // @ts-ignore
          if (res.code != 200 && (!res.notifies.length || res.notifies[0].msg_id != 10002)) this.notifyService.checkCode(res, false);
        }),
        map(data => {
          return data.body as SMTPSettings
        })
      );
  }

  save(obj: any = {}, action_append: string = 'update', action_update: string = 'update'): Observable<IResponseBodyObject<SMTPSettings>>  {

    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }

    let requestBody = Helper.requestFormat(action_append, this.objectName, obj);
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('id') && obj['id']) {
      requestBody = Helper.requestFormat(action_update, this.objectName, obj);
    }
    return this.http.post<IResponseBodyObject<SMTPSettings>>(this.AppConfig.getValue('sourceUrl')+'eapi/', requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res, false);
        })
      );
  }
}
