import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Pipe({
  name: 'dateYYYYMMDD'
})

export class DateYYYYMMDDEpochPipe implements PipeTransform {
  transform(value: string, lang, arg: any = null): any {
    // lang - передан для того, чтобы пайп заметил изменение языка и сам перерисовал дату
    if (value) {
     return moment(value, 'YYYY-MM-DD').format('L');
    }
    if (arg) { return arg }
    return moment().format('L');
  }
}
