import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {IResponseListObject} from '../../../_helpers/api.helpers';
import {Observable} from 'rxjs';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DOCQueue} from './doc-queue';


@Injectable({
  providedIn: 'root'
})


export class DOCQueueService extends BaseService<DOCQueue> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService, protected fb: RxFormBuilder) {
    super(DOCQueue.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  list(params: any = {}, action = 'list_with_detail'): Observable<IResponseListObject<DOCQueue>> {
    params['sort'] = {name: '+'}
    return super.list(params, action);
  }
}
