import {ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute} from '@angular/router';
import {FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {MediaMatcher} from '@angular/cdk/layout';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {AuthenticationService} from '../../../auth/authentication.service';
import {ConfirmComponent} from '../../../dialogs/modals/confirm/confirm.component';
import {DDSEBaseParams, DDSEDefaultLeg, DDSEOneLeg, MultiLegs} from './domain-dialog-script';
import {DomainDialogScriptNodeDialogComponent} from './domain-dialog-script-node-dialog.component';
import {DDSEModel, DDSERouterGroup} from './domain-dialog-script.constant';
import {DomainDialogScriptService} from './domain-dialog-script.service';


@Component({
  selector: 'app-domain-dialog-script-node',
  templateUrl: 'domain-dialog-script-node.component.html',
  styles: [`
    ul, li {list-style-type: none;}
    .detail {
      margin: 5px 0;
      padding: 5px;
      border: 1px solid black;
      display: inline-block;
    }
    .mat-tab-label {
      height: 28px !important;
    }
  `],
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DomainDialogScriptNodeComponent),
      multi: true
    }
  ]
})

export class DomainDialogScriptNodeComponent {
  @Input() control: any;
  @Input() marketplace;
  @Input() licenses;
  @Input() isOther: boolean = false; // нога Иначе
  @Input()  isChild: boolean = false; // нога в многоножке, например в расписании или меню  public rxFormHelpers = new RxFormHelpers();
  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  public isInvalid: boolean = false;
  public isNew: boolean = false; // добавление нового узла в маршрут
  public isShow: boolean = true; // развернуть/свернуть блок многоножки
  public rxFormHelpers = new RxFormHelpers();
  public readonly DDSERouterGroup = DDSERouterGroup;

  constructor(
    public api: DomainDialogScriptService,
    public notifyService: NotifyService,
    public auth: AuthenticationService,
    public route: ActivatedRoute,
    public fb: RxFormBuilder,
    public media: MediaMatcher,
    private dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 850px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  canPersonalLic(item) {
    if (!item?.license) return true;
    else return (this.licenses || []).includes(item.license);
  }

  canIntegration(item) {
    if (item.group !== 'integration') return true;
    if ((this.marketplace || []).includes(item.param)) return true;
    if (Array.isArray(item.param)) {
      if ((this.marketplace || []).some(r=> item.param.indexOf(r) >= 0)) return true;
    }
    return false;
  }

  findParentManyChildren(control): boolean {
    if (control.parent) {
      if (control.parent.legs || control.parent.default_leg) {
        return true;
      }
      return this.findParentManyChildren(control.parent)
    }
    else {
      return false;
    }
  }

  getDDSEGroup(group: string) {
    let groupElems = [];
    for (let el of Object.keys(DDSEModel)) {
      if (DDSEModel[el].group == group) groupElems.push(DDSEModel[el]);
    }
    return DDSEModel.filter(
      item => item.group == group && /*(this.auth.isCCMode() >= item.onlyCC) &&*/ (this.canIntegration(item)) && (this.canPersonalLic(item))
    );
  }

  getNodeData(model_name?: string) {
    return DDSEModel.find(m => m.name == (model_name || this.control.value.model_name)) || {};
  }

  changeNodeType(model_name) {
    this.control.patchValue({model_name: model_name});
    this.control.setControl('params', this.api.paramsModel(model_name));
    let nodeData = this.getNodeData(model_name);

    if (nodeData['child'] == 'manychildrens') {
      const leg = this.control.get('leg');

      // рисуем дефолтную ногу (она же нога ИНАЧЕ)
      (<FormGroup>this.control).setControl('default_leg', this.fb.formGroup(new DDSEDefaultLeg()));
      let m = new DDSEOneLeg();
      // m.params = new DDSEBaseParams();
      let addingControl = this.fb.formGroup(m);
      this.control.get('default_leg').setControl('leg', addingControl);

      // прикрепляем все, что ниже этого узла в маршруте, в ногу ИНАЧЕ
      if (leg) {
        this.control.removeControl('leg');
        this.control.get('default_leg').setControl('leg', leg);
      }

      // рисуем многожковые ноги
      this.control.setControl('legs', this.fb.formGroup(new MultiLegs()));
      // эта нога нужна, чтобы модель создать, а далее нужно удалять
      if (this.control.get('legs').get('deletingKey')) this.control.get('legs').removeControl('deletingKey');
    }

    this.isNew = false;
    if (this.getNodeData(model_name)['editable']) this.editNode(this.control);
  }

  addNewNode() {
    let m = new DDSEOneLeg();
    // m.params = new DOCBaseMessageParams();
    let addingControl = this.fb.formGroup(m);
    if (this.control.value.leg != null) addingControl.setControl('leg', this.control.get('leg'));
    (this.control).setControl('leg', addingControl);
  }

  editNode(control) {
    this.dialog.open(DomainDialogScriptNodeDialogComponent, {
      panelClass: (['DDSEMenu'].indexOf(control.value.model_name) != -1) ? 'dialog-size-full' : 'dialog-compact',
      data: {
        control: control,
        marketplace: this.marketplace,
        nodeData: this.getNodeData()
      }
    });
  }

  deleteNode() {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        if (this.isOther || this.isChild) { // ноги многоножки
          if (this.control.get('leg')) { // у ноги есть цепочка ниже, тогда ее вставляю вместе удаляемого элемента
            const childLeg: FormGroup = this.control.get('leg');
            (<FormGroup>this.control.parent).removeControl('leg');
            (<FormGroup>this.control.parent).addControl('leg', childLeg);
          } else { // у ноги нет цепи ниже, вставляю пустой блок, т.к. ногу удалить отсюда нельзя, только в предке, конфигурируя условия
            (<FormGroup>this.control.parent).setControl('leg', this.api.legModel(null));
          }
        } else if (this.control.get('legs') || this.control.get('default_leg')) { // корень многоножки (например: менюшки или расписания)
          // все из ноги Иначе вставляю вместо удаляемого элемента
          const childLeg: FormGroup = this.control.get('default_leg').get('leg');
          (<FormGroup>this.control.parent).removeControl('leg');
          (<FormGroup>this.control.parent).addControl('leg', childLeg);
        } else if (this.control.get('leg')) {
          const child: FormGroup = this.control.get('leg');
          (<FormGroup>this.control.parent).removeControl('leg');
          (<FormGroup>this.control.parent).addControl('leg', child);
        } else if (this.control.parent.get('leg')) {
          // последний элемент в цепочке
          this.control.parent.removeControl('leg');
        }
      }
    });
  }

  gotoLink() {
    if (this.api.getLabel(this.control.value) != null) {
      window.open(`${window.location.origin}${this.getNodeData()['goto']}${this.control.value?.params?.form_id}`, '_blank');
    }
  }
}
