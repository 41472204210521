import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Helper, IResponseObject} from '../_helpers/api.helpers';
import {iif, Observable, of as observableOf, throwError} from 'rxjs';
import {catchError, delay, map, retryWhen, tap, concatMap} from 'rxjs/operators';
import {JsonAppConfigService} from "../config/json-app-config.service";
const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})


export class WebAppService {
  constructor(public http: HttpClient, public AppConfig: JsonAppConfigService) {

  }

  basicAuth<P>(params: any = {}): Observable<IResponseObject> {
    //const requestBody = Helper.requestFormat('auth', 'DOCWebChat', params)
    return this.http.get<IResponseObject>(this.AppConfig.getValue('sourceUrl')+'integration/telegram/webapp/'+params.token+'/', {})
      .pipe(
        map(data => {
          return data as IResponseObject
        }),
        // catchError(() => observableOf({} as T))
      )
  }

  postTgData<P>(token, params: any = {}): Observable<any> {
    return this.http.post<any>(this.AppConfig.getValue('sourceUrl')+'integration/telegram/webapp/'+token+'/', params, {})
  }

}
