import {SelectionModel} from '@angular/cdk/collections';
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatChipList} from '@angular/material/chips';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, of as observableOf} from 'rxjs';
import {distinctUntilChanged, throttleTime} from 'rxjs/operators';
import {NotifyService} from '../../../_helpers/notify.service';
import {BaseListComponent} from '../../../_helpers/base-list.component';
import {ConfirmComponent} from '../../../dialogs/modals/confirm/confirm.component';
import {DomainUserService} from '../../../users/domain-user.service';
import {DomainCRMDFieldConfService} from '../dfields/dfield.service';
import {DomainCRMEntity} from './entity';
import {DomainCRMEntityImportComponent} from './entity-import.component';
import {DomainCRMEntityService} from './entity.service';

@Component({
  selector: 'app-entities',
  template: `
    <ng-template #userLink let-user="user">
      <span *ngIf="user">{{user.uid + ' - ' + user.name}}</span>
    </ng-template>

    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card class="p-t-0">
          <mat-toolbar color="primary" [class.no-shadow]="!showFilter">
            <span [ngClass.xs]="'text-oneline'" [matTooltip]="(entity_type == 0 ? 'DOMAINCRMCOMPANIES' : 'DOMAINCRMCONTACTS')|translate"
                  translate>
              {{entity_type == 0 ? 'DOMAINCRMCOMPANIES' : 'DOMAINCRMCONTACTS'}}
            </span>
            <span fxFlex></span>
            <a mat-mini-fab color="white" class="m-r-10" [ngClass.xs]="'m-l-5'" routerLink="edit">
              <mat-icon class="text-primary">add</mat-icon>
            </a>
            <button mat-mini-fab color="white" class="m-r-10" (click)="import()"
                    [matTooltip]="(entity_type == 0 ? 'DOMAINCRMCOMPANY.IMPORT' : 'DOMAINCRMCONTACT.IMPORT')|translate">
              <mat-icon class="text-primary">file_upload</mat-icon>
            </button>
            <button mat-mini-fab color="white" (click)="openFilter(filterText)">
              <mat-icon class="text-primary">search</mat-icon>
            </button>
            <button mat-mini-fab class="m-l-10" color="white" *ngIf="!!navigate" routerLink="/{{navigate}}">
              <mat-icon class="text-primary" style="margin-top: -3px;">reply</mat-icon>
            </button>

            <form class="app-search-chips" [ngClass]="{'show-search': showFilter}" (keydown.enter)="$event.preventDefault()">
              <mat-form-field class="bg-white">
                <mat-chip-list #filterChipList [formControl]="filterChipCtrl">
                  <ng-container *ngIf="filterChipCtrl.value.length > 0">
                    <mat-chip *ngFor="let item of (filterChipCtrl.value || []); let i=index" class="mat-chip-selected"
                              [removable]="true" (removed)="removeChip(i)"
                    >
                      <ng-container *ngIf="item.option['key'] == 'pbx_host_id'; else optionName">
                        <a [routerLink]="['/', 'pbx-hosts', 'edit', item.value]" class="text-white">
                          <ng-content *ngTemplateOutlet="optionName; context{tooltip: ('GO_TO_HOST'|translate)}"></ng-content>
                        </a>
                      </ng-container>
                      <ng-template #optionName let-tooltip="tooltip">
                        <span
                            [matTooltip]="(tooltip ? tooltip : '') + ((!tooltip ? (item.option['name']|translate) : '') + ': ' + (item.option['obj'] ? item.option['obj']['name'] : item.value))">
                          {{(item.option['name']|translate) + ': ' + (item.option['obj'] ? item.option['obj']['name'] : item.value)}}
                        </span>
                      </ng-template>
                      <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                  </ng-container>
                  <input class="form-control" #filterText #filterTrigger="matAutocompleteTrigger"
                         (keyup.enter)="addChip(filterText.value, filterText['activeOption'])"
                         (keyup.escape)="filterCloseBtn.click();"
                         [placeholder]="(entity_type === 0 ? 'FILTER_CRM_COMPANY_TEXT' : 'FILTER_CRM_CONTACT_TEXT')|translate"
                         [matTooltip]="'FILTER_CRM_TEXT'|translate"
                         [matChipInputFor]="filterChipList"
                         [matAutocomplete]="autoFilterFields">
                  <mat-autocomplete #autoFilterFields="matAutocomplete" autoActiveFirstOption
                                    (optionSelected)="filterText.value ? addChip(filterText.value, $event.option.value) : null">
                    <ng-container *ngFor="let fOption of filteredOptions">
                      <mat-option *ngIf="fOption.simple" [value]="fOption">{{fOption.name|translate}}</mat-option>
                    </ng-container>
                  </mat-autocomplete>
<!--                  <a class="cl-srh-btn text-accent" (click)="openFilterDialog()" [matTooltip]="'OPEN_FILTER_DIALOG'|translate" style="right: 42px;">-->
<!--                    <mat-icon>manage_search</mat-icon>-->
<!--                  </a>-->
                  <a #filterCloseBtn class="cl-srh-btn text-muted" [matTooltip]="'CLEAR_AND_CLOSE_FILTER'|translate"
                     (click)="filterTrigger.closePanel(); showFilter = !showFilter; filterText.value=''; filterChipCtrl.setValue([]); applyFilter('');">
                    <mat-icon>close</mat-icon>
                  </a>
                </mat-chip-list>
              </mat-form-field>
            </form>
          </mat-toolbar>

          <mat-card-content>
            <div class="example-container">
              <div *ngIf="lastCalledEntities.length > 0" class="p-b-10">
                <mat-chip-list>
                  <ng-container *ngFor="let ent of lastCalledEntities; let i = index">
                    <mat-chip class="bg-thirdly mat-chip-290 mat-chip-selected"
                              [removable]="true" [selectable]="false" [selected]="false"
                              (removed)="removeFromLastCalling(ent.id)">
                      <a routerLink="edit/{{ent.id}}" [state]="{filter: filterCtrl.value}">{{ent.name}}</a>
                      <mat-icon [matTooltip]="'DELETE_FROM_FAST_PANEL'|translate" matChipRemove>close</mat-icon>
                    </mat-chip>
                  </ng-container>
                </mat-chip-list>
              </div>

              <div class="h-10 example-loading-shade">
                <mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate" color="primary"></mat-progress-bar>
                <div class="example-rate-limit-reached text-warning" *ngIf="isRateLimitReached" [translate]="'NOTIFY.502'"></div>
              </div>

              <div class="example-table-container responsive-table">
                <table mat-table [dataSource]="dataSource" class="example-table" matSort matSortActive="id" matTableResponsive
                       matSortDisableClear matSortDirection="asc">
                  <!-- Checkbox Column -->
                  <ng-container matColumnDef="select">
                    <th mat-header-cell [hidden]="selection.selected.length === 0" *matHeaderCellDef>
                      <mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()"
                                    [disabled]="removing"
                      >
                      </mat-checkbox>
                    </th>
                    <td mat-cell [hidden]="selection.selected.length === 0" *matCellDef="let row">
                      <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)"
                                    [disabled]="removing">
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="id">
                    <th fxShow fxHide.sm fxHide.md mat-header-cell *matHeaderCellDef>#</th>
                    <td fxShow fxHide.sm fxHide.md mat-cell *matCellDef="let row">{{row.id}}</td>
                  </ng-container>
                  <ng-container matColumnDef="name">
                    <th fxShow mat-header-cell *matHeaderCellDef>{{ (entity_type == 0 ? 'NAME' : 'FIO')|translate }}</th>
                    <td fxShow mat-cell *matCellDef="let row">{{row.name}}
                      <mat-hint *ngIf="row.error && row.error[0]">{{row.error[0].text}}</mat-hint>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="displayedColumns.indexOf('parent_id_list') != -1" matColumnDef="parent_id_list">
                    <th fxShow fxHide.sm fxHide.md mat-header-cell *matHeaderCellDef>{{ 'DOMAINCRMCOMPANY'|translate }}</th>
                    <td fxShow fxHide.sm fxHide.md mat-cell *matCellDef="let row">
                      <span *ngFor="let companyID of row.parent_id_list; let i =index;">
                        <a mat-raised-button class="bg-light-extra-inverse mat-button-small"
                           [routerLink]="['/', 'crm', 'companies', 'edit', companyID]"
                           [class.m-r-5]="i < row.parent_id_list.length-1">
                          {{ companies[companyID]?.name + (i < row.parent_id_list.length - 1 ? ',' : '') }}
                        </a>
                      </span>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="desc">
                    <th fxShow fxHide.sm fxHide.md mat-header-cell
                        *matHeaderCellDef>{{ (entity_type == 0 ? 'DESCRIPTION' : 'COMMENT')|translate }}</th>
                    <td fxShow fxHide.sm fxHide.md mat-cell *matCellDef="let row">{{row.desc}}</td>
                  </ng-container>
                  <ng-container matColumnDef="responsible_user_id">
                    <th fxShow mat-header-cell *matHeaderCellDef>{{ 'RESPONSIBLE_USER_ID'|translate }}</th>
                    <td fxShow mat-cell *matCellDef="let row">
                      <ng-content *ngTemplateOutlet="userLink; context{user: getResponsibleUserName(row.responsible_user_id)}"></ng-content>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="phone_list">
                    <th fxShow fxHide.sm fxHide.md mat-header-cell *matHeaderCellDef>{{ 'PHONE_LIST'|translate }}</th>
                    <td fxShow fxHide.sm fxHide.md mat-cell *matCellDef="let row">
                      <div *ngFor="let phoneObj of row.phone_list">{{phoneObj.value}}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="email_list">
                    <th fxShow fxHide.sm fxHide.md mat-header-cell *matHeaderCellDef>{{ 'EMAIL_LIST'|translate }}</th>
                    <td fxShow fxHide.sm fxHide.md mat-cell *matCellDef="let row">
                      <div *ngFor="let emailObj of row.email_list">{{emailObj.value}}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="address_list">
                    <th fxShow mat-header-cell *matHeaderCellDef>{{ 'ADDRESS_LIST'|translate }}</th>
                    <td fxShow mat-cell *matCellDef="let row">
                      <div *ngFor="let addressObj of row.address_list">{{addressObj.value}}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="dfields">
                    <th fxShow fxHide.sm fxHide.md mat-header-cell *matHeaderCellDef>{{ 'CRM.DFIELDS'|translate }}</th>
                    <td fxShow fxHide.sm fxHide.md mat-cell *matCellDef="let row">{{row.dfields}}</td>
                  </ng-container>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell mat-button-header *matHeaderCellDef class="text-right">
                      <button *ngIf="selection.selected.length > 0"
                              mat-icon-button color="primary"
                              [matMenuTriggerFor]="massActionMenu"
                              [matTooltip]="'MASSACTIONS'|translate"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #massActionMenu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="onMultiDelete()" [matTooltip]="'TO_DELETE'|translate" [disabled]="removing">
                          <mat-icon class="text-danger">delete</mat-icon>
                          {{'TO_DELETE'|translate}}
                        </button>
                      </mat-menu>
                    </th>
                    <td mat-cell *matCellDef="let row" align="right">
                      <ng-container *ngIf="selection.selected.length === 0">
                        <a mat-icon-button routerLink="edit/{{row.id}}" [state]="{filter: filterCtrl.value}">
                          <mat-icon class="text-accent">edit</mat-icon>
                        </a>
                        <button mat-icon-button (click)="onDelete(row)">
                          <mat-icon class="text-danger">delete</mat-icon>
                        </button>
                      </ng-container>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                      [class.blinker]="row.blinker || (removing && selection.isSelected(row))"
                      (click)="selection.toggle(row)"
                      (dblclick)="onRowClicked(row.id)"
                  ></tr>
                </table>
              </div>
              <mat-paginator matLocalStoragePageSize [length]="resultsLength" [pageSize]="10"
                             [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>    `,
  styles: [`
      :host ::ng-deep .mat-column-actions {
          padding-right: 0!important;
      }
  `],
  styleUrls: ['../../../material-component/mat-table-responsive/mat-table-responsive.directive.scss'],
})

export class DomainCRMEntityListComponent extends BaseListComponent<DomainCRMEntity> implements OnInit, OnDestroy {
  public entity_type: number; // 0 - компания | 1 - контакт
  public displayedColumns: string[] = ['select', 'id', 'name', 'desc', 'responsible_user_id', 'phone_list', 'email_list', 'address_list', 'actions'];
  public filteredColumns: string[] = ['name'];
  public dataSource: MatTableDataSource<DomainCRMEntity> = new MatTableDataSource<DomainCRMEntity>([]);
  public navigate: string = '';
  public companies = {};
  public domainUsers = [];
  extParams = {sort: {name: '+'}};
  public selection = new SelectionModel<DomainCRMEntity>(true, []);
  public removing = false;
  public dfields = [];
  private lastCalledIDs: number[] = []; // список последних звонивших 5 контактов/компаний
  public lastCalledEntities: any[] = [];
  public lastCalledLSKey: string = 'lastCalledContacts';
  private callingSub;
  public isSingleClick: boolean = false;

  //параметры для фильтрации таблицы:
  public filterChipCtrl: FormControl = new FormControl([]);
  @ViewChild('filterChipList') filterChipList: MatChipList;
  @ViewChild('filterText') filterText: ElementRef<HTMLInputElement>;
  filteredOptions: { key: string, name: string, simple: boolean }[] = [];

  constructor(
    api: DomainCRMEntityService,
    translate: TranslateService,
    notifyService: NotifyService,
    dialog: MatDialog,
    router: Router,
    private domainCRMDFieldConfService: DomainCRMDFieldConfService,
    private domainUserService: DomainUserService
  ) {
    super(api, translate, notifyService, dialog, router);
    this.navigate = 'crm';
    if (this.router.url.endsWith('companies')) {
      this.entity_type = 0;
      this.lastCalledLSKey = 'lastCalledCompanies';
    } else if (this.router.url.endsWith('contacts')) {
      this.entity_type = 1;
    }

    if (this.entity_type === 1) {
      this.displayedColumns.splice(3, 0, 'parent_id_list');
    }

    this.filteredOptions = [
      // simple: true - опции простого поиска, добавляются в поиск при вводе в инпуте и нажатии кнопки Enter
      {key: 'id', name: '#', simple: true},
      {key: 'name', name: this.entity_type == 0 ? 'NAME' : 'FIO', simple: true},
      {key: 'desc', name: this.entity_type == 0 ? 'DESCRIPTION' : 'COMMENT', simple: true},
      {key: 'phone_list@T', name: 'PHONE_LIST', simple: true},
      {key: 'email_list@T', name: 'EMAIL_LIST', simple: true},
      {key: 'address_list@T', name: 'ADDRESS_LIST', simple: true},
      {key: 'dfields', name: 'ADDITIONAL_INFO', simple: true},

      // simple: false - опции расширенного поиска, добавляются в поиск при выборе в окне расширенного поиска
      {key: 'responsible_user_id', name: 'RESPONSIBLE_USER_ID', simple: false},
      {key: 'parent_id_list', name: 'DOMAINCRMCOMPANY', simple: false}
    ];
  }

  ngOnInit() {
    this.domainCRMDFieldConfService.list({
      filter: {type: 0, field_list: [{field: 'obj', value: [this.entity_type], condition_type: 9}]},
      sort: {name: '+'}
    }).subscribe(
      data => {
        this.dfields = data.list;
        if (this.dfields.length < data.total_count) {
          this.domainCRMDFieldConfService.list({
            limit: data.total_count - this.dfields.length,
            offset: this.dfields.length,
            filter: {type: 0, field_list: [{field: 'obj', value: [0], condition_type: 9}]},
            sort: {name: '+'}
          }).subscribe(
            data => this.dfields = [...this.dfields, ...data.list],
            resp => this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502
          );
        }
      }
    );

    this.getLastCalledEntities();

    // подписка на выполнение вызова из другого компонента
    // @ts-ignore
    this.callingSub = this.api.calling$.pipe(throttleTime(800), distinctUntilChanged()).subscribe(ent => {
      if (ent) {
        let lastCallingIDs = JSON.parse(localStorage.getItem(this.lastCalledLSKey)) || [];
        // добавляю сущность в список 5 последних звонивших
        let ind = lastCallingIDs.indexOf(ent.id);
        if (ind == -1) { // его не было в списке, поэтому просто добавляю его в начало списка
          lastCallingIDs.unshift(ent.id);
          if (lastCallingIDs.length > 5) lastCallingIDs.splice(5);
          localStorage.setItem(this.lastCalledLSKey, JSON.stringify(lastCallingIDs));
          this.getLastCalledEntities();
        } else if (ind != 0) { // сущность была в списке, переношу ее в начало
          lastCallingIDs.splice(ind, 1);
          lastCallingIDs.unshift(ent.id);
          localStorage.setItem(this.lastCalledLSKey, JSON.stringify(lastCallingIDs));
          this.getLastCalledEntities();
        } // иначе сущность уже в начале списка
      }
    });
  }

  ngOnDestroy() {
    if (this.callingSub) this.callingSub.unsubscribe();
  }

  setDataSource(data) {
    this.isLoadingResults = true;
    let userBindings = data.map(ent => ent.responsible_user_id);
    let companyBindings = (this.entity_type == 1) ? data.reduce((idList, ent) => [...idList, ...ent.parent_id_list], []) : [];
    forkJoin([
      userBindings.length > 0 ? this.domainUserService.toSelectWithDetail({
        filter: {
          type: 0,
          field_list: [
            {field: 'id', value: userBindings, condition_type: 9}
          ]
        },
        limit: userBindings.length,
        sort: {name: '+', surname: '+', user_name: '+', user_surname: '+'}
      }) : observableOf(null),
      companyBindings.length > 0 ? this.api.toSelect({
        filter: {
          type: 0,
          field_list: [
            {field: 'id', value: companyBindings, condition_type: 9}
          ]
        },
        limit: companyBindings.length,
        sort: {name: '+', surname: '+', user_name: '+', user_surname: '+'}
      }, 'select') : observableOf(null),
    ]).subscribe(
      results => {
        if (results[0]) {
          this.domainUsers = [
            ...results[0].map(u => {
              return {
                id: u.id,
                user_id: u.user_id,
                name: u['user_name'] + (u['user_surname'] ? ' ' + u['user_surname'] : ''),
                proto: u.proto,
                uid: u.uid
              };
            })
          ];
        }

        if (results[1]) {
          results[1].forEach(company => this.companies[company.id] = company);
        }
        super.setDataSource(data);
        this.isLoadingResults = false;
      },
      resp => {
        this.isLoadingResults = false;
        this.isRateLimitReached = data.code == 502;
      }
    );
  }

  applyFilter(filterValue: string) {
    super.applyFilter(filterValue);
    this.getLastCalledEntities();
  }

  checkboxLabel(row?: DomainCRMEntity): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${this.dataSource.data.findIndex(tr => tr.id === row.id)}`;
  }

  isAllSelected() {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  getResponsibleUserName(userId) {
    return this.domainUsers.find(u => u.id == userId);
  }

  getLastCalledEntities() {
    if (localStorage.getItem(this.lastCalledLSKey)) {
      this.lastCalledIDs = JSON.parse(localStorage.getItem(this.lastCalledLSKey));

      // с учетом фильтра и при этом условие между фильтрами стоит И, т.к. айдишники обязательны, а вот другие поля - нет,
      // но АПИ фильтрации не позволяет так написать
      let filter = this.getFilter();
      if (filter?.filter) {
        filter.filter.type = 0;
        filter.filter.field_list.push({field: 'id', value: this.lastCalledIDs, condition_type: 9});
      } else filter = {
        filter: {
          type: 0,
          field_list: [
            {field: 'id', value: this.lastCalledIDs, condition_type: 9}
          ]
        }
      };
      // запрашиваю сущности отдельно
      this.api.list({
        limit: this.lastCalledIDs.length,
        ...filter
      }).subscribe(
        data => {
          this.lastCalledEntities = data.list;
          if (data.list.length != this.lastCalledIDs.length) {
            this.lastCalledIDs = this.lastCalledIDs.filter(id => this.lastCalledEntities.filter(d => d.id == id).length > 0);
            localStorage.setItem(this.lastCalledLSKey, JSON.stringify(this.lastCalledIDs));
          }

          this.lastCalledEntities.sort((d1, d2) => this.lastCalledIDs.indexOf(d1.id) - this.lastCalledIDs.indexOf(d2.id));
        },
        resp => {
          this.isLoadingResults = false;
          this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
        }
      );
    }
  }

  removeFromLastCalling(ent_id) {
    this.lastCalledEntities = this.lastCalledEntities.filter(ent => ent.id != ent_id);
    this.lastCalledIDs.splice(this.lastCalledIDs.indexOf(ent_id), 1);
    if (this.lastCalledIDs.length == 0) localStorage.removeItem(this.lastCalledLSKey);
    else localStorage.setItem(this.lastCalledLSKey, JSON.stringify(this.lastCalledIDs));
  }

  addChip(filterValue?: any, autoCompleteOption?: any): void {
    if (!autoCompleteOption) {
      filterValue = filterValue.trim().toLowerCase();
    }

    if (filterValue) {
      if (autoCompleteOption) {
        this.filterChipList.ngControl.value.push({option: autoCompleteOption, value: filterValue});
      } else {
        for (let option of this.filteredOptions) {
          if (option.simple) {
            this.filterChipList.ngControl.value.push({option: option, value: filterValue});
          }
        }
      }

      this.filterText.nativeElement.value = '';
    }

    this.applyFilter('');
  }

  removeChip(index) {
    if (this.filterChipList.ngControl.value.length > 1) {
      this.filterChipList.ngControl.value.splice(index, 1);
    } else if (this.filterChipList.ngControl.value.length == 1) {
      this.filterChipList.ngControl.reset([]);
    }
    this.applyFilter('');
  }

  getFilter() {
    let filterData = {filter: {field_list: [], type: 0}}; // условие между полями; FILTER_TYPE_AND = 0, FILTER_TYPE_OR = 1
    filterData.filter.field_list.push({field: 'entity_type', value: this.entity_type, condition_type: 0});

    // формирую фильтр из чипсов
    if (this.filterChipList?.ngControl?.value.length > 0) {
      // условие между полями ИЛИ
      for (let fld of this.filterChipList.ngControl.value) {
        if (fld['option']['key'] != 'responsible_user_id' && fld['option']['key'] != 'parent_id_list') {
          if (filterData['filter']['field_list'].indexOf(fld['value']) == -1) filterData['filter']['field_list'].push({
            field: fld['option']['key'],
            condition_type: 3, // поиск по частичному совпадению
            value: fld['value']
          });
        } else {
          filterData['filter']['field_list'].push({
            field: fld['option']['key'],
            condition_type: 0, // поиск по точному совпадению
            value: fld['option']['key'] === 'responsible_user_id' ? fld['value'] : [fld['value']]
          });
        }
      }
    }

    return filterData;
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(ExtFilterDialogComponent, {
      panelClass: 'dialog-size-full',
      role: 'alertdialog',
      data: {entity_type: this.entity_type}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm && result.obj) {
        let filteredOption = this.filteredOptions.find(o => o.key == result.criterion);
        if (filteredOption.key == 'responsible_user_id' || filteredOption.key == 'parent_id_list') {
          if (filteredOption.key == 'parent_id_list') {
            for (let i in result.obj) {
              this.addChip(result.obj[i].id, Object.assign(filteredOption, {obj: {...result.obj[i]}}));
            }
          } else this.addChip(result.obj.id, Object.assign(filteredOption, {obj: result.obj}));
        } else {
          this.addChip(result.obj.trim(), filteredOption);
        }
      }
    });
  }

  import() {
    const dialogRef = this.dialog.open(DomainCRMEntityImportComponent, {
      // panelClass: 'dialog-size-full',
      width: 'calc(100%-200px)',
      height: 'calc(100%-100px)',
      data: {entity_type: this.entity_type}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.confirm) {
        this.refresh();
      }
    });
  }

  cbDeleteSuccess() {
    super.cbDeleteSuccess();
    this.getLastCalledEntities();
  }

  cbDeleteError() {
    super.cbDeleteError();
    this.getLastCalledEntities();
  }

  onMultiDelete() {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MULTI_MESSAGE'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.removing = true;
        let cntSuccess = 0, cntCurr = 0, cntSelected = this.selection.selected.length;
        for (let row of this.selection.selected) {
          this.api.delete(row.id).subscribe(
            data => {
              cntCurr += 1;
              if (data.code === 200) {
                cntSuccess += 1;
                this.selection.toggle(row);
              }
              if (cntSuccess === cntSelected) {
                this.removing = false;
                this.refresh();
              } else {
                this.removing = cntCurr !== cntSelected;
                let removedInd = this.dataSource.data.findIndex(v => v.id === row.id);
                if (removedInd !== -1) {
                  this.dataSource.data.splice(removedInd, 1);
                  this.table.renderRows();
                }
              }
              this.getLastCalledEntities();
            },
            resp => {
              this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
              row['error'] = this.notifyService.showRequestResult(resp, false);

              cntCurr += 1;
              this.removing = cntCurr !== cntSelected;

              this.getLastCalledEntities();
            }
          );
        }
      }
    });
  }

  // onRowClicked(row, isSingleClick?) {
  //   this.isSingleClick = isSingleClick;
  //   if (!this.isSingleClick) super.onRowClicked(row.id);
  //   else setTimeout(() => {
  //     if (this.isSingleClick) {
  //       this.selection.toggle(row);
  //       this.isSingleClick = false;
  //     } else super.onRowClicked(row.id);
  //   }, this.entity_type ? 175 : 200);
  // }
}

@Component({
  selector: 'ext-filter-dialog',
  templateUrl: 'ext-filter-dialog.component.html',
  styles: [`
      ::ng-deep .ng-dropdown-panel {
          z-index: 9999999!important;
      }
  `]
})
export class ExtFilterDialogComponent implements OnInit {
  public isServerAvailable: boolean = true;
  public options: any[] = [];
  public objControl = new FormControl(null, Validators.required);
  public criterionControl = new FormControl('responsible_user_id', Validators.required);
  public companies = [];
  public domainUsers = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entity_type: number },
    protected notifyService: NotifyService,
    private api: DomainCRMEntityService
  ) {
    if (data.entity_type == 1) {
      this.criterionControl.setValue('parent_id_list');
    }
  }

  ngOnInit() {
    this.api.toSelect({
      filter: {type: 0, field_list: [{field: 'entity_type', value: 0, condition_type: 0}]}, sort: {name: '+'}, limit: 1000
    }, 'select').subscribe(
      companies => this.companies = [...companies],
      resp => this.isServerAvailable = this.notifyService.setFormErrors(resp) != 502
    );
  }
}
