import {prop} from '@rxweb/reactive-form-validators';

export class DomainHideContactCall {
  @prop() id?: number;
  @prop() dt: number;
  @prop() external_id: string;
  @prop() hc_id: number;
  @prop() first: number; // Инициатор: 0 - Абонент А; 1 - Абонент Б
  @prop() first_uuid: string ; // Идентификатор вызова Инициатору
  @prop() second_uuid: string ; // Идентификатор вызова Вызываемому
  @prop() call_type: string; // Тип вызова: АПИ или Абонент
  @prop() status: number; // [HIDE_CONTACT_CALL_STATUS_OUT_B]

  static className() {
    return 'DomainHideContactCall';
  }
}
