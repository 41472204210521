import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {AudioPlayerComponent, Track} from '@khajegan/ngx-audio-player';


@Component({
  selector: 'app-audio-player-dialog',
  templateUrl: 'audio-player-dialog.component.html',
  styleUrls: ['audio-player-dialog.component.scss']
})

export class AudioPlayerDialogComponent implements AfterViewInit {
  @ViewChild('player') player: AudioPlayerComponent;
  @ViewChild('dialogRef') dialogRef: ElementRef;
  public fileLoad = false;
  public track: Track[] = []
  constructor(
    public dialogPlayer: MatDialogRef<AudioPlayerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
      this.track.push({
        link: data?.link,
        title: data?.name

      } as Track);
  }

  ngAfterViewInit() {
    if (this.player) {
      (this.player.player.nativeElement as HTMLAudioElement).onloadstart = (event) => {
        this.dialogRef.nativeElement.querySelector('.ngx-audio-player').setAttribute('style', 'display: flex !important;');
        this.fileLoad = false;
      }

      (this.player.player.nativeElement as HTMLAudioElement).onloadedmetadata = (event) => {
        this.fileLoad = true;

      }

      (this.player.player.nativeElement as HTMLAudioElement).onerror = (event) => {
        this.fileLoad = false;
        setTimeout(_=>this.dialogRef.nativeElement.querySelector('.ngx-audio-player').setAttribute('style', 'display: none !important;'), 800);
      }
    }
  }

  max(number: number, number2: number) {
    return Math.max(number, number2);
  }

  min(number: number, number2: number) {
    return Math.min(number, number2);
  }

  setPosition(pos: number) {
    this.player.currentTime = pos;
    (this.player.player.nativeElement as HTMLAudioElement).currentTime = pos;
  }

  setRate(pos: number) {
    (this.player.player.nativeElement as HTMLAudioElement).playbackRate = pos;
  }

  getRate() {
    if (this.player) return Math.round((this.player.player.nativeElement as HTMLAudioElement).playbackRate*10) / 10;
    else return 1;
  }

  clickId(id) {
   const btn =  document.getElementById('player_'+id)
   if (btn)  {
     btn.click();
     this.dialogPlayer.close();
   }
  }

  goComment(id) {
    const btn =  document.getElementById('comment_'+id)
    console.log(btn);
    console.log(id);
    if (btn)  {
      btn.click();
      this.dialogPlayer.close();
    }
  }

  isLoad(event: Event) {
    console.log(event);
    this.fileLoad = true;
  }
}
