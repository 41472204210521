import {prop, required} from "@rxweb/reactive-form-validators";

export class DomainCrmSettings {
  @prop() event_outbound_call: number;
  @required() event_inbound_call: number;
  @required() event_field_call_id: number;
  @required() event_field_selected_documents: number;

  static className() {
    return 'DomainCRMSetting';
  }
}
