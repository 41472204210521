import {NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService} from '../auth/authentication.service';
import {first} from 'rxjs/operators';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {DateAdapter} from '@angular/material/core';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {AppHeaderComponent} from '../layouts/full/header/header.component';
import {APP_MENU_MODULES} from '../app.injection';
import {MenuService} from '../roles/menu.service';

const moment = _rollupMoment || _moment;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  msg = '';
  loading = false;
  loadingSSO = false;

  error = '';
  showAzureBtn = false;
  showADFSBtn = false;
  loginForm: FormGroup;
  defaultPath = '/dashboard';

  constructor(
      private authenticationService: AuthenticationService,
      private route: ActivatedRoute,
      private router: Router,
      private AppConfig: JsonAppConfigService,
      private _adapter: DateAdapter<any>,
      private _dt_adapter: NgxMatDateAdapter<any>,
      public translate: TranslateService,
      @Inject(APP_MENU_MODULES) public routerModule,
      public menu: MenuService,
      private formGroup: FormBuilder
  ) {
    this.loginForm = this.formGroup.group({
      u1: [null, Validators.required],
      p2: [null, Validators.required],
      re_me: [false]
    });

    if (this.authenticationService.currentUserValue) {
      if (this.menu.allowMenu(this.routerModule.menu).find(item => item.state == 'desktop')) {
        this.defaultPath = '/desktop';
      }
      let path = this.route.snapshot.paramMap.get('nextUrl') ? decodeURIComponent(this.route.snapshot.paramMap.get('nextUrl')) : this.defaultPath;
      if (path.split('?').length > 1) this.router.navigateByUrl(path);
      else this.router.navigate([path]);
    } else if (this.route.snapshot.queryParamMap.get('callback_token')) {
      this.getSSOUserData();
    } else if (this.route.snapshot.queryParamMap.get('domain')) {
      this.authenticationService.getSSOAvailable(this.route.snapshot.queryParamMap.get('domain')).subscribe(
        data => {
          this.showAzureBtn = data['azure'];
          this.showADFSBtn = data['adfs'];
        },
        err => console.error(err)
      );
    }
  }

  ngOnInit() {
  }

  changeLanguage(value) {
    this.translate.use(value);
    this._adapter.setLocale(value);
    this._dt_adapter.setLocale(value);
    moment.locale(value);
    if (localStorage) localStorage.setItem('ifaceLang', value);
  }

  check() {
    this.loginForm.markAllAsTouched();
    this.loginForm.updateValueAndValidity();
    if (this.loginForm.valid) {
      this.loading = true;
      this.authenticationService.login(this.loginForm.value.u1, this.loginForm.value.p2, this.loginForm.value.re_me)
        .pipe(first())
        .subscribe(
          data => {
            // debugger;
            this.loading = false;
            this.AppConfig.load(this.authenticationService.getDomain()).toPromise();
            if (this.menu.allowMenu(this.routerModule.menu).find(item => item.state == 'desktop')) {
              this.defaultPath = '/desktop';
            }
            let path = this.route.snapshot.paramMap.get('nextUrl') ? decodeURIComponent(this.route.snapshot.paramMap.get('nextUrl')) : this.defaultPath;
            if (path.split('?').length > 1) {
              this.router.navigateByUrl(path);
            } else {
              this.router.navigate([path]);
            }
          },
          error => {
            // debugger;
            this.msg = error;
            this.error = error;
            this.loading = false;
          });
    }
  }

  getSSORedirect(idp: string) {
    this.authenticationService.getSSORedirect({domain_name: this.route.snapshot.queryParamMap.get('domain'), idp: idp}).subscribe(
      data => {
        window.location.href = data.url;
      },
      error => {
        this.msg = error;
        this.error = error;
      }
    );
  }

  getSSOUserData() {
    this.loadingSSO = true;
    this.authenticationService.getSSOUserData(this.route.snapshot.queryParamMap.get('callback_token')).pipe(first()).subscribe(
      data => {
        if (this.menu.allowMenu(this.routerModule.menu).find(item => item.state == 'desktop')) {
          this.defaultPath = '/desktop';
        }
        console.log(this.defaultPath);
        window.location.replace(this.defaultPath);
      },
      error => {
        this.msg = error;
        this.error = error;
        this.loadingSSO = false;
      });
  }
}
