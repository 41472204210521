import { Injectable } from '@angular/core';
import {headers} from '../../../../_helpers/base.service';
import {AuthenticationService} from '../../../../auth/authentication.service';
import {JsonAppConfigService} from '../../../../config/json-app-config.service';
import {DomainService} from '../../../../domains/domain.service';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class DesktopControlPanelService  {
  constructor(public authenticationService: AuthenticationService,
              public AppConfig: JsonAppConfigService,
              public http: HttpClient,
              public router: Router, public route: ActivatedRoute,
              public notifyService: NotifyService,
              public domainService: DomainService,
              public translate: TranslateService) {
  }
}
