import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {NotifyService} from '../../../_helpers/notify.service';
import {FormControl, FormGroup} from '@angular/forms';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {TAXI_TAGS} from '../../../_helpers/constant';

@Component({
  selector: 'app-tag-dialog',
  templateUrl: './tagDialog.component.html',
  styleUrls: ['./tagDialog.component.css']
})

export class TagDialogComponent implements OnInit {
  public returnFile;
  public rxFormHelpers = new RxFormHelpers();
  public tag_select = TAXI_TAGS;
  public form: FormGroup = new FormGroup({
    tag: new FormControl(''),
    dpe_name: new FormControl('DPEDomainTaxiTag'),
  });

  constructor(
    public dialogRef: MatDialogRef<TagDialogComponent>,
    public translate: TranslateService,
    public notifyService: NotifyService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (data?.tag_select) {
      this.tag_select = data.tag_select;
    }
    if (data?.dpe_name) {
      this.form.get('dpe_name').setValue(data.dpe_name);
    }
  }

  ngOnInit(): void {
  }
}
