import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Pipe({
  name: 'epochTime'
})

export class TimeEpochPipe implements PipeTransform {
  transform(value: number | undefined, lang, withSeconds: boolean = false): string {
    // lang - передан для того, чтобы пайп заметил изменение языка и сам перерисовал дату
    if (value) {
      const dt = new Date();
      dt.setTime(value * 1000);
      return moment(dt).format(withSeconds ? 'LTS' : 'LT')
    }
    return '-';
  }
}
