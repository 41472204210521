import {digit, maxLength, maxNumber, minLength, minNumber, prop, required} from '@rxweb/reactive-form-validators';

export class DOCQueue {
  @prop() id?: number;
  @required() domain_id: number;
  @required() @minLength({value: 2}) @maxLength({value: 250}) name: string;
  @required() strategy: number = 1;
  @required() tier_rules_apply: boolean = false;
  @required() tier_rule_wait_second: number = 10;
  @required() tier_rule_wait_multiply_level: boolean = false;
  @required() max_wait_time: number = 0;
  @required() max_wait_time_with_no_agent: number = 0; // -1 выходит сразу, 0 - не выполняет проверку, >0 время в секундах, сколько ждать
  @required() max_wait_members: number = 0; // -1 выходит сразу, 0 - не выполняет проверку, >0 время в секундах, сколько максимум в ожидании
  @required() ring_progressively_delay: number = 10;
  @required() @digit() status: number = 1;

  static className() {
    return 'DOCQueue';
  }
}

export class DOCTier {
  @required() @digit() domain_id: number;
  @required() @digit() agent_id: number;
  @required() @digit() queue_id: number;
  @required() @digit() @minNumber({value: 0}) @maxNumber({value: 10}) level: number = 1;
  @required() @digit() @minNumber({value: 0}) @maxNumber({value: 10}) position: number = 1;

  static className() {
    return 'DOCTier';
  }
}
