import {Injectable, OnDestroy} from '@angular/core';
import {AuthenticationService} from '../../auth/authentication.service';
import {JsonAppConfigService} from '../../config/json-app-config.service';
import {DomainService} from '../../domains/domain.service';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, iif, Observable, Subject, Subscription} from 'rxjs';
import {IResponseListObject} from '../../_helpers/api.helpers';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../_helpers/notify.service';
import {AgentService} from '../../queues/agent.service';
import {QueueService} from '../../queues/queue.service';
import {AGENT_STATUS_LIST} from '../../_helpers/constant';
import {DomainAgentStatusService} from '../../domains/domain-agent-status/domain-agent-status.service';
import {StorageMap} from '@ngx-pwa/local-storage';


export interface MenuEvent {
  name: string;
  columns: string[];
}

@Injectable({
  providedIn: 'root'
})

export class DesktopService implements OnDestroy {
  subscription: Subscription = new Subscription();
  public columns: any = [];
  public changeMenu = new Subject<MenuEvent>();
  public queueData$ = new BehaviorSubject<any>([]);
  public agentData$ = new Subject<any>();

  public currentQueue = new BehaviorSubject<any>(null);
  public toggleEvent = new Subject<any>();
  public searchOffEvent = new Subject<any>();
  public listQueue = [];
  public status_system = AGENT_STATUS_LIST;
  public customAgentStatus = [];
  constructor(public authenticationService: AuthenticationService,
              public AppConfig: JsonAppConfigService,
              public http: HttpClient,
              public notifyService: NotifyService,
              public domainService: DomainService,
              public queueService: QueueService,
              public tierService: AgentService,
              public storage: StorageMap,
              public agentStatus: DomainAgentStatusService,
              public translate: TranslateService) {
    let sub1$ = queueService.toSelect({}, 'select').subscribe(item=>this.listQueue = item );
    let sub2$ = agentStatus.toSelect( {domain_id: authenticationService.getDomain()}).subscribe(msg=> {
      this.customAgentStatus = msg;
    })
    this.subscription.add(sub1$);
    this.subscription.add(sub2$);
  }
  getStatuses = () => this.status_system.concat(this.customAgentStatus);
  getUserStatus = () => this.status_system.filter(item=>item.type == 'user').concat(this.customAgentStatus);

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  setMenu(menu: MenuEvent) {
    this.changeMenu.next(menu);

  }
  getTranslateText(text: string) {
    const cyrillicPattern = /^[\u0400-\u04FF]+$/;
    let rus = 'щ   ш  ч  ц  ю  я  ё  ж  ы э а б в г д е з и й к л м н о п р с т у ф х 1 2 3 4 5 6 7 8 9 0'.split(/ +/g),
        eng = 'shh sh ch cz yu ya yo zh y e a b v g d e z i j k l m n o p r s t u f x 1 2 3 4 5 6 7 8 9 0'.split(/ +/g);

    let pure_text = text.match(/[a-zA-Zа-яА-Я\s]+/gui);
    if (!pure_text?.length) return text;
    const isCyrillic = cyrillicPattern.test(pure_text?.join(''));
    for( let x = 0; x < rus.length; x++) {
      const ru = rus[x] || ' ';
      const en = eng[x] || ' ';
      text = text.split(isCyrillic ? ru : en).join(isCyrillic ? en : ru);
      text = text.split(isCyrillic ? ru.toUpperCase() : en.toUpperCase())
        .join(isCyrillic ? en.toUpperCase() : ru.toUpperCase());
    }
    return text;
  }

  findColumns(data: any = [], key: string) {
    for( let x = 0; x < data.length; x++) {
      let grid = data[x];
      for( let y = 0; y < grid?.children?.length; y++) {
        let component = grid.children[y];
        if (component?.id == key) {
          return component?.columns || [];
        }
      }
    }
    return [];
  }

  getTiers(params: any = {}, action: string = 'list'): Observable<IResponseListObject<any>> {
    return this.tierService.list(params, action);
  }

}
