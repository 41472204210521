import {NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {Component, OnInit} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import {AppService} from '../../../app.service';
import { AuthenticationService } from '../../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {ROLE_TYPE_PROVIDER} from '../../../_helpers/constant';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {Router} from '@angular/router';
import {UserProfileService} from '../../../user-profile/user-profile.service';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  providers: [],
  styleUrls: [],
  styles: [`:host {
    height: 40px;
    display: inline-flex;
    position: relative;
    vertical-align: middle;
  }`,
  `::ng-deep .flag-button .mat-select-arrow {
    display: none;
  }
  ::ng-deep .flag-button .mat-select-trigger {
      height: 40px;
      width: 40px;
  }
  ::ng-deep .flag-button mat-select-trigger {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
    ::ng-deep .flag-button .mat-select-value {
      height: 40px;
      width: 40px;
    }`]
})
export class AppHeaderComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  public window;

  constructor(
    public auth: AuthenticationService,
    public appService: AppService,
    public translate: TranslateService,
    public profileService: UserProfileService,
    private AppConfig: JsonAppConfigService,
    private _adapter: DateAdapter<any>,
    private _dt_adapter: NgxMatDateAdapter<any>,
    private router: Router
  ) {
    // подписываюсь на получение актуальных данных авторизованного в кабинете пользователя
    this.auth.currentUser.subscribe(user => {
      // обновляю данные домена в заголовке
      this.getDomainTestData();
    });
  }

  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Launch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];
  logout() {
    this.auth.logout(false);
  }

  changeSelectedCountryCode(value: string): void {
    // именно в этом порядке!!
    moment.locale(value);
    this._adapter.setLocale(value);
    this._dt_adapter.setLocale(value);
    this.translate.use(value);
    if (localStorage) localStorage.setItem('ifaceLang', value);

    this.profileService.get(null, {__ignore__: {10002: true}}).subscribe(data => {
      if (data) {
        data.locale = (value == 'ru') ? 'ru_RU' : 'en_US';
        this.profileService.set(data).subscribe();
      }
    });

  }

  ngOnInit(){
    this.window = window;
  }

  checkReturnProvider() {
    const role = this.auth.currentUserValue?.role_type;
    const domain_id = this.auth.currentUserValue?.domain_id;
    return (Object.values(ROLE_TYPE_PROVIDER).includes(role) && domain_id);
  }

  getDomainTestData() {
    return this.auth.currentUserValue?.domain_test_until ? moment(this.auth.currentUserValue.domain_test_until).format('L') : '';
  }

  redirectTo(uri:string){
    console.log('test');
    this.auth.setDomain(null, null, null, null);
    this.auth.setLicense([]);
    window.location.href = window.location.origin + uri;
    // this.router.navigate([uri]).then(()=>
    //   window.location.reload())
  }
}
