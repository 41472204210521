import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../_helpers/base.service';
import {AuthenticationService} from '../auth/authentication.service';
import {Tier} from './tier';
import {JsonAppConfigService} from '../config/json-app-config.service';


@Injectable({
  providedIn: 'root'
})

export class EapiAgentService extends BaseService<Tier> {
  protected requestPathKey: string = 'sourceUrl';
  protected extraPath: string = 'eapi/';
  private api: HttpClient;
  private notify: NotifyService;

  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, AppConfig: JsonAppConfigService) {
    super(Tier.className(), http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.notify = notifyService;
  }
}
