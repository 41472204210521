import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {FormControl, FormGroup} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatChipList} from '@angular/material/chips';
import {ENTER} from '@angular/cdk/keycodes';
import {MatTableDataSource} from '@angular/material/table';
import {choice} from "@rxweb/reactive-form-validators";
import {Options} from "../reports/options";
import {CC_CALL_STATUS} from "./constant";


export class RxFormHelpers {
  public static readonly ipV4: RegExp = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  public static readonly ipV4OrGlobalVars: RegExp = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^\$\$?\{[a-z0-9_-]{1,}}$/;
  public static readonly ipV4Cidr: RegExp = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\/(?:1?[0-9]|2[0-9]|3[0-2]))$/;
  public static readonly ipV4OrCidr: RegExp = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/(:?[1-9]|1[0-9]|2[0-9]|3[0-2]))?$/;
  public static readonly url: RegExp = /^((?:https?:\/\/)?(?:(?:(?:(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|((\*\.)?([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,63}?))(?::\d{2,5})?(?:(?:(?:(?:\/[0-9a-zA-Z\-\.]{1,}){1,})?\/?)?\/?)?)$/;
  public static readonly domain: RegExp = /^(?:(?:[a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,63}?)$/; // например: pbx.runtel.ru
  public static readonly domainOrIPV4: RegExp = /^((?:(?:(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))|(?:(?:[a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,63}?)))$/;
  // tslint:disable-next-line:max-line-length
  public ipV6: RegExp = /^((?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(:[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(:[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(:[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(:[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,6}|:)|(?::((?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(%[0-9a-zA-Z]{1,})?$/;
  public static readonly email: RegExp = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  public static readonly phone: RegExp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;
  public separatorKeysCodes = [ENTER];

  public filterOptions: Options[] = [
    {id: 'direction_list', key: 'direction_list',  select: [
        { id: 0, name: 'DPE.STARTOUTBOUND' },
        { id: 1, name: 'DPE.STARTINBOUND' },
        { id: 2, name: 'DPE.STARTINTERNAL' }
      ],
      name: 'DIRECTION', type: 'multiple', disabled: false, visible: false },
    {id: 'status_list', key: 'status_list', select: [
        { id: '0', name: 'CALL_STATUS.ANSWERED' },
        { id: '1', name: 'CALL_STATUS.NOT_ANSWERED' },
        { id: '2', name: 'CALL_STATUS.BUSY' },
        { id: '3', name: 'CALL_STATUS.FORWARD' },
        { id: '4', name: 'CALL_STATUS.TRANSFER' }
      ],
      name: 'STATUS', type: 'multiple', disabled: false, visible: false },
    {id: 'src_list', key: 'src_list', select: [],
      name: 'SRC', type: 'multiple_input', disabled: false, visible: false },
    {id: 'uniqueid_list', key: 'uniqueid_list', select: [],
      name: 'DOMAINCRMSETTING.EVENT_FIELD_CALL_ID', type: 'multiple_input', disabled: false, visible: false },
    {id: 'dst_list', key: 'dst_list', select: [],
      name: 'DST', type: 'multiple_input', disabled: false, visible: false },
    {id: 'duration', key: 'duration', select: [], name: 'DURATION', type: 'input', disabled: false, visible: false },
    {id: 'billsec', key: 'billsec', select: [], name: 'BILLSEC', type: 'input', disabled: false, visible: false },
    {id: 'queue_quality', key: 'queue_quality', select: [], name: 'CALL_EVALUATION', type: 'input', disabled: false, visible: false },

    {id: 'min_quality', key: 'min_quality', select: [], name: 'MIN_QUALITY',
      options: {min: 1, max: 20, value: 1}, type: 'number', disabled: false, visible: false },
    {id: 'max_quality', key: 'max_quality', select: [], name: 'MAX_QUALITY',
      options: {min: 1, max: 20, value: 10}, type: 'number', disabled: false, visible: false },

    {id: 'sl_time', key: 'sl_time', select: [], name: 'SL_TIME',
      options: {min: 10, max: 300, value: 20}, type: 'number', disabled: false, visible: false },


    {id: 'pbx_user_list', key: 'pbx_user_list', select: [], name: 'USERS', type: 'search', disabled: false, visible: false },
    {id: 'project_list', key: 'project_list', select: [], name: 'DOMAIN_PROJECTS', type: 'selector', disabled: false, visible: false },
    {id: 'org_list', key: 'org_list', select: [], name: 'DOMAINORGUNITS', type: 'selector', disabled: false, visible: false },
    {id: 'phrase_list', key: 'phrase_list', select: [], name: 'PHRASES_CHIP', type: 'selector', disabled: false, visible: false },
    {id: 'group_by', key: 'group_by',
      select: [
        {id: 'hour', name: 'GROUP_BY.HOUR'},
        {id: 'day', name: 'GROUP_BY.DAY'},
        {id: 'month', name: 'GROUP_BY.MONTH'},
      ],
      name: 'GROUP_BY', type: 'select', disabled: false, visible: false },
    {id: 'seconds_to_time', key: 'seconds_to_time',
      select: [
        {id: true, name: 'TIME_FORMAT.HMS'},
        {id: false, name: 'TIME_FORMAT.SECONDS'},
      ],
      name: 'TIME_FORMAT', type: 'select', disabled: false, visible: false },
    {id: 'gw_number_list', key: 'gw_number_list', select: [], name: 'AONS', type: 'selector', disabled: false, visible: false },
    {id: 'gw_name_list', key: 'gw_name_list', select: [], name: 'GATEWAYS', type: 'selector', disabled: false, visible: false },
    {id: 'phrase_group_list', key: 'phrase_group_list', select: [], name: 'SEARCH_PHRASE', type: 'selector', disabled: false, visible: false },
    {id: 'recall_list', key: 'recall_list', select: [
        { id: "", name: 'RECALL_NONE' },
        { id: 0, name: 'RECALL_0' },
        { id: 1, name: 'RECALL_1' },
        { id: 2, name: 'RECALL_2' },
      ], name: 'RECALLS', type: 'multiple', disabled: false, visible: false },

    {id: 'queue_list', key: 'queue_list', select: [], name: 'DPE.QUEUE', type: 'selector', disabled: false, visible: false },
    {id: 'group_list', key: 'group_list', select: [], name: 'GROUP', type: 'selector', disabled: false, visible: false },
    {id: 'queue_dial_status_list', key: 'queue_dial_status_list',
      select: CC_CALL_STATUS,
      name: 'REPORT.BY_DIAL_STATUS', type: 'multiple', disabled: false, visible: false },
    {id: 'hangup_initiator', key: 'hangup_initiator',  select: [
        { id: 0, name: 'DOMAIN_CDR_HANGUP_INITIATOR_UNKNOWN' },
        { id: 1, name: 'DOMAIN_CDR_HANGUP_INITIATOR_SRC' },
        { id: 2, name: 'DOMAIN_CDR_HANGUP_INITIATOR_DST' },
        { id: 3, name: 'DOMAIN_CDR_HANGUP_INITIATOR_SRC_REFUSED' }
      ],
      name: 'DOMAIN_CDR_HANGUP_INITIATOR', type: 'multiple', disabled: false, visible: false },
  ];

  public filterModel = {
    direction_list: new FormControl({value: [], disabled: false}),
    status_list: new FormControl({value: [], disabled: false}),
    src_list: new FormControl({value: [], disabled: false}),
    dst_list: new FormControl({value: [], disabled: false}),
    uniqueid_list: new FormControl({value: [], disabled: false}),
    gw_number_list: new FormControl({value: [], disabled: false}),
    gw_name_list: new FormControl({value: [], disabled: false}),
    phrase_group_list: new FormControl({value: [], disabled: false}),
    recall_list: new FormControl({value: [], disabled: false}),
    duration: new FormControl({value: '', disabled: false}),
    billsec: new FormControl({value: '', disabled: false}),
    project_list: new FormControl({value: [], disabled: false}),
    group_list: new FormControl({value: [], disabled: false}),
    pbx_user_list: new FormControl({value: [], disabled: false}),
    queue_quality: new FormControl({value: '', disabled: false}),
    queue_list: new FormControl({value: [], disabled: false}),
    queue_dial_status_list: new FormControl({value: [], disabled: false}),
    min_quality: new FormControl({value: 1, disabled: false}),
    max_quality: new FormControl({value: 10, disabled: false}),
    sl_time: new FormControl({value: 20, disabled: false}),
    group_by: new FormControl({value: 'day', disabled: false}),
    seconds_to_time: new FormControl({value: true, disabled: false}),
    hangup_initiator: new FormControl({value: '', disabled: false}),
    org_list: new FormControl({value: [], disabled: false}),
    phrase_list: new FormControl({value: [], disabled: false}),
  };

  public visibleFilter = {
    project_list: new FormControl({value: false, disabled: true}),
    group_list: new FormControl({value: false, disabled: true}),
    gw_number_list: new FormControl({value: false, disabled: true}),
    gw_name_list: new FormControl({value: false, disabled: true}),
    phrase_group_list: new FormControl({value: false, disabled: true}),
    recall_list: new FormControl({value: false, disabled: true}),
    src_list: new FormControl({value: false, disabled: true}),
    dst_list: new FormControl({value: false, disabled: true}),
    uniqueid_list: new FormControl({value: false, disabled: true}),
    region_list: new FormControl({value: false, disabled: true}), // TODO:
    geo_type_list: new FormControl({value: false, disabled: true}), // TODO:
    direction_list: new FormControl({value: false, disabled: true}),
    status_list: new FormControl({value: false, disabled: true}),
    duration: new FormControl({value: false, disabled: true}),
    billsec: new FormControl({value: false, disabled: true}),
    queue_quality: new FormControl({value: false, disabled: true}),
    pbx_user_list: new FormControl({value: false, disabled: true}),
    src_or_dst_list: new FormControl({value: false, disabled: true}), // TODO:
    queue_list: new FormControl({value: false, disabled: true}),
    queue_dial_status_list: new FormControl({value: false, disabled: true}),
    min_quality: new FormControl({value: false, disabled: true}),
    max_quality: new FormControl({value: false, disabled: true}),
    sl_time: new FormControl({value: false, disabled: true}),
    group_by: new FormControl({value: false, disabled: true}),
    seconds_to_time: new FormControl({value: false, disabled: true}),
    hangup_initiator: new FormControl({value: false, disabled: true}),
    org_list: new FormControl({value: false, disabled: true}),
    phrase_list: new FormControl({value: false, disabled: true}),
  };


  statusChange(form: FormGroup, event: MatSlideToggleChange, name='status') {
    if (event.checked === true) {
      form.controls[name].setValue(1);
    } else {
      form.controls[name].setValue(0);
    }
  }

  uniqueFilter(array, propertyName) {
      return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
  }

  statusCheckBoxArray(form: FormGroup, event: MatCheckboxChange) {
    if (event.checked === true) {
      form.controls['status'].setValue(1);
    } else {
      form.controls['status'].setValue(0);
    }
  }

  chipValidRequire(chipList: MatChipList): boolean {
    return (chipList.chips.length > 0);
  }

  validateIP(ip: any) {
    let val = (new RegExp(RxFormHelpers.ipV4)).test(ip);
    if (val) {
      const splitDots = ip.split('.');
      for (const ipNum of splitDots) {
        val = ipNum <= 255;
        if (!val) {
          break;
        }
      }
    }
    return val;
  }

  validateCIDR(ip: any) {
    let val = (new RegExp(RxFormHelpers.ipV4OrCidr)).test(ip);
    if (val) {
      const splitDots = ip.split('/')[0].split('.');
      for (const ipNum of splitDots) {
        val = ipNum <= 255;
        if (!val) {
          break;
        }
      }
    }
    return val;
  }

  validateICE(ip: any) {
    let val = (new RegExp(RxFormHelpers.ipV4)).test(ip);
    if (val) {
      const splitDots = ip.split('.');
      for (const ipNum of splitDots) {
        val = ipNum <= 255;
        if (!val) {
          break;
        }
      }
    }
    return val;
  }

  sizeText(text, start=0, end=100) {
    if (!text) return false;
    if (start<=text.length && text.length<=end) return true;
    return false;
  }

  findError(errors) {
    const error = 'NOTIFY.UNKNOW';
    if (Object.keys(errors).length > 0) {
      const key = Object.keys(errors)[0];
      if (key == 'required') {
        return 'ERROR.REQUIRED'
      }
      if (key == 'matDatetimePickerMin') {
        return 'NOTIFY.DATE_CREATER_THAN'
      }
      if ('message' in errors[key]) {
        return errors[key]['message'];
      }
    }
    return error;
  }

  findErrorArgs(errors) {
    for (const er of Object.keys(errors)) {
      if (Object.keys(errors).length > 0) {
        const key = Object.keys(errors)[0];
        if (errors[key] && errors[key]['refValues']?.length === 1) {
          return {
            arg1: errors[key]['refValues'][0]
          };
        }
        if (errors[key] && errors[key]['refValues']?.length === 2) {
          return {
            value: errors[key]['refValues'][0],
            arg1: errors[key]['refValues'][1]
          };
        }
        if (errors[key] && errors[key]['refValues']?.length === 3) {
          return {
            value: errors[key]['refValues'][0],
            arg1: errors[key]['refValues'][1],
            arg2: errors[key]['refValues'][2]
          };
        }
        if (key == 'matDatetimePickerMin') {
          return {
            arg1: errors[key].min
          }
        }
        return '';
      }
    }
    return '';
  }

  copyInputElem(input) {
    const inputElement = <HTMLInputElement>input.nativeElement;
    inputElement.select();
    document.execCommand('copy');
    inputElement.blur();
  }

  copyInputFromValue(value) {
    const inputElement = document.createElement('input');
    document.body.appendChild(inputElement);
    inputElement.value=value;
    //<HTMLInputElement>input.nativeElement;
    inputElement.select();
    document.execCommand('copy');
    inputElement.blur();
    document.body.removeChild(inputElement);
  }

  generatePassword(passwordLenght, onlyNumbers: boolean = false) {
    // Generate random password from array
    let newPassword = '';
    const library = !onlyNumbers ? 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPWRSTUVWXYZ0123456789' : '0123456789';
    for (let i = 0; i < passwordLenght; i++) {
      newPassword += library[Math.floor(Math.random() * library.length)];
    }
    return newPassword;
  }

  dataSourceSum(dataSource: MatTableDataSource<any>, name: string) {
    const res = dataSource?.data?.reduce((acc, arr) => acc + arr[name], 0) || 0;
    return Math.floor(res * 100) / 100;
  }

  dataSourceAvg(dataSource: MatTableDataSource<any>, name: string) {
    const res = dataSource?.data?.reduce((acc, arr) => acc + arr[name], 0) || 0;
    const length = dataSource?.data?.length;
    if (length>0) return Math.floor(res / length);
    return 0;
  }


  validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      c.markAsTouched(); // чтобы оторазился текст ошибки сразу, а не при нажатии на кнопку "Сохранить"
      return {
        validateArrayNotEmpty: { valid: false, message: 'ERROR.REQUIRED' }
      };
    }
    return null;
  }

  validateEmail(email) {
    return RxFormHelpers.email.test(String(email).toLowerCase());
  }

  removeChip(control, item) {
    const index = control.value.indexOf(item);
    if (index >= 0) {
      control.value.splice(index, 1);
    }
    control.updateValueAndValidity();
    control.markAllAsTouched();
  }

  removeChipByIndex(control, ind) {
    control.value.splice(ind, 1);
    control.updateValueAndValidity();
    control.markAllAsTouched();
  }

  addChip(control, event): void {
    if (event.input) {
      event.input.value = '';
    }
    let v = event.value?.trim();
    if (v) {
      control.value.push(v);
    }
    control.updateValueAndValidity();
    control.markAllAsTouched();
  }


  addMatAutoChip(control, event): void {
    if (event.input) {
      event.input.value = '';
    }
    if (event.option.value) {
      control.value.push(event.option.value);
    }
    control.updateValueAndValidity();
    control.markAllAsTouched();
  }

  filterMatAutoChoices(allChoices: string[] = [], exludeList: any[] = [], inpValue: string = null) {
    return allChoices.filter(choice => exludeList.indexOf(choice) == -1 && (!inpValue || (choice.toLowerCase().indexOf(inpValue.toLowerCase()) === 0)));
  }

  filterMatAutoChoicesAsObj(allChoices: {name: string, value: string}[] = [], exludeList: any[] = [], inpValue: string = null) {
    return allChoices.filter(choice => exludeList.indexOf(choice.name) == -1 && (!inpValue || (choice.name.toLowerCase().indexOf(inpValue.toLowerCase()) === 0) || (choice.value.toLowerCase().indexOf(inpValue.toLowerCase()) === 0)));
  }

  getFirstMatAutoChoice(allChoices: string[] = [], exludeList: any[] = [], inpValue: string = null) {
    let filtered_choices = this.filterMatAutoChoices(allChoices, exludeList, inpValue);
    return filtered_choices.length > 0 ? filtered_choices[0] : null;
  }

  validatePhone(phone) {
    return RxFormHelpers.phone.test(phone);
  }

  getKeys(obj: any = {}): Array<any> {
    return Object.keys(obj);
  }





}



