import {maxLength, prop, required} from '@rxweb/reactive-form-validators';

export class DomainProject {
  @prop() id?: number;
  @prop() domain_id: number;
  @required() @maxLength({value: 150}) name: string;
  @required() gw_number_list: number[] = [];
  @required() queue_list: number[] = [];
  @required() group_list: number[] = [];
  @required() sv_user_list: number[] = [];

  static className() {
    return 'DomainProject';
  };
}
