import {
  choice,
  digit,
  maxLength,
  maxNumber,
  minLength,
  minNumber,
  pattern,
  prop,
  propArray,
  propObject,
  required, requiredTrue
} from '@rxweb/reactive-form-validators';
import {generateGUID} from '../../../../vertojs';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {DPCScheduleLeg} from '../../../dialplans/components/schedule/dialplan.schedule';

const MESSAGE_TYPE_ROUTE = 0;
const MESSAGE_TYPE_DIALOG = 1;

export class DOCBaseMessageParams {
  @prop() name: string;
}

export class DOCBaseMessage {
  @required() model_name: string = 'DOCRMSelectDOC';
  @propObject(DOCBaseMessageParams) params: DOCBaseMessageParams;
}

export class DOCBaseRouteMessage extends DOCBaseMessage{
  @required() id: string = generateGUID();
}

export class DOCBaseDialogMessage extends DOCBaseMessage {
}

export class DOCRMWithoutLeg extends DOCBaseRouteMessage {
}

export class DOCRMOneLeg extends DOCBaseRouteMessage {
  @propObject(DOCBaseRouteMessage) leg: DOCBaseRouteMessage;
}

export class DOCRMDefaultLeg {
  @prop() label: string;
  @propObject(DOCBaseRouteMessage) leg: DOCBaseRouteMessage;
}

export class MultiLegs {
  @prop() deletingKey: DOCRMDefaultLeg;
  [key: string]: DOCRMDefaultLeg;
}

export class DOCRMMultiLegs {
  @required() id: string = generateGUID();
  @propArray(MultiLegs) legs: MultiLegs;
  @required({conditionalExpression: x => x.model_name != 'DOCRMMenu'}) @propObject(DOCRMDefaultLeg) default_leg: DOCRMDefaultLeg;
}

/* Одноногие модели */
export class DOCRMNotifyParams extends DOCBaseMessageParams {
  @required() text: string;
}
export class DOCRMNotify extends DOCRMOneLeg {
  @required() model_name: string = 'DOCRMNotify';
  @propObject(DOCRMNotifyParams) params: DOCRMNotifyParams;
}

export class DOCRMUserParams extends DOCBaseMessageParams {
  @required() agent_id: string;
}
export class DOCRMUser extends DOCRMOneLeg {
  @required() model_name: string = 'DOCRMUser';
  @propObject(DOCRMUserParams) params: DOCRMUserParams;
}

export class DOCRMQueueParams extends DOCBaseMessageParams {
  @required() queue_id: number;
  @prop() text: string;
  @digit() base_score: number = 0; // Приоритет мембера
}
export class DOCRMQueue extends DOCRMOneLeg {
  @required() model_name: string = 'DOCRMQueue';
  @propObject(DOCRMQueueParams) params: DOCRMQueueParams;
}

export class DOCRMSingleQuestionParams extends DOCBaseMessageParams {
  @required() question: string;
  @required() var_re: string;
  @required() var_name: string;
  @required() hint: string;
  @required() error: string;
  @required() @digit() @maxNumber({value: 10}) max_retry: number;

}
export class DOCRMSingleQuestion extends DOCRMOneLeg {
  @required() model_name: string = 'DOCRMSingleQuestion';
  @propObject(DOCRMSingleQuestionParams) params: DOCRMSingleQuestionParams;
}

export class DOCRMSingleQuestionAnswerParams extends DOCBaseMessageParams {
  @required() msg_id: string;
  @required() value: string;
}
export class DOCRMSingleQuestionAnswer extends DOCRMOneLeg {
  @required() model_name: string = 'DOCRMSingleQuestionAnswer';
  @propObject(DOCRMSingleQuestionAnswerParams) params: DOCRMSingleQuestionAnswerParams;
}

export class DOCRMIGEntitySearchParams extends DOCBaseMessageParams {
  @required() pm_name: string;
  @digit() field: number = 1; // IG_SEARCH_FIELD_PHONE = 1 | IG_SEARCH_FIELD_EMAIL = 2
}
export class DOCRMIGEntitySearch extends DOCRMOneLeg {
  @required() model_name: string = 'DOCRMIGEntitySearch';
  @propObject(DOCRMIGEntitySearchParams) params: DOCRMIGEntitySearchParams;
}

export class DOCRMDetailFormParams extends DOCBaseMessageParams {
  @required() form_id: number;
  @prop() long_form_id: number;
}
export class DOCRMDetailForm extends DOCRMOneLeg {
  @required() model_name: string = 'DOCRMDetailForm';
  @propObject(DOCRMDetailFormParams) params: DOCRMDetailFormParams;
}

export class DOCRMTransferParams extends DOCBaseMessageParams {
  @required() dst_type: number = 0; // DOCRM_TRANSFER_DST_CHATPLAN = 0 (перевод на маршрут)
  @required() dst_id: number;
}
export class DOCRMTransfer extends DOCRMOneLeg {
  @required() model_name: string = 'DOCRMTransfer';
  @propObject(DOCRMTransferParams) params: DOCRMTransferParams;
}

export class DOCRMDuplicateByEmailParams extends DOCBaseMessageParams {
  @required() subject_template: number;
  @required() html_template: number;
  @required() txt_template: number;
  @requiredTrue({conditionalExpression: x => x.recipient_list.length == 0, message: 'SEND_CUSTOMER_OR_RECIPIENT_LIST'}) send_customer: boolean = true;
  @choice({minLength: 1, conditionalExpression: x => !x.send_customer, message: 'SEND_CUSTOMER_OR_RECIPIENT_LIST'}) recipient_list: string[] = [];
}
export class DOCRMDuplicateByEmail extends DOCRMOneLeg {
  @required() model_name: string = 'DOCRMDuplicateByEmail';
  @propObject(DOCRMDuplicateByEmailParams) params: DOCRMDuplicateByEmailParams;
}
/* Конец одноногих моделей */

/* Многоногие модели */
export class DOCRMMenuRow {
  @required() button: string;
  @required() label: string; // для отрисовки на форме, а так в АПИ этого поля нет
}
export class DOCRMLegMenu {
  @required() button: string;
}
export class DOCRMLegMenuConditions {
  @prop() deletingKey: DOCRMLegMenu | DOCRMMenuRow;
  [key: string]: DOCRMLegMenu | DOCRMMenuRow;
}
export class DOCRMMenuParams extends DOCBaseMessageParams {
  @required() great: string;
  @required() choice_timeout: number = 0;
  @propObject(DOCRMLegMenuConditions) conditions: DOCRMLegMenuConditions;

}
export class DOCRMMenu extends DOCRMMultiLegs {
  @required() model_name: string = 'DOCRMMenu';
  @propObject(DOCRMMenuParams) params: DOCRMMenuParams;
}

export class DPEScheduleConditions {
  @prop() deletingKey: DPCScheduleLeg;
  [key: string]: DPCScheduleLeg;
}
export class DOCRMScheduleParams extends DOCBaseMessageParams {
  @propObject(DPEScheduleConditions) conditions: DPEScheduleConditions;
}
export class DOCRMSchedule extends DOCRMMultiLegs {
  @required() model_name: string = 'DOCRMSchedule';
  @propObject(DOCRMScheduleParams) params: DOCRMScheduleParams;
}

export class DOCRMExternalControlCondition {
  @prop() deletingKey: string | DOCRMMenuRow;
  [key: string]: string | DOCRMMenuRow;
}
export class DOCRMExternalControlParams extends DOCBaseMessageParams {
  @required() @pattern({expression:{onlyDigit: RxFormHelpers.url}, message: 'ERROR.URL'}) url: string;
  @required() authorization: string;
  @required() control_name: string;
  @required() params: any = {}; // любой json
  @propObject(DOCRMExternalControlCondition) conditions: DOCRMExternalControlCondition; // ключ(строка): значение(строка)
}
export class DOCRMExternalControl extends DOCRMMultiLegs {
  @required() model_name: string = 'DOCRMExternalControl';
  @propObject(DOCRMExternalControlParams) params: DOCRMExternalControlParams;
}

export class DOCRMConditionRouterRow {
  @required() model_name: string = 'DOCRMConditionRouterRow';
}
export class DOCRMConditionRouterRowCallCount extends DOCRMConditionRouterRow {
  @required() model_name: string = 'DOCRMConditionRouterRowCallCount';
  @required() max_calls: number;
  @required() period: number; // choices = [PERIOD_HOUR, PERIOD_DAY, PERIOD_WEEK, PERIOD_MONTH, PERIOD_YEAR]
  @required() status_list: number[] = []; // choices = list(PBX_CALL_DIAL_STATUS_DICT.keys()))
}
export class DOCRMConditionRouterRowQueueLen extends DOCRMConditionRouterRow {
  @required() model_name: string = 'DOCRMConditionRouterRowQueueLen';
  @required() queue_id: number;
  @required() max_len: number;
}
export class DOCRMConditionRouterRowQueueAgentAvailable extends DOCRMConditionRouterRow {
  @required() model_name: string = 'DOCRMConditionRouterRowQueueAgentAvailable';
  @required() queue_id: number;
}

export class DOCRMConditionRouterRowUserAvailable extends DOCRMConditionRouterRow {
  @required() model_name: string = 'DOCRMConditionRouterRowUserAvailable';
  @required() value: string;
}

export class DOCRMConditionRouterRowValueCompare extends DOCRMConditionRouterRow {
  @required() model_name: string = 'DOCRMConditionRouterRowValueCompare';
  @required() name: string;
  @required() type: number = 0; // VAR_TYPE_INT = 0 VAR_TYPE_STR = 1 VAR_TYPE_DECIMAL = 4 (// VAR_TYPE_LIST = 2 VAR_TYPE_DICT = 3)
  @required() value: string;
  @required() condition: number = 0; /* choices = [CONDITION_TYPE_EQUAL, CONDITION_TYPE_LIKE,
    CONDITION_TYPE_ENDSWITH, CONDITION_TYPE_STARTSWITH,
    CONDITION_TYPE_MORE, CONDITION_TYPE_MORE_OR_EQUAL, CONDITION_TYPE_LESS,
    CONDITION_TYPE_LESS_OR_EQUAL, CONDITION_TYPE_NOT_EQUAL, CONDITION_TYPE_IN,
    CONDITION_TYPE_NOT_IN
  ]*/
}
export class DOCRMConditionRouterRowQueueSL extends DOCRMConditionRouterRow {
  @required() model_name: string = 'DOCRMConditionRouterRowQueueSL';
  @required() queue_id: number;
  @required() @minNumber({value: 1}) @maxNumber({value: 100}) sl_perc: number;
  @required() @minNumber({value: 1}) @maxNumber({value: 3600}) sl_time: number;
  @required() @minNumber({value: 5}) @maxNumber({value: 60}) range_time: number;
}
export class DOCRMConditionRouterRowAgentAnswer extends DOCRMConditionRouterRow {
  @required() model_name: string = 'DOCRMConditionRouterRowAgentAnswer';
}
export class DOCRMConditionRouterCondition {
  @prop() deletingKey: DOCRMConditionRouterRow;
  [key: string]: DOCRMConditionRouterRow;
}
export class DOCRMConditionRouterParams extends DOCBaseMessageParams {
  @propObject(DOCRMConditionRouterCondition) conditions: DOCRMConditionRouterCondition;
}
export class DOCRMConditionRouter extends DOCRMMultiLegs {
  @required() model_name: string = 'DOCRMConditionRouter';
  @propObject(DOCRMConditionRouterParams) params: DOCRMConditionRouterParams;
}
/* Конец многоногих моделей */

/* Модели без элемента в маршрутизации */
export class DOCSMVoiceCallParams extends DOCBaseMessageParams {
  @required() call_uuid: string;
  @required() direction: number;
}

export class DOCSMVoiceCall extends DOCBaseMessageParams {
  @required() model_name: string = 'DOCSMVoiceCall';
  @propObject(DOCSMVoiceCallParams) params: DOCSMVoiceCallParams;
}


/* Конец моделей без элемента в маршрутизации */

export class DOCRMStart {
  @propObject(DOCBaseRouteMessage) leg: DOCBaseRouteMessage;
}

export class DOCChatPlan {
  @prop() id?: number;
  @required() domain_id: number;
  @required() @minLength({value: 2}) @maxLength({value: 250}) name: string;
  @required() @propObject(DOCRMStart) body: DOCRMStart;
  @required() @digit() status: number = 1;

  static className() {
    return 'DOCChatPlan';
  }
}
