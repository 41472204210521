import {Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, HostBinding} from '@angular/core';
import { ModalBoxService } from './modal-box.service';

@Component({
    selector: 'app-modal-box',
    templateUrl: 'modal-box.component.html',
    styleUrls: ['modal-box.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ModalBoxComponent implements OnInit, OnDestroy {
    @Input() @HostBinding('attr.id') id: string;
    @Input() hideByWrapper: boolean = true;
    @Input() parent: HTMLElement;
    private element: any;

    constructor(private modalService: ModalBoxService, private el: ElementRef) {
        this.element = el.nativeElement;
    }

    ngOnInit(): void {
        // ensure id attribute exists
        if (!this.id) {
            console.error('modal-box must have an id');
            return;
        }

        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        (this.parent || document.body).appendChild(this.element);

        // close modal-box on background click
        this.element.addEventListener('click', el => {
            if (el.target.className === 'app-modal-box' && this.hideByWrapper) {
                this.close();
            }
        });

        // add self (this modal-box instance) to the modal-box service so it's accessible from controllers
        this.modalService.add(this);
    }

    // remove self from modal-box service when component is destroyed
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    // open modal-box
    open(): void {
        this.element.style.display = 'block';
        document.body.classList.add('app-modal-box-open');
    }

    // close modal-box
    close(): void {
        this.element.style.display = 'none';
        document.body.classList.remove('app-modal-box-open');
    }
}
