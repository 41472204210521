import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService, headers} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {Observable} from 'rxjs';
import {Helper, IResponseBodyObject} from '../../../_helpers/api.helpers';
import {map, tap} from 'rxjs/operators';
import {DomainCrmSettings} from "./domain-crm-settings";

@Injectable({
  providedIn: 'root'
})

export class DomainCrmSettingsService extends BaseService<DomainCrmSettings> {
  constructor(
    http: HttpClient,
    notifyService: NotifyService,
    router: Router,
    route: ActivatedRoute,
    authenticationService: AuthenticationService,
    AppConfig: JsonAppConfigService
  ) {
    super(DomainCrmSettings.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  get(id: number, params: any = {}, action: string = 'get'): Observable<DomainCrmSettings> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue?.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, { ...params});
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers}).pipe(
      tap(res => {
        // @ts-ignore
        if (res.code != 200 && (!res.notifies.length || res.notifies[0].msg_id != 10002)) this.notifyService.checkCode(res);
      }),
      map(data => {
        return {
          ...data.body?.params,
          id: data.body?.id,
          domain_id: data.body?.domain_id,
        } as DomainCrmSettings
      })
    );
  }

  save(obj_: any, action_append: string = 'append', action_update: string = 'update'): Observable<IResponseBodyObject<DomainCrmSettings>>  {
    const id = obj_['id'];
    const domain_id = obj_['domain_id'];
    delete obj_['id'];
    delete obj_['domain_id'];

    let obj = {
      id: id,
      domain_id: domain_id,
      params: obj_
    }

    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }

    let requestBody = Helper.requestFormat(action_append, this.objectName, obj);
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty('id') && obj['id']) {
      requestBody = Helper.requestFormat(action_update, this.objectName, obj);
    }
    return this.http.post<IResponseBodyObject<DomainCrmSettings>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }
}
