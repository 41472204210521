import {Component, OnInit, ViewChild} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatExpansionPanel} from '@angular/material/expansion';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable, of as observableOf} from 'rxjs';
import {ISelect} from '../_helpers/api.helpers';
import {RxFormHelpers} from '../_helpers/form.helpers';
import {LSModel} from '../local-stream/local-stream';
import {LocalStreamService} from '../local-stream/local-stream.service';
import {LSAudioList} from '../local-stream/local-stream';
import {NotifyService} from '../_helpers/notify.service';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {AuthenticationService} from '../auth/authentication.service';
import {BaseFormComponent} from '../_helpers/base-form.component';
import {VoiceDirectory} from './voice-directory';
import {VoiceDirectoryService} from './voice-directory.service';
import {MatTableDataSource} from '@angular/material/table';
import {FileDialogComponent} from '../dialogs/modals/fileDialog/fileDialog.component';
import {FileDialogSystemComponent} from '../dialogs/modals/fileDialog/fileDialog.system.component';
import {MatDialog} from '@angular/material/dialog';
import {AudioService} from '../audio/audio.service';
import {AudioSystemService} from '../audio/audio.system.service';
import {AudioPlayerDialogComponent} from '../file-explorer/modals/audioDialog/audio-player-dialog.component';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {ConfirmComponent} from '../dialogs/modals/confirm/confirm.component';


@Component({
  selector: 'app-domain-dialplan-voice-directory',
  templateUrl: './voice-directory-form.component.html',
  styles: [
    `th.mat-header-cell.mat-column-actions, td.mat-cell.mat-column-actions {
    min-width: 125px;
    }`,
    `th.mat-header-cell:last-of-type, td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
      padding-right: 0px;
    }`
  ]
})

export class VoiceDirectoryFormComponent extends BaseFormComponent<VoiceDirectory> implements OnInit {

  is_received_data: Observable<boolean> = observableOf(false);
  lang: FormControl = new FormControl('ru');
  dataSource: MatTableDataSource<any>;
  audioIDStore = [];
  audioStore = {};
  constructor(
    public api: VoiceDirectoryService,
    fb: RxFormBuilder,
    route: ActivatedRoute,
    router: Router,
    http: HttpClient,
    notifyService: NotifyService,
    authenticationService: AuthenticationService,
    public audioService: AudioService,
    public audioSystemService: AudioSystemService,
    public dialog: MatDialog,
    private AppConfig: JsonAppConfigService
  ) {
    super(VoiceDirectory, api, fb, route, router, http, notifyService, authenticationService);
    super.navigate = 'domain-settings';
    this.api.changeObject();
    if (this.api.isDomainView()) {
      this.navigate = this.api.getDomainMode() == 0 ? '' : 'domain-settings';
    } else {
      this.navigate = 'pbx-settings';
    }

    let model = new VoiceDirectory();
    this.form = fb.formGroup(model);
    this.lang.valueChanges.subscribe(val=> {
      this.load(val);
    });

    this.api.get(null).subscribe(
      data => {
        this.audioIDStore = [];
        if (data.directory) {
          let cc = {};
          Object.keys(data.directory)?.forEach(lang=>{
            if (data.directory[lang] && Object.keys(data.directory[lang])?.length > 0) {
              cc[lang] = this.fb.group(data.directory[lang] || {});
              this.audioIDStore = [...this.audioIDStore, ...Object.values(data.directory[lang])];
            }
          });

          if (Object.keys(cc)?.length > 0) {
            this.form.addControl('directory', this.fb.group(cc));
            this.getLabel();
          }

        }
        this.form.patchValue(<VoiceDirectory>data)
        this.isLoadingResults = false;
        this.is_received_data = observableOf(true);
        this.callbackFormPatch(<VoiceDirectory>data);
        this.form.markAsPristine();

      }, resp => {
        this.isLoadingResults = false;
        this.isServerAvailable = this.notifyService.setFormErrors(resp, this.form, this.navigate) != 502;
      }
    );

  }

  load(val) {
    this.api.changeObject();
    this.dataSource = new MatTableDataSource([])
    this.api.action('get_voice_library', {lang: val}).subscribe(data => {
      Object.keys(data.body).forEach(key => this.dataSource.data = [...this.dataSource.data,
        {key: key, description: data.body[key], value: ''}
      ])
    })

  }
  ngOnInit() {

    this.rxFormHelpers = new RxFormHelpers();
    this.form.markAsPristine();
    this.load(this.lang.value);
  }

  findKey(key, field = 'name') {
   const id_st = this.form.get('directory')?.value?.[this.lang.value]?.[key];
   if (id_st && this.audioStore[id_st]) {
     return this.audioStore[id_st]?.[field] || '';
   }
   return '';
  }

  getAllAudio() {

  }

  getLabel() {


    let domain = this.authenticationService.getDomain();
    if (domain) {
      this.audioService.detailsByIds(this.audioIDStore)
        .subscribe(audio=>
          audio.forEach(
            item =>  this.audioStore[`${item.id}`] = {
              name: item.name,
              link: item.link,
              id: item.id
            }
          )
        );
    } else  {
      this.audioSystemService.detailsByIds(this.audioIDStore)
        .subscribe(audio =>
          audio.forEach(
            item => this.audioStore[`${item.id}`] = {
              name: item.name,
              link: item.link,
              id: item.id
            }
          ), error => console.log(error)
        );
    }
  }

  clearFile(key) {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {


        (<FormGroup>(<FormGroup>this.form.get('directory')).controls[this.lang.value]).removeControl(key);
        this.dataSource.data = [...this.dataSource.data];


      }
    });
  }

  selectFile(key) {
      let dialogRef;
      let domain = this.authenticationService.getDomain();
      if (domain) {
        dialogRef = this.dialog.open(FileDialogComponent, {panelClass: 'dialog-size-full', data: {domain: domain}});
      } else {
        dialogRef = this.dialog.open(FileDialogSystemComponent, {panelClass: 'dialog-size-full'});
      }

      dialogRef.afterClosed().subscribe(item => {
        if (item) {

          this.audioStore[`${item.value}`] = {
            name: item.label,
            link: item.link,
            id: item.value
          }

          this.audioIDStore.push(item.value);

          let directory = this.form.get('directory')?.value;
          let cc = {};
          if (directory) {
            if (!directory.hasOwnProperty(this.lang.value)) {
              directory[this.lang.value][key] = item.value;
            }

            Object.keys(directory).forEach(lang=>{
              if (lang == this.lang.value) { directory[lang][key] = item.value; }
              cc[lang] = this.fb.group(directory[lang]);
            });


          } else {
            cc[this.lang.value] = this.fb.group({[key]: item.value})
          }


          this.form.setControl('directory', this.fb.group(cc));
          this.dataSource.data = [...this.dataSource.data];
        }
      });
  }

  openAudioPlayerDialog(link) {
    const dialogRef = this.dialog.open(AudioPlayerDialogComponent, {data: {link: `${this.AppConfig.getValue('sourceUrl')}${link}`}});
    dialogRef.afterClosed().subscribe(result => {});
  }
}
