import { minLength, prop, propArray, propObject, required} from '@rxweb/reactive-form-validators';
import {generateGUID} from '../../../../vertojs';

// Кнопки быстрых действий в CTI-панели
export class HotKeyButtons {
  @required() id: string = generateGUID();
  @required() icon: string;
  @required() name: string;
  @required() action: number = 0; // [HOT_KEY_ACTION_DP_TRANSFER (0; Перевод по маршруту)]

  @required() value: string; // ID маршрута
  @prop() indicator: number; // [HOT_KEY_ACTION_INDICATOR_QUEUE_FREE_AGENTS (0; Индикатор на кнопке, к примеру свободных агентов в очереди)]
  @prop() indicator_val: string; // ID объекта для извлечения данных.
}

export class HotKeyCallDirectionState {
  @propArray(HotKeyButtons) inbound_answered: HotKeyButtons[]; // горячие клавиши для входящего отвеченного вызова
  @propArray(HotKeyButtons) outbound_answered: HotKeyButtons[]; // горячие клавиши для исходящего отвеченного вызова
}

export class CallHotKeyGroup {
  @propObject(HotKeyCallDirectionState) '-1': HotKeyCallDirectionState; // -1 - все группы
}

export class ChatHotKeyGroup {
  @propArray(HotKeyButtons) '-1': HotKeyButtons[] // -1 - все группы
}

export class DomainCTICallerNameValues {
  @required() source: number = 1;
  @required() path: string = '';
  @required() is_required: boolean = false;
}

export class DomainCTICallerNameVar {
  @prop() deletingKey: any;
  [key: string]: DomainCTICallerNameValues;
}

export class DomainCTICallerNameData {
  @propObject(DomainCTICallerNameVar) vars: DomainCTICallerNameVar;
  @prop() str_list: Array<string> = []; // для него заводится в форме массив и там проверки делаются, не здесь
}

export class DomainCTI {
  @required() model_name: string = DomainCTI.className();
  @required() auto_user: boolean = true;
  @required() show_dnd: boolean = true;
  @required() show_video_call: boolean = true;
  @required() show_transfer: boolean = true;
  @required() click_to_call: boolean = false; // Включаем возможность кликать по номерам телефонов
  @required() wss: string;
  @prop() show_eavesdrop: number[] = [1, 2, 3];
  @prop() agent_status_on_login: number; // статус агента при входе в панель
  @prop() du_agent_status_on_outbound_call: number; // менять статус агента при исходящем вызове на первой линии на выбранный (!перерывные статусы)
  @prop() du_agent_status_on_inbound_call: number; // менять статус агента при входящем вызове НЕ ИЗ ОЧЕРЕДИ (!перерывные статусы)
  @prop() du_agent_status_on_chat: number; // менять статус агента при принятии сообщения из чата (!перерывные статусы)
  @prop() omni_agent_status_on_call: number; // менять статус ОМНИ-агента при входящем вызове (!перерывные статусы)
  @propObject(CallHotKeyGroup) call_hk: CallHotKeyGroup; // {<group_id>: {<call_state>: [HK, ..., HK]}}; -1 - все группы -> состояние звонка -> вызов
  @propObject(ChatHotKeyGroup) chat_hk: ChatHotKeyGroup; // {<group_id>: [HK, ..., HK]}; -1 - все группы
  @prop() default_blind_transfer: number = 1; // 0 - выключить и не скрывать, 1 - включить и не скрывать, 2 - выключить и скрыть, 3 -включить и скрыть
  @prop() caller_name_search_order: string[] = ['crm', 'user', 'book', 'custom', 'caller_id_name'];
  @propObject(DomainCTICallerNameData) custom_caller_name: DomainCTICallerNameData;
  @prop() hide_action_btn: boolean = false; // скрытие кнопки действий (справа от кнопки номером)

  static className() {
    return 'DomainCTI';
  }
}
