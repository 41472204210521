import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject, SubscriptionLike, Observer, interval} from 'rxjs';
import {share, distinctUntilChanged, takeWhile, filter, startWith} from 'rxjs/operators';
import {getUUID4} from './base.function';
import {ROLE_TYPE_PROVIDER} from './constant';
import {NotifyService} from './notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {WebSocketSubject, WebSocketSubjectConfig} from 'rxjs/webSocket';
import {SocketService} from "./socket.service";

@Injectable()
export class BaseWsService extends SocketService implements OnDestroy {

  constructor(public channels: any[] = [], public http: HttpClient, public notifyService: NotifyService,
              public router: Router, public route: ActivatedRoute, public authenticationService: AuthenticationService,
              public translate: TranslateService, public AppConfig: JsonAppConfigService) {

    super(channels, http, notifyService, router, route, authenticationService, translate, AppConfig)
    this.config['url'] = this.AppConfig.getValue('wsUrl')
    this.setConfig(this.config)

    // подписываюсь на получение актуальных данных авторизованного в кабинете пользователя
    this.authenticationService.currentUser.subscribe(user => {
      // подключение основного сокета, если есть пользователь и сокет еще не поднят
      if (user && user.user_id && user.user_name && user.user_email && this.readyState == 0 && !this.websocket$) this.connect();
    });
  }

  ngOnDestroy() {
    // отписываюсь от сообщений в сокете
    for (let channel in this.channels) {
      console.log('отписываюсь от сообщений в сокете:', channel);
      this.sendMessage({
        obj: 'WebSocketMember',
        action: 'unsubscribe',
        params: {
          name: channel
        }
      });
    }
    this.channels = [];
    super.ngOnDestroy()
  }

  protected subscribe_on_connect(msg) {
    if (msg.code === 407 || msg.code === 401) { // токена нет или он устарел
      ///this.notifyService.messageByCode(msg.code);
      // remove user from local storage to log user out
      this.authenticationService.is_token_alive().subscribe(() => {});
      return false;
    }
    switch (msg.action) {
      case 'ping':
        // Если пинг не прилетит, то будет переподключение сокета
        if (this.pingTimeout) clearTimeout(this.pingTimeout);
        this.pingTimeout = setTimeout(() => {
          // в случае, если установлен таймер на переподключение к сокету, то,
          // не дожидаясь таймера, вызываю reconnect
          this.pingOut = true;
          this.clearReconnectTimers();
          console.log('%cпереподключение по пингу #' + this.pingTimeout, 'color: darkorange; font-size: 14px;');
          this.reconnect();
        }, this.pingInterval);
        this.wsMessages$.next(msg);
        break;
      case 'connect': // при открытии сокета приходит сообщение о подключении, в ответ на которое нужно отправить запрос на авторизацию
        if (msg.code == 200)
          this.sendMessage({
            obj: 'WebSocketMember',
            action: 'auth',
            params: {
              authorization: `Bearer ${this.authenticationService.currentUserValue?.token}`
            }
          });
        else this.notifyService.message('ERROR.WS.CONNECTION_FAILED');
        break;
      case 'auth': // ответ на запрос авторизации
        if (msg.code == 200) {
          // отправляю ID домена в сессию, если пользователь является провайдером (суперпользователем)
          if (this.authenticationService.isRoleProvider() && this.authenticationService.currentUserValue?.domain_id != null)
            this.sendMessage({
              action: 'set_domain',
              obj: 'WebSocketMember',
              params: {
                id: this.authenticationService.currentUserValue?.domain_id
              }
            });
          else {
            this.readyState = 1;
            if (this.reconnection$) {
              console.log('%cудаляю переподключение', 'color: darkorange; font-size: 14px;', new Date());
              this.reconnection$ = null;
            }
            for (let channel in this.channels) {
              this.sendMessage({
                obj: 'WebSocketMember',
                action: 'subscribe',
                params: {
                  name: channel,
                  ...this.channels[channel]
                }
              });
            }

            // правляю подписчикам уведомление о том, что пройдена авторизация и они могут отправлять сообщения на подписки
            this.wsMessages$.next({obj: 'WebSocketMember', action: 'auth'});
          }
        } else this.notifyService.message('ERROR.WS.AUTH_ERROR');
        break;
      case 'set_domain': // подписываюсь на каналы, переданные в channels (после установки ID домена)
        if (msg.code == 200) {
          this.readyState = 1;
          if (this.reconnection$) {
            console.log('%cудаляю переподключение', 'color: darkorange; font-size: 14px;', new Date());
            this.reconnection$ = null;
          }

          for (let channel in this.channels) {
            this.sendMessage({
              obj: 'WebSocketMember',
              action: 'subscribe',
              params: {
                name: channel,
                ...this.channels[channel]
              }
            });
          }

          // отправляю подписчикам уведомление о том, что пройдена авторизация и они могут отправлять сообщения на подписки
          this.wsMessages$.next({obj: 'WebSocketMember', action: 'auth'});
        } else this.notifyService.message('ERROR.WS.SET_DOMAIN_ERROR');
        break;
      default:
        this.wsMessages$.next(msg);
    }
  }

  protected subscribe_on_reconnect() {
    if (!this.websocket$ || !this.isConnected) {
      if (this.authenticationService.currentUserValue) {
        this.clearReconnectTimers();
        // переподключение к сокету через минуту
        console.log('%cустанавливаю таймер на минуту на переподключение', 'background-color:orange;');
        this.reconnectionTimer = setTimeout(() => {
          this.clearReconnectTimers();
          console.log('%cпереподключение по таймеру', 'background-color:orange;');
          this.reconnect();
        }, this.reconnectionInterval);
        this.reconnectionTimeTimer = setInterval(() => this.secBeforeReconnect -= 1, 1000);
        // console.log('таймер на минуту: ', this.reconnectionTimer);
      } else {
        this.notifyService.auth.logout();
      }
    }
  }

  subscribe(channels: {name: string, params?: any}[] = []) {
    console.log(`${channels[0].name}":${this.readyState}`)
    if (this.readyState == 1 && channels.length > 0) {
      for (let ch of channels) {
        this.sendMessage({
          obj: 'WebSocketMember',
          action: 'subscribe',
          params: {
            name: ch.name,
            ...(ch.params || {})
          }
        });
      }
    }
  }

  unsubscribe(channels: string[] = []) {
    if (this.readyState == 1) {
      for (let ch of channels) {
        this.sendMessage({
          obj: 'WebSocketMember',
          action: 'unsubscribe',
          params: {
            name: ch
          }
        });
      }
    }
  }
}
