import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {NotifyService} from '../../../_helpers/notify.service';
import {FormControl, FormGroup} from '@angular/forms';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {PERIOD_LOCKED_LIST} from '../../../_helpers/constant'


@Component({
  selector: 'app-cron-dialog',
  templateUrl: './cronDialog.component.html',
  styleUrls: ['./cronDialog.component.css']
})

export class CronDialogComponent implements OnInit {
  public form: FormGroup = new FormGroup({
    cron: new FormControl('* * * * *', [
      RxwebValidators.required({message:'ERROR.REQUIRED'})
    ])
  });
  public rxFormHelpers = new RxFormHelpers();

  constructor(public dialogRef: MatDialogRef<CronDialogComponent>, public notifyService: NotifyService,
              @Inject(MAT_DIALOG_DATA) public data
              ) {
    this.form.patchValue({cron: data.cron})

  }

  ngOnInit(): void {
  }

  save(form: FormGroup) {
    form.updateValueAndValidity();
    form.markAllAsTouched();
    if (form.valid) {
      this.dialogRef.close(form.value.cron);
    }

  }

  close() {
    this.dialogRef.close();
  }
}
