import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Helper, IResponseListObject} from '../../../_helpers/api.helpers';
import {NotifyService} from '../../../_helpers/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {AppService} from '../../../app.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DomainExtension} from '../marketplace';
import {ActivatedRoute, Router} from '@angular/router';

const headers = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})
export class DEPS4BService {

  constructor(
    public http: HttpClient,
    public notifyService: NotifyService,
    public authenticationService: AuthenticationService,
    public translate: TranslateService,
    public router: Router, public route: ActivatedRoute,
    public appService: AppService,
    public AppConfig: JsonAppConfigService
  ) {}

  changeSkypeChannel(users: string[]) {
    this.appService.subscribe( [{name: 'mss4b|user_state', params: {params: users}}]);
  }

  mss4b_create_application(params: any = {}): Observable<any> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    params['dep_name'] = 'DEPMicrosoftSkypeForBusiness';
    const requestBody = Helper.requestFormat('mss4b_create_application', DomainExtension.className(), params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res) => {
          this.notifyService.checkCode(res);
        })
      );
  }
  getSkypeIcon(number) {

    // Invalid(-1),
    // None(0) – Do not use this enumerator.This flag indicates that the cotact state is unspecified.,
    // Free(3500) – A flag indicating that the contact is available,
    // FreeIdle(5000) – Contact is free but inactive,
    // Busy(6500) – A flag indicating that the contact is busy and inactive,
    // BusyIdle(7500) – Contact is busy but inactive,
    // DoNotDisturb(9500) – A flag indicating that the contact does not want to be disturbed,
    // TemporarilyAway (12500) – A flag indicating that the contact is temporarily away,
    // Away(15500) – A flag indicating that the contact is away,
    // Offline(18500) – A flag indicating that the contact is signed out.

    switch (number) {
      case 3500:
        return ['done', '#4dd964'];
      case 6500:
      case 7500:
        return [null, '#f44336'];
      case 9500:
        return ['remove', '#f44336'];
      case 12500:
      case 5000:
      case 15500:
        return ['schedule', '#ffba00'];
      case 18500:
        return ['schedule', '#afacac'];
      default:
        return [null, '#afacac' ];
    }
  }
}
