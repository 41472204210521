import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {BehaviorSubject, of as observableOf} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {catchError, tap} from 'rxjs/operators';
import {AppService} from '../../../../../app.service';
import {DesktopKeptService} from '../../desktop-kept.service';
import {MatTableDataSource} from '@angular/material/table';
import {NotifyService} from '../../../../../_helpers/notify.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {DesktopColumns} from '../../../../_common/desktop-columns';
import {CTIService} from "../../../../../cti-panel/cti-panel.service";

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-send-mail-dialog',
  template: `
    <h1 mat-dialog-title>{{'DESKTOP.SEND_MAIL'|translate}}<span fxFlex></span>
      <mat-icon color="primary" (click)="dialogSendMail.close()" style="cursor: pointer">close</mat-icon>
    </h1>
    <mat-dialog-content>
      <div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100" class="mat-card-top chat-app">
        <div class="example-container">
          <div class="example-loading-shade h-10">
            <mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate" color="primary"></mat-progress-bar>
            <div class="example-rate-limit-reached text-warning" *ngIf="isRateLimitReached"
                 [translate]="'NOTIFY.502'"></div>
          </div>
          <div class="ps table-container" [perfectScrollbar]="config">
            <table mat-table [dataSource]="dataSource" class="table" matSort matSortActive="id"
                   matTableResponsive
                   matSortDisableClear matSortDirection="asc">
              <ng-container matColumnDef="dial_status">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                  <mat-icon *ngIf="row.dial_status === 1 && row.direction == 0" class="red"
                            [matTooltip]="'CALL_STATUS.NOT_ANSWERED'|translate">call_missed_outgoing</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 1 && row.direction == 1" class="red"
                            [matTooltip]="'CALL_STATUS.NOT_ANSWERED'|translate">phone_missed</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 1 && row.direction == 2" class="red"
                            [matTooltip]="'CALL_STATUS.NOT_ANSWERED'|translate">swap_horiz</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 0 && row.direction == 0" class="green"
                            [matTooltip]="'CALL_STATUS.ANSWERED'|translate">phone_forwarded</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 0 && row.direction == 1" class="green"
                            [matTooltip]="'CALL_STATUS.ANSWERED'|translate">phone_callback</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 0 && row.direction == 2" class="green"
                            [matTooltip]="'CALL_STATUS.ANSWERED'|translate">swap_horiz</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 2" class="red"
                            [matTooltip]="'CALL_STATUS.BUSY'|translate">hourglass_bottom</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 3" class="yellow"
                            [matTooltip]="'CALL_STATUS.FORWARD'|translate">forward</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 4" class="yellow"
                            [matTooltip]="'CALL_STATUS.TRANSFER'|translate">forward</mat-icon>

                  <mat-icon *ngIf="row.dial_status === 'ringing'" class="yellow"
                            [matTooltip]="'CALL_STATUS.RINGING'|translate">ring_volume</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 'attached'" class="yellow"
                            [matTooltip]="'CALL_STATUS.ATTACHED'|translate">ring_volume</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 'ended'" class="yellow"
                            [matTooltip]="'CALL_STATUS.ENDED'|translate">call_end</mat-icon>
                  <mat-icon *ngIf="row.dial_status === 'active'" class="yellow"
                            [matTooltip]="'CALL_STATUS.ACTIVE'|translate">support_agent</mat-icon>

                </td>
              </ng-container>

              <ng-container *ngFor="let column of columns">
                <ng-container matColumnDef="{{column.id}}"
                              *ngIf="!['dial_status'].includes(column.id)">
                  <th mat-header-cell *matHeaderCellDef>{{ (column.name || '') |translate }}</th>
                  <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="['dt', 't_ctl_a_dt'].includes(column.id) else simple">
                      {{row[column.id] | momentDateTime : translate['currentLang'] : true}}
                    </ng-container>
                    <ng-template #simple>
                      {{row[column.id] || ''}}
                    </ng-template>
                  </td>
                </ng-container>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedRowIndex=highlight(row)"
              style="cursor: pointer;" [class.blinker]="row.blinker" [class.highlightTable]="selectedRowIndex == highlight(row)"></tr>

            </table>
          </div>
        </div>
      </div>
      <div fxFlex="100" class="m-t-10">
        <mat-form-field class="mr-1 ml-1" fxFlex>
          <mat-label>{{'COMMENT'|translate}}</mat-label>
          <textarea matInput #myInput
                    [(ngModel)]="msg"></textarea>
          <mat-hint> <cite>* {{'ALERT.ENTER_SHIFT'|translate}}</cite></mat-hint>


          <!--        <input matInput [(ngModel)]="msg" [placeholder]="'COMMENT'|translate" #myInput (keyup.enter)="OnAddMsg()">-->
        </mat-form-field>
      </div>

    </mat-dialog-content>

  <mat-dialog-actions class="chat-footer">
      <button mat-raised-button color="primary" (click)="OnAddMsg()" [disabled]="!msg || !selectedRowIndex">
          {{'SEND_MAIL' | translate}}
      </button>
  </mat-dialog-actions>

  `,
  styleUrls: ['./send-mail-dialog.scss'],
  styles: [
  ]
})

export class SendMailDialogComponent implements OnDestroy, OnInit {

  @ViewChild('myInput', { static: true }) myInput: ElementRef;
  msg: string;
  displayedColumns: string[] = [];
  displayedColFilter: string[] = [];
  activeCalls = [];
  selectedRowIndex:any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public config: PerfectScrollbarConfigInterface = {
    minScrollbarLength: 25,
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
    wheelSpeed: 2,
    wheelPropagation: true
  };
  public columns = [
    new DesktopColumns('dial_status', 'CH_DIAL_STATUS', true),
    new DesktopColumns('dt', 'CH_DT', true),
    new DesktopColumns('src', 'CH_SRC', true, 'field', 'input'),
    new DesktopColumns('user_src', 'CH_USER_SRC', true),
    new DesktopColumns('dst', 'CH_DST', true, 'field', 'input'),
    new DesktopColumns('user_dst', 'CH_USER_DST', true),
    // new DesktopColumns('t_ctl_a_dt', 'CH_TRANSFER_DT', true),
    // new DesktopColumns('transferred_number', 'CH_TRANSFER', true),
    // new DesktopColumns('user_trans', 'CH_USER_TRANS', true),
    //new CheckboxItem('direction', 'CH_DIRECTION', true),
  ];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  extParams = {

  };
  empFilters = [];
  filter = {};

  constructor(
    public dialogSendMail: MatDialogRef<SendMailDialogComponent>,
    public appService: AppService,
    public CTI: CTIService,
    public api: DesktopKeptService,
    public notifyService: NotifyService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.displayedColumns = this.columns.filter(item=>item.checked).map(item=>item.id);
    this.CTI.ctiCalls$.subscribe(
      calls => {
        this.activeCalls = calls.map(item=>
          {
            return {
              dial_status: item.status,
              id: item.call_id,
              dt: item.dt_start,
              src: item.caller_id_number,
              user_src: item.caller_id_name,
              dst: item.callee_id_number,
              user_dst: item.callee_id_name,
              direction: item.direction,
            }
          }
        );
        const current_id = this.activeCalls.map(item=>item.id);
        this.dataSource.data = [...this.activeCalls, ...this.dataSource.data.filter(item=>!current_id.includes(item.id))];
      }
    )
  }

  ngOnInit() {
    this.filter = {filter: {field_list: [
          {
            field: 'dst',
            condition_type: 0, // CONDITION_TYPE_EQUAL = 0,CONDITION_TYPE_STARTSWITH = 1,
                               // CONDITION_TYPE_ENDSWITH = 2,CONDITION_TYPE_LIKE = 3
            value: this.CTI.ctiUser$.value
          },
          {
            field: 'src',
            condition_type: 0,
            value: this.CTI.ctiUser$.value
          }
        ], type: 1}};
    this.refresh();
  }

  ngOnDestroy(): void {

  }

  highlight(row){
    return row;
  }


  isOver(): boolean {
    return window.matchMedia('(max-width: 960px)').matches;
  }


  refresh() {
    this.isLoadingResults = true;
    this.api['getCDRs']({
      limit: 30,
      sort: {dt: '-'},
      offset: 0,
      ...this.extParams,
      ...this.filter
    }).pipe(
      tap(res => {
        this.notifyService.checkCode(res, false);
      }),
      catchError(resp => {
        this.dataSource.data = [...this.activeCalls];
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = 0;

        let code = this.notifyService.setFormErrors(resp);
        return observableOf({list: [], total_count: 0, code: code});
      })
    ).subscribe(item=>{
      const current_id = this.activeCalls.map(item=>item.id);
      this.dataSource.data = [...this.activeCalls, ...item.list.filter(item=>!current_id.includes(item.id))];
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.resultsLength = item.list?.length || 0;
    });


  }

  OnAddMsg() {
      if (this.data?.id) this.api.sendMail({
        id: this.data?.id,
        comment: this.msg,
        call: this.selectedRowIndex
      }).subscribe(resp => {
        if (resp.code == 200) {
          this.dialogSendMail.close();
          this.notifyService.message('NOTIFY.200');
        }
      });

  }
}
