import {AfterViewInit, Directive, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {RxFormHelpers} from './form.helpers';
import {NotifyService} from './notify.service';
import {merge, Observable, of as observableOf} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap} from 'rxjs/operators';
import {getRoleName} from './constant';
import {ConfirmComponent} from '../dialogs/modals/confirm/confirm.component';
import {BaseService} from './base.service';

@Directive()
export class BaseListComponent<T> implements AfterViewInit {
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>([]);
  spans = [];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  extParams = {};
  public getRole = getRoleName;
  public listAction = 'list';
  //параметры для фильтрации таблицы:
  public filteredColumns: string[] = []; // список полей, по которым осуществляется поиск
  public filter: string = '';
  public showFilter: boolean = false;
  public rxFormHelpers = new RxFormHelpers();
  public filterCtrl: FormControl = new FormControl('');

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatSort) sort: MatSort;

  protected extraPath: string = '';
  constructor(
    public api: BaseService<T>,
    public translate: TranslateService,
    public notifyService: NotifyService,
    public dialog: MatDialog,
    public router: Router) {
    this.translate.onLangChange.subscribe(() => {
      this.refresh();
    });

    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state &&
      'filter' in this.router.getCurrentNavigation().extras.state &&
      this.router.getCurrentNavigation().extras.state.filter.length > 0)
    {
        this.filterCtrl.setValue(this.router.getCurrentNavigation().extras.state.filter);
        this.filter = this.router.getCurrentNavigation().extras.state.filter;
        this.showFilter = true;
    }

  }

  callbackList(data: Array<T>): Observable<T[]> {
    return observableOf(data);

  }

  cacheSpan(key, accessor, data) {
    this.spans = [];
    for (let i = 0; i < data.length;) {
      let currentValue = accessor(data[i]);
      let count = 1;

      for (let j = i + 1; j < data.length; j++) {
        if (currentValue != accessor(data[j])) {
          break;
        }
        count++;
      }

      if (!this.spans[i]) {
        this.spans[i] = {};
      }

      this.spans[i][key] = count;
      i += count;
    }

  }

  getRowSpan(col, index) {
    return this.spans[index] && this.spans[index][col];
  }

  paramsToLoad() {
    return {
      limit: 1000000,
      offset: 0,
      ...this.extParams,
      ...this.getFilter(),
      ...this.getParams(),
    }
  }
  actionToLoad(tag = 'xlsx') {
    return `${this.listAction}_${tag}`
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.refresh());
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        debounceTime(1200),
        distinctUntilChanged(),
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          return this.api.list({
            limit: this.paginator.pageSize,
            offset: this.paginator.pageIndex * this.paginator.pageSize,
            ...this.extParams,
            ...this.getFilter(),
            ...this.getParams(),

          }, this.listAction).pipe(
            tap(res => {
              this.notifyService.checkCode(res);
            }),
            catchError(resp => {
              let code = this.notifyService.setFormErrors(resp);
              return observableOf({list: [], total_count: 0, code: code});
            })
          );
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data.code == 502;
          this.resultsLength = data.total_count;
          return data.list;
        })
      ).subscribe(data => {
        this.callbackList(data).subscribe(
          item => this.setDataSource(item),
          error => this.setDataSource([])
        );
      }
    );
  }

  setDataSource(data) {
    this.dataSource.data = data;
  }

  getParams() {
    return {};
  }

  onRowClicked(id) {
    this.api.details(id, {filter: this.filterCtrl.value});
  }

  onCopy(row) {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_ACTION',
        message: 'DIALOG.COPY'
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        row.blinker = true;
        this.api.get(row.id).subscribe(
          data => {
            if (data['id']) {
              delete data['id'];
              this.api.save(data).subscribe(_ => {
                  row.blinker = false;
                  this.refresh();
                  this.notifyService.showRequestResult(data, true);
                },
                resp => {
                  row.blinker = false;
                  this.refresh();
                  this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
                }
              );
            }
          },
          resp => {
            row.blinker = false;
            this.refresh();
            this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
          });
      }
    });

  }

  cbDeleteSuccess(row: any = null) {
    this.refresh();
  }
  cbDeleteError() {
    this.refresh();
  }

  onDelete(row) {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        row.blinker = true;
        this.api.delete(row.id).subscribe(
          data => {
            this.notifyService.showRequestResult(data, true);
            this.cbDeleteSuccess(row);
          },
          resp => {
            row.blinker = false;
            this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
            this.cbDeleteError();
          });
      }
    });
  }

  openFilter(filterInput) {
    this.showFilter = !this.showFilter;
    window.setTimeout(() => filterInput.focus(), 0);
  }

  applyFilter(filterValue: string) {
    this.filter = filterValue.trim().toLowerCase(); // Remove whitespace; MatTableDataSource defaults to lowercase matches
    if (this.paginator && this.paginator.pageIndex != 0) {
      this.paginator.pageIndex = 0;
    }
    // при поиске возвращаю пользователя на первую страницу
    this.refresh();
  }

  getFilter() { // формирую фильтр, если он есть
    if (this.filter && this.filteredColumns.length > 0) {
      let filterData = {filter: {field_list: [], type: 1}}; // условие между полями; FILTER_TYPE_AND = 0, FILTER_TYPE_OR = 1
      for (let field of this.filteredColumns) {
        filterData['filter']['field_list'].push({
          field: field,
          condition_type: 3,
          // CONDITION_TYPE_EQUAL = 0, CONDITION_TYPE_STARTSWITH = 1, CONDITION_TYPE_ENDSWITH = 2, CONDITION_TYPE_LIKE = 3
          value: this.filter
        });
      }
      return filterData;
    } else {
      return {};
    }
  }

  refresh() {
    if (this.paginator) {
      this.paginator._changePageSize(this.paginator.pageSize);
    }
  }
}
