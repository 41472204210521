import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Helper, IResponseBodyObject, IResponseListObject} from '../_helpers/api.helpers';
import {UserProfile} from './user-profile';
import {iif, Observable, of as observableOf, throwError} from 'rxjs';
import {delay, map, retryWhen, tap, concatMap} from 'rxjs/operators';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {JsonAppConfigService} from '../config/json-app-config.service';
const headers = new HttpHeaders().set('Content-Type', 'application/json');
import * as CryptoJS from 'crypto-js';
import {AuthenticationService} from "../auth/authentication.service";
const MOBILE_SECRET_KEY = '1563248796320156';


@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private http: HttpClient,
    private notifyService: NotifyService,
    private router: Router,
    private route: ActivatedRoute,
    private AppConfig: JsonAppConfigService,
    private authenticationService: AuthenticationService
  ) {
  }

  get(user_id: number|null = null, params: any = {}): Observable<UserProfile> {
    let ignore = {};
    if (params.ignore) {
      ignore = {...params.ignore};
      delete params['ignore'];
    }
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('get_profile', 'User', {...(user_id ? {user_id: user_id} : {}), ...params});
    return this.http.post<IResponseBodyObject<UserProfile>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          if (res.code != 200 && (!res.notifies.length || !(ignore[res.notifies[0].msg_id]))) this.notifyService.checkCode(res);
        }),
        map(data => {
          return <UserProfile>data.body;
        })
      );
  }

  getURL() {
    return this.AppConfig.getValue('sourceUrl');
  }

  encrypt(data) {
    const key  = CryptoJS.enc.Latin1.parse(MOBILE_SECRET_KEY);
    const iv   = CryptoJS.enc.Latin1.parse(MOBILE_SECRET_KEY);
    data = CryptoJS.AES.encrypt(data, key,
      {iv:iv,mode:CryptoJS.mode.CBC,padding:CryptoJS.pad.ZeroPadding
      });
    data = data.toString();
    return data;
  }

  decrypt(data) {
    const key  = CryptoJS.enc.Latin1.parse(MOBILE_SECRET_KEY);
    const iv   = CryptoJS.enc.Latin1.parse(MOBILE_SECRET_KEY);
    data = CryptoJS.AES.decrypt(data, key, {iv:iv,padding:CryptoJS.pad.ZeroPadding});
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  }

  get_verto(): Observable<UserProfile> {
    const requestBody = Helper.requestFormat('get_profile_verto', 'User', {});
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        map(data => {
          return data.body;
        })
      );
  }

  get_verto_qrcode(): Observable<any> {
    const requestBody = Helper.requestFormat('get_verto_qrcode', 'User', {});
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        map(data => {
          return Object.assign(data.body, {
            url: this.AppConfig.getValue('sourceUrl')
          });
        })
      );
  }

  set(obj: UserProfile): Observable<IResponseBodyObject<UserProfile>>  {
    let params = {};
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('set_profile', 'User', {...obj, ...params});
    return this.http.post<IResponseBodyObject<UserProfile>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }));
  }
}
