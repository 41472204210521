import {ErrorHandler, Injectable} from '@angular/core';

@Injectable()
export class BaseErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
      console.error('%c Chunks are outdated: ', 'background: cyan;', error);
    } else console.error(error);
  }
}
