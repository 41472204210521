export const dtmfFreqs = [
  [697, 770, 852, 941],
  [1209, 1336, 1477, 1633]
];

export const dtmfChars = [
  ['1', '2', '3', 'A'],
  ['4', '5', '6', 'B'],
  ['7', '8', '9', 'C'],
  ['*', '0', '#', 'D'],
];

export default class Dtmf {
  constructor(
    public options = {
      duration: 100,
      pause: 40
    },
    public audioContext = new window.AudioContext,
    public grid = []
    ) {
    for (let i = 0; i < dtmfFreqs[0].length; i++) {
      const row = [];
      const freq1 = dtmfFreqs[0][i];
      for (let j = 0; j < dtmfFreqs[1].length; j++) {
        const freq2 = dtmfFreqs[1][j];
        const button = {
          gain1: audioContext.createGain(),
          osc1: audioContext.createOscillator(),
          osc2: audioContext.createOscillator(),

        };
        button.gain1.gain.value = 0.0;
        button.gain1.connect(audioContext.destination);
        button.osc1.type = 'sine';
        button.osc1.frequency.value = freq1;
        button.osc1.connect(button.gain1);
        button.osc2.type = 'sine';
        button.osc2.frequency.value = freq2;
        button.osc2.connect(button.gain1);
        button.osc1.start(0);
        button.osc2.start(0);
        row.push(button);
      }
      grid.push(row);
    }
    this.options = options;
    this.audioContext = audioContext;
    this.grid = grid;
  }


  play(str) {
    const seq = str.split('');
    const grid = this.grid;
    const duration = this.options.duration;
    const pause = this.options.pause;

    const nextPlay = function () {
      const char = seq.shift();
      if (!char) return;
      for (let i = 0; i < dtmfChars.length; i++) {
          for (let j = 0; j < dtmfChars[i].length; j++) {
            if (dtmfChars[i][j] === char) {
              const button = grid[i][j];
              if (button) {
                button.gain1.gain.value = 1.0;
                setTimeout(function () {
                  button.gain1.gain.value = 0.0;
                  setTimeout(nextPlay, pause);
                }, duration);
              }
            }
          }
        }

    };
    nextPlay();
  }

  destroy() {
    if (this.audioContext) {
      if (typeof this.audioContext.close === 'function') {
        this.audioContext.close();
      }
      this.audioContext = null;
    }
  }
}

export {Dtmf}
