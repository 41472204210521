import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {tap} from 'rxjs/operators';
import {Helper, IResponseBodyObject, IResponseListObject} from '../../../_helpers/api.helpers';
import {Observable} from 'rxjs';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService, headers} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {DomainDetailForm} from '../../domain-detail-form/domain-detail-form';
import {DOCDialog} from './doc-dialog';


@Injectable({
  providedIn: 'root'
})


export class DOCDialogService extends BaseService<DOCDialog> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService, protected fb: RxFormBuilder) {
    super(DOCDialog.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  list(params: any = {}, action: string = 'list'): Observable<IResponseListObject<DOCDialog>> {
    params['sort'] = {create_dt: '-'}
    return super.list(params, action);
  }

  message_list(params: any = {}) : Observable<IResponseListObject<any>> {
    params['sort'] = {create_dt: '+'}
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('list', 'DOCDialogMessage', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  customer_list(params: any = {}) : Observable<IResponseListObject<any>> {
    params['sort'] = {create_dt: '+'}
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('list', 'DOCCustomer', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  stat_list(params: any = {}) : Observable<IResponseBodyObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('get', 'DomainObjectName', params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers});
  }
}
