import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Helper, IResponseListObject, IResponseBodyObject, ISelect} from '../_helpers/api.helpers';
import {forkJoin, Observable, of as observableOf, Subject} from 'rxjs';
import {catchError, concatAll, delay, map, retryWhen, take, tap} from 'rxjs/operators';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../_helpers/base.service';
import {AuthenticationService} from '../auth/authentication.service';
import {Agent, DomainUser} from './domain-user';
import {UserService} from './user.service';
import {User} from './user';
import {JsonAppConfigService} from '../config/json-app-config.service';
const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})


export class DomainUserService extends BaseService<DomainUser> implements OnDestroy {
  private api: HttpClient;
  private notify: NotifyService;
  private user: UserService;

  private usersSource = new Subject<IResponseListObject<DomainUser>>();
  users$ = this.usersSource.asObservable();

  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              userService: UserService, AppConfig: JsonAppConfigService
              ) {
    super('DomainUser', http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.user = userService
    this.notify = notifyService;
  }

  ngOnDestroy() {
    console.log('%cusersSource complete', 'background-color: yellow');
    this.usersSource.complete();
  }

  list(params: any = {}, action: string = 'list'): Observable<IResponseListObject<DomainUser>> {
    let is_preloading = params.is_preloading;
    delete params.is_preloading;
    let copy_params = Object.assign({}, params);
    return super.list(params, action).pipe(
      map(data => {
        this.usersSource.next(Object.assign(data, {is_preloading: is_preloading, params: copy_params}));
        return data;
      })
    );
  }

  toSelectWithDetail(params: any = {}, action: string = 'list_with_detail'): Observable<DomainUser[]> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, params);
    return this.http.post<IResponseListObject<DomainUser>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => data.list as DomainUser[]
        ),
        retryWhen(errors => errors.pipe(delay(3000), take(3))),
        catchError((error) => observableOf([] as DomainUser[]))
      );
  }

  action(action, params: any = {}): Observable<IResponseBodyObject<DomainUser>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    return super.action(action, params);
  }

  agentStatusSwitch(id: number, status_id: number): Observable<DomainUser>  {
    let obj = {id: id, status_id: status_id};
    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    let requestBody = Helper.requestFormat('agent_status_switch', this.objectName, obj);

    return this.http.post<IResponseBodyObject<DomainUser>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => data.body as DomainUser),
        catchError(() => observableOf({} as DomainUser))
      );
  }

  agentEnabled(id: number, exist: boolean): Observable<Agent>  {
    let obj = {id: id};
    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    let requestBody = Helper.requestFormat((exist)?'agent_enable':'agent_disable', this.objectName, obj);

    return this.http.post<IResponseBodyObject<Agent>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => (data.body || null) as Agent)
      );
  }

  clear_list(){
    this.usersSource.next(Object.assign({list: [], total_count: 0} as IResponseListObject<DomainUser>, {clear: true}));
  }

  eavesdrop(id: number, uuid: string): Observable<any> {
    let obj = {
      "session": uuid,
      "domain_user_id": id
    }
    obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    let requestBody = Helper.requestFormat("call", "DomainEavesdrop", obj);
    return this.http.post<any>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
  }
}
