import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpProgressEvent} from '@angular/common/http';
import {Helper, IResponseListObject, IResponseBodyObject, ISelect} from '../_helpers/api.helpers';
import {iif, Observable, of as observableOf, throwError} from 'rxjs';
import {catchError, delay, map, retryWhen, tap, concatMap} from 'rxjs/operators';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../_helpers/base.service';
import {AuthenticationService} from '../auth/authentication.service';
import {Audio} from './audio';
import {FileElement} from '../file-explorer/model/element';
import {JsonAppConfigService} from '../config/json-app-config.service';
const headers = new HttpHeaders().set('Content-Type', 'application/json');

@Injectable({
  providedIn: 'root'
})


export class AudioService extends BaseService<Audio> {
  private api: HttpClient;
  private notify: NotifyService;
  private auth: AuthenticationService;
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService, AppConfig: JsonAppConfigService) {
    super('DomainAudio', http, notifyService, router, route, authenticationService, AppConfig);
    this.api = http;
    this.notify = notifyService;
    this.auth = authenticationService;
    this.changeObject();
  }

  changeObject(object = null) {
    if (object) {
      super.objectName = object;
    }
    else if (this.isDomainView()) {
      super.objectName = 'DomainAudio';
    } else if (this.isSystemView()) {
      super.objectName = 'SystemAudio';
    } else {

    }
  }



  move(params) {
    if (this.auth.currentUserValue?.domain_id) {
      params['domain_id'] = this.auth.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('move', this.objectName, params, false);
    return this.api.post<IResponseBodyObject<Audio>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notify.checkCode(res);
        }),
        map(data => {
            return <Audio> data.body;
          }
        ),
        catchError((error) => {
          return observableOf(<Audio>{});
        })
      );
  }

  rename(params) {
    if (this.auth.currentUserValue?.domain_id) {
      params['domain_id'] = this.auth.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('rename', this.objectName, params);
    return this.api.post<IResponseBodyObject<Audio>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notify.checkCode(res);
        }),
        map(data => {
            return <Audio> data.body;
          }
        )
      );
  }

  upload(form: FormData)  {
    return this.api.post<IResponseBodyObject<Audio>>(
      this.AppConfig.getValue('apiUrl'), form, {reportProgress: true, observe: 'body'}
    ).pipe(
      tap((res) => {
        this.notify.checkCode(res);
      })
    );
  }


  explorer(params: any = {}): Observable<FileElement[]> {
    if (this.auth.currentUserValue?.domain_id) {
      params['domain_id'] = this.auth.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('explorer', this.objectName, params);
    return this.api.post<IResponseListObject<FileElement>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notify.checkCode(res);
        }),
        map(data => {
            return <FileElement[]> data.list;
          }
        ),
        // retryWhen(errors => errors.pipe(delay(3000), take(3))),
        retryWhen(errors =>
          errors.pipe(// delay(3000), take(3)
            concatMap((e, i) =>
              // Executes a conditional Observable depending on the result
              // of the first argument
              iif(
                () => {return (i == 2) || e.message == "403"},
                // If the condition is true we throw the error (the last error)
                throwError(e),
                // Otherwise we pipe this back into our stream and delay the retry
                observableOf(e).pipe(delay(3000))
              ))
          ))
      );
  }

  detailsByIds(list: any[]): Observable<any> {
    const params = {list: list};
    if (this.auth.currentUserValue?.domain_id) {
      params['domain_id'] = this.auth.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('detail_by_ids', 'DomainAudio', params);
    return this.api.post<any>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res)  => {
          this.notifyService.checkCode(res);
        }),
        map(data=> data.list)
      );
  }


  calcProgressPercent(event: HttpProgressEvent) {
    return Math.round(100 * event.loaded / event.total);
  }
}
