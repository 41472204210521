import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {FormArray, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatChipList} from '@angular/material/chips';
import {MatDialog} from '@angular/material/dialog';
import {FileDialogComponent} from '../../dialogs/modals/fileDialog/fileDialog.component';
import {FileDialogSystemComponent} from '../../dialogs/modals/fileDialog/fileDialog.system.component';
import {LSModel} from '../../local-stream/local-stream';
import {Track} from '@khajegan/ngx-audio-player';
import {PlayListDialogComponent} from '../../file-explorer/modals/playListDialog/play-list-dialog.component';
import {AudioService} from '../../audio/audio.service';
import {AudioSystemService} from '../../audio/audio.system.service';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {JsonAppConfigService} from '../../config/json-app-config.service';
import {SpeechDialogComponent} from '../../dialogs/modals/speechDialog/speechDialog.component';
import {NOTIFY_TAGS, TAXI_TAGS} from '../../_helpers/constant';
import {TagDialogComponent} from '../../dialogs/modals/tagDialog/tagDialog.component';
import {TagNotifyDialogComponent} from '../../dialogs/modals/tagNotifyDialog/tagNotifyDialog.component';
import {DirectoryDialogComponent} from '../../dialogs/modals/directoryDialog/directoryDialog.component';


@Component({
  selector: 'app-audio-input',
  templateUrl: './audio-input.component.html',
  styleUrls: ['./audio-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(()=>AudioInputComponent),
      multi: true
    }
  ]
})

export class AudioInputComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @Output() focusOut: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('chipList') audioChips: MatChipList;
  editMode = false;
  value = '';
  @Input() disabled = false;
  @Input() taxi = false;
  @Input() tech = false;
  @Input() notify = false;
  @Input() cc_member = [];
  @Input() notify_option = [];
  @Input() control;
  @Input() update: string;


  @Input() placeholder = 'PLAY_LIST';
  public sysAudio = new Set();
  public audio_label = {};

  public domAudio = new Set();
  public speechAudio = new Set();

  constructor(
    public dialog: MatDialog,
    public audioService: AudioService,
    public audioSystemService: AudioSystemService,
    public fb: RxFormBuilder,
    private AppConfig: JsonAppConfigService
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
   if (changes.update && changes.update.previousValue!==changes.update.currentValue) {
     this.updateLabel();
   }
  }

  updateLabel() {
    this.control.value.forEach(item => {
      if (item.dpe_name == 'DPEAudioSystem') {
        this.sysAudio.add(item.id);
      }
      else if (item.dpe_name == 'DPEAudioDomain') {
        this.domAudio.add(item.id);
      }
      else if (item.dpe_name == 'DPEDomainVoiceKit') {
        console.log(item)
        this.audio_label[`DPEDomainVoiceKit#${item.id}_${this.hash(item.template)}`] = {
          name: item.template,
          link: '',
          template: item.template,
          id: item.id
        }
      }
      else if (item.dpe_name == 'DPEAudioTag') {
        this.audio_label[`DPEAudioTag#${item.value}`] = {
          name: item.value,
          link: '',
          id: item.value
        }
      }
      else if (item.dpe_name == 'DPEDomainTaxiTag') {
        setTimeout(() => {
          this.audio_label[`DPEDomainTaxiTag#${item.tag}`] = {
            name: TAXI_TAGS.find(tag=>tag.id == item.tag)?.name,
            link: '',
            id: item.tag
          }
        })
      }
      else if (item.dpe_name == 'DPEAudioCCMemberTag') {
        setTimeout(() => {
          this.audio_label[`DPEAudioCCMemberTag#${item.tag}`] = {
            name: this.cc_member.find(tag=>tag.id == item.tag)?.name,
            link: '',
            id: item.tag
          }
        })
      }
      else if (NOTIFY_TAGS.find(tag=> tag.id === item.dpe_name)) {
        setTimeout(() => {
          for (let note of NOTIFY_TAGS) {
            this.audio_label[`${note.id}`] = {
              name: note.name,
              link: '',
              id: note.id
            }
          }
        })
      }
    });

    if (this.domAudio.size > 0) {
      this.audioService.detailsByIds(Array.from(this.domAudio.values()))
        .subscribe(audio=>
          audio.forEach(
            item =>  this.audio_label[`DPEAudioDomain#${item.id}`] = {
              name: item.name,
              link: item.link,
              id: item.id
            }
          )
        );
    }

    if (this.speechAudio.size > 0) {
      this.audioService.detailsByIds(Array.from(this.domAudio.values()))
        .subscribe(audio=>
          audio.forEach(
            item =>  this.audio_label[`DPEAudioDomain#${item.id}`] = {
              name: item.name,
              link: item.link,
              id: item.id
            }
          )
        );
    }

    if (this.sysAudio.size > 0) {
      this.audioSystemService.detailsByIds(Array.from(this.sysAudio.values()))
        .subscribe(audio =>
          audio.forEach(
            item => this.audio_label[`DPEAudioSystem#${item.id}`] = {
              name: item.name,
              link: item.link,
              id: item.id
            }
          ), error => console.log(error)
        );
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {}

  ngAfterViewInit(): void {
    this.updateLabel();
    this.control.statusChanges.subscribe(status => {
      this.audioChips.errorState = status === 'INVALID'
    });
  }

  getColor(model) {
    return (model=='DPEAudioSystem') ? 'primary' : ((model=='DPEAudioDomain') ? 'accent' : 'secondary' );
  }

  hash(val) {
    let hash = 0, i, chr;
    if (val.length === 0) return hash;
    for (i = 0; i < val.length; i++) {
      chr = val.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  getLabel(model, item) {
    let lab = null;
    if (model=='DPEAudioTag') return item.value;
    if (model=='DPEAudioAlarm') return 'TECH_AUDIO';
    if (model=='DPEDomainVoiceKit') return item.template;
    if (item.tag) lab = this.audio_label[`${model}#${item.tag}`];
    if (!lab && item.id) lab = this.audio_label[`${model}#${item.id}`];
    if (!lab) lab = this.audio_label[`${model}`];
    if (lab && lab.name) return lab.name;
    return  null;

  }

  openSpeechDialog() {
    const dialogRef = this.dialog.open(SpeechDialogComponent, {panelClass: 'dialog-size-full'});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        (<FormArray>this.control).push(this.fb.group(<LSModel>{
          id: result.id,
          dpe_name: result.dpe_name,
          template: result.template
        }));
        this.audio_label[`${result.dpe_name}#${result.id}_${this.hash(result.template)}`] = {
          name: result.template,
          link: '',
          template: result.template,
          id: result.id
        }
      }
    });
  }

  openVoiceDialog() {
    const dialogRef = this.dialog.open(DirectoryDialogComponent, {panelClass: 'dialog-size-full'});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        (<FormArray>this.control).push(this.fb.group(result
        ));
      }
    });
  }

  openAudioDialog(domain = null) {
    let dialogRef;
    console.log('open audio');
    if (domain) {
      dialogRef = this.dialog.open(FileDialogComponent, {panelClass: 'dialog-size-full', data: {domain: domain}});
    } else {
      dialogRef = this.dialog.open(FileDialogSystemComponent, {panelClass: 'dialog-size-full'});
    }

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const dpe = (domain) ? 'DPEAudioDomain' : 'DPEAudioSystem';
        (<FormArray>this.control).push(this.fb.group(<LSModel>{id: result.value, dpe_name: dpe}));
        this.audio_label[`${dpe}#${result.value}`] = {
          name: result.label,
          link: result.link,
          id: result.value
        };
      }
    });
  }

  removeAudio(index) {
    if (index > -1) {
      (<FormArray>this.control).removeAt(index);
    }
  }

  openAudioPlayerDialog() {
    const play_list: Track[] = [];

    (this.control.value || [])
      .forEach(item =>
      {
        const key = `${item.dpe_name}#${item.id}`;
        const link = this.audio_label[key]?.link;
        const title = this.audio_label[key]?.name || '';
        if (link?.length<5) return;
        play_list.push({link: `${this.AppConfig.getValue('sourceUrl')}${link}`, title: title} as Track);

      });

    if (play_list.length) {
      const dialogRef = this.dialog.open(PlayListDialogComponent, {data: play_list});
      dialogRef.afterClosed().subscribe();
    }
  }

  blurEvent(){
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
    this.control.updateValueAndValidity();
    this.focusOut.emit();
  }

  openTaxiDialog() {
    const dialogRef = this.dialog.open(TagDialogComponent, {panelClass: 'dialog-size-full'});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        (<FormArray>this.control).push(this.fb.group(<LSModel>{
          tag: result.tag,
          dpe_name: result.dpe_name
        }));
        this.audio_label[`${result.dpe_name}#${result.tag}`] = {
          name: TAXI_TAGS.find(tag=>tag.id == result.tag)?.name,
          link: '',
          id: result.tag,
        };
      }
    });
  }


  addTech() {
        (<FormArray>this.control).push(this.fb.group(<LSModel>{
          dpe_name: 'DPEAudioAlarm'
        }));
  }

  openMemberDialog() {
    const dialogRef = this.dialog.open(TagDialogComponent,
      {data: {tag_select: this.cc_member, dpe_name: 'DPEAudioCCMemberTag'},
        panelClass: 'dialog-size-full'});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        (<FormArray>this.control).push(this.fb.group(<LSModel>{
          tag: result.tag,
          dpe_name: result.dpe_name
        }));
        this.audio_label[`${result.dpe_name}#${result.tag}`] = {
          name: this.cc_member.find(tag=>tag.id == result.tag)?.name,
          link: '',
          id: result.tag,
        };
        console.log(this.audio_label[`${result.dpe_name}#${result.tag}`]);
      }
    });
  }

  openNotifyDialog() {
    const dialogRef = this.dialog.open(TagNotifyDialogComponent,
      {data: {notify_option: this.notify_option}, panelClass: 'dialog-size-full'});
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        (<FormArray>this.control).push(this.fb.group(<LSModel>result));
        this.audio_label[`${result.dpe_name}`] = {
          name: NOTIFY_TAGS.find(tag=>tag.id == result.dpe_name)?.name,
          link: '',
          id: result.dpe_name
        }
      }
    });
  }
}
