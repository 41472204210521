import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Pipe({
  name: 'toDDHHMMSS'
})

export class ToDDHHMMSS implements PipeTransform {
  transform(s: number): string {
    if (s == -1) return '-';
    let sec_num = Math.floor(s);
    let days: string | number = Math.floor(sec_num / 24 / 3600);
    let hours: string | number = Math.floor((sec_num - (days * 24 * 3600)) / 3600);
    let minutes: string | number = Math.floor((sec_num - (days * 24 * 3600) - (hours * 3600)) / 60);
    let seconds: string | number = sec_num - (days * 24 * 3600) - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = '0'+hours;}
    if (minutes < 10) {minutes = '0'+minutes;}
    if (seconds < 10) {seconds = '0'+seconds;}
    return days+' '+hours+':'+minutes+':'+seconds;
  }
}
