import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Pipe({
  name: 'epochDate'
})

export class EpochDatePipe implements PipeTransform {
  transform(value: number | undefined, lang, arg?: number | undefined): string {
    // lang - передан для того, чтобы пайп заметил изменение языка и сам перерисовал дату
    if (value) {
      const dt = new Date();
      dt.setTime(value * 1000);
      if (arg) {
        const dt2 = new Date();
        dt2.setTime(arg * 1000);
        return `${moment(dt).format('L')} - ${moment(dt2).format('L')}`;
      }
      return moment(dt).format('L');
    }
    return '-';
  }
}
