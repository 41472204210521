import { VertoLab } from './verto-lab'

enum CallDirection {
  Inbound,
  Outbound
}

enum CallState {
  None,
  Waited,
  Sended,
}

/*

Verto RTC is an interface to WebRTC

*/

class VertoRtc extends VertoLab{
  private pc      : RTCPeerConnection
  private state     : CallState = CallState.None
  private presdp    : string
  private direction : CallDirection = CallDirection.Inbound
  private codec: string = 'audio/PCMA';
  private ice_timer : ReturnType<typeof setTimeout>
  private ice_timeout : number

  constructor(conf: RTCConfiguration, direction?: CallDirection, ice_timeout?: number, debug?: boolean, codec?: string) {
    super(debug)
    this.ice_timeout = (ice_timeout?ice_timeout:3000)
   // conf.iceCandidatePoolSize = ('iceCandidatePoolSize' in conf? conf.iceCandidatePoolSize: 1)
   //  console.log(conf);
    this.pc = new RTCPeerConnection(conf)
    this.pc.ontrack = this.onTrack.bind(this)
    this.pc.onnegotiationneeded = this.onNegotiation.bind(this)
    this.pc.onicecandidate = this.onCandidate.bind(this)
    this.pc.onicegatheringstatechange = this.onIceGatheringStateChange.bind(this)
    if(typeof direction!== 'undefined') this.direction = direction
    if (codec) this.codec = codec;
  }

  private onTrack(event: RTCTrackEvent) {
    this.emitEvent('track', event.track)
    this.emitEvent('stream', event.streams[0])
  }


  private onCandidate(event: RTCPeerConnectionIceEvent) {
  }

  private onIceGatheringStateChange(event: Event){
    if(this.pc.iceGatheringState == 'complete') {
      if(this.ice_timer) clearTimeout(this.ice_timer)
      if(this.state == CallState.Sended) return // Offer or answer is already sent
      if(this.direction)
        this.emitEvent('send-offer',this.pc?.localDescription)
      else
        this.emitEvent('send-answer',this.pc?.localDescription)
    }
  }

  private iceTimerTriggered() {
    if(this.state != CallState.Waited) return // The call is not in Waited state, do nothing
    this.state = CallState.Sended
    if(this.direction)
      this.emitEvent('send-offer',this.pc?.localDescription)
    else
      this.emitEvent('send-answer',this.pc?.localDescription)
  }

  private onNegotiation() {
    const transceiver = this.pc.getTransceivers();
    const acodecs = RTCRtpSender.getCapabilities('audio')?.codecs;
    const vcodecs = RTCRtpSender.getCapabilities('video')?.codecs;

    console.log('%ccodecs:', 'color: orange; font-weight: 700; font-size: 20px;', acodecs);
    console.log('%cvideo codecs:', 'color: orange; font-weight: 700; font-size: 20px;', vcodecs);

    const selectedCodecIndex = acodecs.findIndex(c => c.mimeType === this.codec);
    // const selectedVideoCodecIndex = vcodecs.findIndex(c => c.mimeType === 'video/VP9');

    transceiver.filter(trans=>trans.receiver.track.kind == 'audio' || trans.sender.track.kind == 'audio')
      .forEach(t => t.setCodecPreferences([acodecs[selectedCodecIndex]]));

    // transceiver.filter(trans=>trans.receiver.track.kind == 'video' || trans.sender.track.kind == 'video')
    //   .forEach(t => t.setCodecPreferences([vcodecs[selectedVideoCodecIndex]]));

    // console.log('%ctranceiver:', 'color: orange; font-weight: 700; font-size: 20px;', transceiver);

    this.pc
    .createOffer()
    .then(offer => {
      this.ice_timer = setTimeout(this.iceTimerTriggered.bind(this), this.ice_timeout)
      return this.pc.setLocalDescription(offer)
    })
    .then(() => {
      this.state = CallState.Waited
    })
    .catch(error => {})
  }

  getStats() {
    return this.pc ? this.pc.getStats() : Promise.reject()
  }

  onMedia(sdp: string) {
    this.pc.setRemoteDescription(new RTCSessionDescription({type: 'answer', sdp}))
    .then(() => {
    })
    .catch(error => {
    })
  }

  preSdp(sdp: string){
    this.presdp = sdp
  }

  addTrack(track: MediaStreamTrack) {
    this.pc.addTrack(track)
  }

  answer(tracks: Array<MediaStreamTrack>) {
    for(let t of tracks) this.pc.addTrack(t)
    this.ice_timer = setTimeout(this.iceTimerTriggered.bind(this), this.ice_timeout)
    this.pc.setRemoteDescription(new RTCSessionDescription({type: 'offer', sdp: this.presdp}))
    .then(() => {
      this.pc.createAnswer()
      .then(description => {
        this.pc.setLocalDescription(description)
        this.state = CallState.Waited
      })
    })
  }

  close() {
    if (this.pc) {
      this.pc.onconnectionstatechange = null;
      this.pc.ondatachannel = null;
      this.pc.onicecandidate = null;
      this.pc.onicecandidateerror = null;
      this.pc.oniceconnectionstatechange = null;
      this.pc.onicegatheringstatechange = null;
      this.pc.onnegotiationneeded = null;
      this.pc.onsignalingstatechange = null;
      this.pc.ontrack = null;
      this.pc.close();
      this.pc = null;
    }
  }
}

export { VertoRtc, CallDirection }
