import {NgModule} from '@angular/core';
import {ConfirmComponent} from "./dialogs/modals/confirm/confirm.component";
import {MaterialModule} from "./material.module";
import {TranslateModule} from "@ngx-translate/core";
import {PipeModule} from "./_helpers/pipe.module";
import {ChatGroupDialogComponent} from "./chat/dialogs/chat-group-dialog.component";
import {AudioFormComponent} from "./audio/audio-form.component";
import {AudioFormSystemComponent} from "./audio/audio-system-form.component";
import {CommonModule} from "@angular/common";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {FileExplorerModule} from "./file-explorer/file-explorer.module";
import {GravatarModule} from "ngx-gravatar";
import {AvatarModule} from "ngx-avatar-2";
import {FlexLayoutModule} from "@angular/flex-layout";
import {RouterLink} from "@angular/router";

@NgModule({
  declarations: [ // Компоненты, которые в обоих проектах (в основном и CTI), т.к. панель собирается в отдельный проект
    ConfirmComponent,
    ChatGroupDialogComponent,
    AudioFormComponent,
    AudioFormSystemComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    PipeModule,
    PerfectScrollbarModule,
    FileExplorerModule,
    GravatarModule,
    AvatarModule,
    FlexLayoutModule,
    RouterLink
  ],
  exports: [
    ConfirmComponent,
    ChatGroupDialogComponent,
    AudioFormComponent,
    AudioFormSystemComponent
  ]
})
export class SharedAppModule {}
