import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RxFormBuilder, RxwebValidators} from '@rxweb/reactive-form-validators';
import {NotifyService} from '../../../../../_helpers/notify.service';
import {FormControl, FormGroup} from '@angular/forms';
import {RxFormHelpers} from '../../../../../_helpers/form.helpers';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-phone-dialog',
  template: `<h1 mat-dialog-title translate>{{data.title}}</h1>

  <mat-dialog-content>
    <form *ngIf="form" [formGroup]="form" (keydown.enter)="$event.preventDefault()">
      <mat-card-content fxLayout="row wrap" class="m-b-0 m-t-0  m-r-0 m-l-0">
        <div class="p-l-0 p-r-0" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">

          <mat-form-field>
            <input required matInput placeholder="{{'EC_NAME'|translate}}" formControlName="name">
            <mat-error *ngIf="form.controls['name'].touched && form.controls['name'].errors"
                       [translate]="rxFormHelpers.findError(form.controls['name'].errors)"
                       [translateParams]="rxFormHelpers.findErrorArgs(form.controls['name'].errors)"

            ></mat-error>
          </mat-form-field>
        </div>
        <div class="p-l-0 p-r-0" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">

          <mat-form-field>
            <input required matInput placeholder="{{'EC_PHONE'|translate}}" formControlName="phone">
            <mat-error *ngIf="form.controls['phone'].touched && form.controls['phone'].errors"
                       [translate]="rxFormHelpers.findError(form.controls['phone'].errors)"
                       [translateParams]="rxFormHelpers.findErrorArgs(form.controls['phone'].errors)"

            ></mat-error>
          </mat-form-field>
        </div>
        <div class="p-l-0 p-r-0" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="{{'EC_COMMENT'|translate}}" formControlName="comment">
          </mat-form-field>
        </div>
      </mat-card-content>
    </form>

  </mat-dialog-content>

  <mat-dialog-actions align="end" class="line-top">
    <button [disabled]="!form.value.phone" mat-raised-button (click)="save(form)" color="primary">{{'SUBMIT'|translate}}</button>
    <button mat-raised-button (click)="close()">{{'CANCEL'|translate}}</button>
  </mat-dialog-actions>
  `,
  styleUrls: []
})

export class ExternalClientsDialogComponent implements OnInit {
  public readonly keptNumber: RegExp = /^\+7[0-9]{10}$/;

  public form: FormGroup = new FormGroup({
    id: new FormControl(null),
    name: new FormControl('', [
      RxwebValidators.required({message:'ERROR.REQUIRED'}),
      RxwebValidators.minLength({value: 3})
    ]),
    comment: new FormControl(''),
    phone: new FormControl('', [
      RxwebValidators.required({message:'ERROR.REQUIRED'}),
      RxwebValidators.pattern({expression: {onlyDigit: this.keptNumber}, message: 'ERROR.PHONE_FORMAT'})
    ])
  });
  public rxFormHelpers = new RxFormHelpers();


  constructor(public dialogRef: MatDialogRef<ExternalClientsDialogComponent>, public notifyService: NotifyService,
              public translate: TranslateService,
              @Inject(MAT_DIALOG_DATA) public data: any
              ) {

  }

  ngOnInit(): void {
    this.form.patchValue(this.data?.form || {});
  }

  save(form: FormGroup) {
    form.updateValueAndValidity();
    form.markAllAsTouched();
    if (form.valid) {
      this.dialogRef.close(form.value);
    }

  }

  close() {
    this.dialogRef.close(null);
  }
}
