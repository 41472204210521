import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'user'
})
@Injectable()
export class SearchUserPipe implements PipeTransform {
  transform(items: any[], value : string): any {
    if (!items) return '';
    const user =  items.find(it => it['id'] == value);
    if (user) {
      let span = document.createElement('span');
      let i = document.createElement('i');
      i.className = 'mat-icon material-icons mat-icon-no-color text-center mat-tooltip-trigger';
      i.style.marginRight='3px';
      i.style.top='6px';
      i.style.position='relative';
      if (user.id > 0 && user.proto==0) i.innerHTML = 'dialer_sip';
      else if (user.id > 0 && user.proto==1) i.innerHTML = 'phonelink';
      else if (user.id > 0 && user.proto==2) i.innerHTML = 'sim_card';
      let sp = document.createElement('span');
      sp.innerHTML = user.uid;
      sp.className = 'm-l-5 m-r-5';

      span.appendChild(i);
      span.appendChild(sp);
      if (user?.user_name && user.user_name!==user.uid) {
        let s_user = document.createElement('span');
        s_user.innerHTML = user.user_name;
        s_user.className = 'm-l-5 m-r-5';
        span.appendChild(s_user);
      }
      if (user?.user_surname && user.user_surname!==user.uid) {
        let su_user = document.createElement('span');
        su_user.innerHTML = user.user_surname;
        su_user.className = 'm-l-5 m-r-5';
        span.appendChild(su_user);
      }
      return span.outerHTML;
    }
    return value;

  }
}
