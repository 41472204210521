import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of as observableOf} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {BaseListComponent} from '../../_helpers/base-list.component';
import {getOffset} from '../../_helpers/base.function';
import {NotifyService} from '../../_helpers/notify.service';
import {DomainService} from '../../domains/domain.service';
import {DomainHideContactCall} from './domain-hide-contact-call';
import {DomainHideContactCallService} from './domain-hide-contact-call.service';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {BaseReportService} from "../../_helpers/base-report.service";
import {AudioPlayerDialogComponent} from "../../file-explorer/modals/audioDialog/audio-player-dialog.component";
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-domain-hide-contact-call',
  templateUrl: './domain-hide-contact-call.component.html',
  styleUrls: ['../../material-component/mat-table-responsive/mat-table-responsive.directive.scss'],
  styles: [`
      :host ::ng-deep .p-b-0 .mat-form-field-wrapper{
          margin-bottom: -1.25em;
      }

      :host ::ng-deep .p-b-0 .mat-form-field-infix {
          border-top-width: 0!important;
      }
  `]
})
export class DomainHideContactCallComponent extends BaseListComponent<DomainHideContactCall> {
  @Input() hc_id: number = null;
  public sortParams = {f0: '-'};
  public navigate: string = '';
  public hide_contacts = {};
  displayedColumns: string[] = ['dt', 'call_type', 'first', 'duration', 'status', 'actions'];

  // Панель фильтров
  filterForm: FormGroup = new FormGroup({
    period: new FormControl(5), // значение по-умолчанию для фильтра Период
    start_dt: new FormControl(moment.utc().startOf('month')),
    end_dt: new FormControl(moment.utc().endOf('month')),
    hidden_id: new FormControl(null)
  });
  filterPeriod = 5;
  timezone = 'Europe/Moscow';

  constructor(
    api: DomainHideContactCallService,
    public translate: TranslateService,
    notifyService: NotifyService,
    public domainService: DomainService,
    public route: ActivatedRoute,
    public baseReportService: BaseReportService,
    public dialog: MatDialog,
    router: Router
  ) {
    super(api, translate, notifyService, dialog, router);
    this.navigate = 'reports';

    domainService.get_tz().subscribe(data => this.timezone = data);

    // if (this.router.getCurrentNavigation().extras.state && 'filter' in this.router.getCurrentNavigation().extras.state && this.router.getCurrentNavigation().extras.state.filter) {
    //   this.filterForm.patchValue(JSON.parse(this.router.getCurrentNavigation().extras.state.filter));
    // }
  }


  callbackList(data: Array<DomainHideContactCall>): Observable<DomainHideContactCall[]> {
    return (data.length > 0 ?
      this.baseReportService.report('DomainReportCallsHistory', {
        limit: this.paginator.pageSize,
        offset: this.paginator.pageIndex * this.paginator.pageSize,
        sort: this.sortParams,
        ...this.callHistoryGetParams(data.map(c => c.first_uuid))
      }, 'get').pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        catchError(resp => {
          let code = this.notifyService.setFormErrors(resp);
          return observableOf(data);
        }),
        map(data2 => {
          for (let call of (data2?.list?.table || [])) {
            let call_contact = data.find(contact => contact.first_uuid == call.id);
            if (call_contact) call_contact['call_data'] = {...call};
          }
          return data;
        })
      ) :
      observableOf(data)
    );
  }

  changePeriod(val) {
    let period;

    switch (val) {
      case 0: period = null; break;
      case 1:
        period = {
          start_dt: moment.utc().add(-1, 'days').startOf('day'),
          end_dt: moment.utc().add(-1, 'days').endOf('day')
        }
        break;
      case 3:
        period = {
          start_dt: moment.utc().startOf('day'),
          end_dt: moment.utc().endOf('day')
        }
        break;
      case 4:
        period = {
          start_dt: moment.utc().startOf('week'),
          end_dt: moment.utc().endOf('week')
        }
        break;
      case 5:
        period = {
          start_dt: moment.utc().startOf('month'),
          end_dt: moment.utc().endOf('month')
        }
        break;
      case 6:
        period = {
          start_dt: moment.utc().startOf('year'),
          end_dt: moment.utc().endOf('year')
        }
        break;
    }

    if (!period) return;

    this.filterForm.patchValue(period);

    this.applyFilter();
  }

  applyFilter() {
    if (this.paginator.pageIndex != 0) this.paginator.pageIndex = 0; // при поиске возвращаю пользователя на первую страницу
    this.refresh();
  }

  getParams(): {} {
    let filterData = {filter: {field_list: [{field: 'hc_id', value: this.hc_id, condition_type: 0}], type: 0}}; // 0 - AND, 1 - OR
    // let res = Object.assign({}, this.filterForm.value);
    // if (res.start_dt) {
    //   filterData['filter']['field_list'].push({
    //     field: 'dt',
    //     condition_type: 5,
    //     value: moment(res.start_dt).unix() - getOffset(this.timezone)
    //   });
    //   delete res['start_dt'];
    // }
    // if (res.end_dt) {
    //   filterData['filter']['field_list'].push({
    //     field: 'dt',
    //     condition_type: 7,
    //     value: moment(res.end_dt).unix() - getOffset(this.timezone)
    //   });
    //   delete res['end_dt'];
    // }
    // if (res.hidden_id) {
    //   filterData['filter']['field_list'].push({
    //     field: 'hc_id',
    //     condition_type: 0,
    //     value: res.hidden_id
    //   });
    // }

    return filterData;
  }

  callHistoryGetParams(call_uids): {} {
    let params = {}; // 0 - AND, 1 - OR
    // let res = Object.assign({}, this.filterForm.value);
    // if (res.start_dt) params['start_dt'] = moment(res.start_dt).unix() - getOffset(this.timezone);
    // if (res.end_dt) params['end_dt'] = moment(res.end_dt).unix() - getOffset(this.timezone);
    params['uniqueid_list'] = call_uids;
    return params;
  }

  callHistoryDownloadAudio(link, data) {
    let name = 'noname.mp3';
    //if (data && data.id) name = `${data.id}.mp3`;
    if (data && data.f0) name = `${this.api.escapeFileName(data.f0)}.mp3`;
    this.baseReportService.downloadFile(link, name);
  }

  callHistoryFindPrev(index: number) {
    if (this.dataSource.data[index - 1]) return (this.dataSource.data[index - 1] as any)?.first_uuid;
    return '';
  }

  callHistoryFindNext(index: number) {
    if (this.dataSource.data[index + 1]) return (this.dataSource.data[index + 1] as any)?.first_uuid;
    return '';
  }

  callHistoryOpenAudioPlayerDialog(link, name = '', prev = '', next = '', id = '') {
    const dialogRef = this.dialog.open(AudioPlayerDialogComponent, {
      data: {
        id: id,
        link: link,
        name: name,
        prev: prev,
        next: next,
        comment: ''
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
