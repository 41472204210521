function generateGUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0,
    v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}


interface VertoEventHandler {
  evt: {(data: any):void},
  id: string
}

class VertoLab {

  private event_handlers: {[name: string]: Array<VertoEventHandler>} = {}
  public debug: boolean = false;

  constructor(debug: boolean = false){
    this.debug = debug;
  }

  subscribeEvent(name: string, handler: {(data:any):void}):string{
    let id = generateGUID()
    if(!this.event_handlers[name]) this.event_handlers[name] = []
    this.event_handlers[name].push({id, evt: handler})
    return id
  }

  unsubscribeEvent(name: string, handlerID?: string){
    if(handlerID) {
      this.event_handlers[name] = this.event_handlers[name]
        .map((v, i, a) => { if(v.id == handlerID) return; else return v; })
    } else {
      this.event_handlers[name] = []
    }
  }

  emitEvent(name: string, data?: any){
    if(this.event_handlers[name])
    for(let h of this.event_handlers[name]){
      h.evt(data)
    }
  }

}

export { VertoLab, generateGUID }
