import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NotifyService} from '../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../_helpers/base.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../config/json-app-config.service';
import {DomainAgentStatus} from './domain-agent-status';

@Injectable({
  providedIn: 'root'
})

export class DomainAgentStatusService extends BaseService<DomainAgentStatus> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService) {
    super(DomainAgentStatus.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }
}
