import {propObject, prop, digit, required, propArray} from '@rxweb/reactive-form-validators';
import {LSAudioList} from '../../../local-stream/local-stream';

export class DPCQueueEvaluation {
  @required()
  @digit()
  max_value: number = 10;

  @required()
  @digit()
  min_value: number = 0;

  @required()
  @digit()
  wait_time: number = 5;

  @required()
  save_error_value = true

  @required()
  allow_speech = false;

  @propObject(LSAudioList)
  audio_before: LSAudioList;
}

export class DPCQueue {
  @prop() name: string = '';

  @required()
  @digit()
  queue_id: number;

  @prop()
  @digit()
  member_priority: number  = 0;

  @required()
  call_evaluation = false;

  @propObject(LSAudioList)
  audio_after: LSAudioList;

  @required()
  stop_record = false;

  @propArray(DPCQueueEvaluation)
  questions: Array<DPCQueueEvaluation> = [];

}


