import {propObject, prop, digit, maxLength, required, propArray} from '@rxweb/reactive-form-validators';

export class LSModel {
  @digit()
  id?: number;
  @prop()
  value?: string;
  @prop()
  template?: string;
  @prop()
  lang?: string;
  @prop()
  field?: string;
  @prop()
  currency?: string;
  @prop()
  split_size?: string;
  @prop()
  dpe_name: string;
}

export class LSAudioList {
  @propArray(LSModel)
  list: LSModel[] = [];
}

export class LocalStream {
  @digit()
  id?: number;
  @required() @maxLength({value: 150})
  name = '';
  @digit()
  status: number = 1;
  @propObject(LSAudioList)
  audio_list: LSAudioList;
  @prop()
  labels = {};
  @prop()
  links = {};
}

