import {
  digit,
  maxLength,
  minLength,
  prop, propArray,
  propObject,
  required
} from '@rxweb/reactive-form-validators';
import {generateGUID} from '../../../../vertojs';

export class DDSEBaseParams {
  @prop() name: string;
}

export class DDSEBase {
  @required() model_name: string = 'DDSESelect';
  @required() id: string = generateGUID();
}

export class DDSEWithoutLeg extends DDSEBase {
}

export class DDSEOneLeg extends DDSEBase {
  @propObject(DDSEBase) leg: DDSEBase;
}

export class DDSEDefaultLeg {
  @prop() label: string;
  @propObject(DDSEBase) leg: DDSEBase;
}

export class MultiLegs {
  @prop() deletingKey: DDSEDefaultLeg;
  [key: string]: DDSEDefaultLeg;
}

export class DDSEMultiLegs {
  @required() id: string = generateGUID();
  @propObject(MultiLegs) legs: MultiLegs;
  @propObject(DDSEDefaultLeg) default_leg: DDSEDefaultLeg;
}

/* Одноногие модели */
export class DDSEFormParams extends DDSEBaseParams {
  @required() form_id: string;
  @required() ver: number;
}
export class DDSEForm extends DDSEOneLeg {
  @required() model_name: string = 'DDSEForm';
  @propObject(DDSEFormParams) params: DDSEFormParams;
}

export class DDSESetVarParams extends DDSEBaseParams {
  @required() var_name: string;
}
export class DDSESetVar extends DDSEOneLeg {
  @required() model_name: string = 'DDSESetVar';
  @propObject(DDSESetVarParams) params: DDSESetVarParams;
}
/* Конец одноногих моделей */

/* Многоногие модели */
export class MenuRow {
  @required() button: string;
  @required() label: string; // для отрисовки на форме, а так в АПИ этого поля нет
}
export class LegMenu {
  @required() button: string;
}
export class DDSEMenuConditions {
  @prop() deletingKey: LegMenu | MenuRow;
  [key: string]: LegMenu | MenuRow;
}
export class DDSEMenuParams extends DDSEBaseParams {
  @required() great: string;
  @propObject(DDSEMenuConditions) conditions: DDSEMenuConditions;

}
export class DDSEMenu extends DDSEMultiLegs {
  @required() model_name: string = 'DDSEMenu';
  @propObject(DDSEMenuParams) params: DDSEMenuParams;
}
/* Конец многоногих моделей */


export class DDSEStart {
  @propObject(DDSEBase) leg: DDSEBase;
}

export class DomainDialogScript {
  @prop() id?: string;
  @required() domain_id: number;
  @required() @minLength({value: 2}) @maxLength({value: 250}) name: string;
  @required() @propObject(DDSEStart) body: DDSEStart;
  @prop() ver: string;

  static className() {
    return 'DomainDialogScript';
  }
}

export class DomainDialogScriptVersion {
  @prop() id?: number;
  @required() @digit() script_id: number;
  @required() @digit() ver: number;
  @required() @propObject(DDSEStart) body: DDSEStart;

  static className() {
    return 'DomainDialogScriptVer';
  }
}

export class DomainDialogScriptResultRow {
  @required() id: number;
  @required() value: any;
}

export class DomainDialogScriptResult {
  @prop() id?: number;
  @required() script_id: string;
  @required() script_ver: number;
  @required() @propArray(DomainDialogScriptResultRow) body: DomainDialogScriptResultRow;
  @required() owner_id: number; // user.id
  @prop() call_id: string;
  @prop() dialog_id: string;
  @prop() event_id: number;

  static className() {
    return 'DomainDialogScriptResult';
  }
}
