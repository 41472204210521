import {Inject, Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {ROLE_TYPE_CUSTOM, ROLE_TYPE_PROVIDER} from '../_helpers/constant';
import {APP_MENU_DOMAIN_SETTINGS, APP_MENU_MODULES, APP_MENU_MARKETPLACE} from '../app.injection';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {MenuService} from '../roles/menu.service';
import {AuthenticationService} from './authentication.service';
import {NotifyService} from '../_helpers/notify.service';

@Injectable({providedIn: 'root'})
export class AuthModule implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public notifyService: NotifyService,
    public menu: MenuService,
    @Inject(APP_MENU_MODULES) public routerModule,
    @Inject(APP_MENU_DOMAIN_SETTINGS) public routerDomainSettings,
    @Inject(APP_MENU_MARKETPLACE) public routerMP,
    private ConfigService: JsonAppConfigService
  ) {
  }

  isRoleProvider() {
    return Object.values(ROLE_TYPE_PROVIDER).includes(this.authenticationService.currentUserValue?.role_type);
  }

  isRoleOperator() {
    return this.authenticationService.currentUserValue?.role_type===4 &&
      this.authenticationService.currentUserValue?.role_perms?.User?.includes(14); //self
  }

  isRoleCustom() {
    return Object.values(ROLE_TYPE_CUSTOM).includes(this.authenticationService.currentUserValue?.role_type);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('checkActivate');
    const currentUser = this.authenticationService?.currentUserValue;
    const formattedState = state.url.split('/')[1].split(';nextUrl=')[0].split('?');

    // переадресую на страницу технических работ, если не удалось получить DomainWhiteLabel-настройки !при обновлении страницы в браузере!
    if (this.ConfigService.getValue('loadingError')) {
      if (formattedState.length > 1) this.router.navigate(['/tech_work', {nextUrl: decodeURIComponent(formattedState[1])}], {queryParamsHandling: 'preserve'});
      else if (!state.url || state.url == '/') this.router.navigate(['/tech_work']);
      else this.router.navigate(['/tech_work', {nextUrl: state.url}], {queryParamsHandling: 'preserve'});
      return false;
    }

    if (currentUser) {
      if (!this.isRoleProvider() && currentUser?.domain_billing_options?.block_face) {
        this.notifyService.message('FINANCE_BLOCK', {BALANCE: currentUser?.domain_billing_options?.balance});
        return false;
      }
      else if (this.menu.getMenuItem().map(item => item.state).includes(formattedState[0])
        || this.menu.allowMenu(this.routerModule.menu).find(item => item.state == formattedState[0])
        || this.menu.allowMenu(this.routerDomainSettings.menu).find(item => item.state == formattedState[0])
        || this.menu.allowMenu(this.routerMP.menu).find(item => item.state == formattedState[0])
      ) {
        // адрес "/users/my" доступен только для Оператора домена,
        // поэтому выполняю перенаправление пользователей с другой ролью на страницу со списком сотрудников
        // if (this.isRoleOperator() && state.url.endsWith('/users')){
        //   this.router.navigate(['/', 'users', 'my', this.authenticationService.currentUserValue.user_id], {queryParamsHandling: 'preserve'});
        // }
        return true;
      } else {
        // если это провайдер платформы и он пытается зайти в меню домена, то перебрасываю его в таблицу доменов
        const role = this.authenticationService.currentUserValue?.role_type;
        const domain_mode = this.authenticationService.currentUserValue?.domain_mode;
        if (
          this.isRoleProvider() &&
          ((this.isRoleCustom() && this.menu.getMenu(role, domain_mode, true, this.authenticationService.listVisible()).map(item => item.state).includes(formattedState[0])) ||
            (!this.isRoleCustom() && this.menu.getMenu(role, domain_mode, true).map(item => item.state).includes(formattedState[0]))
          )
        ) {
          if (!this.authenticationService.getDomain()) {
            this.router.navigate(['/domains', {nextUrl: state.url}], {queryParamsHandling: 'preserve'});
          } else {
            return true;
          }
        }
        else if (formattedState[0].startsWith('profile')) {
          return true;
        }
        else if (this.menu.allowMenu(this.routerModule.menu).find(item => item.state == 'desktop')) {
          this.router.navigate(['/desktop']);
        }
        else if (this.menu.getMenuItem().map(item => item.state).length>0) {
          this.router.navigate([this.menu.getMenuItem().map(item => item.state)[0]]);
        } else {
          this.notifyService.message('NOTIFY.403');
          return false;
        }
        return true;
      }

      // logged in so return true

    }


    if (formattedState.length > 1) {
      // this.router.navigate(['/login', {nextUrl: decodeURIComponent(formattedState[1])}], {queryParamsHandling: 'preserve'});
      window.location.replace('/login;nextUrl='+formattedState[1]);
    } else if (!state.url || state.url == '/') {
      window.location.replace('/login');
    } else {
      // this.router.navigate(['/login', {nextUrl: state.url}], {queryParamsHandling: 'preserve'});
      window.location.replace('/login;nextUrl='+encodeURIComponent(state.url));
    }
    return false;
  }
}
