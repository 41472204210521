import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'map'
})
@Injectable()
export class MapPipe implements PipeTransform {
  transform(items: any[], field : string): any[] {
    if (!items) return [];

    return items.map(it => it[field])
  }
}
