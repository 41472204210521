import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseListComponent} from '../../_helpers/base-list.component';
import {NotifyService} from '../../_helpers/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmComponent} from '../../dialogs/modals/confirm/confirm.component';
import {DomainFileStorage} from './domain-file-storage';
import {DomainFileStorageService} from './domain-file-storage.service';

@Component({
  selector: 'app-domain-file-storages-list',
  templateUrl: './domain-file-storage-list.component.html',
  styleUrls: ['../../material-component/mat-table-responsive/mat-table-responsive.directive.scss'],
  styles: [`
    .system-file-storages.xs ::ng-deep td.mat-cell.cdk-cell.ng-star-inserted {
      grid-template-columns: 39% 61% !important;
    }
    .system-file-storages ::ng-deep td.mat-column-actions {
      min-width: 144px;
    }
  `]
})

export class DomainFileStorageListComponent extends BaseListComponent<DomainFileStorage>{
  displayedColumns: string[] = ['name', 'link', 'size', 'actions'];
  filteredColumns: string[] = ['name'];
  constructor(
    api: DomainFileStorageService,
    translate: TranslateService,
    notifyService: NotifyService,
    public route: ActivatedRoute,
    dialog: MatDialog,
    router: Router
  ) {
    super(api, translate, notifyService, dialog, router);
  }

  getParams() {
    return {
      folder: '/'
    };
  }
  onDelete(row) {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        row.blinker = true;
        this.api.delete(row.id).subscribe(
          data => {
            this.notifyService.showRequestResult(data, true);
            this.refresh();
          },
          resp => {
            row.blinker = false;
            this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
          }
        );
      }
    });
  }
}
