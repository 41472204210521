import {InjectionToken} from '@angular/core';
import {MenuItem} from './roles/menu.service';
import {Routes} from '@angular/router';

export interface MenuInject {
  menu: MenuItem[];
  router: Routes;
}

export const APP_MENU_MODULES: InjectionToken<MenuInject> = new InjectionToken<any[]>('menu.module');
export const APP_MENU_DOMAIN_SETTINGS: InjectionToken<MenuInject> = new InjectionToken<any[]>('menu.domain-settings.module');
export const APP_MENU_MARKETPLACE: InjectionToken<MenuInject> = new InjectionToken<any[]>('menu.marketplace.module');
