import 'hammerjs';
import {NgxMatDatetimePickerModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule} from '@angular-material-components/moment-adapter';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {RouterModule} from '@angular/router';
import {NgSelectModule} from '@ng-select/ng-select';
import {SkeletonLoaderModule} from '../../../_helpers/skeleton-loader.module';
import {MatSelectUserModule} from '../../../material-component/mat-select-user/mat-select-user.module';
import { MaterialModule } from '../../../material.module';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import {PipeModule} from '../../../_helpers/pipe.module';
import {MatTableResponsiveModule} from '../../../material-component/mat-table-responsive/mat-table-responsive.module';
import {DomainCRMEventFormComponent} from './event-form.component';
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";
import {NgxAudioPlayerModule} from "@khajegan/ngx-audio-player";


@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkTableModule,
    PipeModule,
    MatTableResponsiveModule,
    SkeletonLoaderModule,
    MatSelectUserModule,
    NgSelectModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatDatepickerModule,
    RouterModule,
    RxReactiveFormsModule,
    NgxAudioPlayerModule,
  ],
  exports: [
    DomainCRMEventFormComponent
  ],
  declarations: [DomainCRMEventFormComponent]
})
export class DomainCRMEventModule {}
