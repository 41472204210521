import {
  digit,
  maxLength,
  minLength,
  minNumber,
  pattern,
  prop,
  port,
  propArray,
  propObject,
  required, toBoolean, choice
} from '@rxweb/reactive-form-validators';
import {generateGUID} from '../../../_helpers/base.function';
import {RxFormHelpers} from '../../../_helpers/form.helpers';
import {DPCScheduleLeg} from '../../../dialplans/components/schedule/dialplan.schedule';

export class DOCChannelProviderBase {
  @prop() id: number;
  @required() model_name: string = 'DOCChannelBase';
  @required()  @minNumber({value: 60}) dialog_ttl: number = 600;
  @required() push_dialogs_on_connect: boolean = true;

  static className() {
    return 'DOCChannelBase';
  }
}

export class DOCChannelFormOption {
  @required() model_name: string;
  @prop() session_var: string;
  @required() field: string;
}

export class DOCChannelScheduleOption {
  @propObject(DPCScheduleLeg) value: DPCScheduleLeg;
  @required() message: string;
}

export class DOCChannelProviderTokenBase extends DOCChannelProviderBase {
  @required() @minLength({value: 36}) token: string = generateGUID();
  @propArray(DOCChannelFormOption) form_options: DOCChannelFormOption[];
  @propObject(DOCChannelScheduleOption) schedule: DOCChannelScheduleOption;
}

export class DOCChannelFormOptionLabel extends DOCChannelFormOption {
  @required() model_name: string = 'DOCChannelFormOptionLabel';
  @required() label: string;
  @required() label_type: number = 0; // 0 - text, 1 - html
}

export class DOCChannelFormOptionSubmit extends DOCChannelFormOption {
  @required() model_name: string = 'DOCChannelFormOptionSubmit';
  @required() label: string;
  @required() label_type: number = 0; // 0 - text, 1 - html
}

export class DOCChannelFormOptionInput extends DOCChannelFormOption {
  @required() model_name: string = 'DOCChannelFormOptionInput';
  @required() required: boolean = true;
  @required() label: string;
  @required() label_type: number = 0; // 0 - text, 1 - html
  @required() icon: string;
}

export class DOCChannelFormOptionEMail extends DOCChannelFormOptionInput {
  @required() model_name: string = 'DOCChannelFormOptionEMail';
}

export class DOCChannelFormOptionPhone extends DOCChannelFormOptionInput {
  @required() model_name: string = 'DOCChannelFormOptionPhone';
}

export class DOCChannelFormOptionRadioButtonChoice {
  @required() required: boolean = true;
  @required() label: string;
  @required() label_type: number = 1; // 0 - text, 1 - html
  @required() value: string;
}

export class DOCChannelFormOptionRadioButton extends DOCChannelFormOption {
  @required() model_name: string = 'DOCChannelFormOptionRadioButton';
  @required() label: string;
  @required() label_type: number = 1; // 0 - text, 1 - html
  @propArray(DOCChannelFormOptionRadioButtonChoice) radio_list: DOCChannelFormOptionRadioButtonChoice[];
  @prop() required: string[] = [];
}

export class DOCChannelProviderPublicWebChat extends DOCChannelProviderTokenBase {
  @required() model_name: string = 'DOCChannelProviderPublicWebChat';
  @required() @minNumber({value: 600}) session_ttl: number = 600;
  @prop() delay_adding_new_dialogs: number;
}

export class DOCChannelProviderPortalChat extends DOCChannelProviderBase {
  @required() model_name: string = 'DOCChannelProviderPortalChat';
  @required()  @minNumber({value: 600}) session_ttl: number = 600;
  @required() @minLength({value: 36}) token: string = generateGUID();
  @pattern({expression:{onlyDigit: RxFormHelpers.url}, message: 'ERROR.URL'}) site_url: string;
  @minLength({value: 36}) site_token: string;
  @propObject(DOCChannelScheduleOption) schedule: DOCChannelScheduleOption;
  @prop() delay_adding_new_dialogs: number;

  static className() {
    return 'DOCChannelBase';
  }
}

export class DOCChannelProviderEMailIMAP {
  @required() model_name: string = 'DOCChannelProviderEMailIMAP';
  @required() host: string;
  @required() port: string;
  @required() username: string;
  @required() password: string;
}

export class DOCChannelProviderEMailSMTP {
  @required() model_name: string = 'DOCChannelProviderEMailSMTP';
  @required() host: string = 'smtp.yandex.ru';
  @required() @port() port: number = 587;
  @required() from_address: string = '';
  @required() from_name: string = '';

  // тоглер, если с авторизацией - показываем поля username и ниже.
  @prop() with_auth: boolean = false;
  @required({conditionalExpression: x => x.with_auth}) username: string = '';
  @required({conditionalExpression: x => x.with_auth}) password: string = '';
  @prop() @toBoolean() use_tls: boolean = true;
  @prop() @toBoolean() start_tls: boolean = true;
}

export class DOCChannelProviderEMail extends DOCChannelProviderBase {
  @required() model_name: string = 'DOCChannelProviderEMail';
  @required() @propObject(DOCChannelProviderEMailIMAP) inbound: DOCChannelProviderEMailIMAP;
  @required() @propObject(DOCChannelProviderEMailSMTP) outbound: DOCChannelProviderEMailSMTP;
}

export class DOCChannelProviderTelegram extends DOCChannelProviderBase {
  @required() model_name: string = 'DOCChannelProviderTelegram';
  @required() @minLength({value: 36}) token: string = '';
  @propArray(DOCChannelFormOption) form_options: DOCChannelFormOption[];
  @propArray(DOCChannelFormOption) anon_form_options: DOCChannelFormOption[];
  @required() allow_anon: boolean = true;
  @prop() menu_help_text: string;
  @prop() menu_help_description: string;
}

export class DOCChannelProviderVoIPCall extends DOCChannelProviderBase {
  @required() model_name: string = 'DOCChannelProviderVoIPCall';
  @prop() gw_number_list: string[] = [];
  @prop() gw_pool_list: number[] = [];
}

export class DOCChannelProviderSMS extends DOCChannelProviderBase {
  @required() model_name: string = 'DOCChannelProviderSMS';
  @required() domain_extension_id: number;
}

export class DOCChannel {
  @required() domain_id: number;
  @required() @maxLength({value: 250}) name: string;
  @required() @propObject(DOCChannelProviderBase) provider: DOCChannelProviderBase;
  @required({conditionalExpression: x => x.provider?.model_name !== 'DOCChannelProviderVoIPCall'}) chatplan_id: number;
  @required() @digit() status: number = 1;

  static className() {
    return 'DOCChannel';
  }
}
