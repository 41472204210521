import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightTag'
})
export class HighlightTagPipe implements PipeTransform {
  transform(value: string, tag: string, style: string = 'yellow-highlight'): string {
    if (!value) return value;

    const regex = new RegExp(`<${tag}>(.*?)<\/${tag}>`, 'g');

    return value.replace(regex, `<span class="${style}">$1</span>`);
  }
}
