import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseListComponent} from '../../../_helpers/base-list.component';
import {NotifyService} from '../../../_helpers/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {DomainDialogScriptForm} from './domain-dialog-script-form';
import {DomainDialogScriptFormService} from './domain-dialog-script-form.service';
import {ConfirmComponent} from "../../../dialogs/modals/confirm/confirm.component";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {DDSFieldTypes} from "../../../_helpers/constant";

@Component({
  selector: 'app-dds-form-list',
  templateUrl: './domain-dialog-script-form-list.component.html',
  styleUrls: ['../../../material-component/mat-table-responsive/mat-table-responsive.directive.scss'],
  styles: [
    '.mat-column-icon, .mat-column-hk { text-align: center; }',
    `
    :host td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type {
      padding-right: 0;
    }

    .mat-column-icon, .mat-column-hk { text-align: center; }

    tr.example-detail-row {
      height: 0;
    }
    .responsive-table .example-detail-row td.mat-cell:first-of-type {
      padding-left: 0!important;
    }

    tr.example-element-row:not(.example-expanded-row):hover {
      background: whitesmoke;
      cursor: pointer;
    }
    tr.example-element-row:not(.example-expanded-row):active {
      background: #efefef;
    }
    .example-element-row td {
      border-bottom-width: 0;
    }
    .example-element-detail {
      overflow: hidden;
      display: block;
    }
    .example-expanded-row {
      background: whitesmoke;
    }
    .example-expanded-row + tr .example-element-detail {
      padding: 0 10px 10px 10px;
    }
  `
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class DomainDialogScriptFormListComponent extends BaseListComponent<DomainDialogScriptForm>{
  displayedColumns: string[] = ['name', 'body', 'actions'];
  filteredColumns: string[] = ['name'];
  public navigate: string = '';
  public expandedElement: DomainDialogScriptForm | null;

  constructor(
    api: DomainDialogScriptFormService,
    translate: TranslateService,
    notifyService: NotifyService,
    public route: ActivatedRoute,
    dialog: MatDialog,
    router: Router
  ) {
    super(api, translate, notifyService, dialog, router);
    this.navigate = 'domain-catalogs';
  }

  onRowClicked(row) {
    this.api.details(row, {filter: this.filterCtrl.value});
  }

  onDelete(row) {
    const confirmDialog = this.dialog.open(ConfirmComponent, {
      data: {
        title: 'DIALOG.CONFIRM_TITLE',
        message: 'DIALOG.CONFIRM_MESSAGE'
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        row.blinker = true;
        this.api.delete(row).subscribe(
          data => {
            this.notifyService.showRequestResult(data, true);
            this.cbDeleteSuccess();
          },
          resp => {
            row.blinker = false;
            this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
            this.cbDeleteError();
          });
      }
    });
  }

  getFieldType(model_name) {
    return DDSFieldTypes.find(f => f.model_name == model_name);
  }
}
