import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  HostListener, Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {PERM_SELECT_LIST} from '../_helpers/constant';
import {NotifyService} from '../_helpers/notify.service';
import {AppService} from '../app.service';
import {DialPlan} from '../dialplans/dialplan';
import {DomainAddressBook} from '../domains/domain-address-books/domain-address-book';
import {DomainCRMEntity} from '../domains/domain-crm/entities/entity';
import {DOCAgent} from '../domains/domain-omni-channel/doc-agent/doc-agent';
import {ModalBoxService} from '../modal-box/modal-box.service';
import {Queue} from '../queues/queue';
import {forkJoin, SubscriptionLike, of as observableOf, Observable} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, take, throttleTime, concatMap} from 'rxjs/operators';
import {CTIService} from "./cti-panel.service";
import {AuthenticationService} from "../auth/authentication.service";
import {DomainCRMEntityService} from "../domains/domain-crm/entities/entity.service";
import {DialogBoxMessageButtonObject, DialogBoxMessageObject} from "../_helpers/api.helpers";
import {DomainCTIService} from "../domains/domain-settings/cti-settings/cti-settings.service";
import {DomainCTI} from "../domains/domain-settings/cti-settings/cti-settings";
import {DomainCRMRefEventService} from "../domains/domain-crm/events/ref-event.service";
import {DomainCRMRefEvent} from "../domains/domain-crm/events/event";
// @ts-ignore
import {default as _rollupMoment} from "moment/moment";
import * as _moment from "moment/moment";
import {MatButton} from "@angular/material/button";
import {DomainService} from "../domains/domain.service";
const moment = _rollupMoment || _moment;
declare var require: any;
const jsonpath = require('jsonpath-plus');

@Component({
  selector: 'cti-panel',

  templateUrl: './cti-panel.component.html',
  styleUrls: ['./cti-panel.component.scss'],
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CTIPanelComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() host_source_link: string = '';
  public alerts = [
    {label: 'GUDOK1', value: '/assets/sounds/1.mp3'},
    {label: 'GUDOK2', value: '/assets/sounds/2.mp3'},
    {label: 'GUDOK3', value: '/assets/sounds/3.mp3'},
    {label: 'DEFAULT_VALUE', value: '/assets/sounds/bell_ring2.mp3'},
    {label: 'MUTE', value: null}
  ];
  public testAudio: Boolean = false;
  public testAudioTimeout: number = null;
  public eavesdrop1 = '';
  public eavesdrop2 = '';
  public window;
  public licenses = [];

  private messages$; // сообщения сокета
  private messagesSub: SubscriptionLike;

  public config: PerfectScrollbarConfigInterface = {useBothWheelAxes: false, suppressScrollX: true, suppressScrollY: false};
  public showPhone = false;
  public ctiSettings: DomainCTI;

  public events = [];
  public JSONPath = jsonpath.JSONPath;

  @ViewChild('dtmfInp') dtmfInp;
  @ViewChild('transferNumber') transferNumber;
  @ViewChild('transferBtn') transferBtn: MatButton;
  @ViewChild('numberToCall') numberToCall;
  @ViewChild('searchNumber') searchNumber;
  @ViewChild('ngSelectUser') ngSelectUser;

  private windowActive: boolean = true;

  constructor(
    private elemRef: ElementRef,
    public appService: AppService,
    public authenticationService: AuthenticationService,
    public translate: TranslateService,
    public modalService: ModalBoxService,
    public notifyService: NotifyService,
    public domainCRMService: DomainCRMEntityService,
    private domainCRMRefEventService: DomainCRMRefEventService,

    private cdr: ChangeDetectorRef,
    public CTI: CTIService,
    public ctiSettingsService: DomainCTIService,
    public domainService: DomainService
  ) {
    this.CTI.elemRef = this.elemRef;

    if (this.authenticationService.isLic('Built-inCRM')) {
      this.domainCRMRefEventService.list({sort: {name: '+'}, limit: 500}).subscribe(
        events => {
          this.events = events?.list || [];
        }
      );
    }

    this.CTI.hasOmniAgentPerm = this.authenticationService.hasPerm(DOCAgent.className(), PERM_SELECT_LIST, true);
    if (localStorage?.getItem('filterEntityType')) this.CTI.filterEntityType.setValue(+localStorage?.getItem('filterEntityType') || 0);
    if (localStorage?.getItem('filterToCallEntityType')) this.CTI.filterToCallEntityType.setValue(+localStorage?.getItem('filterToCallEntityType') || 0);

    let entityPerms = [
      this.authenticationService.hasPerm('User'/*.className()*/, PERM_SELECT_LIST, true),
      this.authenticationService.hasPerm(Queue.className(), PERM_SELECT_LIST, true),
      this.authenticationService.hasPerm(DialPlan.className(), PERM_SELECT_LIST, true),
      this.authenticationService.hasPerm(DomainAddressBook.className(), PERM_SELECT_LIST, true),
      (
        this.authenticationService.isLic('Built-inCRM') &&
        this.authenticationService.hasPerm(DomainCRMEntity.className(), PERM_SELECT_LIST, true)
      ),
    ];
    this.CTI.entityTypes = this.CTI.entityTypes.filter((v, i) => entityPerms[i]);

    // меняю тип искомого или переводимого объекта на другой, когда это не "Прямой набор" и не "Поиск по всем", т.к. они не облагаются тарифом
    if (this.CTI.entityTypes.length == 0 && this.CTI.filterEntityType.value != -1) this.CTI.filterEntityType.setValue(-1);
    else if (this.CTI.filterEntityType.value > 0 && this.CTI.entityTypes.indexOf(this.CTI.filterEntityType.value) == -1) this.CTI.filterEntityType.setValue(0);

    if (this.CTI.filterToCallEntityType.value > 0 && this.CTI.entityTypes.indexOf(this.CTI.filterToCallEntityType.value) == -1) this.CTI.filterToCallEntityType.setValue(0);

    this.CTI.blindTransferMode.setValue(localStorage?.getItem('blindTransferMode') == '0' ? [] : [true]);

    this.CTI.ctiUpdate$.subscribe(_ => {
      this.cdr.detectChanges();
    });

    this.CTI.cmd$.subscribe(cmd => {
      switch (cmd) {
        case 'transferPanel::close':
          this.modalService.close('transferPanel');
          break;

      }
    });

    this.CTI.init$.subscribe(() => this.subscriber());
  }

  @HostListener('window:focus')
  onFocus() {
    this.windowActive = true;
  }

  @HostListener('window:blur')
  onBlur() {
    this.windowActive = false;
  }

  @HostListener('callTo', ['$event'])
  callToEvt(ev:KeyboardEvent) {
    console.log('%ccallTo event: ', 'color: orange; font-weight: 700;', ev);
    if (ev.detail['number']?.length > 0) {
      this.CTI.ctiActions$.next({obj: 'call', action: 'makeCall', number: ev.detail['number'], name: ev.detail['name']});
    }
  }

  ngOnInit(): void {
    this.CTI.host_source_link = this.host_source_link;
    this.CTI.alarm =  this.host_source_link + '/assets/sounds/bell_ring2.mp3';
    this.CTI.alarmChat =  this.host_source_link + '/assets/sounds/1.mp3';
    for (let alert of this.alerts) {
      if (alert.value?.startsWith('/assets')) alert.value = this.host_source_link + alert.value;
    }

    if (localStorage && localStorage.getItem('verto_session')) {
      this.CTI.transportConfig.sessid = localStorage.getItem('verto_session');
    } else if (localStorage) {
      localStorage.setItem('verto_session', this.CTI.transportConfig.sessid);
    }

    if (localStorage) {
      if (localStorage.getItem('agent_status_on_outbound_call_prev')) {
        this.CTI.du_agent_status_on_outbound_call_prev = +localStorage.getItem('agent_status_on_outbound_call_prev');
      }
      if (localStorage.getItem('omni_agent_status_on_call_prev')) {
        this.CTI.omni_agent_status_on_call_prev = +localStorage.getItem('omni_agent_status_on_call_prev');
      }

      if (localStorage.getItem('verto_settings')) {
        let verto_settings = JSON.parse(localStorage.getItem('verto_settings'));
        if (verto_settings) {
          if (verto_settings.volume) this.CTI.volume = verto_settings.volume;
          if (verto_settings.notify) this.CTI.notify = verto_settings.notify;
          if (verto_settings.alarm) this.CTI.alarm = verto_settings.alarm;
          if (verto_settings.alarmChat) this.CTI.alarmChat = verto_settings.alarmChat;
          if (verto_settings.skinId) this.CTI.skinId = verto_settings.skinId;
          if (verto_settings.autocall) this.CTI.autocall = verto_settings.autocall;
          if (verto_settings.videocall) this.CTI.videocall = verto_settings.videocall && this.CTI.showVideoCall;
          if (verto_settings.autowait) this.CTI.autowait = verto_settings.autowait;
        }
      }

      if (localStorage.getItem('ctiSettings')) {
        let ctiSettings = JSON.parse(localStorage.getItem('ctiSettings'));
        if (ctiSettings.dnd && this.CTI.showDND) this.CTI.dnd = ctiSettings.dnd;
        if (ctiSettings.videocall) this.CTI.videocall = ctiSettings.videocall && this.CTI.showVideoCall;
        if (ctiSettings.isMuted) this.CTI.isMuted = ctiSettings.isMuted;
        if (ctiSettings.isCam) this.CTI.isCam = ctiSettings.isCam;
      }

      this.CTI.get_last_calls_from_ls();
    }

    this.CTI.setSkinId().then();

    this.messages$ = this.appService.on<any>();
    this.messagesSub = this.messages$.subscribe(
      msg => this.onMessage(msg)
    );

    this.CTI.chatEvent$.subscribe(data=> {
      if (data>0) {
        this.CTI.createAudioSource(this.CTI.alarmChat, true, true).then(_=>{
          if (this.CTI.chatAlarmSource) {
            this.CTI.chatAlarmSource.stop();
          }
          this.CTI.chatAlarmSource = _;
          // перепроверяю, что есть входящий чат, чтобы не гудел в ситуации, когда начал создаваться аудиоконтекст, а чат в этот момент завершился
          if (this.CTI.chatEvent$.value == 0) this.CTI.chatAlarmSource.stop();
        })
      }  else if (this.CTI.chatAlarmSource) {
        this.CTI.chatAlarmSource.stop();
      }
    });

    this.CTI.chatEventMessages$.subscribe(data=> {
      if (data>0 && this.CTI.notify) {
        this.CTI.createAudioSource(this.CTI.alarmChat, true, false).then(_=>{
          this.CTI.chatAlarmSource = _;
        })
      }
    });

    this.CTI.ctiCalls$.subscribe(data=> {
      if (data?.find(call => call.status == 'ringing' && call.direction == 1)) {
        this.CTI.createAudioSource(this.CTI.alarm, true, true).then(_=>{
          if (this.CTI.phoneAlarmSource){
            this.CTI.phoneAlarmSource.stop();
          }
          this.CTI.phoneAlarmSource = _;
          // перепроверяю, что есть вызов, чтобы не гудел в ситуации, когда начал создаваться аудиоконтекст, а вызов в этот момент завершился
          if (!this.CTI.ctiCalls$.value?.find(call => call.status == 'ringing' && call.direction == 1)) this.CTI.phoneAlarmSource.stop();
        })
      }  else if (this.CTI.phoneAlarmSource){
        this.CTI.phoneAlarmSource.stop();
      }
    });
    forkJoin([
      this.CTI.verto.get_verto().pipe(take(1)),
      this.CTI.agentStatusService.toSelect(),
      this.ctiSettingsService.get(null).pipe(catchError(data => observableOf(null)))
    ]).subscribe(
      results => {
        let item = results[0];
        this.showPhone = item != null && Object.keys(item).length > 0 && item.device_id == 'CTI';
        this.CTI.showDND = item.show_dnd;
        this.CTI.showVideoCall = item.show_video_call;
        this.CTI.showTransfer = item.show_transfer != null ? item.show_transfer : true;
        this.CTI.deny_inbound_call = item.deny_inbound_call;
        this.CTI.du_agent_status_on_outbound_call = item.du_agent_status_on_outbound_call;
        this.CTI.du_agent_status_on_chat = item.du_agent_status_on_chat;
        this.CTI.omni_agent_status_on_call = item.omni_agent_status_on_call;
        this.CTI.showEavesdropBtns = item.eavesdrop?.map(numb => numb.toString()) || ['1', '2', '3'];
        this.CTI.callHotKeys = item.call_hk;
        this.CTI.chatHotKeys = item.chat_hk;
        this.CTI.favorite_settings = item.cti_settings;

        if (results[2] && Object.keys(results[2]).length > 0) {
          this.ctiSettings = results[2];
          this.CTI.ctiSettings = this.ctiSettings;
          if (this.ctiSettings.default_blind_transfer == 2) { // выключить и скрыть
            this.CTI.updateBlindTransferMode(false);
            this.CTI.blindTransferMode.setValue([]);
          } else if (this.ctiSettings.default_blind_transfer == 3) { // включить и скрыть
            this.CTI.updateBlindTransferMode(true);
            this.CTI.blindTransferMode.setValue([true]);
          } else if (this.ctiSettings.default_blind_transfer != null && !localStorage?.getItem('blindTransferMode')) { // если пользователь не нажимал кнопку слепого перевода, то устанавливаю из настроек
            this.CTI.updateBlindTransferMode(this.ctiSettings.default_blind_transfer == 1);
            this.CTI.blindTransferMode.setValue(this.ctiSettings.default_blind_transfer == 1 ? [true] : []);
          }
        }

        if (this.showPhone) {
          // подписываюсь на изменение прав доступа к микрофону
          this.CTI.userStateErrorSub = this.CTI.verto.get_state('microphone' as PermissionName).subscribe(state => {
            this.CTI.userStateError = state != 'granted';
            this.cdr.detectChanges();
          });

          if (item?.login && item?.wss && item?.password) {
            this.CTI.hasPhoneProfile = true;
            this.CTI.transportConfig.login = item.login;
            this.CTI.transportConfig.passwd = item.password;
            this.CTI.transportConfig.socketUrl = item.wss;
            this.CTI.saveCreadsToLS('webplogin', this.CTI.transportConfig.login);
            this.CTI.saveCreadsToLS('webswd', this.CTI.transportConfig.passwd);
            this.CTI.saveCreadsToLS('socketUrl', this.CTI.transportConfig.socketUrl);
            if (item.ice_server_list) {
              item.ice_server_list?.forEach(ice => {
                this.CTI.addICEServer({urls: ice});
              });
            }

            this.CTI.ctiState$.next('offline');

            if (item?.auto_login) {
              this.CTI.autologin = item.auto_login;
              this.CTI.checkSessionExists();
            }
          } else {
            this.CTI.hasPhoneProfile = false;
            this.CTI.transportConfig.login = item.login || (localStorage && localStorage.getItem('webplogin') ? localStorage.getItem('webplogin') : '');
            this.CTI.transportConfig.passwd = item.password || (localStorage && localStorage.getItem('webswd') ? localStorage.getItem('webswd') : '');
            this.CTI.transportConfig.socketUrl = item.wss || (localStorage && localStorage.getItem('socketUrl') ? localStorage.getItem('socketUrl') : '');
            if (item.ice_server_list) {
              item.ice_server_list?.forEach(ice => {
                this.CTI.addICEServer({urls: ice});
              });
            }
            // утснавливаю статус "Выключен", чтобы можно было включить его
            if (this.CTI.transportConfig.login && this.CTI.transportConfig.passwd && this.CTI.transportConfig.socketUrl) this.CTI.ctiState$.next('offline');
            this.cdr.detectChanges();
          }
        }

        let data = results[1];
        if (data) this.CTI.agent_status_list = data.map(st => ({
          ...st,
          colorClass: !st.color ? ((st.base_status_id == -1 || st.id == -1) ? 'text-success' : ((st.base_status_id == -3 || st.id == -3) ? 'text-muted' : 'text-danger')) : ''
        }));



        if (this.authenticationService.isLic('Built-inCustomerChat') && this.CTI.hasOmniAgentPerm) {
          this.CTI.omniAgent = new FormControl(null);

          this.CTI.docAgentService.get(this.authenticationService.getUserId(), {__ignore__: {10002: true}}).subscribe(
            data => {
              if (this.CTI.omniAgent) {
                if (data != null) {
                  this.CTI.omniAgent.setValue(data['status_id']);
                  this.CTI.omniAgentStatusPrev = data['status_id'];
                }
                if (!this.showPhone) {
                  if (this.CTI.isOmniStatusChanging) {
                    // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
                    this.CTI.checkOmniAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
                    this.cdr.detectChanges();
                  } else {
                    this.CTI.checkOmniAgentStatusAfterUpdate = false;
                    this.cdr.detectChanges();
                    this.CTI.checkAndUpdateOmniAgentStatus();
                  }
                }
              }

              this.appService.subscribe([{name: 'omni|agent|my_status', params: {params: this.authenticationService.getUserId()}}]);
              this.cdr.detectChanges();
            },
            resp => {
              this.appService.subscribe([{name: 'omni|agent|my_status', params: {params: this.authenticationService.getUserId()}}]);
              this.cdr.detectChanges();
            }
          );
        }

        this.cdr.detectChanges();
      },
      error => {
        console.log(error)
      }
    );
  }

  getRefEvent(id) {
    return this.events.find(ev => ev.id == id) || {} as DomainCRMRefEvent;
  }

  ngAfterViewInit() {
    this.window = window;
    this.cdr.detectChanges();

    // Подписка на событие ввода текста в поле "Номер" на вкладке Перевод.
    this.CTI.searchSub = this.CTI.search$.pipe(
      debounceTime(300), // в течении этого времени не учитываются изменения в поле поиска
      distinctUntilChanged(),
      switchMap((filterValue: any) => { // отменяю предыдущий запрос и начинаю новый
        this.CTI.clearEntityCounters();
        return this.CTI.findEntities(filterValue?.trim().toLowerCase(), this.CTI.filterEntityType.value);
      })
    ).subscribe(
      data => {/*if (this.debug) */console.log('transfer.search next:', data); this.CTI.loadingDomainUsers = false; this.cdr.detectChanges();},
      resp => {/*if (this.debug) */console.log('transfer.search error:', resp); this.CTI.loadingDomainUsers = false; this.cdr.detectChanges();}
    );

    // Подписка на событие ввода текста в поле "Введите номер"
    this.CTI.searchToCallSub = this.CTI.searchToCall$.pipe(
      debounceTime(300), // в течении этого времени не учитываются изменения в поле поиска
      distinctUntilChanged(),
      switchMap((filterValue: any) => { // отменяю предыдущий запрос и начинаю новый
        this.CTI.clearEntityCounters();
        return this.CTI.findEntities(filterValue?.trim().toLowerCase(), this.CTI.filterToCallEntityType.value);
      })
    ).subscribe(
      data => {/*if (this.debug) */console.log('search next:', data); this.CTI.loadingDomainUsers = false; this.cdr.detectChanges();},
      resp => {/*if (this.debug) */console.log('search error:', resp); this.CTI.loadingDomainUsers = false; this.cdr.detectChanges();}
    );

    // Подписка на событие ввода текста в поле "Введите номер"
    this.CTI.searchOnlyUserToCallSub = this.CTI.searchOnlyUserToCall$.pipe(
      debounceTime(300), // в течении этого времени не учитываются изменения в поле поиска
      distinctUntilChanged(),
      switchMap((filterValue: any) => { // отменяю предыдущий запрос и начинаю новый
        this.CTI.clearEntityCounters();
        return this.CTI.findEntities(filterValue?.trim().toLowerCase(), 1);
      })
    ).subscribe(
      data => {/*if (this.debug) */console.log('search next:', data); this.CTI.loadingDomainUsers = false; this.cdr.detectChanges();},
      resp => {/*if (this.debug) */console.log('search error:', resp); this.CTI.loadingDomainUsers = false; this.cdr.detectChanges();}
    );

    // this.elemRef.nativeElement.addEventListener('callTo', (number, name) => {
    //   console.log('%cCallTo event: '+number+', '+name, 'color: orange; font-weight: 700;');
    // });

    this.elemRef.nativeElement.addEventListener('logout', () => {
      console.log('%cРазрегистрация..', 'color: green; font-weight: 700;');
      this.authenticationService.logout(false);
    });

    document.querySelector('app-root')?.dispatchEvent(new Event('cti-panel-init'));
    document.querySelector('cti-panel-root')?.dispatchEvent(new Event('cti-panel-init'));
  }

  ngOnDestroy() {
    if (this.CTI.debug) console.log('%cphone destroy', 'font-size: 18px; color: orange;');
    if (this.testAudioTimeout) clearTimeout(this.testAudioTimeout);
    this.CTI.verto.logout().then(r => {});

    if (this.CTI.ctiActionsSub) this.CTI.ctiActionsSub.unsubscribe();
    if (this.CTI.userStateErrorSub) this.CTI.userStateErrorSub.unsubscribe();
    this.CTI.destroy$.next(null);
    this.CTI.destroy$.complete();
    this.CTI.outbound_calls = [];

    this.appService.unsubscribe(['domain_user_agent_status']);
    this.appService.unsubscribe(['omni|agent|my_status']);
    this.appService.unsubscribe(['personal|notify']);
    if (this.messagesSub) this.messagesSub.unsubscribe();
    if (this.CTI.onDialogSub) this.CTI.onDialogSub.unsubscribe();
    if (this.CTI.searchSub) this.CTI.searchSub.unsubscribe();
    if (this.CTI.searchToCallSub) this.CTI.searchToCallSub.unsubscribe();
    if (this.CTI.searchOnlyUserToCallSub) this.CTI.searchOnlyUserToCall$.unsubscribe();
  }

  public onMessage(msg: any = {}) {
    if (typeof msg==='string') msg = JSON.parse(msg);
    if (msg.code === 407 || msg.code === 401) { // токена нет или он устарел
      this.notifyService.messageByCode(msg.code);
      // remove user from local storage to log user out
      this.notifyService.auth.logout();
      return false;
    }

    if (msg.action == 'start' && msg.obj == 'DomainCRMEvent') {

      const refEvent = this.getRefEvent(msg.body?.ref_event_id)


      // ./crm/journal/{entity_id}/{id}


      this.domainCRMService.list({filter: {field_list: [{field: 'id', value: msg.body.entity_id, condition_type: 0}], type: 0}}).pipe(
        catchError(resp => observableOf(null)),
      ).subscribe(data=>{
        if (data.list && data.list.length>0) {

          const entity = data.list[0];
          const entity_type = (entity.entity_type == 0) ? 'COMPANY' : 'CONTACT';

          if (entity) {
            const object: DialogBoxMessageObject = {
              timeout: 0,
              icon: refEvent?.etype == 0 ? 'call' : (refEvent?.etype == 1 ? 'sms' : 'description'),
              header: `#${msg.body?.id} ${refEvent.name}`,
              date: ' ',




              html: `<b>${this.translate.instant(entity_type)}:</b> ${entity?.name}<br>`+
                    `<b>${this.translate.instant("DESCRIPTION")}:</b> ${msg.body?.name}<br>`+
                    `<b>${this.translate.instant("SCHEDULE.START_DATE")}:</b> ${moment(msg.body?.start_dt*1000).format('L LT')}`,

            }

            object["buttons"] = [
              {
                type: 'mat-raised-button',
                html: 'GOTO',
                classNames: 'p-l-10 p-r-10 m-b-5',

                routerLink: ['crm', 'journal', msg.body.entity_id, msg.body?.id],
                icon: "pending_actions",
                iconClassNames: 'text-primary',
                cb: this.onActivityClick, // функция
                cbContext: this, // ее контекст
                cbParams: [] // параметры, передаваемые в функцию
              } as DialogBoxMessageButtonObject,
            ]

            this.CTI.notificationBarService.message(object);

          }
        }
      })

    }

    switch (msg.action) {
      case 'auth':
        this.appService.subscribe([{name: 'personal|notify', params: {}}]);
        if (this.CTI.verto.isLogged()) {
          this.appService.subscribe([{name: 'domain_user_agent_status', params: {params: this.CTI.mynumber}}]);
        }

        if (this.CTI.omniAgent != null) {
          this.appService.subscribe([{name: 'omni|agent|my_status', params: {params: this.authenticationService.getUserId()}}]);
        }

        break;
      case 'subscribe':

        break;
      case 'agent_status_change': // изменение статуса агента
        //{"action":"agent_status_change","action_id":null,"obj":"DomainOnlineDashboard","code":200,"notifies":null,"body":{"1008":20}}
        if (this.CTI.verto.isLogged() && msg.code == 200 && msg.obj == 'DomainOnlineDashboard' &&
          this.CTI.mynumber in msg.body && msg.body[this.CTI.mynumber] != this.CTI.agentStatus.value &&
          (msg.body[this.CTI.mynumber] > 0 || [-1, -3, -4].indexOf(msg.body[this.CTI.mynumber]) != -1) // либо из таблицы Статусы агента, либо 3 предопределенных
        ) {
          if (this.CTI.agentStatus.value != msg.body[this.CTI.mynumber]) this.CTI.startAgentStatusTimer();
          this.CTI.agentStatus.setValue(msg.body[this.CTI.mynumber], {emitEvent: true});
          if (this.CTI.debug) console.log('Установка статуса агента из сокета:', this.CTI.agentStatus.value);
          this.cdr.detectChanges();
        }
        break;
      case 'omni_agent_status_change': // изменение статуса ОМНИ-агента
        //{"action":"omni_agent_status_change","action_id":null,"obj":"DomainOnlineDashboard","code":200,"notifies":null,"body":{"user_id":51,"status":-1}}
        if (
          this.authenticationService.isLic('Built-inCRM') && this.CTI.hasOmniAgentPerm &&
          msg.code == 200 && msg.obj == 'DomainOnlineDashboard' &&
          this.authenticationService.getUserId() == msg.body.user_id
        ) {
          if (msg?.body?.status == null) this.CTI.omniAgent = null;
          else if (!this.CTI.omniAgent) this.CTI.omniAgent = new FormControl(msg?.body?.status);
          else this.CTI.omniAgent.setValue(msg?.body?.status);
          if (this.CTI.debug) console.log('Установка статуса ОМНИ-агента из сокета:', this.CTI.omniAgent?.value);
          this.cdr.detectChanges();
        }
        break;
    }
  }

  async subscriber() {
    this.CTI.verto.phone.subscribeEvent('attach', call => {
      if (this.CTI.verto.isLogged()) {
        let cachedCalls = 'cachedCall' in localStorage ? JSON.parse(localStorage.getItem('cachedCall')) : [];
        // Убираю все вызовы в статусе ringing, т.к. они завершаются при обновлении страницы или падении сокета
        cachedCalls = cachedCalls.filter(c => c.status != 'ringing').map(c => {
          if (c.transfering?.call?.status == 'ringing') delete c['transfering'];
          return c;
        });
        let cachedCall = cachedCalls.find(c => c.id == call.id);
        let existedCall = this.CTI.lines_calls.find(c => c.call.id == call.id); // при падении сокета вызов все еще будет на линии
        let transferCall = (
          this.CTI.lines_calls.find(c => c.transfering?.call?.id == call?.id) ||
          cachedCalls.find(c => c.transfering?.call?.id == call?.id)
        )?.transfering; // при падении сокета придет аттач вызовом, на который будет осуществлен неслепой перевод

        console.log('%cЗапрашиваю  навигатора разрешения: audio: true, video:' + !!((cachedCall && cachedCall.video && Object.keys(cachedCall.video).length > 0) || (existedCall && existedCall.video && Object.keys(existedCall.video).length > 0)), 'background: yellow; font-size: 20px;');
        navigator.mediaDevices.getUserMedia({
          audio: true,
          video: !!((cachedCall && cachedCall.video && Object.keys(cachedCall.video).length > 0) || (existedCall && existedCall.video && Object.keys(existedCall.video).length > 0))
        }).then((local_stream) => {
          if (existedCall) local_stream = existedCall.call.local_stream;
          else if (transferCall && transferCall.call && Object.keys(transferCall.call.local_stream || {}).length > 0) local_stream = transferCall.call.local_stream;

          call.answer(local_stream.getTracks());
          call.subscribeEvent('track', (track) => {
            let stream = new MediaStream();
            stream.addTrack(track);
            if (this.CTI.debug) console.log('TRACK >>>>>>>>>>'+track.kind);

            if (track.kind == 'video') {
              this.CTI.video = stream;
              call.video = stream;
            }
            else if (track.kind == 'audio') {
              let hasSocketFallen = false;
              if (this.CTI.debug) console.log('%cattach.track', 'color: blue; font-weight: 700;');

              if (transferCall) { // для трансферного вызова поднимаю голосовой поток
                transferCall.stream = stream;
                transferCall.track = track;
                transferCall.call = call;
                let parentCall = this.CTI.lines_calls.find(c => c.transfering?.call?.id == call.id);
                if (!parentCall) this.CTI.attachedTransferingCall = transferCall;
                else {
                  parentCall.transfering.call = call;
                  parentCall.transfering.stream = stream;
                  parentCall.transfering.track = track;
                }
              } else { // для обычного вызова на линии поднимаю поток
                let calls_on_hold = 'calls_on_hold' in localStorage ? JSON.parse(localStorage.getItem('calls_on_hold')) : {};

                // Проверяю, чтобы не было мигания и перескакивания с линии на линию, что присуще аттачу,
                // что вызов еще не отображен, т.к., при падении сокета, линии телефона с отвеченными вызовами не очищаются.
                if (!existedCall) { // вызов пришел при обновлении страницы => отрисовываю его на линии
                  let transfering = null;
                  if (localStorage) {
                    if (cachedCall) {
                      call.options.caller_id_number = cachedCall.options.caller_id_number;
                      call.options.caller_id_name = cachedCall.options.caller_id_name;
                      call.options.callee_id_number = cachedCall.options.callee_id_number;
                      call.options.callee_id_name = cachedCall.options.callee_id_name;
                      call.options.fio = cachedCall.options.fio;
                      if (cachedCall.eavesdrop) call.eavesdrop = cachedCall.eavesdrop;
                      call.local_stream = local_stream;
                      transfering = cachedCall.transfering;
                    }
                  }
                  if (call.options.caller_id_number != this.CTI.mynumber) call.direction = 1;
                  this.CTI.lines_calls.push({call: call, track: track, stream: stream});
                  let attachedCall = this.CTI.lines_calls.find(c => c.call.id == call.id);
                  if (transfering) attachedCall.transfering = {...transfering};
                  if (transfering?.call?.id && transfering.call.id == this.CTI.attachedTransferingCall?.call?.id) {
                    attachedCall.transfering.call = this.CTI.attachedTransferingCall.call;
                    attachedCall.transfering.stream = this.CTI.attachedTransferingCall.stream;
                    attachedCall.transfering.track = this.CTI.attachedTransferingCall.track;
                  }
                  this.CTI.start_timer(call.id);

                  call.status = 'attached';
                  this.CTI.refreshCTICalls();

                  call.status = call.id in calls_on_hold ? 'held' : 'active';
                  // если вызов один или он был активен на момент обновления страницы, то тоже перехожу на линию с ним
                  if (!(call.id in calls_on_hold) || this.CTI.lines_calls.length == 1) {
                    const line = this.CTI.lines_calls.findIndex(c => c.call.id == call.id);
                    if (line>-1) {
                      this.CTI.line(line);
                      if (this.CTI.curLine != line && this.dtmfInp) {
                        this.dtmfInp.nativeElement.value = '';
                        this.cdr.detectChanges();
                      }
                    }
                  }

                  // снимаю с удержания, если оно поставлено было трансфером
                  if (attachedCall.call.id in calls_on_hold && calls_on_hold[attachedCall.call.id] == 'transfer' && !attachedCall.transfering?.call?.id) {
                    attachedCall.call?.unhold();
                    attachedCall.call.status = 'active';

                    delete calls_on_hold[attachedCall.call.id];
                    if (this.CTI.debug) console.log('снимаю с удержания вызов:', attachedCall.call.id);
                    localStorage.setItem('calls_on_hold', JSON.stringify(calls_on_hold));
                  }
                } else { // вызов пришел при падении сокета (что привело к поломке голоса в вызове) => восстанавливаю голос
                  hasSocketFallen = true;
                  existedCall.track = track;
                  existedCall.stream = stream;

                  // снимаю с удержания, если оно поставлено было трансфером
                  if (existedCall.call.id in calls_on_hold && calls_on_hold[existedCall.call.id] == 'transfer' && !existedCall.transfering?.call?.id) {
                    existedCall.call?.unhold();
                    existedCall.call.status = 'active';

                    delete calls_on_hold[existedCall.call.id];
                    if (this.CTI.debug) console.log('снимаю с удержания вызов:', existedCall.call.id);
                    localStorage.setItem('calls_on_hold', JSON.stringify(calls_on_hold));
                  }
                  this.cdr.detectChanges();
                }

                if (this.CTI.isMuted) this.CTI.toggleMic(true);
                if (this.CTI.isCam) this.CTI.toggleCam(true);

                if (call?.options?.caller_id_name == 'Eavesdrop' || call?.options?.callee_id_name == 'Eavesdrop') {
                  const regexp = /Eavesdrop_(\w+)_(\w+)/gm;

                  let str = (call?.options?.caller_id_name == 'Eavesdrop') ? (call?.options?.caller_id_number) : (call?.options?.callee_id_number);
                  let matchAll: any = Array.from(str.matchAll(regexp));

                  if (matchAll.length > 0) {
                    const match = Array.from(matchAll[0]);
                    if (match.length === 3) {
                      this.eavesdrop1 = match[1] as string;
                      this.eavesdrop2 = match[2] as string;
                    }
                  }
                }

                if (this.CTI.lines_calls.length == 1 && !hasSocketFallen) {
                  if (this.CTI.omniAgent) {
                    if (this.CTI.isOmniStatusChanging) {
                      // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
                      this.CTI.checkOmniAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
                      this.cdr.detectChanges();
                    } else {
                      this.CTI.checkOmniAgentStatusAfterUpdate = false;
                      this.cdr.detectChanges();
                      this.CTI.checkAndUpdateOmniAgentStatus();
                    }
                  }

                  this.CTI.verto.get_agent_status_verto().pipe(take(1)).toPromise().then(
                    data => {
                      if (data) {
                        this.CTI.agentStatusPrev = data;
                        this.CTI.agentStatus.setValue(data, {emitEvent: true});
                        if (this.CTI.debug) console.log('Первичное получение статуса агента при аттаче вызова: ' + this.CTI.agentStatus.value);
                        this.CTI.startAgentStatusTimer();

                        if (this.CTI.isStatusChanging) {
                          // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
                          this.CTI.checkAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
                          if (this.CTI.debug) console.log('\tустановлен флаг обновления статуса агента.');
                          this.cdr.detectChanges();
                        } else {
                          this.CTI.checkAgentStatusAfterUpdate = false;
                          if (this.CTI.debug) console.log('\tобновление статуса агента по флагу...');
                          this.cdr.detectChanges();
                          this.CTI.checkAndUpdateAgentStatus();
                        }

                        this.cdr.detectChanges();
                      }
                    }
                  );
                }
              }
            } else {
              // Здесь будет kind == screen
            }
            this.cdr.detectChanges();
          });
        }, () => {
          this.CTI.userStateError = true;
          this.notifyService.message(this.translate.instant('MICROPHONE_IS_REQUIRED'));
          this.cdr.detectChanges();
        });
      }
    });

    this.CTI.verto.phone.subscribeEvent('clientReady', result => {
      this.CTI.logining = false;

      // очистка перед подключением, на случай разрыва сокета
      if (this.CTI.ctiActionsSub) this.CTI.ctiActionsSub.unsubscribe();
      this.appService.unsubscribe(['domain_user_agent_status']);
      if (this.CTI.onDialogSub) this.CTI.onDialogSub.unsubscribe();
      // очищаю неотвеченные входящие/исходящие/трансферные вызовы, т.к. при падении сокета, они завершатся
      for (let c of [...this.CTI.lines_calls.filter(c => c.call.status == 'ringing' || c.transfering?.call?.status == 'ringing'), ...this.CTI.outbound_calls.filter(c => c.call.status == 'ringing')]) {
        if (c.call.status == 'ringing') { // исх./вх. вызов
          this.CTI.onHangup(c.call.id, {cause: 'NETWORK_OUT_OF_ORDER', causeCode: 502});
        } else if (c.transfering?.call?.id) { // трансферный вызов
          this.CTI.onTransferCancel(c?.call?.id, {cause: 'NETWORK_OUT_OF_ORDER', causeCode: 502});
        }
      }
      // конец блока очистки перед подлкючением

      if (this.CTI.verto.isLogged()) {
        if (this.CTI.needReconnect) { // повторно регистрирую сессию, чтобы получить и отобразить вызовы
          this.CTI.onLogout();
        } else {
          this.CTI.needReconnect = 0;
          this.CTI.mynumber = this.CTI.transportConfig.login.split('@')[0];
          this.CTI.ctiUser$.next(this.CTI.mynumber);
          // this.userState = this.CTI.dnd ? 'dnd' : (this.CTI.autocall ? 'autoAnswer' : 'online');
          this.CTI.ctiState$.next(this.CTI.dnd ? 'dnd' : (this.CTI.autocall ? 'autoAnswer' : 'online'));

          this.CTI.onDialogSub = this.CTI.chatEventDialogs$.subscribe(count => {
            if (this.CTI.debug) console.log('%cПодписка на диалоги:', 'font-size: 20px; background: cyan', '\r\n',
              '\tколичество диалогов: '+count, '\r\n',
              '\tколичество вызовов: ', this.CTI.lines_calls.length || this.CTI.outbound_calls.length, '\r\n',
              '\tстатус агента: ', this.CTI.agentStatus.value, '\r\n',
              '\tменяется ли статус агента: ', this.CTI.isStatusChanging ? 'да' : 'нет', '\r\n'
            );
            if (count != null && this.CTI.agentStatus.value != null) {
              if (this.CTI.isStatusChanging) {
                // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
                this.CTI.checkAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
                if (this.CTI.debug) console.log('\tустановлен флаг обновления статуса агента.');
                this.cdr.detectChanges();
              } else {
                this.CTI.checkAgentStatusAfterUpdate = false;
                if (this.CTI.debug) console.log('\tобновление статус агента по флагу...');
                this.cdr.detectChanges();
                this.CTI.checkAndUpdateAgentStatus();
              }
            }
          });

          // если нет вызовов, то запрашиваю статусы агента, иначе это запросится в аттаче
          if (this.CTI.lines_calls.length == 0 &&
            (!localStorage.getItem('calls_dt_start') || Object.keys(JSON.parse(localStorage.getItem('calls_dt_start'))).length == 0)
          ) {
            this.CTI.verto.get_agent_status_verto().pipe(take(1)).toPromise().then(
              data => {
                if (data) {
                  this.CTI.agentStatusPrev = data;
                  this.CTI.agentStatus.setValue(data, {emitEvent: true});
                  if (this.CTI.debug) console.log('Первичное получение статуса агента при включении телефона: ' + this.CTI.agentStatus.value);
                  this.CTI.startAgentStatusTimer();

                  if (this.CTI.isStatusChanging) {
                    // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
                    this.CTI.checkAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
                    if (this.CTI.debug) console.log('\tустановлен флаг обновления статуса агента.');
                    this.cdr.detectChanges();
                  } else if (this.ctiSettings.agent_status_on_login && this.ctiSettings.agent_status_on_login != data) {
                    this.CTI.agentStatusPrev = this.ctiSettings.agent_status_on_login;
                    this.CTI.agentStatus.setValue(this.ctiSettings.agent_status_on_login, {emitEvent: true});
                    if (this.CTI.debug) console.log('\tустанавливаю статус из настроек CTI-панели при регистрации: ' + this.CTI.agentStatus.value);
                    this.CTI.changeAgentStatus(this.ctiSettings.agent_status_on_login, 1);
                  } else {
                    this.CTI.checkAgentStatusAfterUpdate = false;
                    if (this.CTI.debug) console.log('\tобновление статуса агента по флагу...');
                    this.cdr.detectChanges();
                    this.CTI.checkAndUpdateAgentStatus();
                  }

                  this.cdr.detectChanges();
                }
              }
            );

            if (this.CTI.omniAgent) {
              if (this.CTI.isOmniStatusChanging) {
                // не могу сейчас установить статус, т.к. тот, что текущий, возможно неактуальный
                this.CTI.checkOmniAgentStatusAfterUpdate = true; // говорю проверить по окончании смены статуса
                this.cdr.detectChanges();
              } else {
                this.CTI.checkOmniAgentStatusAfterUpdate = false;
                this.cdr.detectChanges();
                this.CTI.checkAndUpdateOmniAgentStatus();
              }
            }
          }

          this.appService.subscribe([{name: 'domain_user_agent_status', params: {params: this.CTI.mynumber}}]);

          // подписка на выполнение вызова из другого компонента
          this.CTI.ctiActionsSub = this.CTI.ctiActions$.pipe(throttleTime(800), distinctUntilChanged())
            .subscribe(data => {
            if (data.obj == 'call') {
              // проверяю, что не сам себе звонит
              if (data.number == this.CTI.mynumber) return;

              // проверяю, что не звонит повторно на номер, который еще не ответил, если ответил, то ладно пусть звонит еще
              if (
                // проверяю, что вызова нет в списке исходящих, т.к. здесь лежат вызовы, поток которых даже не поднят еще
                this.CTI.outbound_calls.filter(c => c.options?.callee_id_number == data.number).length > 0 ||
                // проверяю статус вызова, если есть еще неотвеченный, то уведомляю об этом
                this.CTI.lines_calls.filter(c => c.call?.options?.callee_id_number == data.number && c.call.status == 'ringing').length > 0
              ) {
                // this.notifyService.message('CALL_ALREADY_EXISTS', {number: data.number});
                return;
              }

              // проверяю, что еще нет исходящего вызова и тогда звоню, иначе говорю, что нет свободной линии
              if (this.CTI.outbound_calls.length == 2 || this.CTI.lines_calls.length == 2) {
                this.notifyService.message('LINE_BUSY');
                return;
              }

              switch (data.action) {
                case 'makeCall':
                  this.CTI.onCall(data.number, data.name, !!data?.video);
                  break;

                case 'transferCall':
                  if (this.transferBtn && data.number) {
                    this.openModalBox('transferPanel', this.transferBtn, {direction: this.CTI.lines_calls[this.CTI.curLine]?.call.direction});

                    if (this.CTI.filterEntityType.value != -1) {
                      this.CTI.filterEntityType.setValue(-1, {emitEvent: false});
                      this.CTI.updateFilterEntity('filterEntityType', -1, data.number);
                    }

                    this.transferNumber.nativeElement.value = data.number;

                    if (this.CTI.blindTransferMode.value.length > 0 && !data.isBlind) {
                      this.CTI.updateBlindTransferMode(false);
                      this.CTI.blindTransferMode.setValue([]);
                    } else if (this.CTI.blindTransferMode.value.length == 0 && data.isBlind) {
                      this.CTI.updateBlindTransferMode(true);
                      this.CTI.blindTransferMode.setValue([true]);
                    }

                    this.CTI.onTransfer(this.CTI.lines_calls[this.CTI.curLine]?.call?.id, data.number, data.isBlind);
                  }
                  break;
              }
            }
          });
          this.cdr.detectChanges();

          navigator.mediaDevices.getUserMedia({audio: true}).then(
            local_stream => {},
            error => {
              this.CTI.userStateError = true;
              this.notifyService.message(this.translate.instant('MICROPHONE_IS_REQUIRED'));
              this.cdr.detectChanges();
            }
          );
        }
      }

      this.cdr.detectChanges();
    });

    this.CTI.verto.phone.subscribeEvent('event', data => {
      if (this.CTI.verto.isLogged()) {
        console.log(data);
      }
    });

    this.CTI.verto.phone.subscribeEvent('invite', call => {
      this.CTI.notificationBarService.closeOldMessages(this.CTI.lines_calls.map(c => c.call.id), 'call'); // очищаю старые зависшие сообщения, если такие есть
      if (this.CTI.verto.isLogged()) {
        let cause = '';
        if (this.CTI.ctiState$.value == 'dnd') cause = 'Do Not Disturb'; // Установлено "Не беспокоить"
        else if (this.CTI.lines_calls.length == 2) cause = 'USER_BUSY'; // достигнуто максимальное количество линий
        // else if (this.CTI.deny_inbound_call && this.CTI.lines_calls.length == 1) cause = 'DENY_INBOUND_CALL'; // при выставленной одноканальности // закомментировано, т.к. проверяется на уровне Vertojs
        else if (this.CTI.lines_calls.length == 1 && this.CTI.isEavesdrop(this.CTI.lines_calls[0])) cause = 'EAVESDROP'; // при суфлировании
        else if (call.options.caller_id_number == this.CTI.mynumber) cause = 'USER_BUSY';

        if (cause) {
          this.CTI.onHangup(call.id, {cause: cause, causeCode: 486});
        } else {
          let incallEvent = new CustomEvent("incall", {'detail': {number: call.options.caller_id_number, name: call.options.caller_id_name}});
          this.elemRef.nativeElement.dispatchEvent(incallEvent);
          // window.postMessage({action: 'incall', number:  call.options.caller_id_number, name: call.options.caller_id_name}, '*');
          // // подписка на postMessage:
          // // window.addEventListener("message", (evt) => console.log(evt), false);
          call.status = 'ringing';
          call.direction = 1;
          call.options.callee_id_number = this.CTI.mynumber;
          call.options.callee_id_name = this.CTI.mynumber;
          this.CTI.lines_calls.push({call: call});

          console.log(call.options.caller_id_number);
          console.log(call.options.caller_id_name);

          this.CTI.refreshCTICalls();
          this.CTI.refreshLocalStorageCalls();

          // уведомление о вызове контакта/компании из CRM
          if (!(call.options.caller_id_name == 'Eavesdrop' || call.options.callee_id_name == 'Eavesdrop')) {
            let permission = Notification.permission;

            let caller_name_req: Observable<any> = observableOf({list: []});

            for (let o of (this.ctiSettings.caller_name_search_order || ['crm', 'book', 'user', 'custom', 'caller_id_name'])) {
              let req = null;

              switch (o) {
                case 'user':
                  if (this.CTI.entityTypes.indexOf(1) != -1 && call.options.caller_id_number.length <= 9) req = this.CTI.userService.list(
                    {
                      limit: 1,
                      offset: 0,
                      filter: {field_list: [{field: 'domain_user.user_id.uid', condition_type: 0, value: call.options.caller_id_number}], type: 1}
                    },
                    'select'
                  ).pipe(
                    catchError(data => observableOf({list: [], notificationKey: 'NEW_INBOUND_CALL_BODY', type: 'user'})),
                    map(userList => {
                      if (userList?.list?.length > 0) {
                        call.options.fio = userList.list[0].name + (userList.list[0].surname ? ' ' + userList.list[0].surname : '');


                        return {list: [{name: call.options.fio}], notificationKey: 'NEW_INBOUND_CALL_BODY', type: 'user'};
                      } else return {list: [], notificationKey: 'NEW_INBOUND_CALL_BODY', type: 'user'};
                    })
                  );
                  break;
                case 'book':
                  if (this.CTI.entityTypes.indexOf(4) != -1) req = this.CTI.addressBookService.list(
                    {
                      limit: 1, offset: 0,
                      filter: {field_list: [{field: 'phones@T', value: call.options.caller_id_number, condition_type: 3}], type: 1}
                    }, 'select'
                  ).pipe(
                    catchError(data => observableOf({list: [], notificationKey: 'NEW_INBOUND_CALL_BODY', type: 'book'})),
                    map(addressList => {
                      if (addressList?.list?.length > 0) {
                        call.options.fio = addressList.list[0].name;


                      }
                      return {list: addressList?.list, notificationKey: 'NEW_INBOUND_CALL_BODY', type: 'book'};
                    })
                  );
                  break;
                case 'caller_id_name':
                  if (call.options.caller_id_number == call.options.caller_id_name) {
                    req = observableOf({list: [], notificationKey: 'NEW_INBOUND_CALL_BODY', type: 'caller_id_name'}); // пустой массив, потому что не нужно отображать номер на плитке дважды
                  } else {
                    req = observableOf([{name: call.options.caller_id_name}]).pipe(
                      map((data) => {
                        call.options.fio = data[0].name;

                        return {list: [{name: data[0].name}], notificationKey: 'NEW_INBOUND_CALL_BODY', type: 'caller_id_name'};
                      })
                    );
                  }
                  break;
                case 'custom':
                  if (this.CTI.ctiSettings?.custom_caller_name?.str_list?.length > 0) {
                    req = this.domainService.get_data({source_type: 'call', obj_ident: call.id}).pipe(
                      catchError(data => observableOf({list: [], notificationKey: 'NEW_INBOUND_CALL_BODY', type: 'custom'})),
                      map(session_data => {
                        if (session_data?.body) {
                          call.options.fio = '';
                          for (let phraseInd = 0; phraseInd < this.CTI.ctiSettings.custom_caller_name?.str_list.length; phraseInd++) {
                            let str = this.CTI.ctiSettings.custom_caller_name?.str_list[phraseInd];
                            for (let param in this.CTI.ctiSettings.custom_caller_name?.vars) {
                              let paramValue;
                              if (this.CTI.ctiSettings.custom_caller_name?.vars[param].source == 1) {
                                paramValue = this.JSONPath(this.CTI.ctiSettings.custom_caller_name?.vars[param].path, session_data.body?.call)[0];
                              } else if (this.CTI.ctiSettings.custom_caller_name?.vars[param].source == 2) {
                                paramValue = this.JSONPath(this.CTI.ctiSettings.custom_caller_name?.vars[param].path, session_data.body?.omni_dialog)[0];
                              }

                              // если параметр фразы обязательный, но его не оказалось в сессионных данных, то фразу не отображаю
                              if (
                                (paramValue == '' || paramValue == null) &&
                                str.includes('{{' + param + '}}') &&
                                this.CTI.ctiSettings.custom_caller_name?.vars[param].is_required
                              ) {
                                str = '';
                                break;
                              } else {
                                str = str.split('{{' + param + '}}').join(paramValue);
                              }
                            }
                            if (str && phraseInd != this.CTI.ctiSettings.custom_caller_name?.str_list.length-1) str += '<span class="m-r-4"></span>';

                            call.options.fio += str;
                          }

                        }
                        return {list: call.options.fio ? [call.options.fio] : [], notificationKey: 'NEW_INBOUND_CALL_BODY', type: 'custom'}; // если нет ни одной фразы, то возвращаю пустой список для поиска дальше
                      })
                    );
                  }
                  break;
                case 'crm':
                  if (this.authenticationService.isLic('Built-inCRM')) {
                    req = this.domainCRMService.list({search_string: call.options.caller_id_number}, 'search_by_phone').pipe(
                      catchError(resp => {
                        this.notifyService.setFormErrors(resp);
                        return observableOf(null);
                      }),
                      map(crmData => {
                        let notificationKey = 'CRM_NEW_CALL_BODY';
                        if (crmData != null) {

                          let notificationName = null;


                          let crmObj = crmData['body']['result'].length > 0 ? crmData['body']['result'][0] : {};
                          if (crmData['body']['result'].length > 0) {
                            notificationName = crmObj['name'];
                            call.options.fio = crmObj['name'];
                            const html = crmObj['name'];

                            if (crmObj.entity_type == 1) {
                              notificationKey = 'CONTACT_CALLING';

                              // if (window.location.pathname.startsWith('/omni/desktop') && this.authenticationService.isLic('Built-inCustomerChat')) {
                              //   msgData['buttons'][0]['routerLink'] = ['omni', 'desktop'];
                              // } else if (this.authenticationService.isLic('Desktop')) {
                              //   msgData['buttons'][0]['routerLink'] = ['desktop'];
                              // } else msgData['buttons'][0]['routerLink'] = ['crm', 'contacts', 'edit', crmObj.id];
                            } else {
                              notificationKey = 'COMPANY_CALLING';
                              // msgData['buttons'][0]['matTooltip'] = 'CRM.ACCEPT_COMPANY_CALL_HINT';
                              //
                              // if (window.location.pathname.startsWith('/omni/desktop') && this.authenticationService.isLic('Built-inCustomerChat')) {
                              //   msgData['buttons'][0]['routerLink'] = ['omni', 'desktop'];
                              // } else if (this.authenticationService.isLic('Desktop')) {
                              //   msgData['buttons'][0]['routerLink'] = ['desktop'];
                              // } else msgData['buttons'][0]['routerLink'] = ['crm', 'companies', 'edit', crmObj.id];
                            }
                            return {list: [{name: notificationName}], crmObj: crmObj,  notificationKey: notificationKey, type: 'crm', html: html};
                          } else {
                            call.options.fio = this.translate.instant('UNKNOWN_NUMBER');
                            const html = this.translate.instant('FROM_NUMBER', {number: call.options.caller_id_number});
                            return {list: [], notificationKey: notificationKey, type: 'crm', html: html};
                          }
                        } else return {list: [], notificationKey: notificationKey, type: 'crm'};
                      })
                    );
                  }
                  break;
              }

              if (req) {
                caller_name_req = caller_name_req.pipe(concatMap(data => {
                  console.log('%cРезультат выполнения запроса:', 'color: red; background: lightblue;', data);
                  return !data?.list?.length ? req : observableOf(data);
                }));
              }
            }

            const storage = JSON.parse(this.authenticationService.secureStorage.getItem('currentUser'));
            const call_processor = storage.call_processor;

            let routerLink = this.window.location.pathname;
            let routerLinkTab = null;

            caller_name_req.pipe(
              switchMap(data => {

                return this.domainService.get_data({source_type: 'call', obj_ident: call.id}).pipe(
                  map(crm_data=>{

                        return {
                          call_id: call.id,
                          call_uuid: crm_data.body?.call?.call_data?.uniqueid,
                          dialog_id: crm_data.body?.omni_dialog?.id,
                          ...data
                        }
                      })


                // return this.domainCRMService.exchange_call_id({uuid: call.id}).pipe(
                //   map(crm_data=>{
                //
                //     return {
                //       call_uuid: crm_data.body || call.id,
                //       ...data
                //     }
                //   })


                )})
            ).subscribe(
              data => {
                let msgData = {
                  header: 'INBOUND_CALL',
                  id: call.id, // при завершении вызова, будет скрыто уведомление, связанное с ним
                  timeout: 0, // не скрывать автоматически
                  objType: 'call'
                } as DialogBoxMessageObject;

                let crmObj = {}
                let notificationKey = 'CRM_NEW_CALL_BODY';
                let notificationName = call.options.fio;
                let tooltip = 'CRM.ACCEPT_UNKNOWN_CALL_HINT';

                switch (call_processor) {
                  case 1:
                    break;
                  case 3:
                    if (data?.crmObj?.entity_type == 1) {
                      notificationKey = 'CONTACT_CALLING';
                      tooltip = 'CRM.ACCEPT_CONTACT_CALL_HINT';
                      routerLink = ['crm', 'contacts', 'edit', data?.crmObj?.id];
                    } else if (data?.crmObj?.entity_type == 0) {
                      notificationKey = 'COMPANY_CALLING';
                      tooltip = 'CRM.ACCEPT_COMPANY_CALL_HINT';

                      routerLink = ['crm', 'companies', 'edit', data?.crmObj?.id];
                    } else routerLink = '/crm/contacts/edit'
                    break;
                  case 4:
                    routerLink = '/desktop'
                    break;
                  case 41:
                    routerLink = '/desktop'
                    routerLinkTab = 'crm'
                    break;
                  case 2:
                    routerLink = '/omni/desktop'
                    routerLinkTab = 'dialog'
                    break;
                  case 21:
                    routerLink = '/omni/desktop'
                    routerLinkTab = 'crm'
                    break;
                  case 22:
                    routerLink = '/omni/desktop'
                    routerLinkTab = 'form'
                    break;
                  case 23:
                    routerLink = '/omni/desktop'
                    routerLinkTab = 'script'
                    break;
                }

                console.log('CALL_UUID', data?.call_uuid)
                console.log('CALL_ID', data?.call_id)
                console.log('DIALOG_ID', data?.dialog_id)
                console.log('routerLink', routerLink)
                console.log('routerLinkTab', routerLinkTab)

                if (data?.html) {
                  msgData['html'] = data?.html
                }

                if (data?.crmObj) {
                  crmObj = data?.crmObj
                  notificationName = data?.crmObj['name'];
                }

                msgData['buttons'] = [
                  {
                    id: 'answer' + call.id,
                    type: 'mat-raised-button',
                    html: 'CRM.ACCEPT_CALL',
                    matTooltip: tooltip,
                    classNames: 'p-l-10 p-r-10 m-b-5',

                    routerLink: routerLink,
                    queryParams: {
                      tab: routerLinkTab,
                      call_uuid: data?.call_uuid,
                      call_id: data?.call_id,
                      dialog_id: data?.dialog_id
                    },

                    icon: 'call',
                    iconClassNames: 'text-green',
                    cb: this.CTI.answer, // функция
                    cbContext: this.CTI, // ее контекст
                    cbParams: [call.id, false, crmObj] // параметры, передаваемые в функцию
                  } as DialogBoxMessageButtonObject,

                  {
                    id: 'hangup' + call.id,
                    type: 'mat-raised-button',
                    html: 'CRM.REJECT_CALL',
                    matTooltip: 'CRM.REJECT_CALL_HINT',
                    classNames: 'p-l-10 p-r-10 m-b-5',

                    icon: 'call_end',
                    iconClassNames: 'text-warning',

                    cb: this.CTI.onHangup,
                    cbContext: this.CTI,
                    cbParams: [call.id]
                  } as DialogBoxMessageButtonObject
                ];

                if (this.CTI.lines_calls.find(c => c.call.id == call.id)) { // проверяю наличие вызова, т.к. пока запрос поиска контакта/компании выполнится, вызов уже может быть завершен по какой-либо причине
                  let line_call = this.CTI.lines_calls.find(c => c.call.id == call.id);
                  line_call.crmObj = crmObj;

                  console.log('%cСтатус вызова (' + call.id + ') после выполнения запросов: ' + line_call.call.status, 'background-color: lightgreen;');
                  if (this.CTI.lines_calls.find(c => c.call.id == call.id)?.call.status == 'ringing') {
                    this.CTI.notificationBarService.message(msgData); // показываю уведомление для !неотвеченного

                    if (this.CTI.lines_calls.length == 1) {
                      this.CTI.line(0);
                      if (this.CTI.curLine != 0 && this.dtmfInp) {
                        this.dtmfInp.nativeElement.value = '';
                        this.cdr.detectChanges();
                      }
                      if (this.CTI.autocall) {
                        this.CTI.autocallTimeoutNumber = setTimeout(_ => {
                          console.log('%cСтатус вызова (' + call.id + ') перед автоответом: ' + this.CTI.lines_calls.find(c => c.call.id == call.id)?.call.status, 'background-color: lightgreen;');
                          if (this.CTI.lines_calls.find(c => c.call.id == call.id)?.call?.status == 'ringing') {
                            console.log('%cАвтоответ на вызов: ' + call.id, 'background-color: lightgreen;');
                            this.answer(call.id);
                          } else {
                            console.log('%cОткрытие карточки на вызов, отвеченный по кнопке "Ответить": ' + call.id, 'background-color: lightgreen;');
                            // вызываю открытие карточки уже после ответа на вызов
                            this.CTI.openCRMCard(this.CTI.lines_calls.find(c => c.call.id == call.id));
                          }

                          // ИНАЧЕ ничего не делаю, т.к. есть уведомление, в котором содержатся данные CRM-объекта, и будет кликнуто оно
                        }, (this.CTI.autowait || 5) * 1000);
                      }
                    }

                    if (permission === 'granted') this.showNotification(call, notificationKey, notificationName);
                    else if (permission === 'default') this.requestAndShowPermission(call, notificationKey, notificationName);
                  } else {
                    console.log('%cОткрытие карточки на вызов, отвеченный по кнопке "Ответить": ' + call.id, 'background-color: lightgreen;');
                    // вызываю открытие карточки уже после ответа на вызов, т.к. уведомления о входящем уже не отобразится, т.к. вызов уже отвечен
                    this.CTI.openCRMCard(this.CTI.lines_calls.find(c => c.call.id == call.id));
                  }
                }

                this.CTI.update_last_calls(call.options.caller_id_number, (call.options.fio || call.options.caller_id_name), 1);
              }
            );
          } else if (this.CTI.lines_calls.length == 1) {
            this.CTI.line(0);
            if (this.CTI.curLine != 0 && this.dtmfInp) {
              this.dtmfInp.nativeElement.value = '';
              this.cdr.detectChanges();
            }
            if (this.CTI.autocall) setTimeout(_ => {
              if (this.CTI.lines_calls.find(c => c.call.id == call.id)?.call?.status == 'ringing') {
                console.log('%cАвтответ на вызов при суфлировании: ' + call.id, 'background-color: lightgreen;');
                this.answer(call.id);
              }
            }, (this.CTI.autowait || 5) * 1000);
          }
        }

        this.cdr.detectChanges();
      }
    });

    this.CTI.verto.phone.subscribeEvent('answer', params => {
      if (this.CTI.verto.isLogged()) {
        this.CTI.start_timer(params.callID);
        let call = this.CTI.lines_calls.find(call => call.call.id == params.callID);
        if (call) {
          call.call.status = 'active';
          if (this.CTI.isMuted) this.CTI.toggleMic(true);
          if (this.CTI.isCam) this.CTI.toggleCam(true);
          this.CTI.refreshCTICalls();
          this.CTI.refreshLocalStorageCalls();
          // перехожу на линию, где получила ответ на вызов, т.к. вызов активен
          let callLineNumber = this.CTI.lines_calls.findIndex(c => c.call.id == call.call.id);
          if (callLineNumber != this.CTI.curLine) {
            this.CTI.line(callLineNumber);
            if (this.dtmfInp) {
              this.dtmfInp.nativeElement.value = '';
              this.cdr.detectChanges();
            }
          }
        }
        this.cdr.detectChanges();
      }
    });

    this.CTI.verto.phone.subscribeEvent('bye', bye => {
      if (this.CTI.verto.isLogged()) {
        this.CTI.clearCall(bye);
        this.cdr.detectChanges();
      }
    });

    this.CTI.verto.phone.subscribeEvent('display', display => {
      if (this.CTI.verto.isLogged()) {
        let call = this.CTI.lines_calls.find(c => c.call.id == display.callID);
        if (call && (call.call.options.caller_id_number != display.caller_id_number || call.call.options.callee_id_number != display.callee_id_number)) {
          // поменялся абонент, с которым разговариваю => надо отобразить изменения + снять с удержания
          call.call.options.caller_id_number = display.caller_id_number;
          call.call.options.caller_id_name = display.caller_id_name;
          call.call.options.callee_id_number = display.callee_id_number;
          call.call.options.callee_id_name = display.callee_id_name;

          if (call?.call.status == 'held') this.CTI.toggleHold(call);

          if (display?.caller_id_name=='Eavesdrop' || display?.callee_id_name=='Eavesdrop') {
            const regexp = /Eavesdrop_(\w+)_(\w+)/gm;

            let str = (display?.caller_id_name=='Eavesdrop') ? (display?.caller_id_number) : (display?.callee_id_number);
            let matchAll: any = Array.from(str.matchAll(regexp));

            if (matchAll.length>0) {
              const match = Array.from(matchAll[0]);
              if (match.length===3) {
                this.eavesdrop1 = match[1] as string;
                this.eavesdrop2 = match[2] as string;

              }
            }

            if (!call.call.eavesdrop) call.call.eavesdrop = 0;
          }

          this.cdr.detectChanges();
          this.CTI.refreshCTICalls();
          this.CTI.refreshLocalStorageCalls();
        }
      }
    });

    this.CTI.verto.phone.subscribeEvent('punt', punt => {
      // игнорирую событие при выключенном телефоне, а то сообщение "Неправильный пароль" будет вымещено сообщением "Зарегистрирован на др. вкл."
      if (this.CTI.verto.isLogged()) {
        // Событие регистрации телефона в другой вкладке (доступно, если в домене выставлена опция "Регистрация телефона в новой вкладке" = 0).
        // Данный параметр позволит сохранить в хранилище данные по вызовам.
        this.CTI.punt = true;

        // Выхожу из телефона с сохранением данных о времени и состоянии вызовов в локальном хранилище.
        this.CTI.onLogout();
        this.appService.unsubscribe(['domain_user_agent_status']);
        this.notifyService.message('CTI.REG_ON_ANOTHER_TAB');
      }
    });

    this.CTI.verto.phone.subscribeEvent('event', params => {
      console.log('Event >>>>>>>>>>>>>');
      console.log(params);

      if (params.pvtData) {
        switch (params.pvtData.action) {
          case "conference-liveArray-join":
            if (!params.pvtData.screenShare && !params.pvtData.videoOnly) {
              console.log("conference-liveArray-join");
              this.CTI.stopConference();
              this.CTI.startConference(this.CTI.lines_calls[this.CTI.curLine]?.call, params.pvtData);
              this.CTI.updateVideoSize();
            }
            break;
          case "conference-liveArray-part":
            if (!params.pvtData.screenShare && !params.pvtData.videoOnly) {
              console.log("conference-liveArray-part");
              this.CTI.stopConference();
            }
            break;
        }
      }
      else if (params?.eventData?.contentType === "layout-info") {
          this.CTI.canvasData[params.eventData.canvasInfo.canvasID] = params.eventData.canvasInfo;

          console.log(this.CTI.video)
          // this.update_overlay();
      }
      else if (params?.data?.action == 'response') {
        switch (params?.data['conf-command']) {
          case "list-videoLayouts":
            this.CTI.confLayouts = params.data.responseData;
            break
        }
      }
      else if (this.CTI.verto.conf && this.CTI.verto?.conf?.laChannel == params?.eventChannel) {
        switch (params?.data?.action) {
          case "init":
            this.CTI.verto.conf.init(params.data);
            break;

          case "bootObj":
            this.CTI.verto.conf.bootObj(params.data);
            break;

          case "add":
            this.CTI.verto.conf.add(params.data);
            break;

          case "modify":
            this.CTI.verto.conf.modify(params.data);
            break;

          case "del":
            this.CTI.verto.conf.del(params.data);
            break;

          case "clear":
            this.CTI.verto.conf.clear(params.data);
            break;

          case "reorder":
            this.CTI.verto.conf.reorder(params.data);
            break;
        }

      }
    });

    this.CTI.verto.phone.subscribeEvent('broadcast', params => {
      console.log('Broadcast >>>>>>>>>>>>>');
      console.log(params);
    });
  }

  update_overlay(){

  }

  answer(callID){ // клик по кнопке из уведомления, если оно есть
    if (this.CTI.autocallTimeoutNumber) clearTimeout(this.CTI.autocallTimeoutNumber);
    console.log('%cПринятие вызова: ' + callID, 'background-color: lightgreen;', 'crmObj:', this.CTI.lines_calls.find(c => c.call.id == callID)?.crmObj);
    let eventAnswerBtn = document.getElementById('answer'+callID);
    if (eventAnswerBtn) eventAnswerBtn.click();
    else this.CTI.answer(callID, false);
  }

  hideMatAutoComplete(mac) {
    setTimeout(() => mac?.closePanel(), 50);
  }

  openModalBox(id: string, triggerElem, params: any = {}) {
    const rect = triggerElem.getBoundingClientRect? triggerElem.getBoundingClientRect() : triggerElem._elementRef.nativeElement.getBoundingClientRect();
    let openingBox = document.getElementById(id);
    openingBox.style.position = 'absolute';
    openingBox.style['padding-left'] = `${rect.left}px`;
    openingBox.style['padding-top'] = `${rect.bottom}px`;
    this.modalService.open(id);
    if (id == 'dtmfBtns') setTimeout(() => {if (this.dtmfInp) this.dtmfInp.nativeElement.focus();});
    if (id == 'transferPanel') setTimeout(() => {
      if (this.transferNumber) {
        this.CTI.clearEntityCounters();
        this.CTI.search$.next(this.CTI.lines_calls[this.CTI.curLine]?.transfering?.number || '');
        // очистка поля ввода номера для перевода (т.к. он может быть на другой линии)
        this.transferNumber.nativeElement.value = this.CTI.lines_calls[this.CTI.curLine]?.transfering?.number || '';
        this.cdr.detectChanges();
        this.transferNumber.nativeElement.focus();
      }

      this.CTI.getCallHotKeys(params.direction);
    });
    if (id == 'numberSearchPanel') setTimeout(() => {
      if (this.searchNumber) {
        this.searchNumber.nativeElement.value = this.numberToCall.nativeElement.value;
        this.CTI.searchToCall$.next(this.searchNumber.nativeElement.value);
        this.cdr.detectChanges();
        this.searchNumber.nativeElement.focus();
      }
    });
  }

  openBigBox(id: string, width: string = '100%') {
    let openingBox = document.getElementById(id);
    openingBox.style.position = 'absolute';
    openingBox.style['padding-left'] = `0px`;
    openingBox.style['width'] = width;
    openingBox.style['padding-top'] = `60px`;
    this.modalService.open(id);
  }

  showNotification(call, key: string = 'NEW_INBOUND_CALL_BODY', name: string|null = null) {
    if (this.CTI.debug) {
      console.log('%cdocument.visibilityState: ' + document.visibilityState, 'background: cyan; font-size: 16px;');
      console.log('%cdocument.hidden: ' + document.hidden, 'background: cyan; font-size: 16px;');
      console.log('%cwindow is active: ' + this.windowActive, 'background: cyan; font-size: 16px;');
    }
    if (document.visibilityState === 'visible' /*&& this.windowActive*/) {
      return;
    }
    let title = this.translate.instant('NEW_INBOUND_CALL');

    let body = this.translate.instant(key, {ABONENT: call?.caller_id_number || call?.options?.caller_id_number, name: name});
    let notification = new Notification(title, { body });
    notification.onclick = () => {
      notification.close();
      window.parent.focus();
    }
  }

  requestAndShowPermission(call, key: string = 'NEW_INBOUND_CALL_BODY', name: string|null = null) {
    Notification.requestPermission((permission) => {
      if (permission === 'granted') {
        this.showNotification(call, key, name);
      }
    });
  }

  showChat() {
    return this.CTI.showChat()
  }

  onActivityClick() {

  }
}
