import {required, prop, maxLength, minNumber, maxNumber, digit, propObject, unique} from '@rxweb/reactive-form-validators';


export class DomainChat {
  @prop()
  id?: number;

  static className() {
    return 'DomainPersonalChat';
  };
}
