import { NgModule } from '@angular/core';
import { MatSelectUserComponent } from './mat-select-user.component';
import {MaterialModule} from '../../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {CdkTableModule} from '@angular/cdk/table';
import {PipeModule} from '../../_helpers/pipe.module';
import {NgMathPipesModule} from 'ngx-pipes';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkTableModule,
    PipeModule,
    NgMathPipesModule,
    NgSelectModule
  ],
  declarations: [
    MatSelectUserComponent
  ],
  exports: [
    MatSelectUserComponent
  ]
})
export class MatSelectUserModule { }
