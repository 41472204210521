import 'hammerjs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { SkeletonLoaderModule } from '../../_helpers/skeleton-loader.module';
import {MatSelectUserModule} from '../../material-component/mat-select-user/mat-select-user.module';
import { MaterialModule } from '../../material.module';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../_helpers/pipe.module';
import { MatTableResponsiveModule } from '../../material-component/mat-table-responsive/mat-table-responsive.module';
import {DomainDialogScriptFormComponent} from './scripts/domain-dialog-script-form.component';
import {DomainDialogScriptListComponent} from './scripts/domain-dialog-script-list.component';
import {DomainDialogScriptNodeDialogComponent} from './scripts/domain-dialog-script-node-dialog.component';
import {DomainDialogScriptNodeComponent} from './scripts/domain-dialog-script-node.component';
import {NgArrayPipesModule} from "ngx-pipes";
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {
  DomainDialogScriptFormListComponent
} from "./forms/domain-dialog-script-form-list.component";
import {
  DomainDialogScriptFormFormComponent, DomainDialogScriptFormViewDialogComponent,
} from "./forms/domain-dialog-script-form-form.component";
import {DomainDialogScriptFormViewComponent} from "./forms/domain-dialog-script-form-view.component";
import {DomainHideContactCallModule} from "../../reports/domain-hide-contact/domain-hide-contact-call.module";
import {NgxMatDatetimePickerModule} from "@angular-material-components/datetime-picker";
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";
import {DomainDialogScriptResultComponent} from "./scripts/domain-dialog-script-result.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkTableModule,
    PipeModule,
    MatTableResponsiveModule,
    SkeletonLoaderModule,
    NgSelectModule,
    MatSelectUserModule,
    NgArrayPipesModule,
    NgxJsonViewerModule,
    DomainHideContactCallModule,
    NgxMatDatetimePickerModule,
    RxReactiveFormsModule
  ],
  providers: [],
  declarations: [
    DomainDialogScriptListComponent, DomainDialogScriptFormComponent, DomainDialogScriptResultComponent,
    DomainDialogScriptNodeComponent, DomainDialogScriptNodeDialogComponent,
    DomainDialogScriptFormListComponent, DomainDialogScriptFormFormComponent,
    DomainDialogScriptFormViewComponent, DomainDialogScriptFormViewDialogComponent
  ],
  exports: [
    DomainDialogScriptFormViewComponent,
    DomainDialogScriptResultComponent
  ]
})
export class DomainDialogScriptModule {}
