import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-edit-input',
  templateUrl: './edit-input.component.html',
  styleUrls: ['./edit-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(()=>EditInputComponent),
      multi: true
    }
  ]
})

export class EditInputComponent implements ControlValueAccessor {
  @Output() focusOut: EventEmitter<string> = new EventEmitter<string>();
  @Output() inputChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onInput: EventEmitter<string> = new EventEmitter<string>();
  @Input() editMode: boolean = false;
  value = '';
  disabled = false;
  @Input() withWrap: boolean = true;
  @Input() type = 'text';
  @Input() width = 75;
  @Input() height = 25;
  @Input() placeholder = '';
  @Input() suffix = '';
  @Input() min = null;

  private onChange = (value: any) => {};
  private onTouched = () => {};

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  writeValue(outsideValue) {
    // получить из Forms API
    this.value = outsideValue;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  updateValue(insideValue) {
    this.value = insideValue; // html
    this.onChange(insideValue); // уведомить Forms API
    this.onTouched();
  }

  onFocusOut() {
    this.focusOut.emit(this.value);
  }

  onInputEvt() {
    this.onInput.emit(this.value);
  }

  onInputChange() {
    if (typeof this.value == 'string') {
      this.inputChange.emit(this.value);
    }
  }













}
