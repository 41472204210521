import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {merge, Observable, of as observableOf, SubscriptionLike} from 'rxjs';
import {AppService} from '../../../../../app.service';
import {DesktopKeptService} from '../../desktop-kept.service';
import {NotifyService} from '../../../../../_helpers/notify.service';
import {DEPS4BService} from '../../../../../domains/marketplace/skype-for-business/s4b.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {BaseTableDirectoryComponent} from '../../../../_common/base-table-directory.component';
import {SendMailDialogComponent} from '../send-mail-dialog/send-mail-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {DesktopService} from '../../../../_common/base-desktop.service';
import {MatSelect} from '@angular/material/select';
import {OpenDADialogComponent} from '../open-da-dialog/open-da-dialog.component'
import {CTIService} from "../../../../../cti-panel/cti-panel.service";

@Component({
  selector: 'app-staff-directory',
  templateUrl: './staff-directory.component.html',
  styleUrls: ['./staff-directory.component.scss', '../../../../_common/desktop-table.scss'],
  providers: [DEPS4BService]

})
export class StaffDirectoryComponent extends BaseTableDirectoryComponent implements OnInit, AfterViewInit, OnDestroy {
  public config: PerfectScrollbarConfigInterface = {
    minScrollbarLength: 25,
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
    wheelSpeed: 2,
    wheelPropagation: true
  };
  @ViewChildren('filter') filtersInput: QueryList<ElementRef>;
  @ViewChildren('filterSelect') filtersSelect: QueryList<MatSelect>;
  private messages$; // сообщения сокета
  private messagesSub: SubscriptionLike;
  public skype_cir: {[key: string]: any } = {};
  constructor(public keptApi: DesktopKeptService,
              public api: DesktopService,
              public appService: AppService,
              public CTI: CTIService,
              public s4bService: DEPS4BService, public dialog: MatDialog,
              public notifyService: NotifyService) {
    super('StaffDirectoryComponent', api, notifyService);
    super.extParams = {
      sort: {name: '+'}
    };

    super.methods = this.methods;
    this.messages$ = this.appService.on<any>();
    this.messagesSub = this.messages$.subscribe(
      msg => this.onMessage(msg),
      err => console.log(err),
      () => console.log('complete')
    );

    this.appService.subscribe([{name: 'mss4b|user_state', params: {params: []}}]);

  }

  ngOnInit() {
    this.searchOff(false);
    super.ngOnInit();
  }

  ngOnDestroy() {
    this.appService.unsubscribe(['mss4b|user_state']);
    this.messagesSub.unsubscribe();
    super.ngOnDestroy();
  }

  getApi(params) {
    return this.keptApi.staffDirectory(params);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.api.searchOffEvent.subscribe(evt=> {
      if (evt == 'StaffDirectoryComponent') this.searchOff();
    });

    let status = this.filtersSelect?.find(item=>item.id == 'status');
    if (status) setTimeout( () => status.value = 'Current', 1000);

  }

  updateSourceSkypeState(message) {
    let user = message.user;
    if (user) {
      this.dataSource.data.filter(item=>item?.sip_address?.toLowerCase() == user?.toLowerCase())
        .forEach(item=>item.s4b_status = message);
      this.dataSource.data.filter(item=>item?.pa_sip_address?.toLowerCase() == user?.toLowerCase())
        .forEach(item=>item.pa_s4b_status = message);
      this.dataSource.data.filter(item=>item?.da_sip_address?.toLowerCase() == user?.toLowerCase())
        .forEach(item=>item.da_s4b_status = message);
      this.table.renderRows();
    }
  }

  public onMessage(msg: any = {}) {
    //console.log('form. received message:', msg);
    if (typeof msg === 'string') msg = JSON.parse(msg);
    let member_index, member;
    switch (`${msg.action}::${msg.obj}`) {
      case 'auth::WebSocketMember':  // событие установки соединения с сокетом, после которого нужно подписаться на нужные события
        let skype_user = [
          ...this.dataSource.data.filter(item=>!!item?.sip_address).map(item=>item?.sip_address?.toLowerCase()),
          ...this.dataSource.data.filter(item=>!!item?.pa_sip_address).map(item=>item?.pa_sip_address?.toLowerCase()),
          ...this.dataSource.data.filter(item=>!!item?.da_sip_address).map(item=>item?.da_sip_address?.toLowerCase())
        ];

        this.appService.subscribe( [{name: 'mss4b|user_state', params: {params: skype_user.length > 0 ? skype_user : []}}]);
        break;
      case 'subscribe::WebSocketMember':
        if (msg.code == 200) {
          // console.log('Запрашиваю данные из OnMessage');
          //this.getQueues();
          //this.getCustomStatus();
        }
        break;
      case 'update_state::MicrosoftSkypeForBusinessMember':
        if (msg.code == 200 && msg?.body?.user) {
          this.skype_cir[msg?.body?.user] = msg.body;
          this.updateSourceSkypeState(msg.body);
          // console.log(`Обновлен пользователь ${msg?.body?.user} - ${msg?.body}`);
        }
        break;

      // default:
      //   if (msg.action!=='ping') console.log(msg);
    }
  }

  getIcon(number) {
    return this.s4bService.getSkypeIcon(number);
  }
  getFilter() {
    if (this.empFilters.length>0) {
      let filterData = {filter: {field_list: [], type: 0}};
      for (let filter of this.empFilters) {

        let query = {
          field: filter.name,
          condition_type: filter.type // CONDITION_TYPE_EQUAL = 0, CONDITION_TYPE_STARTSWITH = 1,
          // CONDITION_TYPE_ENDSWITH = 2, CONDITION_TYPE_LIKE = 3
        };
        if (['name', 'position', 'business_unit', 'pa_name', 'da_name', 'country', 'office', 'local_name'].includes(filter.name)) {
          query['value_condition_type'] = 1;
          query['value_list'] = [filter.value, this.api.getTranslateText(filter.value)];

        } else {
          query['value'] = filter.value
        }
        filterData['filter']['field_list'].push(query);
      }
      return filterData;
    } else {
      return {};
    }
  }

  setDataSource(data) {
    super.setDataSource(data);
    this.updateSkype();
  }

  updateSkype() {
      this.s4bService.changeSkypeChannel(this.getSkypeUsers());
  }

  getSkypeUsers() {
    return [...new Set([
      ...this.dataSource.data.filter(item=>!!item?.sip_address).map(item=>item?.sip_address?.toLowerCase()),
      ...this.dataSource.data.filter(item=>!!item?.pa_sip_address).map(item=>item?.pa_sip_address?.toLowerCase()),
      ...this.dataSource.data.filter(item=>!!item?.da_sip_address).map(item=>item?.da_sip_address?.toLowerCase())
    ])];
  }


  sendMail(id: number) {
    const dialogRef = this.dialog.open(SendMailDialogComponent, {panelClass: 'dialog-compact',
      data: {
        id: id
      }
    });

  }

  openDA(business_unit: string) {
    const dialogRef = this.dialog.open(OpenDADialogComponent, {panelClass: 'dialog-compact',
      data: {
        skype_users: this.getSkypeUsers(),
        business_unit: business_unit
      }
    });

  }

  searchOff(refresh = true) {
    this.filtersInput?.forEach(item=>{
      item.nativeElement.value = '';
    });

    this.filtersSelect?.forEach(item=>{
      if (item.id == 'status') item.value = 'Current';
      else item.value = '';
    });

    this.empFilters = [];
    this.empFilters.push({name: 'status', value: 'Current', type: 0});
    if (refresh) {
      if (this.paginator && this.paginator.pageIndex != 0) this.paginator.pageIndex = 0;
      this.refresh();
    }
  }
}
