import {required, prop, maxLength, numeric, NumericValueType} from '@rxweb/reactive-form-validators';

export class DomainAgentStatus {
  @prop() id?: number;
  @required() @maxLength({value: 150}) name: string;
  @required() @numeric({acceptValue: NumericValueType.Both}) base_status_id: number; // -1 (В Очереди), -2 (В Очереди(Одиночный)), -3 (На перерыве), -4 (Вне очереди)
  @required() icon: string;
  @prop() hk: string; // '3', '4', '5', '6', '7', '8', '9'
  @prop() color: string;

  static className() {
    return 'DomainAgentStatus';
  }
}
