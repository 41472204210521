import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {AudioService} from '../../../audio/audio.service';
import {getUUID4} from '../../../_helpers/base.function';

@Component({
  selector: 'app-load-dialog',
  templateUrl: './loadDialog.component.html',
  styleUrls: ['./loadDialog.component.css']
})
export class LoadDialogComponent implements OnInit {
  files: FileList;
  message: string[] = [];
  progressInfos = []
  path: string = '/';

  constructor(public dialogRef: MatDialogRef<LoadDialogComponent>,
              public translate: TranslateService,
              public serviceApi: AudioService,
              @Inject(MAT_DIALOG_DATA) public data: {files: FileList, path: string}
  ) {
    this.files = data.files;
    this.path = data.path;
  }

  ngOnInit() {
    this.message = []
    this.progressInfos = [];
    for (let i = 0; i < this.files.length; i++) {
      this.upload(i, this.files[i]);
    }
  }

  upload(idx, file) {
    this.progressInfos[idx] = { value: 0, fileName: file.name, error: '' };
    const fd = new FormData();
    const domain_id = this.serviceApi.getDomain();
    fd.append('file_data', file, file.name);
    fd.append('action', 'append');
    fd.append('action_id', getUUID4(4));

    if (domain_id) {
      fd.append('domain_id', `${domain_id}`);
      fd.append('obj', 'DomainAudio');
    } else {
      fd.append('obj', 'SystemAudio');
    }

    fd.append('name', file.name);
    fd.append('folder', this.path || '/');

    if (this.serviceApi.getDomain()) {
      fd.append('options', JSON.stringify({source: 'BlankFileOptions'}));
    }

    this.serviceApi.upload(fd)
      .subscribe(res => {
        if (res.code == 200) {
          this.progressInfos[idx].value = 100;
          this.message.push(this.progressInfos[idx].fileName);
        } else  {

        }

      }, res => {
        this.progressInfos[idx].value = 0;
        let error_text = '';
        if (res.notifies) {
          for (let err of res.notifies) {
            if (err['msg_id']) {
              error_text = this.translate.instant('ERROR.'+err['msg_id'])
              this.progressInfos[idx].error = error_text;
            } else {
              this.progressInfos[idx].error = this.translate.instant('NOT_LOAD_FILE', {file: file.name});
            }
          }
        }


        //this.progressInfos[idx].error = this.translate.instant('NOT_LOAD_FILE', {file: file.name});
      });

  }
}
