import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class CustomPaginator extends MatPaginatorIntl {
  constructor(public translate: TranslateService) {
    super();
    this.setTranslate();
    this.translate.onLangChange.subscribe(() => {
      this.setTranslate();
    });
  }
  setTranslate(): void {
    this.translate.get([
      'ITEMS_PER_PAGE_LABEL',
      'NEXT_PAGE_LABEL',
      'PREVIOUS_PAGE_LABEL',
      'FIRST_PAGE_LABEL',
      'LAST_PAGE_LABEL',
      'RANGE_PAGE_LABEL'
    ]).subscribe((transl) => {
      this.nextPageLabel = transl['NEXT_PAGE_LABEL'];
      this.previousPageLabel = transl['PREVIOUS_PAGE_LABEL'];
      this.itemsPerPageLabel = transl['ITEMS_PER_PAGE_LABEL'];
      this.firstPageLabel = transl['FIRST_PAGE_LABEL'];
      this.lastPageLabel = transl['LAST_PAGE_LABEL'];
      this.getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
          return  `0 ${transl['RANGE_PAGE_LABEL']} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the domain-table length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return  `${startIndex + 1} - ${endIndex} ${transl['RANGE_PAGE_LABEL']} ${length}`;
      };
    });
  }
}
