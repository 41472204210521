import 'hammerjs';
import {Injector, Inject, NgModule} from '@angular/core';
import {Router, RouterModule, Routes, ROUTES} from '@angular/router';
import { CommonModule } from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import { MaterialModule } from '../../../../../material.module';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import {LangChangeEvent, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {PipeModule} from '../../../../../_helpers/pipe.module';
import {MatTableResponsiveModule} from '../../../../../material-component/mat-table-responsive/mat-table-responsive.module';
import {SMTPSettingsComponent} from './smtp-settings.component';
import {SkeletonLoaderModule} from '../../../../../_helpers/skeleton-loader.module';
import {HttpClient} from '@angular/common/http';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {ACLRoutes} from '../../../../../acl/acl.routing';

const SMTPRoutes: Routes = [
  {
    path: '',
    component: SMTPSettingsComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,

    TranslateModule.forChild(),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkTableModule,
    PipeModule,
    MatTableResponsiveModule,
    NgSelectModule,
    RouterModule.forChild(SMTPRoutes),
    SkeletonLoaderModule
  ],
  providers: [

  ],
  declarations: [
    SMTPSettingsComponent
  ]
})
export class SMTPSettingsModule {
  constructor(public translationService: TranslateService) {
    const currentLang = this.translationService.currentLang;
    this.translationService.use(currentLang);
    this.translationService.store.onLangChange.subscribe(
      (lang: LangChangeEvent) => {
        console.log(' ==> LazyLoadedModule ', lang);
        this.translationService.use(lang.lang);
      }
    );
  }
}


