import {maxLength, prop, required} from '@rxweb/reactive-form-validators';

export class DomainAddressBook {
  @prop() id?: number;
  @prop() domain_id: number;
  @required() @maxLength({value: 150}) name: string;
  @prop() comment: string = '';
  @prop() group_id: string = '';
  @prop({defaultValue:[]})
  phones: string[] = [];
  @prop({defaultValue:[]})
  emails: string[] = [];

  static className() {
    return 'DomainAddressBook';
  };
}
