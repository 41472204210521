import 'hammerjs';
import {CommonModule} from '@angular/common';
import { NgModule } from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import { MaterialModule } from '../material.module';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {SkeletonLoaderComponent} from './skeleton-loader.component';
import {SkeletonLoaderLegComponent} from './skeleton-loader-leg.component';
import {SkeletonLoaderCardComponent} from './skeleton-loader-card.component';

@NgModule({
  imports: [NgxSkeletonLoaderModule, MaterialModule, FlexLayoutModule, CommonModule],
  providers: [],
  exports: [
    SkeletonLoaderComponent, SkeletonLoaderLegComponent, SkeletonLoaderCardComponent
  ],
  declarations: [
    SkeletonLoaderComponent, SkeletonLoaderLegComponent, SkeletonLoaderCardComponent
  ]
})
export class SkeletonLoaderModule {}
