import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;

@Pipe({
  name: 'epochDT'
})

export class DateTimeEpochPipe implements PipeTransform {
  transform(value: number | undefined, lang='ru-RU', withSeconds: boolean = false, tz: string = 'Europe/Moscow'): string {
    // lang - передан для того, чтобы пайп заметил изменение языка и сам перерисовал дату
    if (value) {
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit',
        timeZone: tz };
      if (withSeconds) options['second'] =  '2-digit';

      return new Date(value * 1000).toLocaleString(lang, options);

    }
    return '-';  }

}
