import {prop, required} from '@rxweb/reactive-form-validators';

export class SystemTextTemplate {
  @required() name: string;
  @required() val: string;
  @required() category: number = 1;
  @required() owner_id: number = -1;
  @required() personal: boolean = false;
  @prop() version: number;
  @prop() param1: number | null = null;
  @prop() param2: number | null = null;

  static className() {
    return 'TextTemplate';
  };
}
