import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Helper, IResponseListObject, IResponseBodyObject} from '../_helpers/api.helpers';
import {Domain} from './domain';
import {Observable, BehaviorSubject, of, of as observableOf, iif, throwError} from 'rxjs';
import {catchError, delay, map, retryWhen, tap, concatMap} from 'rxjs/operators';
import {NotifyService} from '../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../config/json-app-config.service';
import {AuthenticationService} from "../auth/authentication.service";
const headers = new HttpHeaders().set('Content-Type', 'application/json');


@Injectable({
  providedIn: 'root'
})
export class DomainService {
  default_domain_name: any = {name: this.translate.instant('DOMAIN')};
  private domainNameMessageSource = new BehaviorSubject(this.default_domain_name.name);
  domainNameMessage = this.domainNameMessageSource.asObservable();
  constructor(private http: HttpClient, private notifyService: NotifyService, private authenticationService: AuthenticationService,
              private router: Router, private route: ActivatedRoute,
              public translate: TranslateService, private AppConfig: JsonAppConfigService) {
  }

  changeObjectName(obj: any) {
    this.domainNameMessageSource.next(obj.name);
  }

  list(params: any = {}, action: string = 'list'): Observable<IResponseListObject<Domain>> {
    const requestBody = Helper.requestFormat(action, 'Domain', params);
    this.changeObjectName(this.default_domain_name);
    return this.http.post<IResponseListObject<Domain>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers});
  }

  toSelect(params: any = {}, action: string = 'list'): Observable<Domain[]> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, 'Domain', params);
    return this.http.post<IResponseListObject<Domain>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }),
        map(data => data.list as Domain[]
        ),
        retryWhen(errors => errors.pipe(
          // delay(3000),
          // take(3)
          concatMap((e, i) =>
            // Executes a conditional Observable depending on the result
            // of the first argument
            iif(
              () => {return (i == 2) || e.message == '403'},
              // If the condition is true we throw the error (the last error)
              throwError(e),
              // Otherwise we pipe this back into our stream and delay the retry
              observableOf(e).pipe(delay(3000))
            ))
        )),
        catchError((error) => {
          console.log(error);
          return observableOf([] as Domain[]);
        })
      );
  }

  generate_password (params: any = {}): Observable<IResponseBodyObject<any>> {
    const requestBody = Helper.requestFormat('generate_password', 'Domain', params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers});
  }

  license(id = null) {
    const requestBody = Helper.requestFormat('get_available_options', Domain.className(), {
      id: id
    });
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers});
  }

  get(id: number, params: any = {}): Observable<Domain> {
    let ignore = {};
    if (params.ignore) {
      ignore = {...params.ignore};
      delete params['ignore'];
    }
    const requestBody = Helper.requestFormat('get', 'Domain', { id: id, ...params});
    return this.http.post<IResponseBodyObject<Domain>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          if (res.code != 200 && (!res.notifies.length || !(ignore[res.notifies[0].msg_id]))) this.notifyService.checkCode(res);
        }),
        map(data => {
          this.changeObjectName(data.body);
          return <Domain>data.body;
        })
      );
  }

  delete(id: number): Observable<IResponseBodyObject<Domain>>  {
    const requestBody = Helper.requestFormat('delete', 'Domain', {id: id});
    this.changeObjectName(this.default_domain_name);
    // @ts-ignore
    return this.http.post<IResponseBodyObject<Domain>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  save(domain: Domain): Observable<IResponseBodyObject<Domain>> {
    let requestBody = Helper.requestFormat('append', 'Domain', domain);
    if (domain.id) {
      requestBody = Helper.requestFormat('update', 'Domain', domain);
    }
    return this.http.post<IResponseBodyObject<Domain>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers}).pipe(
      tap(res => {
        this.notifyService.checkCode(res);
      })
    );
  }

  test(_id: number): Observable<IResponseBodyObject<Domain>>  {
    let requestBody = Helper.requestFormat('test_enable', 'Domain', {id: _id});
    return this.http.post<IResponseBodyObject<Domain>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        }));
  }

  details(id) {
    this.router.navigate([this.router.url + '/edit', id]);
  }

  move(dst_host_id: number, domain_id_list: number[]): Observable<Domain> {
    const requestBody = Helper.requestFormat('move', 'Domain', { dst_host_id: dst_host_id, domain_id_list: domain_id_list});
    return this.http.post<any>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  action(action, params: any = {}): Observable<IResponseBodyObject<Domain>> {
    const requestBody = Helper.requestFormat(action, Domain.className(), params);
    return this.http.post<IResponseBodyObject<Domain>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  getLimit(params: any = {}): Observable<IResponseBodyObject<any>> {
    const requestBody = Helper.requestFormat('get_limit_value', 'DomainSettings', params);
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  sendEmail(params: any = {}): Observable<IResponseBodyObject<any>> {
    const requestBody = Helper.requestFormat('send', 'GSSmtp', params);
      return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
    .pipe(
      tap(res => {
        this.notifyService.checkCode(res);
      })
    );
  }

  get_tz(params={}) {
    // return of(this.notifyService.auth.getUserTZ());
    if (!this.notifyService.auth.getDomain()) {
      return of(this.notifyService.auth.getUserTZ());
    }
    const requestBody = Helper.requestFormat('get_shared_data', 'Domain', { id: this.notifyService.auth.getDomain(), ...params});
    return this.http.post<IResponseBodyObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        map(data => {
          return data.body?.tz || this.notifyService.auth.getUserTZ();
        })
      );
  }

  get_data(params: any = {}): Observable<any> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('get_session_data', 'Domain', params);
    return this.http.post<IResponseListObject<any>>(this.AppConfig.getValue('apiUrl'), requestBody, {headers: headers})
      .pipe(
        tap((res) => {
          if (res.code != 200 && (!res.notifies.length || res.notifies[0].msg_id != 10002)) this.notifyService.checkCode(res);
        })
      );
  }
}




