import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseListComponent} from '../../_helpers/base-list.component';
import {NotifyService} from '../../_helpers/notify.service';
import {TranslateService} from '@ngx-translate/core';
import {required, RxFormBuilder} from '@rxweb/reactive-form-validators';
import {CheckboxItem} from '../../_helpers/checkbox-item';
import {KnowledgebaseService, TreeService} from './knowledgebase.service';
import {FlatTreeControl, NestedTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource} from '@angular/material/tree';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {ISelect} from '../../_helpers/api.helpers';
import {CATEGORY_DOC, PERM_EDIT_LIST, ROLE_TYPE_DOMAIN_LIST, ROLE_TYPE_PROVIDER_LIST} from '../../_helpers/constant';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ConfirmComponent} from '../../dialogs/modals/confirm/confirm.component';
import {MediaMatcher} from '@angular/cdk/layout';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {RxFormHelpers} from '../../_helpers/form.helpers';
import {Markdown, MarkdownNode} from '../editor/markdown';
import {map} from 'rxjs/operators';
import {MatSidenav} from '@angular/material/sidenav';
import {StorageMap} from '@ngx-pwa/local-storage';
import {CTIService} from "../../cti-panel/cti-panel.service";


@Component({
  selector: 'app-knowledge-base-viewer',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './knowledgebase-list.component.html',
  styleUrls: [
    './knowledgebase-list.component.scss',
    '../../material-component/mat-table-responsive/mat-table-responsive.directive.scss'
  ],
  styles: [
    `:host ::ng-deep .highlight{
        background-color: #F2E366;
      }
  `],
  providers: [KnowledgebaseService]
})

export class KnowledgebaseListComponent implements OnInit, OnDestroy, AfterViewInit {
  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild('snav', { static: true }) snav: MatSidenav;
  @ViewChild('viewer') viewer;
  @Input()
  toggle: Subject<any>;
  sidebarOpened;
  mobileQuery: MediaQueryList;
  private readonly _mobileQueryListener: () => void;
  public width$ = new BehaviorSubject<number>(200);
  public article$ = new BehaviorSubject<number>(null);
  public markdown: FormGroup;
  public actions = {
    get_from_master: false
  };
  public search_id = null;
  public fieldTypes = [
    {tooltip: 'FILTER_KB_IN', icon: 'find_in_page'},
    {tooltip: 'FILTER_KB_IN_HEADER', icon: 'text_fields'},
    {tooltip: 'FILTER_KB_CONTENT', icon: 'search'},
    {tooltip: 'FILTER_KB_HEADER', icon: 'title'},
    {tooltip: 'FILTER_ALL', icon: 'search_all'},
  ];
  public treeFilter = '';
  public treeFilterArray = [];
  Nodes: Array<MarkdownNode>;
  selectedNode: MarkdownNode; // нужен для отображения детальной информации по выбранному узлу.
  readonly treeControl = new NestedTreeControl<any>(
    node => {
      let arr = [];
      if (this.hasChild(null, node)) arr.push(this.treeService.GetNodes(node.id, node.type));
      else arr.push(of([] as MarkdownNode[]));

      if (this.hasContent(null, node)) arr.push(this.markdownService.GetContent(node.id));
      else arr.push(of([] as Markdown[]));
      // @ts-ignore
      return forkJoin(arr).pipe(map(([s1, s2]) => [...s1, ...s2]))
    }
  );

  readonly dataSource$: BehaviorSubject<MarkdownNode[]>;
  readonly filterSource$: BehaviorSubject<any[]>;
  readonly treeSource: MatTreeNestedDataSource<MarkdownNode>;

  showFilter = false;
  isLoadingResults = false;
  isRateLimitReached = false;
  id: number;
  public storageName = 'MFC';

  filterSource = [];

  public expandStore = [];

  readonly hasChild = (_: number, node: MarkdownNode) => node.has_structure || node.has_content;
  readonly hasContent = (_: number, node: MarkdownNode) => node.has_content;
  readonly trackBy = (_: number, node: MarkdownNode) => node.id;
  PERM_EDIT_LIST = PERM_EDIT_LIST;

  constructor(fb: RxFormBuilder, translate: TranslateService, public notifyService: NotifyService, public treeService: TreeService,
              public markdownService: KnowledgebaseService,
              changeDetectorRef: ChangeDetectorRef,
              media: MediaMatcher, public storage: StorageMap, public CTI: CTIService,
              public router: Router, public route: ActivatedRoute, public dialog: MatDialog) {

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.treeSource = new MatTreeNestedDataSource<MarkdownNode>();
    this.dataSource$ = new BehaviorSubject<MarkdownNode[]>([]);
    this.storage.get(`${this.storageName}::DESKTOP::KNOWLEDGEBASE`).subscribe(
      (store: any) => {
        if (store?.width) this.width$.next(store.width);
        if (store?.path) {
          this.expandStore = JSON.parse(store.path);
        }
        if (store?.article) {
          this.article$.next(store.article);
        }
        this.updateSource();
      });

    this.filterSource$ = new BehaviorSubject<any[]>([]);

    this.dataSource$.subscribe(items => {
      this.treeSource.data = null;
      this.treeSource.data = items;
    });
    this.filterSource$.subscribe(items => {
      this.filterSource = items;
    });
    this.markdown = fb.formGroup(Markdown)
  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {

    this.toggle?.subscribe(event=> {
      //console.log(this.treeControl.dataNodes);
      this.snav.toggle().then(r => {});
    });

    // this.CTI.crmKnoweldge$.subscribe(data=>console.log(data))

  }

  hasCRM() {
    const ls = localStorage.getItem('desktopCRMEntityList');
    if (ls && JSON.parse(ls).length > 0) {
      return true
    }
    return false
  }

  editArticle() {
    this.storage.get(`${this.storageName}::DESKTOP::KNOWLEDGEBASE`).subscribe((data: any)=>{
      if (data?.article && data?.group_id) {
        this.router.navigate([
          '/domain-catalogs/knowledge-editor/content',
          data?.group_id, 'edit', data?.article
        ])
      }
    });
  }

  updateStorage(json) {
    this.storage.get(`${this.storageName}::DESKTOP::KNOWLEDGEBASE`).subscribe(data=>{
      const _data = data || {};
      const obj = Object.assign({}, _data, json);
      this.storage.set(`${this.storageName}::DESKTOP::KNOWLEDGEBASE`, obj).subscribe();
    });
  }

  clearStorage() {
    this.storage.delete(`${this.storageName}::DESKTOP::KNOWLEDGEBASE`).subscribe();
  }

  setWidth(width = 200) {
    this.updateStorage({width: width});
    this.width$.next(width);

  }

  expand(node) {
    console.log(this.treeSource.data);
    const currentNode = this.expandStore
      .find(storeNode=>storeNode.id == node.id && node.hasOwnProperty('has_content'));
    if (currentNode) {
      console.log(currentNode);

      const child$ = (this.treeControl.getChildren(node) as Observable<(MarkdownNode | Markdown)[]>)
        .subscribe(item => {
          item.forEach(elem => {
            console.log(elem);
            // @ts-ignore
           // this.dataSource$.next([...this.treeSource.data, elem]);
            if (elem.hasOwnProperty('has_content')) {
              this.expand(elem as MarkdownNode);
              this.treeControl.expandAll();
            }
          });
          //this.treeControl.expand(node);
          if (child$) child$.unsubscribe();
        })
    }
  }


  updateSource(_type= 0) {
    this.treeService.GetNodes(null, _type).subscribe(
      res =>    {
        this.dataSource$.next(res);


        //this.treeControl.expandAll();
        //res.forEach((node: MarkdownNode) => this.expand(node))
        if (this.article$.value) this.getMarkdown(this.article$.value, false);
        // this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
        // console.log(this.article$.value);
        // if (this.id) {
        //   this.getMarkdown(this.id);
        // }

        // else if (res.length>0) {
        //   (this.treeControl.getChildren(res[0]) as Observable<MarkdownNode[]>)
        //     .subscribe(item=>
        //       item.forEach(elem => {
        //         if (!this.id ) {
        //           this.getMarkdown(elem.id);
        //           this.id = elem.id;
        //         }
        //       })
        //     );
        // }

      },
      resp => this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502
    );
  }

  getMarkdown(id, update_storage= true){
    if (id) {
      if (typeof id === 'string') this.id = parseInt(id, 10);
      else this.id = id;
      this.markdownService.get(this.id).subscribe(data => {
          this.markdown.patchValue(data as Markdown);
          if (update_storage)
          {
            this.article$.next(this.id);
            this.updateStorage({
              article: data.id,
              group_id: data.parent_id,
              path: JSON.stringify(this.treeControl.expansionModel.selected)
            });
          }
          this.isLoadingResults = false;
        },
        resp => {
          this.clearStorage();
          this.isLoadingResults = false;
          this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
        });
      } else {
        this.isLoadingResults = false;
      }

  }

  ngOnDestroy() {
    this.dataSource$.complete();
    this.filterSource$.complete();
  }

  getSearchIcon(type) {
    return this.fieldTypes?.[type]
  }

  searchByType(filter, parent, type) {
    switch (type) {
      case 0:
        this.search(filter, parent, 'content')
        break;
      case 1:
        this.search(filter, parent, 'name')
        break;
      case 2:
        this.search(filter, null, 'content')
        break;
      case 3:
        this.search(filter, null, 'name')
        break;
      case 4:
        this.search(filter, null, 'all')
        break;
      default:
        this.search('')
        break;

    }
  }

  search(filter='', _parent=null, _field = 'content', _lang = 'RU' ) {
    //this.treeFilter = filter;
    if (filter.length>2) {
      this.markdownService.list({
        search: filter,
        parent_id: _parent,
        field: _field
      }, 'search').subscribe(res=> {
        this.treeFilterArray = (res.list || []).map(item=>item.id);
        this.filterSource$.next(res.list);

          this.treeFilter = res.list.map((item: any) => (item.tsq || '').replace(/["']/g,'')).join(' ');

      }, resp => {
        this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
      })
    } else {this.filterSource$.next([]);}

  }

  doAction(evt, action){
    this.treeService.action(action).subscribe(
      data => {
        if (data.code == 200) {
            setTimeout(() => {
              this.actions[action] = false;
              this.notifyService.message('NOTIFY.200');
              this.updateSource();
            }, 1500);
        } else this.actions[action] = false;
      },
      resp => {
        this.isRateLimitReached = this.notifyService.setFormErrors(resp) == 502;
        this.actions[action] = false;
      }
    );


  }

  openFilter(filterInput) {
    this.showFilter = !this.showFilter;
    window.setTimeout(() => filterInput.focus(), 0);
  }

  inGroup(id) {
    return false; //!!this.expandStore.find(item=>item.id == id);
  }
}
