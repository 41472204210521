import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IResponseListObject} from '../../_helpers/api.helpers';
import {Observable} from 'rxjs';
import {NotifyService} from '../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService} from '../../_helpers/base.service';
import {AuthenticationService} from '../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../config/json-app-config.service';
import {DomainHideContactCall} from './domain-hide-contact-call';


@Injectable({
  providedIn: 'root'
})

export class DomainHideContactCallService extends BaseService<DomainHideContactCall> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService) {
    super(DomainHideContactCall.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  list(params: any = {}): Observable<IResponseListObject<DomainHideContactCall>> {
    params['sort'] = {dt: '-'}
    return super.list(params);
  }
}
