import {KnowledgebaseListComponent} from './knowledgebase-list.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SkeletonLoaderModule} from '../../_helpers/skeleton-loader.module';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MaterialModule} from '../../material.module';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {NgArrayPipesModule} from 'ngx-pipes';
import {PipeModule} from '../../_helpers/pipe.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {MatTreeModule} from '@angular/material/tree';
import {MarkdownModule, MarkedOptions} from 'ngx-markdown';
import {HttpClient} from "@angular/common/http";
import {markedOptionsFactory} from "../../_helpers/base.function";
import {UserService} from "../../users/user.service";

@NgModule({
  declarations: [
    KnowledgebaseListComponent
  ],
  imports: [
    CommonModule,
    SkeletonLoaderModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    MatSortModule,
    MaterialModule,
    FlexModule,
    TranslateModule.forChild(),
    FlexLayoutModule,
    NgArrayPipesModule,
    PipeModule,
    NgSelectModule,
    PerfectScrollbarModule,
    MatTreeModule,
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        deps: [ UserService ],
        useFactory: markedOptionsFactory,
      },
    })
  ],
  providers: [],
  exports: [
    KnowledgebaseListComponent
  ]
})
export class KnowledgebaseModule {}
