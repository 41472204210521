import { NgModule } from '@angular/core';
import {MaterialModule} from '../../material.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {CdkTableModule} from '@angular/cdk/table';
import {PipeModule} from '../../_helpers/pipe.module';
import {FieldComponent} from '../../_helpers/dynamic/field.component';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        CdkTableModule,
        PipeModule
    ],
    declarations: [FieldComponent],
    exports: [FieldComponent]
})
export class FieldModule {}
