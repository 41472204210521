import {
  propObject,
  prop,
  alphaNumeric, alpha, compare, contains, creditCard, digit, email, greaterThanEqualTo,
  greaterThan, hexColor, json, lessThanEqualTo, lowerCase, maxLength, maxNumber,
  minNumber, password, pattern,
  lessThan, range, required, time, upperCase, url, propArray, minLength, minDate, maxDate, toBoolean, unique, port, oneOf
} from '@rxweb/reactive-form-validators';
import {LSAudioList} from '../../../local-stream/local-stream';

export class DPCVoiceMail {
  @prop() name: string = '';
  @prop({defaultValue: []})
  groups = [];
  @prop({defaultValue: []})
  users = [];
  @prop({defaultValue: []})
  emails = [];
  @digit() @minNumber({value: 10}) @maxNumber({value: 600})
  max_len_sec: number = 180;
  @propObject(LSAudioList)
  audio_list: LSAudioList;

}
