import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'users'
})

export class UsersStatusPipe implements PipeTransform {
  transform(json: any): string {
    const user_badges = document.createElement('div');

    if (json) {
      try {
        user_badges.innerHTML = '';
        let limit_badge = `<div class="badge bg-light-primary">${json.limit}</div>`;
        let remaining_badge = `<div class="badge bg-yellow">${json.limit-json.enabled-json.disabled}</div>`;
        let enabled_badge = `<div class="badge bg-green">${json.enabled}</div>`;
        user_badges.innerHTML = limit_badge + remaining_badge + enabled_badge;
      } catch(e) {
      }
    }

    return user_badges.outerHTML;
  }
}
