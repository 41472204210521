import {Component, Input, OnInit} from "@angular/core";
import {PerfectScrollbarConfigInterface} from "ngx-perfect-scrollbar";
import {DOCDialogService} from "../domain-omni-channel/doc-dialog/doc-dialog.service";
import {DomainService} from "../domain.service";
declare var require: any;
const jsonpath = require('jsonpath-plus');

interface DetailFormViewData {detail_form: any, long_detail_form: any, omni_customer: any, omni_dialog: any, call_session: any}

@Component({
  selector: 'app-domain-detail-form-view',
  templateUrl: './domain-detail-form-view.component.html',
  styles: [`
    .mat-sidenav-content {
      margin-right: 33%;
    }
    .h30{
      height: 30px;
    }
    .h30.DomainDetailFormRowLine{
      height: 0;
    }
    .DomainDetailFormRowLine ::ng-deep .mat-list-item-content{
      width: 100%;
      padding: 0;
      height: 0;
    }
    .DomainDetailFormRowBlock ::ng-deep .mat-list-item-content{
      width: 100%;
      padding: 0;
    }
  `],
  styleUrls: ['../../material-component/mat-table-responsive/mat-table-responsive.directive.scss']
})
export class DomainDetailFormViewComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {useBothWheelAxes: false, suppressScrollX: true, suppressScrollY: false};
  public JSONPath = jsonpath.JSONPath;
  public isLoadingResults: boolean = true;

  // place - вид отображения формы:
  //  - 0 - обычная форма (один блок - отображается справа от основной части); detail_form
  //  - 1 - расширенная форма (линии с блоками - отображается в основной части); long_detail_form
  @Input() place: number;
  @Input() dialog_id: number;
  @Input() detailFormViewData: DetailFormViewData;

  constructor(
    public api: DOCDialogService,
    public domainService: DomainService
  ) {
  }

  ngOnInit() {
    if (!this.detailFormViewData) {
      this.domainService.get_data({obj_ident: this.dialog_id, source_type: 'omni', place: this.place}).subscribe(
        data => {
          if (data.body) {
            this.detailFormViewData = data.body;
          }
        }
      );
    }
  }

  getWidth(len) {
    return 'calc(' + (100/len) + '% - 20px)';
  }

  getDisplayedColumns(fieldTable) {
    return fieldTable.field_list.map(column => column.path);
  }
}
