import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RxFormBuilder} from '@rxweb/reactive-form-validators';
import {Helper, IResponseBodyObject, IResponseListObject} from '../../../_helpers/api.helpers';
import {Observable} from 'rxjs';
import {generateOrderedGUID} from '../../../_helpers/base.function';
import {NotifyService} from '../../../_helpers/notify.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseService, headers} from '../../../_helpers/base.service';
import {AuthenticationService} from '../../../auth/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {JsonAppConfigService} from '../../../config/json-app-config.service';
import {
  DDSEBase, DDSEBaseParams,
  DDSEForm, DDSEFormParams, DDSEMenu, DDSEMenuConditions, DDSEMenuParams,
  DDSESetVar, DDSESetVarParams,
  DomainDialogScript, MultiLegs

} from './domain-dialog-script';
import {map, tap} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})


export class DomainDialogScriptService extends BaseService<DomainDialogScript> {
  constructor(http: HttpClient, notifyService: NotifyService,
              router: Router, route: ActivatedRoute, authenticationService: AuthenticationService,
              public translate: TranslateService, AppConfig: JsonAppConfigService, protected fb: RxFormBuilder) {
    super(DomainDialogScript.className(), http, notifyService, router, route, authenticationService, AppConfig);
  }

  list(params: any = {}, action = 'list'): Observable<IResponseListObject<DomainDialogScript>> {
     if (!('sort' in params)) params['sort'] = {name: '+'};
    return super.list(params, action);
  }

  get<P=DomainDialogScript>(id: number | string | null, params: any = {}, action: string = 'get'): Observable<P | DomainDialogScript> {
    let ignore = {};
    if (params.ignore) {
      ignore = {...params.ignore};
      delete params['ignore'];
    }
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue?.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, params);
    return this.http.post<IResponseBodyObject<P | DomainDialogScript>>( this.AppConfig.getValue('apiUrl') + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          if (res.code != 200 && (!res.notifies.length || !(ignore[res.notifies[0].msg_id]))) this.notifyService.checkCode(res);
        }),
        map(data => {
          this.changeObjectName(data.body);
          return data.body as (P |DomainDialogScript);
        }),
        // catchError(() => observableOf({} as T))
      );
  }

  getLabel(controlValue) {
    switch (controlValue.model_name) {
      case 'DDSESetVar': return controlValue.params?.var_name;
      default: return controlValue.params?.name;
    }
  }

  getNextUID(uids) {
    if (uids.length > 0) {
      let lastUid = uids.sort()[uids.length -1].substring(0, 8);
      let lastUidInt: number;
      try {
        lastUidInt = parseInt(lastUid, 10);
        if (parseInt(lastUid.substring(0, 8 - lastUidInt.toString().length), 10) == 0) return generateOrderedGUID(lastUidInt + 1);
      } catch (e) {}
    }
    return generateOrderedGUID(uids.length + 1);
  }

  legModel(model_name) {
    let model;
    switch(model_name) {
      /* Одноножки */
      case 'DDSEForm':
        model = new DDSEForm();
        model.params = new DDSEFormParams();
        break;
      case 'DDSESetVar':
        model = new DDSESetVar();
        model.params = new DDSESetVarParams();
        break;

      /* Многоножки */
      case 'DDSEMenu':
        model = new DDSEMenu();
        model.params = new DDSEMenuParams();
        model.params.conditions = new DDSEMenuConditions();
        model.default_leg = new DDSEBase();
        model.legs = new MultiLegs();
        break;

      default:
        model = new DDSEBase();
        model.params = new DDSEBaseParams();
    }
    let modelRx;
    if (model) {
      modelRx = this.fb.formGroup(model);
      modelRx.setControl('params', this.fb.formGroup(model.params));
      if (
        (
          model_name == 'DDSEMenu'
        ) &&
        modelRx.get(['params', 'conditions', 'deletingKey'])
      ) {
        modelRx.get(['params', 'conditions']).removeControl('deletingKey');
      }
      if (model.default_leg) modelRx.setControl('default_leg', this.fb.formGroup(model.default_leg));
      if (model.legs) {
        modelRx.setControl('legs', this.fb.formGroup(model.legs));
        if (modelRx.get('legs')?.get('deletingKey')) modelRx.get('legs').removeControl('deletingKey');
      }
    }
    return modelRx;
  }

  get_from_call(params: any = {}): Observable<IResponseBodyObject<any>> {
    if (this.authenticationService.currentUserValue?.domain_id) {
      params['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat('get_from_call', this.objectName, params);
    return this.http.post<IResponseBodyObject<any>>( this.AppConfig.getValue('apiUrl') + this.extraPath, requestBody, {headers: headers})
      .pipe(
        tap(res => {
          this.notifyService.checkCode(res);
        })
      );
  }

  paramsModel(model_name) {
    return this.legModel(model_name).get('params');
  }

  details(row, state?: any) {
    this.router.navigate([this.router.url + '/edit', row.id, row.ver], {state: state});
  }

  delete(row, action: string = 'delete'): Observable<IResponseBodyObject<DomainDialogScript>>  {
    const obj = {id: row.id, ver: row.ver};
    if (this.authenticationService.currentUserValue?.domain_id) {
      obj['domain_id'] = this.authenticationService.currentUserValue.domain_id;
    }
    const requestBody = Helper.requestFormat(action, this.objectName, obj);
    return this.http.post<IResponseBodyObject<DomainDialogScript>>( this.AppConfig.getValue('apiUrl') + this.extraPath, requestBody, {headers: headers})
      .pipe(tap(res => {this.notifyService.checkCode(res);}));
  }
}
