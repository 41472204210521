import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-tech-work',
  templateUrl: './tech-work.component.html',
  styleUrls: ['./tech-work.scss']
})
export class TechWorkComponent implements OnInit, OnDestroy {
  public timeSec: number = 10;
  private timer;
  private timeOut;
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // таймер времени на странице:
    this.timer = setInterval(() => {
      if (this.timeSec > 0) --this.timeSec;
    }, 1000);

    // проверка доступности АПИ через timeSec-секунд
    this.timeOut = setTimeout(() => this.checkStatus(), this.timeSec * 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
    clearTimeout(this.timeOut);
  }

  checkStatus() {
    let path = this.route.snapshot.paramMap.get('nextUrl') ? decodeURIComponent(this.route.snapshot.paramMap.get('nextUrl')) : '/';
    window.location.href = path;
    if (path.split('?').length > 1) this.router.navigateByUrl(path);
    else this.router.navigate([path]);
  }
}
