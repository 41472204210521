import 'hammerjs';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import { SkeletonLoaderModule } from '../../_helpers/skeleton-loader.module';
import { MaterialModule } from '../../material.module';
import { CdkTableModule } from '@angular/cdk/table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from '../../_helpers/pipe.module';
import { MatTableResponsiveModule } from '../../material-component/mat-table-responsive/mat-table-responsive.module';
import {DomainDetailFormViewComponent} from "./domain-detail-form-view.component";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkTableModule,
    PipeModule,
    MatTableResponsiveModule,
    SkeletonLoaderModule,
    NgSelectModule,
    PerfectScrollbarModule,
    NgxJsonViewerModule
  ],
  providers: [],
  exports: [
    DomainDetailFormViewComponent
  ],
  declarations: [DomainDetailFormViewComponent]
})
export class DomainExtraDetailFormModule {}
