import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {BehaviorSubject, of as observableOf, SubscriptionLike} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {catchError, tap, filter} from 'rxjs/operators';
import {AppService} from '../../../../../app.service';
import {DesktopKeptService} from '../../desktop-kept.service';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {NotifyService} from '../../../../../_helpers/notify.service';
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
import {DesktopColumns} from '../../../../_common/desktop-columns';
import {DEPS4BService} from '../../../../../domains/marketplace/skype-for-business/s4b.service';
import {SendMailDialogComponent} from '../send-mail-dialog/send-mail-dialog.component';
import {CTIService} from "../../../../../cti-panel/cti-panel.service";

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-open-da-dialog',
  template: `
    <h1 mat-dialog-title>{{'DA_NAME'|translate}}<span fxFlex></span>
      <mat-icon color="primary" (click)="dialogOpenDA.close()" style="cursor: pointer">close</mat-icon>
    </h1>
    <mat-dialog-content>
      <div fxFlex.gt-md="100" fxFlex.gt-sm="100" fxFlex="100" class="mat-card-top chat-app">
        <div class="example-container">
          <div class="example-loading-shade h-10">
            <mat-progress-bar *ngIf="isLoadingResults" mode="indeterminate" color="primary"></mat-progress-bar>
            <div class="example-rate-limit-reached text-warning" *ngIf="isRateLimitReached"
                 [translate]="'NOTIFY.502'"></div>
          </div>
          <div class="ps table-container" [perfectScrollbar]="config">
            <table mat-table [dataSource]="dataSource" class="table" matSort matSortActive="id"
                   matTableResponsive
                   matSortDisableClear matSortDirection="asc">
              <ng-container *ngFor="let column of columns">
                <ng-container matColumnDef="{{column.id}}">
                  <th mat-header-cell *matHeaderCellDef>{{ (column.name || '') |translate }}</th>
                  <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="column.id == 'name'">
                      <div class="skype-mail-padding">
                        <ng-container *ngIf="column.id=='name' && row['s4b_status']">
                          <button class="shadow-none fl table-button" matTooltipClass="mat-tooltip-multi-line"
                                  matTooltip="{{row['s4b_status']['state_name']}}"
                                  [style]="{ 'background-color': (getIcon(row['s4b_status']['state_id'])[1]) }">
                            <mat-icon *ngIf="getIcon(row['s4b_status']['state_id'])[0]">
                              {{getIcon(row['s4b_status']['state_id'])[0]}}
                            </mat-icon>
                          </button>
                        </ng-container>
                        <ng-container *ngIf="column.id=='name' && row['email']">
                          <button class="text-white  shadow-none fr mail table-button" matTooltipClass="mat-tooltip-multi-line"
                                  (click) = "sendMail(row['id'])" [disabled]="!CTI.ctiUser$.value"
                                  [class.bg-primary]="CTI.ctiUser$.value"
                                  [matTooltip]="'SEND_MAIL' | translate">
                            <mat-icon>mail</mat-icon>
                          </button>
                        </ng-container>
                      </div>
                      {{row[column.id]!='NULL' && row[column.id]!=null && row[column.id] || ''}}
                    </ng-container>
                    <ng-container *ngIf="['position', 'country', 'office', 'local_name'].includes(column.id)">
                      {{row[column.id] || ''}}
                    </ng-container>
                    <ng-container *ngIf="['ext', 'mobile'].includes(column.id)">
                        <div class="flex-button">
                          <span>{{row[column.id]!='NULL' && row[column.id]!=null && row[column.id] || ''}}</span>
                          <button *ngIf="(column.id=='ext' && row['ext']!='NULL' && row['ext']!=null)"
                                  [style.cursor]="'pointer'" class="shadow-none table-button" [matTooltip]="'MAKE_CALL'|translate"
                                  [style]="{ 'background-color': 'green' }"
                       (click)="CTI.ctiActions$.next({obj: 'call', action: 'makeCall', number: row['ext'], name: row['name']});">
                            <mat-icon color="white">call</mat-icon>
                          </button>
                          <button *ngIf="(column.id=='mobile' && row['mobile']!='NULL' && row['mobile']!=null && row['mobile']?.length>2)"
                                  [style.cursor]="'pointer'" class="shadow-none table-button" [matTooltip]="'MAKE_CALL'|translate"
                                  [style]="{ 'background-color': 'green' }"
                       (click)="CTI.ctiActions$.next({obj: 'call', action: 'makeCall', number: row['mobile'], name: row['name']});">
                            <mat-icon color="white">call</mat-icon>
                          </button>
                        </div>
                    </ng-container>
                  </td>
                </ng-container>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              style="cursor: pointer;" ></tr>

            </table>
          </div>
        </div>
      </div>
    </mat-dialog-content>
  `,
  styleUrls: ['./open-da-dialog.scss'],
  styles: []
})

export class OpenDADialogComponent implements OnDestroy, OnInit {
  @ViewChild(MatTable) table: MatTable<any>;
  msg: string;
  displayedColumns: string[] = [];
  displayedColFilter: string[] = [];
  activeCalls = [];
  selectedRowIndex:any;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  public config: PerfectScrollbarConfigInterface = {
    minScrollbarLength: 25,
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
    wheelSpeed: 2,
    wheelPropagation: true
  };
  public columns = [
    new DesktopColumns('name', 'SD_NAME', true),
    new DesktopColumns('ext', 'SD_EXT', true),
    new DesktopColumns('mobile', 'SD_MOBILE', true),
    new DesktopColumns('position', 'SD_POS', true),
    new DesktopColumns('country', 'SD_COUNTRY', true),
    new DesktopColumns('office', 'SD_OFFICE', true),
    new DesktopColumns('local_name', 'SD_LOCAL_NAME', true),

  ];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  extParams = {

  };
  empFilters = [];
  filter = {};
  public skype_users = [];
  public skype_cir: {[key: string]: any } = {};
  constructor(
    public dialogOpenDA: MatDialogRef<OpenDADialogComponent>,
    public appService: AppService,
    public api: DesktopKeptService,
    public CTI: CTIService,
    public notifyService: NotifyService,
    public translate: TranslateService,
    public s4bService: DEPS4BService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.displayedColumns = this.columns.filter(item=>item.checked).map(item=>item.id);
    this.extParams = {
      business_unit: data.business_unit
    };
    this.skype_users = data.skype_users;
    this.appService.on().pipe(filter((msg: any)=>msg.action=='update_state'&&msg.obj=='MicrosoftSkypeForBusinessMember'))
      .subscribe(
      data=> {
        this.dataSource.data.filter(item=>item?.sip_address?.toLowerCase() == data?.body?.user?.toLowerCase())
          .forEach(item=>item.s4b_status = data.body);
        if (this.table) this.table.renderRows();
      }
    )
  }

  sendMail(id: number) {
    this.dialogOpenDA.close()
    const dialogRef = this.dialog.open(SendMailDialogComponent, {panelClass: 'dialog-compact',
      data: {
        id: id
      }
    });

  }


  ngOnInit() {
    this.refresh();
  }

  ngOnDestroy(): void {

  }


  highlight(row){
    return row;
  }

  getIcon(number) {
    return this.s4bService.getSkypeIcon(number);
  }

  isOver(): boolean {
    return window.matchMedia('(max-width: 960px)').matches;
  }


  refresh() {
    this.isLoadingResults = true;
    this.api['getDA']({
      limit: 30,
      sort: {dt: '-'},
      offset: 0,
      ...this.extParams,
      ...this.filter
    }).pipe(
      tap(res => {
        this.notifyService.checkCode(res, false);
      }),
      catchError(resp => {
        this.dataSource.data = [];
        this.isLoadingResults = false;
        this.isRateLimitReached = false;
        this.resultsLength = 0;

        let code = this.notifyService.setFormErrors(resp);
        return observableOf({list: [], total_count: 0, code: code});
      })
    ).subscribe((item: any) => {
      this.dataSource.data = item?.body?.da_list || [];
      this.s4bService.changeSkypeChannel([...new Set([
        ...this.dataSource.data.filter(item=>!!item?.sip_address).map(item=>item?.sip_address?.toLowerCase()),
        ...this.skype_users
      ])]);
      this.isLoadingResults = false;
      this.isRateLimitReached = false;
      this.resultsLength = item?.body?.da_list?.length || 0;
    });
  }
}
