import 'hammerjs';
import {NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import {CTIPanelComponent} from "./cti-panel.component";
import {ModalBoxModule} from "../modal-box/modal-box.module";
import {RxReactiveFormsModule} from "@rxweb/reactive-form-validators";
import {NgSelectModule} from "@ng-select/ng-select";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {RouterModule} from "@angular/router";
import {MainChatModule} from "../chat/main-chat.module";
import {MatTreeModule} from "@angular/material/tree";
import {AvatarModule} from "ngx-avatar-2";


@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        ModalBoxModule,
        NgSelectModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        RouterModule,
        MainChatModule,
        MatTreeModule,
        AvatarModule
    ],
  exports: [
    CTIPanelComponent
  ],
  declarations: [
    CTIPanelComponent
  ]
})
export class CTIPanelModule {
  constructor() {}
}
