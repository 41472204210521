export class DesktopColumns {
  constructor(
    public id: any,
    public name: any,
    public checked: boolean = false,
    public type: string = 'field',
    public filterType: string = 'empty',
    public filterOptions: any[] = [],
    public synName: string | null = null
    ) {}
}

/*
externalClientsColumns = [
  new DesktopColumns('name', 'EC_NAME', true),
  new CheckboxItem('id', 'EC_ID', false),
  new CheckboxItem('ext_id', 'EC_EXT', false),
  new DesktopColumns('phone', 'EC_PHONE', true),
  new DesktopColumns('comment', 'EC_COMMENT', true)
];*/
